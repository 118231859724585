import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';

interface Props {
  elements: number[];
  selectedElement?: number;
  setSelectedElement: (newSelection: number) => void;
  majorVersion?: number;
}

export default function VersionSelector({
  elements,
  selectedElement,
  setSelectedElement,
  majorVersion,
}: Props): JSX.Element {
  const sortedElements = [...elements].sort((a, b) => b - a);
  const lastElement = elements[sortedElements.length - 1];
  const defaultValue =
    selectedElement ??
    (elements.length > 0 ? elements[elements.length - 1] : '');
  const renderVersion = (version: number) =>
    majorVersion !== undefined
      ? `v${majorVersion}.${version}`
      : `v${version}.0`;

  return (
    <Select
      defaultValue={defaultValue.toString()}
      value={selectedElement?.toString() ?? ''}
      onValueChange={(value: string) => setSelectedElement(parseInt(value, 10))}
    >
      <SelectTrigger className="w-[120px] px-2 py-1 h-9">
        <SelectValue placeholder="Select a fruit" />
      </SelectTrigger>
      <SelectContent className="border-dark-blue-300">
        {sortedElements.length > 0 ? (
          sortedElements.map(element => (
            <SelectItem
              key={element.toString()}
              value={element.toString()}
              className="focus:bg-dark-blue-300 cursor-pointer"
            >
              {element === lastElement
                ? `Latest -- ${renderVersion(element)}`
                : renderVersion(element)}
            </SelectItem>
          ))
        ) : (
          <SelectItem disabled value="No Version Available">
            No version available
          </SelectItem>
        )}
      </SelectContent>
    </Select>
  );
}

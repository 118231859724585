import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { GET_LOCATIONS } from '../TreeVisualizerGraph';
import { defaultAppliedRules } from '../TreeSettingsAtoms';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { Button } from '@mui/material';

interface Props {
  experimentId: string;
  onClose: () => void;
  showConfirmationDialog: boolean;
}

const EXPERIMENT_DELETION = gql(`
mutation deleteExperiment($experimentId: String!, $projectId: String!) {
  deleteExperiment(experimentId: $experimentId, projectId: $projectId) {
    experiment {
      id
    }
  }
}
`);

export default function ExperimentDeletionDialog({
  experimentId,
  onClose,
  showConfirmationDialog,
}: Props) {
  const { projectId } = useParams();
  const appliedRules = useRecoilValue(defaultAppliedRules);

  const [commitExperimentDeletionMutation] = useMutation(EXPERIMENT_DELETION, {
    refetchQueries: [
      {
        query: GET_LOCATIONS,
        variables: { project_id: projectId, filters: appliedRules },
      },
    ],
  });

  const notifyDeletionSaveSuccess = () =>
    toast.success('Deletion successful.', {
      position: 'top-center',
    });

  const notifyDeletionSaveFailed = () =>
    toast.error('Error deleting experiment.', {
      position: 'top-center',
    });

  return (
    <ConfirmationDialog
      onClose={onClose}
      showConfirmationDialog={showConfirmationDialog}
      title={`Delete experiment ${experimentId}.`}
      text={`Are you sure want to delete the experiment ${experimentId}? <br />
    This deletes the experiment along with all of the related data
    including documentations generated from this experiment.`}
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>

          <Button
            size="small"
            variant="contained"
            onClick={() => {
              commitExperimentDeletionMutation({
                variables: {
                  experimentId,
                  projectId,
                },
                onCompleted: () => {
                  notifyDeletionSaveSuccess();
                  onClose();
                },
                onError: () => {
                  notifyDeletionSaveFailed();
                },
              });
            }}
            autoFocus
          >
            Save & Close
          </Button>
        </>
      }
    />
  );
}

import { Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { ProjectType } from '../../__generated__/gql/graphql';
import { Button } from '../../components/ui/button';

interface ModelTypeStageProps {
  onClick: () => void;
}
export default function ModelTypeStage({ onClick }: ModelTypeStageProps) {
  const { setValue } = useFormContext();

  const onClickHandler = (modelType: ProjectType) => {
    setValue('purchasedModel', modelType);
    onClick();
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box>
        <h2>Choose your purchased model or create a custom one</h2>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            marginTop: '2rem',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          {Object.entries(ProjectType)
            .filter(([key]) => key !== 'Custom')
            .map(([key, value]) => (
              <Controller
                key={key}
                name="purchasedModel"
                render={({ field: _field }) => (
                  <Button
                    variant={'outline'}
                    className="h-22 w-30 break-words whitespace-normal"
                    onClick={() =>
                      onClickHandler(
                        ProjectType[key as keyof typeof ProjectType]
                      )
                    }
                  >
                    {ModelTypeEnumyMap[value]}
                  </Button>
                )}
              />
            ))}
        </Box>
      </Box>
    </Box>
  );
}

const ModelTypeEnumyMap: { [key: string]: string } = {
  CHATGPT: 'ChatGPT',
  GITHUB_COPILOT: 'GitHub Copilot',
  MICROSOFT_COPILOT: 'Microsoft Copilot',
  OTHER: 'Other',
  CUSTOM: 'Custom',
};

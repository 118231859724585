import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { ChevronLeft } from 'lucide-react';
import { useProject } from '../contexts/project';
import { toast } from 'sonner';
import {
  DocumentationTemplate,
  DocumentationTemplates,
} from './documentation_view/tabs/template/DocumentationTemplates';
import { DocumentationEntry } from './documentation_view/DocumentationPageStates';
import {
  DocumentationScope,
  DocumentComponentType,
  DataSource,
  ComponentCategory,
  DocumentationType,
} from '../__generated__/gql/graphql';
import { gql, useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import {
  DocumentComponentNameMap,
  CUSTOM_COMPONENT_TYPES,
} from './documentation_view/tabs/components/ComponentNameMapping';
import { Spinner } from '@/components/ui/spinner';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';

const CREATE_DOCUMENTATION_MUTATION = gql(`
  mutation CreateTemplatedDocumentation(
    $name: String!
    $documentationType: DocumentationType!
    $projectId: String
    $components: [DocumentComponentInput!]!
    $documentCreator: String!
    $documentationScope: DocumentationScope!
  ) {
    createDocumentation(
      name: $name
      documentationType: $documentationType
      projectId: $projectId
      components: $components
      documentCreator: $documentCreator
      documentationScope: $documentationScope
    ) {
      documentation {
        __typename
        id
        name
        documentationType
      }
    }
  }
`);

export default function TemplateSelectionPage() {
  const navigate = useNavigate();
  const { project } = useProject();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] =
    useState<DocumentationTemplate | null>(null);
  const [documentTitle, setDocumentTitle] = useState<string>('New Document');
  const [components, setComponents] = useState<DocumentationEntry[]>([]);

  const [createDocumentation, { loading: isCreatingLoading }] = useMutation(
    CREATE_DOCUMENTATION_MUTATION,
    {
      update(cache, { data: { createDocumentation } }) {
        const newDocumentation = createDocumentation.documentation;
        cache.modify({
          fields: {
            allDocumentations(existingDocumentationRefs = []) {
              const newDocumentationRef = cache.writeFragment({
                data: newDocumentation,
                fragment: gql`
                  fragment NewDocumentation on Documentation {
                    id
                    name
                    project {
                      title
                    }
                  }
                `,
              });
              return [...existingDocumentationRefs, newDocumentationRef];
            },
          },
        });
      },
      onCompleted: data => {
        navigate(
          `/documentation/${data.createDocumentation.documentation.id}?view=components`
        );
      },
    }
  );

  const handleTemplateSelection = (template: DocumentationTemplate) => {
    setSelectedTemplate(template);
    setDocumentTitle(template.title);

    // Convert template components to document entries with proper handling of customArguments
    const newComponents = template.components.map(component => ({
      id: uuidv4(),
      type: component.componentType || DocumentComponentType.LegalDisclaimer,
      name: component.name || '',
      query: component.query || '',
      args: component.args || [],
      dataSource: component.dataSource || DataSource.Text,
      category: component.category,
      customArguments: component.customArguments
        ? typeof component.customArguments === 'string'
          ? JSON.parse(component.customArguments)
          : component.customArguments
        : undefined,
    }));

    setComponents(newComponents);
  };

  const handleCreateDocumentation = async () => {
    if (!selectedTemplate) {
      toast.error('Please select a template');
      return;
    }

    try {
      await createDocumentation({
        variables: {
          name: documentTitle,
          documentationType:
            selectedTemplate?.documentationType ?? DocumentationType.Generated,
          projectId:
            selectedTemplate?.documentationScope ===
            DocumentationScope.Organization
              ? undefined
              : project?.id,
          components: components.map(item => ({
            query: item.query ?? '',
            dataSource: item.dataSource ?? DataSource.Code,
            category:
              (item.category as ComponentCategory) ?? ComponentCategory.General,
            componentType: item.type,
            args: item.args ?? [],
            name: item.name ?? '',
            customArguments: item.customArguments
              ? JSON.stringify(item.customArguments)
              : '',
          })),
          documentCreator: 'USER',
          documentationScope: selectedTemplate?.documentationScope,
        },
      });
      toast.success('Documentation created successfully');
    } catch (error) {
      console.error('Error creating documentation:', error);
      toast.error('Failed to create documentation');
    }
  };

  const filteredTemplates = DocumentationTemplates.filter(template =>
    template.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const orgTemplates = filteredTemplates.filter(
    template => template.documentationScope === DocumentationScope.Organization
  );

  const projectTemplates = project
    ? filteredTemplates.filter(
        template => template.documentationScope === DocumentationScope.Project
      )
    : [];

  const experimentTemplates = project
    ? filteredTemplates.filter(
        template =>
          template.documentationScope === DocumentationScope.Experiment
      )
    : [];

  const hasHiddenProjectTemplates =
    !project &&
    filteredTemplates.some(
      template => template.documentationScope === DocumentationScope.Project
    );

  const hasHiddenExperimentTemplates =
    !project &&
    filteredTemplates.some(
      template => template.documentationScope === DocumentationScope.Experiment
    );

  return (
    <div className="flex flex-col h-full w-full overflow-hidden">
      <div className="p-4 flex justify-between items-center flex-shrink-0">
        <div className="flex items-center">
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => navigate('/documentations')}
          >
            <ChevronLeft size={24} className="mr-2" />
            Back to Documentation Overview
          </Button>
        </div>
      </div>

      <div className="flex flex-col flex-1 px-4 pb-4 overflow-hidden">
        <div className="grid grid-cols-12 gap-3 w-full h-full min-h-0">
          <div className="col-span-5 h-full overflow-hidden flex flex-col">
            <div className="flex justify-between items-center mb-3 flex-shrink-0">
              <h3 className="text-2xl font-bold">Select a Template</h3>

              <div className="relative">
                {showSearch ? (
                  <div className="flex items-center border rounded-md px-2 min-w-[200px] bg-background">
                    <SearchIcon />
                    <Input
                      id="search"
                      type="text"
                      value={searchTerm}
                      autoFocus
                      className="border-none focus-visible:ring-0 focus-visible:outline-none"
                      placeholder="Search templates..."
                      onChange={e => setSearchTerm(e.target.value)}
                      onBlur={() => {
                        if (!searchTerm) {
                          setShowSearch(false);
                        }
                      }}
                    />
                    {searchTerm && (
                      <Button
                        variant="ghost"
                        size="sm"
                        className="h-8 w-8 p-0 ml-1"
                        onClick={() => {
                          setSearchTerm('');
                          setShowSearch(false);
                        }}
                      >
                        ×
                      </Button>
                    )}
                  </div>
                ) : (
                  <Button
                    variant="outline"
                    size="sm"
                    className="flex items-center gap-1"
                    onClick={() => setShowSearch(true)}
                  >
                    <SearchIcon fontSize="small" />
                    Search
                  </Button>
                )}
              </div>
            </div>

            <div className="flex-1 border rounded-md overflow-hidden">
              <ScrollArea className="h-full">
                <div className="p-3 pr-5">
                  <h4 className="text-lg font-semibold">
                    Organizational Wide Templates
                  </h4>
                  <Separator className="my-2" />
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-6">
                    {orgTemplates.map(template => (
                      <TemplateCard
                        key={template.id}
                        template={template}
                        isSelected={selectedTemplate?.id === template.id}
                        onSelect={handleTemplateSelection}
                      />
                    ))}
                  </div>

                  {project && (
                    <>
                      <h4 className="text-lg font-semibold mt-4">
                        Project Wide Templates
                      </h4>
                      <Separator className="my-2" />
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-6">
                        {projectTemplates.map(template => (
                          <TemplateCard
                            key={template.id}
                            template={template}
                            isSelected={selectedTemplate?.id === template.id}
                            onSelect={handleTemplateSelection}
                          />
                        ))}
                      </div>

                      <h4 className="text-lg font-semibold mt-4">
                        Experiment Wide Templates
                      </h4>
                      <Separator className="my-2" />
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-6">
                        {experimentTemplates.map(template => (
                          <TemplateCard
                            key={template.id}
                            template={template}
                            isSelected={selectedTemplate?.id === template.id}
                            onSelect={handleTemplateSelection}
                          />
                        ))}
                      </div>
                    </>
                  )}

                  {(hasHiddenProjectTemplates ||
                    hasHiddenExperimentTemplates) &&
                    !project && (
                      <div className="mt-4">
                        <Card className="bg-dark-blue-800 border-gray-500">
                          <CardContent className="p-4">
                            <p className="text-gray-400">
                              Select a project to view project and experiment
                              templates.{' '}
                              <a
                                href="/projects"
                                className="text-blue-400 hover:underline"
                              >
                                Go to projects page
                              </a>
                            </p>
                          </CardContent>
                        </Card>
                      </div>
                    )}
                </div>
              </ScrollArea>
            </div>
          </div>

          <div className="col-span-7 h-full overflow-hidden">
            <Card className="h-full flex flex-col overflow-hidden">
              <CardHeader className="py-3 flex-shrink-0">
                <CardTitle>Template Preview</CardTitle>
              </CardHeader>

              {selectedTemplate ? (
                <CardContent className="flex-1 flex flex-col p-4 overflow-hidden">
                  <div className="mb-4 flex-shrink-0">
                    <h5 className="text-sm font-medium mb-2">Title</h5>
                    <Input
                      value={documentTitle}
                      onChange={e => setDocumentTitle(e.target.value)}
                      className="w-full"
                    />
                  </div>

                  <h5 className="text-sm font-medium mb-2 flex-shrink-0">
                    Description
                  </h5>
                  <p className="mb-4 text-sm flex-shrink-0">
                    {selectedTemplate.description}
                  </p>

                  <div className="flex flex-col flex-1 overflow-hidden">
                    <h5 className="text-sm font-medium mb-2 flex-shrink-0">
                      Components
                    </h5>
                    <div className="border rounded-md flex-1 overflow-hidden">
                      <ScrollArea className="h-full">
                        <div className="p-2 space-y-2">
                          {components.length > 0 ? (
                            components.map(component => (
                              <PreviewComponentCard
                                key={component.id}
                                component={component}
                              />
                            ))
                          ) : (
                            <div className="flex justify-center items-center h-40">
                              <p className="text-gray-500">
                                No components in this template
                              </p>
                            </div>
                          )}
                        </div>
                      </ScrollArea>
                    </div>
                  </div>

                  <div className="pt-4 mt-2 flex-shrink-0">
                    <Button
                      variant="default"
                      className="w-full py-2 text-base"
                      onClick={handleCreateDocumentation}
                      disabled={isCreatingLoading}
                    >
                      {isCreatingLoading ? (
                        <div className="flex items-center justify-center">
                          <Spinner className="mr-2" />
                          Creating...
                        </div>
                      ) : (
                        'Create Documentation'
                      )}
                    </Button>
                  </div>
                </CardContent>
              ) : (
                <CardContent className="flex justify-center items-center flex-1">
                  <p className="text-gray-500">
                    Select a template to see preview and create documentation
                  </p>
                </CardContent>
              )}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

function TemplateCard({
  template,
  isSelected,
  onSelect,
}: {
  template: DocumentationTemplate;
  isSelected: boolean;
  onSelect: (template: DocumentationTemplate) => void;
}) {
  return (
    <Card
      className={`cursor-pointer transition-all duration-200 border-2 ${
        isSelected ? 'bg-dark-blue-200 ' : 'bg-card hover:bg-muted/50 '
      }`}
      onClick={() => onSelect(template)}
    >
      <CardContent className="p-3 h-24 overflow-hidden">
        <h5 className="font-bold text-base mb-1 truncate">{template.title}</h5>
        <p className="text-xs text-muted-foreground line-clamp-2">
          {template.description}
        </p>
      </CardContent>
    </Card>
  );
}

function PreviewComponentCard({
  component,
}: {
  component: DocumentationEntry;
}) {
  const displayName = DocumentComponentNameMap[component.type];
  const isCustomComponent = CUSTOM_COMPONENT_TYPES.includes(component.type);

  return (
    <Card className="flex items-center p-3 bg-card border-border h-[50px]">
      <div className="flex items-center gap-2">
        <span className="font-medium text-sm">{displayName}</span>
        {isCustomComponent && (
          <span className="text-xs text-muted-foreground">- Custom</span>
        )}
        {component.name && component.name !== displayName && (
          <span className="text-xs text-muted-foreground">
            ({component.name})
          </span>
        )}
      </div>
    </Card>
  );
}

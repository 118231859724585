import { DateTime } from 'luxon';
import { TrainingsInterval } from '../__generated__/gql/graphql';
import { INTERVAL_DURATIONS } from '@/constants/intervals';

export interface IntervalData {
  start: DateTime;
  end: DateTime;
  dueDate: DateTime;
  label: string;
  status: 'upcoming' | 'current' | 'past';
  trainingId?: string;
}

const FUTURE_INTERVALS = 1;

function getIntervalLabel(
  start: DateTime,
  interval: TrainingsInterval
): string {
  if (interval === 'ONCE') {
    return `Once (${start.toFormat('yyyy-MM-dd')})`;
  }

  const year = start.year;
  const month = start.monthLong;
  const endDate = start.plus(INTERVAL_DURATIONS[interval]).minus({ days: 1 });

  const formatYearRange = () =>
    endDate.year === year
      ? `${month} - ${endDate.monthLong} ${year}`
      : `${month} ${year} - ${endDate.monthLong} ${endDate.year}`;

  switch (interval) {
    case 'QUARTERLY':
      return `${month} - ${endDate.monthLong} ${year}`;
    case 'YEARLY':
      return formatYearRange();
    case 'BIENNIAL':
      return `${month} ${year} - ${endDate.monthLong} ${endDate.year}`;
    default:
      return `${year}`;
  }
}

function createIntervalData(
  start: DateTime,
  interval: TrainingsInterval,
  status: IntervalData['status'],
  initialDueDate?: DateTime
): IntervalData {
  const end = start
    .plus(INTERVAL_DURATIONS[interval])
    .minus({ days: 1 })
    .endOf('day');

  let dueDate: DateTime;

  if (initialDueDate && interval !== 'ONCE') {
    const intervalsSinceStart = Math.floor(
      start.diff(initialDueDate.startOf('day'), ['days']).days /
        DateTime.fromISO('2000-01-01')
          .plus(INTERVAL_DURATIONS[interval])
          .diff(DateTime.fromISO('2000-01-01'), ['days']).days
    );

    if (intervalsSinceStart >= 0) {
      dueDate = initialDueDate.plus(INTERVAL_DURATIONS[interval]);
    } else {
      dueDate = initialDueDate;
    }

    if (dueDate < start) {
      dueDate = end;
    } else if (dueDate > end) {
      dueDate = end;
    }
  } else {
    dueDate = end;
  }

  return {
    start,
    end,
    dueDate,
    label: getIntervalLabel(start, interval),
    status,
  };
}

/**
 * Generates a list of intervals based on the initial due date and interval type
 */
export function generateIntervals(
  initialStartDate: string,
  interval: TrainingsInterval,
  includeUpcoming: boolean = true,
  initialDueDate?: string
): IntervalData[] {
  const startDate = DateTime.fromISO(initialStartDate).startOf('day');
  const now = DateTime.now().startOf('day');
  const dueDateObj = initialDueDate
    ? DateTime.fromISO(initialDueDate)
    : undefined;

  if (interval === 'ONCE') {
    const start = startDate;
    const end = DateTime.fromISO('9999-12-31');
    const status: IntervalData['status'] = now < start ? 'upcoming' : 'current';
    const dueDate = dueDateObj || end;

    return [
      {
        start,
        end,
        dueDate,
        label: `Once (starting from ${start.toFormat('yyyy-MM-dd')})`,
        status,
      },
    ];
  }

  const intervals: IntervalData[] = [];
  let currentStart = startDate;

  // Add past and current intervals
  while (currentStart <= now) {
    const end = currentStart
      .plus(INTERVAL_DURATIONS[interval])
      .minus({ days: 1 })
      .endOf('day');

    intervals.push(
      createIntervalData(
        currentStart,
        interval,
        end < now ? 'past' : 'current',
        dueDateObj
      )
    );
    currentStart = end.plus({ days: 1 }).startOf('day');
  }

  // Add upcoming intervals
  if (includeUpcoming) {
    for (let i = 0; i < FUTURE_INTERVALS; i++) {
      intervals.push(
        createIntervalData(currentStart, interval, 'upcoming', dueDateObj)
      );
      currentStart = currentStart
        .plus(INTERVAL_DURATIONS[interval])
        .startOf('day');
    }
  }

  return intervals;
}

import React from 'react';
import { Box, Typography } from '@mui/material';
import { Experiment } from '@/__generated__/gql/graphql';
import theme from '@/theme';
import NotFound from '@/components/NotFound';
import FriendlyId from '@/components/ui/friendly-id';

type DataSetIdTabProps = {
  experiment: Experiment;
};

const DataSetIdTab: React.FC<DataSetIdTabProps> = ({ experiment }) => {
  const dataSetStats = experiment?.dataSetStats ?? [];

  return (
    <>
      {dataSetStats.length > 0 ? (
        <Box
          sx={{
            borderRadius: theme.spacing(1),
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 2,
            width: '100%',
          }}
        >
          {Object.entries(dataSetStats).map(
            ([, setInfo]) =>
              setInfo && (
                <Box
                  key={setInfo.id}
                  sx={{
                    bgcolor: '#001529',
                    margin: theme.spacing(1),
                    borderRadius: theme.spacing(1),
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 2,
                    width: '25%',
                  }}
                >
                  <Typography variant="h6">{setInfo.set}</Typography>
                  <Typography>
                    ID:{' '}
                    <FriendlyId
                      id={setInfo.id as string}
                      subtle={true}
                      showFull={false}
                      inline={true}
                    />
                  </Typography>
                </Box>
              )
          )}
        </Box>
      ) : (
        <NotFound subject="dataset-id" />
      )}
    </>
  );
};

export default DataSetIdTab;

import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import {
  Box,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { AddOnType } from '../__generated__/gql/graphql';

interface AddOnProps {
  title: string;
  icon: React.ReactNode;
  subtitle: string;
  description: string;
  benefits: string[];
  buttonText: string;
  addOnType: AddOnType;
  userEmail?: string;
  organizationName?: string;
}

const REQUEST_ADD_ON = gql`
  mutation RequestAddOn(
    $userEmail: String!
    $organizationName: String!
    $type: AddOnType!
  ) {
    requestAddOn(
      userEmail: $userEmail
      organizationName: $organizationName
      type: $type
    ) {
      success
    }
  }
`;

const AddOn: React.FC<AddOnProps> = ({
  title,
  icon,
  subtitle,
  description,
  benefits,
  buttonText,
  addOnType,
  userEmail,
  organizationName,
}) => {
  const [requestSuccess, setRequestSuccess] = useState(false);

  const [requestAddOn, { loading }] = useMutation(REQUEST_ADD_ON, {
    variables: {
      userEmail,
      organizationName,
      type: addOnType,
    },
    onCompleted: (data: { requestAddOn?: { success: boolean } }) => {
      if (data?.requestAddOn?.success) {
        toast.success(`${title} request submitted successfully!`);
        setRequestSuccess(true);
      }
    },
    onError: () => {
      toast.error('Failed to submit request. Please try again.');
    },
  });

  return (
    <Card sx={{ p: 4, boxShadow: 3, borderRadius: 2, height: '100%' }}>
      <CardContent>
        <Box display="flex" alignItems="center" gap={2} mb={3}>
          {icon}
          <Typography variant="h4" fontWeight="bold">
            {title}
          </Typography>
        </Box>
        <Typography variant="h5" color="primary" gutterBottom fontWeight="bold">
          {subtitle}
        </Typography>
        <Typography variant="body1" color="text.secondary" mb={3}>
          {description}
        </Typography>

        <List>
          {benefits.map((benefit, index) => (
            <ListItem key={index} disableGutters>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={benefit} />
            </ListItem>
          ))}
        </List>

        <Box mt={4} mb={2}>
          <Typography variant="h6" color="primary" gutterBottom>
            Custom Enterprise Pricing
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Tailored solutions to fit your organization's needs
          </Typography>
        </Box>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading || requestSuccess}
          onClick={() => requestAddOn()}
          sx={{
            py: 1.5,
            fontSize: '1.1rem',
            fontWeight: 'bold',
            boxShadow: 2,
            '&:hover': {
              boxShadow: 4,
            },
          }}
        >
          {requestSuccess
            ? 'Thank you! Someone from Trail will be in touch with you shortly'
            : loading
            ? 'Submitting...'
            : buttonText}
        </Button>
      </CardContent>
    </Card>
  );
};

export default AddOn;

import CollapsableGridView, { GridCategory } from './CollapsableGridView';
import { gql, useQuery } from '@apollo/client';
import { useProject } from '../contexts/project';
import SearchIcon from '@mui/icons-material/Search';
import {
  AllDocumentationsQuery,
  DocumentationScope,
  DocumentationSummaryFragment,
} from '../__generated__/gql/graphql';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResetDocumentState } from './documentation_view/DocumentationPageStates';
import ExportedDocumentList from './ExportedDocumentList';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { PlusIcon } from 'lucide-react';
import { Input } from '@/components/ui/input';

const GET_DOCUMENTATIONS = gql(`
  query allDocumentations($projectId: String, $hasProject: Boolean!) {
    organizationalDocs: allDocumentations(documentationScope: ORGANIZATION) {
      ...DocumentationSummary
    }
    projectDocs: allDocumentations(projectId: $projectId, documentationScope: PROJECT) @include(if: $hasProject) {
      ...DocumentationSummary
    }
    experimentDocs: allDocumentations(
      projectId: $projectId, 
      documentationScope: EXPERIMENT
    ) @include(if: $hasProject) {
      ...DocumentationSummary
    }
  }
  fragment DocumentationSummary on Documentation {
    id
    name
    documentationScope
    project {
      title
    }
  }
`);

export default function DocumentationOverviewPage() {
  const { project } = useProject();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [documents, setDocuments] = useState<DocumentationSummaryFragment[]>();

  const { loading } = useQuery<AllDocumentationsQuery>(GET_DOCUMENTATIONS, {
    variables: {
      projectId: project?.id || null,
      hasProject: !!project?.id,
    },
    onCompleted: data => {
      if (data) {
        const allDocs = [
          ...(data.organizationalDocs || []),
          ...(data.projectDocs || []),
          ...(data.experimentDocs || []),
        ];
        setDocuments(allDocs as DocumentationSummaryFragment[]);
      }
    },
  });

  const filteredDocuments = documents?.filter(doc =>
    doc.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const organizationalDocuments = filteredDocuments?.filter(
    doc => doc.documentationScope === DocumentationScope.Organization
  );
  const projectDocuments = filteredDocuments?.filter(
    doc => doc.documentationScope === DocumentationScope.Project
  );
  const experimentalDocuments = filteredDocuments?.filter(
    doc => doc.documentationScope === DocumentationScope.Experiment
  );

  const handleDelete = (documentId: string) => {
    setDocuments(prev => prev?.filter(doc => doc.id !== documentId));
    toast.success('Documentation deleted successfully');
  };

  return (
    <div className="pr-4 pb-12">
      <div className="mb-4 flex justify-between">
        <h3>Documentations</h3>
        <div className="flex gap-2 items-center">
          <div className="flex items-center border rounded-md px-2">
            <SearchIcon />
            <Input
              id="search"
              type="text"
              className="w-[400px]  border-none focus-visible:ring-0 focus-visible:outline-none"
              placeholder="Search documents or tags..."
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>
          <CreateNewDocumentButton />
        </div>
      </div>
      <div className="flex flex-col">
        <CollapsableGridView
          loading={loading}
          documents={organizationalDocuments || []}
          gridCategory={GridCategory.ORG}
          onDelete={handleDelete}
        />
        <CollapsableGridView
          loading={loading}
          documents={projectDocuments || []}
          gridCategory={GridCategory.PROJECT}
          onDelete={handleDelete}
        />
        <CollapsableGridView
          loading={loading}
          documents={experimentalDocuments || []}
          gridCategory={GridCategory.EXPERIMENT}
          onDelete={handleDelete}
        />
      </div>
      <ExportedDocumentList />
    </div>
  );
}

function CreateNewDocumentButton() {
  const navigate = useNavigate();
  const resetAll = useResetDocumentState();
  return (
    <Button
      variant="default"
      onClick={() => {
        resetAll();
        navigate('/documentation/templates');
      }}
    >
      <PlusIcon className="text-black" />
      Add Document
    </Button>
  );
}

import { useRecoilValue } from 'recoil';

import { enabledFeaturesAtom } from '../EnabledFeatures';
import FrameworkManagement from './FrameworkManagement';
import UserManagement from './UserManagement';
import FeatureToggleManagement from './FeatureToggleManagement';

export const OrganizationSettingsPage = () => {
  const enabledFeatures = useRecoilValue(enabledFeaturesAtom);

  return (
    <div className="space-y-6 pb-8">
      <div>
        <h1 className=" mb-6">Organization Settings</h1>
      </div>

      <div className="grid md:grid-cols-2 gap-6">
        <div className="space-y-6">
          <FeatureToggleManagement />
          <UserManagement />
        </div>

        {enabledFeatures.showFrameworkManagement && (
          <div>
            <FrameworkManagement />
          </div>
        )}
      </div>
    </div>
  );
};

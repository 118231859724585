import { FC, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'sonner';
import {
  GetFileContentQuery,
  LiteracyTraining,
  TrainingCompletion,
} from '../__generated__/gql/graphql';
import HorizontalLinearStepper from '../sign_up/HorizontalLinearStepper';
import NavigationButton from '../components/NavigationButton';
import { getCurrentStep, getStepLabels } from '../utils/navigationHelpers';
import WelcomeScreen from './training_modal_screens/WelcomeScreen';
import PdfViewerScreen from './training_modal_screens/PdfViewScreen';
import CompletionScreen from './training_modal_screens/CompletionScreen';
import QuestionsScreen from './training_modal_screens/QuestionScreen';
import FailureScreen from './training_modal_screens/FailureScreen';
import { Dialog, DialogContent, DialogPortal } from '@/components/ui/dialog';
import FeedbackButton from '../components/FeedbackButton';

const GET_FILE_CONTENT = gql`
  query GetFileContent($versionedFileId: String!) {
    versionedFile(versionedFileId: $versionedFileId) {
      id
      contentBase64
    }
  }
`;

const COMPLETE_TRAINING = gql`
  mutation CompleteTraining($trainingId: String!) {
    completeTraining(trainingId: $trainingId) {
      trainingCompletion {
        id
        certificateId
        completionDate
        user {
          id
          name
        }
        training {
          id
          title
        }
      }
    }
  }
`;

interface TrainingModalProps {
  open: boolean;
  onClose: () => void;
  training: LiteracyTraining | null;
}

enum TrainingStage {
  Welcome = 'WELCOME',
  PdfViewer = 'PDF_VIEWER',
  Questions = 'QUESTIONS',
  Completion = 'COMPLETION',
  Failure = 'FAILURE',
}

enum AssessmentResult {
  None = 'NONE',
  Pass = 'PASS',
  Fail = 'FAIL',
}

const TrainingModal: FC<TrainingModalProps> = ({ open, onClose, training }) => {
  const hasSections = !!training?.trainingMaterialSections?.length;
  const [activeStep, setActiveStep] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [result, setResult] = useState<AssessmentResult>(AssessmentResult.None);
  const [currentSection, setCurrentSection] = useState(0);
  const [completionData, setCompletionData] =
    useState<TrainingCompletion | null>(null);

  const sections = training?.trainingMaterialSections;
  const versionedFileId =
    sections && sections.length > 0
      ? sections[currentSection]?.material?.id
      : training?.trainingMaterial?.id;

  const { data, loading } = useQuery<GetFileContentQuery>(GET_FILE_CONTENT, {
    variables: versionedFileId ? { versionedFileId } : undefined,
    skip: !versionedFileId || activeStep === 0,
    onError: () => {
      toast.error('Error loading training material');
    },
  });

  const [completeTraining] = useMutation(COMPLETE_TRAINING, {
    onCompleted: data => {
      setCompletionData(data?.completeTraining?.trainingCompletion);
      toast.success('Training completed successfully');
    },
    onError: error => {
      toast.error(`Failed to complete training: ${error.message}`);
    },
    update: (cache, { data }) => {
      const completedTraining = data?.completeTraining?.trainingCompletion;
      if (!completedTraining) return;

      cache.modify({
        id: cache.identify({
          __typename: 'LiteracyTraining',
          id: completedTraining.training.id,
        }),
        fields: {
          currentUserCompletion() {
            return {
              __typename: 'TrainingCompletion',
              id: completedTraining.id,
              completionDate: completedTraining.completionDate,
            };
          },
        },
      });
    },
  });

  const handlePdfLoad = (numPages: number) => {
    setTotalPages(numPages);
  };

  const getCurrentStage = () => {
    switch (activeStep) {
      case 0:
        return TrainingStage.Welcome;
      case 1:
        return TrainingStage.PdfViewer;
      case 2:
        if (result === AssessmentResult.Pass) return TrainingStage.Completion;
        if (result === AssessmentResult.Fail) return TrainingStage.Failure;
        if (training?.questions?.length) return TrainingStage.Questions;
        return TrainingStage.Completion;
      default:
        return TrainingStage.Welcome;
    }
  };

  const handleStart = () => {
    setCurrentPage(1);
    if (hasSections) {
      setCurrentPage(0);
    }
    setActiveStep(1);
  };

  const handleQuestionNavigation = (direction: 'next' | 'back') => {
    if (result !== AssessmentResult.None || activeStep !== 1) return;

    if (hasSections) {
      // With sections
      if (direction === 'next') {
        if (currentPage < totalPages) {
          setCurrentPage(prev => prev + 1);
        } else if (
          currentSection <
          (training?.trainingMaterialSections?.length || 0) - 1
        ) {
          setCurrentSection(prev => prev + 1);
          setCurrentPage(0);
          setTotalPages(1);
        } else {
          setActiveStep(2);
        }
      } else {
        // direction === 'back'
        if (currentPage >= 1) {
          setCurrentPage(prev => prev - 1);
        } else if (currentSection > 0) {
          setCurrentSection(prev => prev - 1);
          setCurrentPage(totalPages);
        } else {
          setActiveStep(0);
        }
      }
    } else {
      // Without sections - simpler case
      if (direction === 'next') {
        if (currentPage < totalPages) {
          setCurrentPage(prev => prev + 1);
        } else {
          setActiveStep(2);
        }
      } else {
        // direction === 'back'
        if (currentPage > 1) {
          setCurrentPage(prev => prev - 1);
        } else {
          setActiveStep(0);
        }
      }
    }
  };

  const resetStates = () => {
    setActiveStep(0);
    setCurrentPage(0);
    setTotalPages(1);
    setResult(AssessmentResult.None);
    setCurrentSection(0);
    setCompletionData(null);
  };

  const handleClose = () => {
    resetStates();
    onClose();
  };

  const handleRetry = () => {
    setResult(AssessmentResult.None);
    resetStates();
  };

  const handleComplete = async () => {
    if (training?.id) {
      await completeTraining({
        variables: { trainingId: training.id },
      });
    }
  };

  const stages: Record<TrainingStage, JSX.Element> = {
    [TrainingStage.Welcome]: (
      <WelcomeScreen training={training} onStart={handleStart} />
    ),
    [TrainingStage.PdfViewer]: (
      <PdfViewerScreen
        isLoading={loading}
        loadingPdf={loading}
        pdfData={data?.versionedFile?.contentBase64 ?? undefined}
        currentPage={currentPage}
        onPageLoad={handlePdfLoad}
        sections={training?.trainingMaterialSections ?? undefined}
        currentSection={currentSection}
      />
    ),
    [TrainingStage.Questions]: (
      <QuestionsScreen
        training={training}
        onComplete={() => setResult(AssessmentResult.Pass)}
        onFail={() => setResult(AssessmentResult.Fail)}
      />
    ),
    [TrainingStage.Completion]: (
      <CompletionScreen
        training={training}
        onComplete={handleComplete}
        completionData={completionData}
      />
    ),
    [TrainingStage.Failure]: <FailureScreen onRetry={handleRetry} />,
  };

  const getStepperConfig = () => {
    const hasQuestions = !!training?.questions?.length;
    const isComplete = result !== AssessmentResult.None;

    return {
      step: getCurrentStep(
        activeStep,
        currentSection,
        hasSections,
        hasQuestions,
        isComplete
      ),
      stepLabels: getStepLabels({
        hasSections,
        sections: training?.trainingMaterialSections || [],
        hasQuestions,
        totalPages,
      }),
    };
  };

  return (
    <Dialog
      open={open}
      onOpenChange={isOpen => {
        if (!isOpen) {
          handleClose();
        }
      }}
    >
      <DialogPortal>
        <DialogContent className="p-4 ml-8 flex w-[90%] h-[95%] justify-between overflow-hidden">
          <div className="w-full flex h-full gap-8 items-center">
            {getCurrentStage() === TrainingStage.PdfViewer && (
              <div className="w-10 h-full flex items-center">
                <NavigationButton
                  direction="back"
                  onClick={() => handleQuestionNavigation('back')}
                  show={activeStep > 0 || currentPage > (hasSections ? 0 : 1)}
                />
              </div>
            )}

            <div className="flex h-full flex-col items-center flex-grow w-full overflow-hidden">
              <div className="flex-shrink-0 mb-2 w-full">
                <div className="w-full overflow-x-auto">
                  <HorizontalLinearStepper
                    {...getStepperConfig()}
                    stepperProps={{
                      sx: {
                        minWidth: 'max-content',
                        padding: '16px',
                        '& .MuiStepLabel-label': {
                          color: 'white',
                          fontSize: '0.875rem',
                        },
                        '& .MuiStepIcon-root': {
                          fontSize: '1.25rem',
                        },
                      },
                    }}
                  />
                </div>
              </div>

              <div className="w-full overflow-auto h-full">
                {stages[getCurrentStage()]}
              </div>
            </div>

            {getCurrentStage() === TrainingStage.PdfViewer && (
              <div className="w-10 h-full flex items-center">
                <NavigationButton
                  direction="next"
                  onClick={() => handleQuestionNavigation('next')}
                  show={activeStep !== 0}
                />
              </div>
            )}
          </div>

          {/* Feedback Button Component */}
          <FeedbackButton position="bottom-right" />
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export default TrainingModal;

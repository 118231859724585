import type React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { FormValues } from '../TrainingCreationModal';
import { Trash2, Plus, Check, AlertCircle } from 'lucide-react';
import { Textarea } from '@/components/ui/textarea';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

interface QuestionItemProps {
  qIndex: number;
  onRemove: (index: number) => void;
}

interface QuestionOption {
  optionText: string;
  isCorrect: boolean;
}

const QuestionItem: React.FC<QuestionItemProps> = ({ qIndex, onRemove }) => {
  const { control, register, setValue, watch } = useFormContext<FormValues>();

  const {
    fields: optionFields,
    append: appendOption,
    remove: removeOption,
  } = useFieldArray({
    control,
    name: `questions.${qIndex}.options`,
  });

  const options = watch(`questions.${qIndex}.options`) || [];
  const correctOptionIndex = options.findIndex(
    (opt: QuestionOption) => opt.isCorrect
  );

  const onSelectCorrect = (value: string) => {
    const selectedIndex = parseInt(value);
    const updatedOptions = options.map((opt: QuestionOption, idx: number) => ({
      ...opt,
      isCorrect: idx === selectedIndex,
    }));
    setValue(`questions.${qIndex}.options`, updatedOptions);
  };

  const getValidationStatus = () => {
    const question = watch(`questions.${qIndex}`);
    const hasQuestionText = question.questionText.trim();
    const hasCorrectAnswer = question.options.some(opt => opt.isCorrect);
    const allOptionsHaveText = question.options.every(opt =>
      opt.optionText.trim()
    );

    return {
      isValid: hasQuestionText && hasCorrectAnswer && allOptionsHaveText,
      errors: [
        !hasQuestionText && 'Question text is required',
        !hasCorrectAnswer && 'At least one correct answer must be selected',
        !allOptionsHaveText && 'All options must have text',
      ].filter(Boolean) as string[],
    };
  };
  const validationStatus = getValidationStatus();

  return (
    <Card className="border-2 border-dark-blue-400 shadow-sm hover:shadow-md transition-shadow">
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="text-lg font-medium">Question {qIndex + 1}</span>
            <TooltipProvider>
              {validationStatus.isValid ? (
                <Tooltip>
                  <TooltipTrigger>
                    <Check className="w-5 h-5 text-green-500" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Question is valid</p>
                  </TooltipContent>
                </Tooltip>
              ) : (
                <Tooltip>
                  <TooltipTrigger>
                    <AlertCircle className="w-5 h-5 text-yellow-500" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <div className="space-y-1">
                      <p className="font-semibold">Please fix the following:</p>
                      <ul className="list-disc list-inside">
                        {validationStatus.errors.map((error, index) => (
                          <li key={index} className="text-sm">
                            {error}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </TooltipContent>
                </Tooltip>
              )}
            </TooltipProvider>
          </div>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => onRemove(qIndex)}
            className="text-white hover:text-red-500"
          >
            <Trash2 className="w-4 h-4" />
          </Button>
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-6 p-6">
        <div className="space-y-2">
          <Label htmlFor={`question-${qIndex}`} className="text-sm font-medium">
            Question
          </Label>
          <Input
            id={`question-${qIndex}`}
            placeholder="Enter your question here..."
            className="border-2 mt-2"
            {...register(`questions.${qIndex}.questionText` as const, {
              required: 'Question text is required',
            })}
          />
        </div>

        <div className="space-y-2">
          <Label
            htmlFor={`question-${qIndex}-description`}
            className="text-sm font-medium"
          >
            Description (Optional)
          </Label>
          <Textarea
            id={`question-${qIndex}-description`}
            placeholder="Add additional context or description..."
            {...register(`questions.${qIndex}.description` as const)}
          />
        </div>

        <div className="space-y-4">
          <Label className="text-sm font-medium">Answer Options</Label>
          <RadioGroup
            value={correctOptionIndex.toString()}
            onValueChange={onSelectCorrect}
            className="space-y-3"
          >
            {optionFields.map((option, oIndex) => (
              <div
                key={option.id}
                className="flex items-center space-x-3 p-2 rounded-lg"
              >
                <RadioGroupItem
                  value={oIndex.toString()}
                  id={`question-${qIndex}-option-${oIndex}`}
                  className="border-2"
                />
                <Input
                  placeholder={`Option ${oIndex + 1}`}
                  className="flex-1 border-2 focus:ring-2 focus:ring-blue-100"
                  {...register(
                    `questions.${qIndex}.options.${oIndex}.optionText` as const,
                    { required: 'Option text is required' }
                  )}
                />
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => removeOption(oIndex)}
                  className="text-gray-500 hover:text-red-500"
                >
                  <Trash2 className="w-4 h-4" />
                </Button>
              </div>
            ))}
          </RadioGroup>
          <Button
            variant="outline"
            onClick={() => appendOption({ optionText: '', isCorrect: false })}
            className="w-full border-dashed border-2"
          >
            <Plus className="w-4 h-4 mr-2" />
            Add Option
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default QuestionItem;

import React, { useMemo } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { colorThemes } from '../theme';
import { useParams, useNavigate } from 'react-router-dom';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  GetGovernanceFrameworkQuery,
  MaterializedCategory,
} from '../__generated__/gql/graphql';
import ScrollToHashElement from '../components/ScrollToHashElement';
import SummaryCard from './components/governance/SummaryCard';
import Category from './components/governance/Category';
import { FilterSection } from './components/governance/FilterSection';
import { CustomList } from '../components/CustomList';
import { useRequirementNavigation } from '../hooks/useRequirementNavigation';
import { useRequirementFilters } from '../hooks/useRequirementFilters';
import { SideNavigation } from './components/governance/SideNavigation';
import { GovernanceFilterService } from '../services/governanceFilterService';
import { GovernanceAnalyticsService } from '../services/governanceAnalyticsService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const GET_GOVERNANCE_FRAMEWORK = gql`
  query GetGovernanceFramework(
    $frameworkId: String!
    $auditId: String!
    $skipAuditStatus: Boolean!
  ) {
    materializedGovernanceFramework(frameworkId: $frameworkId) {
      id
      name
      categories {
        categoryName
        requirements {
          id
          requirementId
          title
          description
          guidance
          approvalResponsibilities
          ownerResponsibilities
          userStatus {
            id
            completionStatus
            lastModified
            created
            evidence {
              id
            }
          }
          approvers {
            id
            name
            email
            initials
          }
          owners {
            id
            name
            email
            initials
          }
          evidenceSuggestions {
            description
          }
          specifications {
            id
            description
          }
          requirementAuditStatus(auditId: $auditId)
            @skip(if: $skipAuditStatus) {
            sections {
              status
              comments
            }
          }
        }
      }
    }
  }
`;

export default function GovernancePage() {
  const { frameworkId, auditId } = useParams<{
    frameworkId: string;
    auditId?: string;
  }>();

  const { data } = useSuspenseQuery<GetGovernanceFrameworkQuery>(
    GET_GOVERNANCE_FRAMEWORK,
    {
      variables: {
        frameworkId,
        auditId: auditId || '',
        skipAuditStatus: !auditId,
      },
    }
  );
  const categories = data?.materializedGovernanceFramework
    ?.categories as MaterializedCategory[];
  const { openRequirementId, setOpenRequirementId } =
    useRequirementNavigation();
  const { filterOptions, setFilterOptions, autocompleteOptions } =
    useRequirementFilters(categories);

  const filteredCategories = useMemo(
    () =>
      GovernanceFilterService.filterCategories(categories, filterOptions) ?? [],
    [categories, filterOptions]
  );

  const navigate = useNavigate();

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <Box display="flex" alignItems="center" gap={2} marginBottom={3}>
        {auditId && (
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(`/audits/${auditId}`)}
            variant="outlined"
          >
            Back to Audit Overview
          </Button>
        )}
        <Typography variant="h4">
          {auditId
            ? `Audit for ${data?.materializedGovernanceFramework?.name}`
            : data?.materializedGovernanceFramework?.name}
        </Typography>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap={2}
        flex="1"
        color={colorThemes.GREY_100}
        overflow={'hidden'}
      >
        <ScrollToHashElement />
        <SideNavigation categories={categories} />
        <Box
          gridColumn="span 10"
          display="flex"
          flexDirection="column"
          overflow="auto"
          gap={'16px'}
        >
          <Box display="flex" gap={1}>
            <SummaryCard
              flex={1}
              description="Requirement Progress"
              progressLabel="Requirements fulfilled"
              totalNumber={
                GovernanceAnalyticsService.countTotalProgress(categories)
                  .totalCount
              }
              fulfilledNumber={
                GovernanceAnalyticsService.countTotalProgress(categories)
                  .completedCount
              }
            />
          </Box>
          <FilterSection
            autocompleteOptions={autocompleteOptions}
            onFilterChange={setFilterOptions}
          />
          <CustomList
            style={{
              padding: 4,
              background: colorThemes.DARK_BLUE_600,
              borderRadius: '8px',
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              overflow: 'auto',
              marginBlock: '0px',
            }}
          >
            {filteredCategories.map(category => (
              <Category
                category={category}
                key={category.categoryName}
                openRequirementId={openRequirementId}
                setOpenRequirementId={setOpenRequirementId}
                auditId={auditId}
              />
            ))}
          </CustomList>
        </Box>
      </Box>
    </Box>
  );
}

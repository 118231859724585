import React from 'react';
import { Experiment } from '@/__generated__/gql/graphql';
import UploadedFilesSelector from '../UploadedFilesSelector';

type FilesTabProps = {
  projectId: string;
  experiment: Experiment;
  gitSyncEnabled?: boolean;
};

const FilesTab: React.FC<FilesTabProps> = ({
  projectId,
  experiment,
  gitSyncEnabled = false,
}) => {
  return (
    <UploadedFilesSelector
      projectId={projectId}
      experimentId={experiment.id}
      gitSyncEnabled={gitSyncEnabled}
    />
  );
};

export default FilesTab;

import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'sonner';
import { GET_DETAILS } from '../DashboardDetails';

const EDIT_USC = gql(`
mutation update_project_intendedusecases($projectId: String!, $projectIntendedUseCases: [String]) {
  updateProject(projectId: $projectId, intendedUseCases: $projectIntendedUseCases) {
  project {
    id
  }
  }
}
`);

interface Props {
  useCases: string[];
  projectId: string;
  onClose: () => void;
}
export default function EditIntendedUseCases({
  useCases,
  projectId,
  onClose,
}: Props) {
  const [commitUseCaseChange] = useMutation(EDIT_USC, {
    refetchQueries: [{ query: GET_DETAILS, variables: { projectId } }],
  });
  const [projectUseCases, setProjectUseCases] = useState(useCases);
  const handleNewUseCase = () => {
    setProjectUseCases([...projectUseCases, '']);
  };
  const handleDeleteUseCase = (index: number) => {
    setProjectUseCases([
      ...projectUseCases.slice(0, index),

      ...projectUseCases.slice(index + 1),
    ]);
  };
  const handleUseCaseChange = (event: string, index: number) => {
    setProjectUseCases([
      ...projectUseCases.slice(0, index),
      event,
      ...projectUseCases.slice(index + 1),
    ]);
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="body2" color="text.secondary">
            Intended Use Cases
          </Typography>

          <Button
            variant="outlined"
            endIcon={<AddBoxIcon sx={{ color: 'inherit' }} />}
            onClick={handleNewUseCase}
          >
            NEW
          </Button>
        </Box>
        {projectUseCases.length === 0 && (
          <>
            {' '}
            <br />
            <Typography variant="body2" color="text.secondary">
              Please use the button on top right to add a new use case.
            </Typography>
            <br />
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            justifyContent: 'space-between',
          }}
        >
          {projectUseCases.map((useCase: any, index: number) => (
            <>
              <Box
                display="flex"
                flexDirection="row"
                paddingX="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: '60%' }}
                  value={useCase}
                  multiline
                  variant="standard"
                  onChange={e => handleUseCaseChange(e.target.value, index)}
                />
                <IconButton
                  onClick={() => handleDeleteUseCase(index)}
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </>
          ))}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Button sx={{ color: 'red' }} onClick={onClose}>
              Discard
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                commitUseCaseChange({
                  variables: {
                    projectId,
                    projectIntendedUseCases: projectUseCases,
                  },
                  onCompleted: () => {
                    onClose();
                  },
                  onError: () => {
                    toast.error('Error saving changes.', {
                      position: 'top-center',
                    });
                  },
                });
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

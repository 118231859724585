import { useMemo, useState } from 'react';
import {
  MaterializedCategory,
  RequirementCompletionStatus,
} from '../__generated__/gql/graphql';
import { FilterOption } from '../types/governace';

interface UniqueUsers {
  owners: string[];
  approvers: string[];
}

const getUniqueUsers = (categories: MaterializedCategory[]): UniqueUsers => {
  if (!categories) return { owners: [], approvers: [] };
  const ownerSet = new Set<string>();
  const approverSet = new Set<string>();

  categories.forEach(category => {
    category?.requirements?.forEach(req => {
      req?.approvers?.forEach(
        approver => approver?.name && approverSet.add(approver.name)
      );
      req?.owners?.forEach(owner => owner?.name && ownerSet.add(owner.name));
    });
  });

  return {
    owners: Array.from(ownerSet),
    approvers: Array.from(approverSet),
  };
};

const getUniqueStatuses = (
  categories: MaterializedCategory[]
): RequirementCompletionStatus[] => {
  if (!categories) return [];
  const statusSet = new Set<RequirementCompletionStatus>();

  categories.forEach(category => {
    category.requirements?.forEach(req => {
      if (req?.userStatus?.completionStatus) {
        statusSet.add(req.userStatus.completionStatus);
      }
    });
  });

  return Array.from(statusSet);
};

const buildAutocompleteOptions = (
  owners: string[],
  approvers: string[],
  statuses: RequirementCompletionStatus[]
): FilterOption[] => {
  return [
    { type: 'owner', value: 'unassigned', label: 'Owner: Unassigned' },
    ...owners.map(user => ({
      type: 'owner' as const,
      value: user,
      label: `Owner: ${user}`,
    })),

    { type: 'approver', value: 'unassigned', label: 'Approver: Unassigned' },
    ...approvers.map(user => ({
      type: 'approver' as const,
      value: user,
      label: `Approver: ${user}`,
    })),

    ...statuses.map(status => ({
      type: 'status' as const,
      value: status,
      label: `Status: ${status}`,
    })),
  ];
};

export interface UseRequirementFiltersResult {
  filterOptions: FilterOption[];
  setFilterOptions: (options: FilterOption[]) => void;
  autocompleteOptions: FilterOption[];
}

export const useRequirementFilters = (
  categories: MaterializedCategory[] = []
): UseRequirementFiltersResult => {
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);

  const { owners, approvers } = useMemo(
    () => getUniqueUsers(categories),
    [categories]
  );

  const allStatuses = useMemo(
    () => getUniqueStatuses(categories),
    [categories]
  );

  const autocompleteOptions = useMemo(
    () => buildAutocompleteOptions(owners, approvers, allStatuses),
    [owners, approvers, allStatuses]
  );

  return {
    filterOptions,
    setFilterOptions,
    autocompleteOptions,
  };
};

import { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'sonner';
import { GetCurrentUserQuery } from '../__generated__/gql/graphql';
import { GET_USER_DETAILS } from './ProfilePage';
import { ApiKeysTable } from './ApiKeysTable';
import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@/components/ui/dialog';

const GENERATE_API_KEY_MUTATION = gql`
  mutation GenerateApiKey($name: String!) {
    generateApiKey(name: $name) {
      apiKey
    }
  }
`;

export default function ManageApiKeys() {
  const { data: userData, refetch } =
    useQuery<GetCurrentUserQuery>(GET_USER_DETAILS);
  const [generateApiKey, { loading: apiKeyLoading }] = useMutation(
    GENERATE_API_KEY_MUTATION,
    {
      onError: error => {
        toast.error(`Error generating API key: ${error.message}`);
      },
      onCompleted: data => {
        setNewApiKey(data.generateApiKey.apiKey);
        setOpenModal(true);
        toast.success('API key generated successfully.');
        refetch(); // Refetch user data to get the updated list of API keys
      },
    }
  );

  const [openModal, setOpenModal] = useState(false);
  const [newApiKey, setNewApiKey] = useState('');
  const [apiKeyName, setApiKeyName] = useState('');

  const handleGenerateApiKey = () => {
    if (apiKeyName.trim() === '') {
      toast.error('API Key Name cannot be empty.');
      return;
    }
    generateApiKey({ variables: { name: apiKeyName } });
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(newApiKey)
      .then(() => {
        toast.success('API key copied to clipboard!');
      })
      .catch(err => {
        console.error('Error copying text: ', err);
        toast.error('Failed to copy API key.');
      });
  };

  const apiKeyDetails = userData?.currentUser?.apiKeys;

  return (
    <>
      <Card className="w-full h-full">
        <CardContent className="pt-6">
          <h5 className="text-xl font-medium mb-4">API Keys</h5>
          {apiKeyDetails && apiKeyDetails.length > 0 ? (
            <ApiKeysTable
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore-next-line - apiKeys won't ever be null here
              apiKeys={apiKeyDetails}
              refetchApiKeys={refetch}
            />
          ) : (
            <p className="text-base">No API Keys generated yet.</p>
          )}
        </CardContent>
        <CardFooter className="flex justify-end items-center">
          <Input
            placeholder="API Key Name"
            className="mr-2 w-[250px]"
            value={apiKeyName}
            onChange={e => setApiKeyName(e.target.value)}
          />
          <Button onClick={handleGenerateApiKey} disabled={apiKeyLoading}>
            {apiKeyLoading ? <>Loading...</> : 'Generate new API Key'}
          </Button>
        </CardFooter>
      </Card>

      <Dialog open={openModal} onOpenChange={setOpenModal}>
        <DialogContent className="sm:max-w-md flex flex-col gap-4">
          <DialogHeader>
            <DialogTitle className="text-center">
              New API Key Generated
            </DialogTitle>
            <DialogDescription className="text-center">
              Please save this key somewhere safe; it will not be accessible
              again.
            </DialogDescription>
          </DialogHeader>

          <div className="font-mono text-center break-all bg-slate-800 p-3 rounded-lg">
            {newApiKey}
          </div>

          <div className="text-center">
            <Button variant="default" onClick={handleCopyToClipboard}>
              Copy API Key
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

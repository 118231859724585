import ProjectIntegrations from '../project_view/ProjectIntegrations';
import { useProject } from '../contexts/project';
import { Card } from '@/components/ui/card';

export const Integrations = () => {
  const { project } = useProject();
  return (
    <Card className="p-4">
      <h4>Integrations</h4>
      {project?.id && <ProjectIntegrations projectID={project.id} />}
      {!project?.id && (
        <p>
          Select a project on the <a href="/projects">AI Registry</a> to view
          integrations
        </p>
      )}
    </Card>
  );
};

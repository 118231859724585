import { Suspense } from 'react';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import { Loading } from '@/components/ui/loading';
import ProjectDetailDrawer, {
  ProjectDetailDrawerProps,
} from './ProjectDetailDrawer';

export default function ProjectDetailCardSuspenseWrapper(
  props: ProjectDetailDrawerProps
) {
  return (
    <Sheet
      open={props.showSidebar}
      onOpenChange={open => !open && props.setShowSidebar(false)}
    >
      <SheetContent
        side="right"
        className="w-[70%] sm:max-w-[70%] overflow-auto"
      >
        <Suspense fallback={<Loading message="Loading project data ..." />}>
          <ProjectDetailDrawer {...props} />
        </Suspense>
      </SheetContent>
    </Sheet>
  );
}

import { ApiKey } from '../__generated__/gql/graphql';
import { toast } from 'sonner';
import { gql } from '../__generated__/gql';
import { useMutation } from '@apollo/client';
import { Button } from '@/components/ui/button';
import { DateTime } from 'luxon';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Trash } from 'lucide-react';

const DELETE_API_KEY_MUTATION = gql(`
  mutation DeleteApiKey($name: String!) {
    deleteApiKey(name: $name) {
      name
    }
  }
`);

export const ApiKeysTable = ({
  apiKeys,
  refetchApiKeys,
}: {
  apiKeys: ApiKey[];
  refetchApiKeys: () => void;
}) => {
  const [deleteApiKey] = useMutation(DELETE_API_KEY_MUTATION, {
    onCompleted: () => {
      toast.success('API key deleted successfully.');
      refetchApiKeys();
    },
    onError: error => {
      toast.error(`Error deleting API key: ${error.message}`);
    },
  });

  const handleDeleteKey = (name: string) => {
    deleteApiKey({
      variables: {
        name,
      },
    });
  };

  return (
    <div className="w-full">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Created on</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {apiKeys.map(apiKey => (
            <TableRow key={apiKey.name} className="h-[30px]">
              <TableCell>{apiKey.name}</TableCell>
              <TableCell>
                {DateTime.fromISO(apiKey.creationTimestamp).toFormat(
                  'd MMM yyyy HH:mm'
                )}
              </TableCell>
              <TableCell>
                <div className="flex items-center justify-end">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleDeleteKey(apiKey.name)}
                    className="ml-1 hover:bg-white/5"
                    aria-label="delete"
                  >
                    <Trash className="h-4 w-4" />
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

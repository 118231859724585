import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Skeleton } from '@/components/ui/skeleton';

interface SkeletonTableProps {
  rowCount: number;
}

const SkeletonTable: React.FC<SkeletonTableProps> = ({ rowCount }) => (
  <div className="rounded-md border">
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>
            <Skeleton className="h-6 " />
          </TableHead>
          <TableHead>
            <Skeleton className="h-6 " />
          </TableHead>
          <TableHead>
            <Skeleton className="h-6" />
          </TableHead>
          <TableHead>
            <Skeleton className="h-6" />
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {Array.from({ length: rowCount }).map((_, idx) => (
          <TableRow key={`skeleton-${idx}`}>
            <TableCell>
              <Skeleton className="h-10" />
            </TableCell>
            <TableCell>
              <Skeleton className="h-10" />
            </TableCell>
            <TableCell>
              <Skeleton className="h-10" />
            </TableCell>
            <TableCell>
              <Skeleton className="h-10" />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
);

export default SkeletonTable;

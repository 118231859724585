import { FC, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'sonner';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Upload, FileText, FileIcon, Info, X, Plus } from 'lucide-react';
import { Spinner } from '@/components/ui/spinner';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from '@/components/ui/alert-dialog';
import SectionUploadField from './SectionUploadField';
import { FormState } from '../TrainingsDrawer';
import {
  LiteracyTraining,
  TrailTrainingPreview,
} from '../../__generated__/gql/graphql';

interface TrainingMaterialsSectionProps {
  formState: FormState;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
  isEditable: boolean;
  training: LiteracyTraining;
  handleUploadFile: (file: File) => Promise<string>;
  availableTrainingMaterials: TrailTrainingPreview[];
}

const TrainingMaterialsSection: FC<TrainingMaterialsSectionProps> = ({
  formState,
  setFormState,
  isEditable,
  training,
  handleUploadFile,
  availableTrainingMaterials,
}) => {
  const [selectedExistingMaterial, setSelectedExistingMaterial] = useState<
    string | null
  >(formState.materialMode === 'existing' ? formState.singleMaterialId : null);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [activeTab, setActiveTab] = useState<'new' | 'existing' | 'sections'>(
    formState.materialMode
  );
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [pendingTabChange, setPendingTabChange] = useState<
    'new' | 'existing' | 'sections' | null
  >(null);
  const [confirmMessage, setConfirmMessage] = useState('');

  const handleUploadMethodChange = (value: string) => {
    const newMode = value as 'new' | 'existing' | 'sections';
    if (newMode !== formState.materialMode) {
      const message =
        newMode === 'new' || newMode === 'existing'
          ? 'Switching to single material will clear all sections. Are you sure?'
          : 'Switching to multiple sections will clear the single material. Are you sure?';
      setConfirmMessage(message);
      setPendingTabChange(newMode);
      setShowConfirmDialog(true);
    } else {
      setActiveTab(newMode);
    }
  };

  const handleConfirmChange = useCallback(() => {
    if (pendingTabChange) {
      setActiveTab(pendingTabChange);
      setFormState(prev => ({
        ...prev,
        materialMode: pendingTabChange,
        singleMaterialId:
          pendingTabChange === 'sections' ? null : prev.singleMaterialId,
        sections: pendingTabChange === 'sections' ? prev.sections : [],
      }));
      setSelectedFile(null);
      setSelectedExistingMaterial(
        pendingTabChange === 'existing' ? formState.singleMaterialId : null
      );
      setPendingTabChange(null);
      setShowConfirmDialog(false);
    }
  }, [pendingTabChange, formState.singleMaterialId, setFormState]);

  const handleCancelChange = useCallback(() => {
    setPendingTabChange(null);
    setShowConfirmDialog(false);
  }, []);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setIsUploading(true);
        try {
          const fileId = await handleUploadFile(file);
          setSelectedFile(file);
          setFormState(prev => ({ ...prev, singleMaterialId: fileId }));
          setSelectedExistingMaterial(null);
        } catch (error) {
          console.error('Error uploading file:', error);
          toast.error('Failed to upload file');
          setSelectedFile(null);
          setFormState(prev => ({ ...prev, singleMaterialId: null }));
        } finally {
          setIsUploading(false);
        }
      }
    },
    [handleUploadFile, setFormState]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'application/pdf': ['.pdf'] },
    maxSize: 10485760, // 10MB
    disabled: formState.materialMode !== 'new',
    onDropRejected: rejectedFiles => {
      rejectedFiles.forEach(({ file, errors }) => {
        errors.forEach(error => {
          switch (error.code) {
            case 'file-too-large':
              toast.error(`${file.name} is too large. Max size is 10MB`);
              break;
            case 'file-invalid-type':
              toast.error(`${file.name} is not a valid PDF file`);
              break;
            default:
              toast.error(`Error uploading ${file.name}: ${error.message}`);
          }
        });
      });
    },
  });

  const handleRemoveFile = useCallback(() => {
    setSelectedFile(null);
    setFormState(prev => ({ ...prev, singleMaterialId: null }));
  }, [setFormState]);

  const handleExistingMaterialChange = useCallback(
    (materialId: string) => {
      setSelectedExistingMaterial(prev =>
        prev === materialId ? null : materialId
      );
      setFormState(prev => ({
        ...prev,
        singleMaterialId:
          prev.singleMaterialId === materialId ? null : materialId,
      }));
      setSelectedFile(null);
    },

    [setFormState]
  );

  const addSection = () => {
    const newSection = { id: Date.now(), title: '', materialId: null };
    setFormState(prev => ({
      ...prev,
      sections: [...prev.sections, newSection],
    }));
  };

  const updateSection = (updatedSection: {
    id: number;
    title: string;
    materialId: string | null;
  }) => {
    setFormState(prev => ({
      ...prev,
      sections: prev.sections.map(sec =>
        sec.id === updatedSection.id ? updatedSection : sec
      ),
    }));
  };

  const removeSection = (id: number) => {
    setFormState(prev => ({
      ...prev,
      sections: prev.sections.filter(sec => sec.id !== id),
    }));
  };

  const getSectionFileName = (materialId: string | null) => {
    if (!materialId) return null;
    const section = training.trainingMaterialSections.find(
      s => s.material.id === materialId
    );
    return section?.material.fileName || null;
  };

  if (!isEditable) {
    return (
      <Card className="mt-6 bg-dark-blue-500 border-0 shadow-none">
        <CardHeader>
          <CardTitle className="text-2xl font-semibold text-white">
            Training Materials
          </CardTitle>
        </CardHeader>
        <CardContent className="pt-0">
          {training.trainingMaterial ? (
            <div className="p-2 bg-dark-blue-400 rounded-md flex items-center">
              <FileIcon className="mr-2 text-white" />

              {training.trainingMaterial.fileName}
            </div>
          ) : training.trainingMaterialSections?.length > 0 ? (
            <div className="space-y-2">
              {training.trainingMaterialSections.map(section => (
                <div
                  key={section.id}
                  className="p-2 bg-dark-blue-400 rounded-md"
                >
                  <div className="font-medium text-white">{section.title}</div>
                  <div className="flex items-center mt-1">
                    <FileIcon className="mr-2 text-white" />
                    {section.material.fileName}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="p-4 text-center text-gray-300">
              No training materials available.
            </div>
          )}
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="mt-9 bg-dark-blue-500 border-0 shadow-none">
      <CardHeader>
        <CardTitle className="text-2xl font-semibold">
          Training Materials
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-6 h-full p-6 pt-0">
        <Tabs value={activeTab} onValueChange={handleUploadMethodChange}>
          <TabsList className="grid grid-cols-3 ">
            <TabsTrigger value="new" className="flex items-center gap-2">
              <Upload className="h-4 w-4" />
              Upload whole Training Material
            </TabsTrigger>
            <TabsTrigger value="sections" className="flex items-center gap-2">
              <FileIcon className="h-4 w-4" />
              Upload Training in Sections
            </TabsTrigger>
            <TabsTrigger value="existing" className="flex items-center gap-2">
              <FileText className="h-4 w-4" />
              Use Trail's Material
            </TabsTrigger>
          </TabsList>

          <div className="py-6">
            {activeTab === 'existing' ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {availableTrainingMaterials.length > 0 ? (
                  availableTrainingMaterials
                    .filter(
                      (
                        material
                      ): material is TrailTrainingPreview & {
                        trainingMaterial: NonNullable<
                          TrailTrainingPreview['trainingMaterial']
                        >;
                      } => Boolean(material?.trainingMaterial?.id)
                    )
                    .map(material => (
                      <Card
                        key={material.id}
                        className={`relative bg-dark-blue-200 transition-all hover:-translate-y-1 hover:shadow-lg cursor-pointer ${
                          selectedExistingMaterial === material.id
                            ? 'border-2 border-primary'
                            : ''
                        }`}
                      >
                        <CardContent
                          onClick={() =>
                            handleExistingMaterialChange(material.id)
                          }
                          className="p-6"
                        >
                          <div className="flex justify-between items-start mb-4">
                            <h3 className="font-bold flex-grow mr-2">
                              {material.previewTitle}
                            </h3>
                          </div>
                          <p className="text-sm text-muted-foreground line-clamp-3 mb-4">
                            {material.description}
                          </p>
                          <Badge
                            variant="outline"
                            className="flex items-center gap-1 w-fit"
                          >
                            <FileIcon className="h-4 w-4" />
                            PDF
                          </Badge>
                        </CardContent>
                      </Card>
                    ))
                ) : (
                  <div className="col-span-full">
                    <div className="flex flex-col items-center justify-center p-8 border-2 border-dashed rounded-lg">
                      <FileText className="h-12 w-12 text-muted-foreground mb-4" />
                      <h3 className="text-lg font-semibold text-muted-foreground mb-2">
                        No Trail Training Materials Available
                      </h3>
                      <p className="text-sm text-muted-foreground text-center">
                        There are currently no existing training materials to
                        choose from. Please check back later.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ) : activeTab === 'new' ? (
              <div
                {...getRootProps()}
                className={`flex w-full border-2 border-dashed rounded-lg p-6 text-center transition-colors ${
                  isDragActive
                    ? 'bg-dark-blue-200'
                    : selectedFile || formState.singleMaterialId
                    ? 'bg-dark-blue-200/50'
                    : ''
                } hover:bg-dark-blue-400 cursor-pointer min-h-46 justify-center items-center`}
              >
                <input {...getInputProps()} />
                <div className="flex flex-col items-center gap-2">
                  {isUploading ? (
                    <Spinner size="medium">
                      <span className="mt-2 text-sm text-muted-foreground">
                        Uploading file...
                      </span>
                    </Spinner>
                  ) : (
                    <>
                      <Upload className="h-8 w-8 text-muted-foreground" />
                      {selectedFile ? (
                        <div className="flex items-center gap-2">
                          <span>{selectedFile.name}</span>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={e => {
                              e.stopPropagation();
                              handleRemoveFile();
                            }}
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        </div>
                      ) : formState.singleMaterialId &&
                        training?.trainingMaterial ? (
                        <div className="flex items-center gap-2">
                          <span>
                            Current file: {training.trainingMaterial.fileName}
                          </span>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={e => {
                              e.stopPropagation();
                              handleRemoveFile();
                            }}
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        </div>
                      ) : (
                        <p>
                          {isDragActive
                            ? 'Drop the file here...'
                            : 'Drag and drop training material, or click to select'}
                        </p>
                      )}
                      <div className="flex items-center gap-1">
                        <span className="text-sm text-muted-foreground">
                          Supported format: PDF (max 10MB)
                        </span>
                        <Info className="h-4 w-4 text-muted-foreground cursor-help" />
                      </div>
                      <p className="text-sm text-muted-foreground italic">
                        Please ensure your PDF is in horizontal orientation
                      </p>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="space-y-4">
                {formState.sections.map((sec, index) => (
                  <SectionUploadField
                    key={`section-${sec.id}-${index}`}
                    section={sec}
                    onChange={updateSection}
                    onRemove={removeSection}
                    handleUploadFile={handleUploadFile}
                    existingFileName={getSectionFileName(sec.materialId)}
                  />
                ))}
                <Button
                  onClick={addSection}
                  className="w-full h-12 mt-4 border-2 border-dashed hover:border-yellow-500"
                  variant="outline"
                >
                  <Plus className="w-5 h-5 mr-2" />
                  Add New Section
                </Button>
              </div>
            )}
          </div>
        </Tabs>

        {/* Confirmation Dialog */}
        <AlertDialog
          open={showConfirmDialog}
          onOpenChange={setShowConfirmDialog}
        >
          <AlertDialogContent>
            <AlertDialogTitle>Confirm Change</AlertDialogTitle>
            <AlertDialogHeader>
              <AlertDialogDescription>{confirmMessage}</AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={handleCancelChange}>
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction onClick={handleConfirmChange}>
                Continue
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </CardContent>
    </Card>
  );
};

export default TrainingMaterialsSection;

import TextResultCard from './TextResultCard';
import ArtifactViewer from './ArtifactViewer';
import { FactViewer } from './FactViewer';
import {
  DocumentationComponentResult,
  DocumentationResultSource,
} from '@/__generated__/gql/graphql';
import {
  MermaidGraphicOutput,
  MLFramework,
  Standard,
  TaskType,
} from '@/__generated__/pydantic_parsers';
import MermaidGraphic from '@/components/MermaidGraphic';
import { SourcesAccordion } from './SourcesAccordion';
import { useFeatureFlagEnabled } from 'posthog-js/react';

interface DocumentationResultCardProps {
  component: DocumentationComponentResult;
}

export default function DocumentationResultCard({
  component,
}: DocumentationResultCardProps) {
  const showBetaFeatures = useFeatureFlagEnabled('beta-tester');

  let content = null;
  // TODO: generate enums in prompt templates and replaces hard coded
  // string values with enums
  switch (component.outputFormat) {
    case 'Standard':
      content = (
        <TextResultCard parsedOutput={JSON.parse(component.text) as Standard} />
      );
      break;
    case 'TaskType':
      content = (
        <div className="p-4">
          <FactViewer
            factResult={(JSON.parse(component.text) as TaskType).task_type}
          />
        </div>
      );
      break;
    case 'MLFramework':
      content = (
        <div className="p-4">
          <FactViewer
            factResult={
              (JSON.parse(component.text) as MLFramework).ml_framework
            }
          />
        </div>
      );
      break;
    case 'MermaidGraphicOutput':
      content = (
        <div className="p-4">
          <MermaidGraphic
            chart={
              (JSON.parse(component.text) as MermaidGraphicOutput).md_string
            }
          />
        </div>
      );
      break;
    default:
      content = <div className="p-4">{component.text}</div>;
  }
  return (
    <div className="bg-white w-full flex flex-col min-h-min rounded-md shadow-sm">
      <div className="p-4 flex flex-col items-start flex-1 w-full">
        <h5 className="text-dark-blue-600 text-lg">{component.name}</h5>
        {(!component.artifacts || component.artifacts.length === 0) && (
          <div className="text-[#1A365D]">{content}</div>
        )}
        <div className="flex flex-col gap-2 w-full">
          {component.artifacts?.map(artifact => (
            <ArtifactViewer key={artifact?.id} artifact={artifact!} />
          ))}
          {showBetaFeatures && (
            <SourcesAccordion
              sources={(component.sources ?? []).filter(
                (source): source is DocumentationResultSource => source !== null
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
}

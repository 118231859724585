import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useMemo } from 'react';
import { Task, TaskCard } from './TaskCard';
import { cva } from 'class-variance-authority';
import { Card, CardContent, CardHeader } from './ui/card';
import { Button } from './ui/button';
import { GripVertical } from 'lucide-react';
import { ScrollArea, ScrollBar } from './ui/scroll-area';
import { CSS } from '@dnd-kit/utilities';

export interface Column {
  id: string;
  title: string;
  count?: number;
}

export type ColumnType = 'Column';

export interface ColumnDragData {
  type: ColumnType;
  column: Column;
}

interface BoardColumnProps {
  column: Column;
  tasks: Task[];
  isOverlay?: boolean;
  onTaskClick?: (task: Task) => void;
}

export function BoardColumn({
  column,
  tasks,
  isOverlay,
  onTaskClick,
}: BoardColumnProps) {
  const tasksIds = useMemo(() => tasks.map(task => task.id), [tasks]);

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: column.id,
    data: {
      type: 'Column',
      column,
    } as ColumnDragData,
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  const variants = cva(
    'w-[350px] max-w-full bg-dark-blue-600 flex flex-col flex-shrink-0 snap-center touch-none',
    {
      variants: {
        dragging: {
          default: 'border-2 border-transparent',
          over: 'ring-2 opacity-30',
          overlay: 'ring-2 ring-primary h-[500px]',
        },
      },
    }
  );

  if (isOverlay) {
    return (
      <Card className="h-[500px] w-[350px] max-w-full bg-background flex flex-col flex-shrink-0 snap-center">
        <CardHeader className="p-4 font-semibold bg-dark-blue-600 border-b-2 text-left flex flex-row space-between items-center">
          <Button
            variant="ghost"
            className="p-1 text-primary/50 -ml-2 h-auto cursor-grab relative"
          >
            <span className="sr-only">{`Move column: ${column.title}`}</span>
            <GripVertical />
          </Button>
          <span className="ml-auto">{column.title}</span>
        </CardHeader>
        <ScrollArea>
          <CardContent className="flex flex-grow flex-col gap-2 p-2">
            {tasks.map(task => (
              <TaskCard key={task.id} task={task} />
            ))}
          </CardContent>
        </ScrollArea>
      </Card>
    );
  }

  // Draggable layout
  return (
    <Card
      ref={setNodeRef}
      style={style}
      className={
        variants({ dragging: isDragging ? 'over' : undefined }) +
        ' bg-background border-0'
      }
    >
      <CardHeader className="p-4 bg-dark-blue-600 rounded-t-lg font-semibold border-b-2 text-left flex flex-row space-between items-center">
        <Button
          variant="ghost"
          {...attributes}
          {...listeners}
          className="p-1 text-secondary-foreground/50 -ml-2 h-auto cursor-grab"
        >
          <span className="sr-only">Move column</span>
          <GripVertical />
        </Button>
        <span className="ml-auto">
          {column.title} ({tasks.length})
        </span>
      </CardHeader>
      <ScrollArea>
        <CardContent className="flex flex-grow flex-col gap-2 p-2">
          <SortableContext
            items={tasksIds}
            strategy={verticalListSortingStrategy}
          >
            {tasks.map(task => (
              <TaskCard
                key={task.id}
                task={task}
                onClick={() => onTaskClick?.(task)}
              />
            ))}
          </SortableContext>
        </CardContent>
      </ScrollArea>
    </Card>
  );
}

export function BoardContainer({ children }: { children: React.ReactNode }) {
  return (
    <ScrollArea className="px-2 md:px-0 flex lg:justify-center pb-4">
      <div className="flex gap-4 items-center flex-row justify-center">
        {children}
      </div>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
}

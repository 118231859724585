import {
  Box,
  Chip,
  Divider,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { gql } from '@/__generated__/gql';
import { Experiment } from '@/__generated__/gql/graphql';
import Timestamp from '@/components/Timestamp';
import { detailExperimentState } from '../TreeSettingsAtoms';
import TabPanel from '@/components/TabPanel';
import FriendlyId from '@/components/ui/friendly-id';

import { useSuspenseQuery } from '@apollo/client';
import RunFactsTab from './tabs/RunFactsTab';
import ArtifactsTab from './tabs/ArtifactsTab';
import DataSetIdTab from './tabs/DataSetIdTab';
import FilesTab from './tabs/FilesTab';
import DocumentationTab from './tabs/DocumentationTab';

type Props = {
  projectId: string;
  experiment: Experiment | undefined;
  businessMeasurement?: any;
  gitSyncEnabled?: boolean;
};

const GET_EXPERIMENT_DATA = gql(`
  query getExperiment($experimentId: String!) {
    experiment(id: $experimentId) {
      id
      title
      parentExperimentId
      comments
      hypothesis
      creationTimestamp
      metricDelta
      paramDelta
      gitCommitHash
      dataSetStats {
        id 
        set
      }
      artifacts {
        id
        name
        size
        contentType
        tags
        url
      }
      instanceRuns {
        id
        isComplete
        parameters
        metrics
        metricsHistory {
          metricName
          history {
          value 
          step
          timeStamp
        }
        }
      }
    }
  }
`);

function ExperimentDetailViewSidebar({
  projectId,
  experiment,
  businessMeasurement,
  gitSyncEnabled = false,
}: Props) {
  const [tab, setTab] = useState(0);
  const setDetailExperiment = useSetRecoilState(detailExperimentState);
  const { data: experimentData } = useSuspenseQuery(GET_EXPERIMENT_DATA, {
    variables: { experimentId: experiment!.id },
  });

  useEffect(() => {
    if (experimentData) {
      setDetailExperiment(experimentData?.experiment ?? undefined);
    }
  }, [experimentData, setDetailExperiment]);

  const instanceRuns = experiment?.instanceRuns ?? [];
  const sampleInstanceRun = instanceRuns[0];

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <Grid container p="10px 20px 20px 20px" justifyContent="space-between">
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box>
              <Typography variant="h4" component="h1" style={{ margin: 0 }}>
                Experiment{' '}
                <FriendlyId
                  id={experiment!.id}
                  subtle={true}
                  showFull={false}
                  inline={true}
                />
              </Typography>
              <Timestamp timestamp={experiment!.creationTimestamp ?? 0} />
            </Box>
            {sampleInstanceRun?.isComplete ? (
              <Chip
                sx={{ m: '20px 0 20px 20px' }}
                icon={<CheckCircleOutlineIcon />}
                label="Complete run"
                color="success"
                variant="outlined"
              />
            ) : (
              <Tooltip title="The model run was interrupted by the user.">
                <Chip
                  sx={{ m: '20px 0 20px 20px' }}
                  icon={<ErrorOutlineIcon />}
                  label="Partial run"
                  color="warning"
                  variant="outlined"
                />
              </Tooltip>
            )}
          </Box>
          <Divider
            sx={{ borderBottomWidth: 1, bgcolor: '#FFFFFF', marginTop: '8px' }}
          />
        </Grid>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Run Facts" id="simple-tab-0" />
          <Tab label="Artifacts" id="simple-tab-1" />
          <Tab label="Data Set ID" id="simple-tab-2" />
          <Tab label="Files" id="simple-tab-3" />
          <Tab label="Documentation" id="simple-tab-4" />
        </Tabs>
      </Grid>

      <TabPanel p="0 20px 10px 20px" value={tab} index={0}>
        <RunFactsTab
          projectId={projectId}
          experiment={experiment!}
          businessMeasurement={businessMeasurement}
        />
      </TabPanel>

      <TabPanel p="0 20px 10px 20px" height="100%" value={tab} index={1}>
        <ArtifactsTab experiment={experiment!} />
      </TabPanel>

      <TabPanel p="0 20px 10px 20px" value={tab} index={2}>
        <DataSetIdTab experiment={experiment!} />
      </TabPanel>

      <TabPanel p="0 20px 10px 20px" height="100%" index={3} value={tab}>
        <FilesTab
          projectId={projectId}
          experiment={experiment!}
          gitSyncEnabled={gitSyncEnabled}
        />
      </TabPanel>

      <TabPanel p="0 20px 10px 20px" height="100%" index={4} value={tab}>
        <DocumentationTab projectId={projectId} experiment={experiment!} />
      </TabPanel>
    </>
  );
}

export default ExperimentDetailViewSidebar;

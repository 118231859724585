import React from 'react';
import { useMutation, useSuspenseQuery, useApolloClient } from '@apollo/client';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { gql } from '@/__generated__/gql';
import {
  Experiment,
  GetExperimentDocumentationsQuery,
  GetExperimentDocumentationResultsQuery,
} from '@/__generated__/gql/graphql';
import { useResetDocumentState } from '@/documentations/documentation_view/DocumentationPageStates';
import { formatDateTime } from '@/utils/dateTimeHelpers';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import {
  CalendarIcon,
  FileIcon,
  PlusIcon,
  DownloadIcon,
  FileTextIcon,
  FileJsonIcon,
} from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

type DocumentationTabProps = {
  projectId: string;
  experiment: Experiment;
};

const GET_EXPERIMENT_DOCUMENTATIONS = gql(`
  query getExperimentDocumentations($projectId: String!) {
    experimentDocs: allDocumentations(
      projectId: $projectId, 
      documentationScope: EXPERIMENT
    ) {
      id
      name
      documentationScope
      project {
        title
      }
    }
  }
`);

const GET_EXPERIMENT_DOCUMENTATION_RESULTS = gql(`
  query getExperimentDocumentationResults($experimentId: String!) {
    experiment(id: $experimentId) {
      id
      documentationResults {
        edges {
          node {
            id
            creationTimestamp
            documentation {
              id
              name
            }
          }
        }
      }
    }
  }
`);

const DOCUMENTATION_RESULT_CONTENT_QUERY = gql(`
  query GetDocumentationResultContent($documentationResultId: String!) {
    documentationResult(id: $documentationResultId) {
      id
      markdownContent
      plainTextContent
      jsonContent
    }
  }
`);

const GENERATE_DOCUMENTATION_MUTATION = gql(`
  mutation GenerateDocumentationFromExperimentView(
    $projectId: String!
    $documentationId: String!
    $experimentId: String!
  ) {
    generateDocumentationResult(
      projectId: $projectId
      documentationId: $documentationId
      experimentId: $experimentId
    ) {
      documentationResult {
        id
      }
    }
  }
`);

interface DownloadButtonProps {
  documentationResultId: string;
  onDownload: (
    documentationResultId: string,
    format: 'markdown' | 'text' | 'json'
  ) => void;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  documentationResultId,
  onDownload,
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className="text-[#a3b8d9] hover:bg-[#15355e] hover:text-white flex items-center gap-1"
          onClick={e => e.stopPropagation()}
        >
          <DownloadIcon className="h-4 w-4" />
          <span>Download</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        side="bottom"
        sideOffset={5}
        className="bg-[#0c1c36] border border-[#0d2045] text-white !z-[9999] shadow-lg"
      >
        <DropdownMenuItem
          className="flex items-center gap-2 cursor-pointer hover:bg-[#15355e]"
          onClick={e => {
            e.stopPropagation();
            onDownload(documentationResultId, 'markdown');
          }}
        >
          <FileTextIcon className="h-4 w-4" />
          <span>Download as Markdown</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="flex items-center gap-2 cursor-pointer hover:bg-[#15355e]"
          onClick={e => {
            e.stopPropagation();
            onDownload(documentationResultId, 'text');
          }}
        >
          <FileTextIcon className="h-4 w-4" />
          <span>Download as Text</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="flex items-center gap-2 cursor-pointer hover:bg-[#15355e]"
          onClick={e => {
            e.stopPropagation();
            onDownload(documentationResultId, 'json');
          }}
        >
          <FileJsonIcon className="h-4 w-4" />
          <span>Download as JSON</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const DocumentationTab: React.FC<DocumentationTabProps> = ({
  projectId,
  experiment,
}) => {
  const navigate = useNavigate();
  const resetDocumentState = useResetDocumentState();
  const client = useApolloClient();

  const { data: documentationsData } =
    useSuspenseQuery<GetExperimentDocumentationsQuery>(
      GET_EXPERIMENT_DOCUMENTATIONS,
      {
        variables: { projectId },
      }
    );

  const { data: documentationResultsData } =
    useSuspenseQuery<GetExperimentDocumentationResultsQuery>(
      GET_EXPERIMENT_DOCUMENTATION_RESULTS,
      {
        variables: { experimentId: experiment.id },
      }
    );

  const [generateDocumentation, { loading: isGeneratingDocumentation }] =
    useMutation(GENERATE_DOCUMENTATION_MUTATION, {
      onCompleted: _data => {
        toast.success('Documentation generated successfully');
      },
      onError: error => {
        toast.error(`Failed to generate documentation: ${error.message}`);
      },
      refetchQueries: [
        {
          query: GET_EXPERIMENT_DOCUMENTATION_RESULTS,
          variables: { experimentId: experiment.id },
        },
      ],
    });

  const handleGenerateDocumentation = (documentationId: string) => {
    generateDocumentation({
      variables: {
        projectId,
        documentationId,
        experimentId: experiment.id,
      },
    });
  };

  const handleViewDocumentationResult = (documentationResultId: string) => {
    resetDocumentState();
    navigate(`/documentation/result/${documentationResultId}`);
  };

  const handleDownloadContent = async (
    documentationResultId: string,
    format: 'markdown' | 'text' | 'json'
  ) => {
    try {
      console.log(
        'Downloading content in format:',
        format,
        'for result ID:',
        documentationResultId
      );

      const { data } = await client.query({
        query: DOCUMENTATION_RESULT_CONTENT_QUERY,
        variables: { documentationResultId },
        fetchPolicy: 'network-only', // Ensure we get fresh data
      });

      console.log('Query response:', data);

      if (!data?.documentationResult) {
        toast.error('Failed to fetch documentation content');
        return;
      }

      let content = '';
      let filename = '';
      let mimeType = '';

      const docName =
        documentationResultsData?.experiment?.documentationResults?.edges.find(
          edge => edge.node.id === documentationResultId
        )?.node.documentation.name || 'documentation';
      const sanitizedName = docName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      const dateStr = new Date().toISOString().split('T')[0];

      if (format === 'markdown') {
        content = data.documentationResult.markdownContent;
        filename = `${sanitizedName}_${dateStr}.md`;
        mimeType = 'text/markdown';
      } else if (format === 'text') {
        content = data.documentationResult.plainTextContent;
        filename = `${sanitizedName}_${dateStr}.txt`;
        mimeType = 'text/plain';
      } else if (format === 'json') {
        content = data.documentationResult.jsonContent;
        filename = `${sanitizedName}_${dateStr}.json`;
        mimeType = 'application/json';
      }

      // Create a blob and download it
      const blob = new Blob([content], { type: mimeType });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      toast.success(`Downloaded ${format} file successfully`);
    } catch (error) {
      console.error('Download error:', error);
      toast.error(
        `Failed to download: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`
      );
    }
  };

  const getLatestResultForTemplate = (templateId: string) => {
    if (!documentationResultsData?.experiment?.documentationResults?.edges)
      return null;

    const results =
      documentationResultsData.experiment.documentationResults.edges
        .filter(({ node }) => node.documentation.id === templateId)
        .sort((a, b) => {
          const dateA = new Date(b.node.creationTimestamp).getTime();
          const dateB = new Date(a.node.creationTimestamp).getTime();
          return dateA - dateB;
        });

    return results.length > 0 ? results[0].node : null;
  };

  return (
    <div className="space-y-6 bg-[#0a1629] p-6 rounded-lg text-white">
      <div>
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-2xl font-semibold text-white">
            Documentation Templates
          </h3>
          <Button
            onClick={() => {
              resetDocumentState();
              navigate('/documentation/?view=template');
            }}
            className="flex items-center gap-2 bg-[#1a3a6c] hover:bg-[#254a82]"
          >
            <PlusIcon className="h-4 w-4" />
            Create New Template
          </Button>
        </div>

        {!documentationsData?.experimentDocs ||
        documentationsData.experimentDocs.length === 0 ? (
          <Card className="border border-[#0d2045] bg-[#0c1c36] shadow-md text-white">
            <CardHeader>
              <CardTitle className="text-white">
                No Documentation Templates Found
              </CardTitle>
              <CardDescription className="text-[#8aa3c8]">
                There are no experiment-specific documentation templates
                available.
              </CardDescription>
            </CardHeader>
            <CardFooter>
              <Button
                onClick={() => {
                  resetDocumentState();
                  navigate('/documentation/?view=template');
                }}
                className="bg-[#1a3a6c] hover:bg-[#254a82]"
              >
                Create New Template
              </Button>
            </CardFooter>
          </Card>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {documentationsData.experimentDocs.map(doc => {
              const latestResult = getLatestResultForTemplate(doc.id);

              return (
                <Card
                  key={doc.id}
                  className="overflow-hidden border border-[#0d2045] bg-[#0c1c36] shadow-md hover:shadow-lg transition-shadow text-white"
                >
                  <CardHeader className="pb-2">
                    <CardTitle className="text-white">{doc.name}</CardTitle>
                    <CardDescription className="text-[#8aa3c8]">
                      Documentation Template
                    </CardDescription>
                  </CardHeader>

                  <CardContent className="pb-2">
                    <div className="flex justify-between gap-4">
                      <Button
                        variant="outline"
                        size="sm"
                        className="border-[#1a3a6c] text-[#a3b8d9] hover:bg-[#0f2a4d] hover:text-white"
                        onClick={() => {
                          resetDocumentState();
                          navigate(`/documentation/${doc.id}`);
                        }}
                      >
                        View Template
                      </Button>
                      <Button
                        size="sm"
                        className="bg-[#1a3a6c] hover:bg-[#254a82]"
                        disabled={isGeneratingDocumentation}
                        onClick={() => handleGenerateDocumentation(doc.id)}
                      >
                        {isGeneratingDocumentation
                          ? 'Generating...'
                          : 'Generate'}
                      </Button>
                    </div>
                  </CardContent>

                  {latestResult && (
                    <>
                      <Separator className="bg-[#0d2045]" />
                      <CardHeader className="py-2">
                        <CardTitle className="text-sm font-medium text-white">
                          Latest Generated Result
                        </CardTitle>
                      </CardHeader>
                      <CardContent className="pt-0">
                        <div className="flex flex-col gap-2">
                          <div
                            className="flex items-center gap-2 p-2 rounded-md bg-[#0f2a4d] cursor-pointer hover:bg-[#15355e] transition-colors"
                            onClick={() =>
                              handleViewDocumentationResult(latestResult.id)
                            }
                          >
                            <FileIcon className="h-4 w-4 text-[#8aa3c8]" />
                            <div className="flex-1">
                              <div className="text-sm font-medium text-[#a3b8d9]">
                                Generated on
                              </div>
                              <div className="text-xs text-[#8aa3c8] flex items-center gap-1">
                                <CalendarIcon className="h-3 w-3" />
                                {formatDateTime(latestResult.creationTimestamp)}
                              </div>
                            </div>
                            <Button
                              variant="ghost"
                              size="sm"
                              className="text-[#a3b8d9] hover:bg-[#15355e] hover:text-white"
                              onClick={e => {
                                e.stopPropagation();
                                handleViewDocumentationResult(latestResult.id);
                              }}
                            >
                              View
                            </Button>
                          </div>

                          <div className="flex justify-end">
                            <DownloadButton
                              documentationResultId={latestResult.id}
                              onDownload={handleDownloadContent}
                            />
                          </div>
                        </div>
                      </CardContent>
                    </>
                  )}
                </Card>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentationTab;

const IMAGES = {
  errorImage: new URL('../resources/unknown_error.png', import.meta.url).href,
  trailLogo: new URL('../resources/trail_logo.svg', import.meta.url).href,
  atlassianLogo: new URL('../resources/atlassian_logo.svg', import.meta.url)
    .href,
  mlFlowLogo: new URL('../resources/mlflow_logo.png.webp', import.meta.url)
    .href,
  gdriveLogo: new URL('../resources/gdrive_logo.svg', import.meta.url).href,
  linkedInLogo: new URL('../resources/linkedin_logo.svg', import.meta.url).href,
};

export default IMAGES;

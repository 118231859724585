import React from 'react';
import { Box } from '@mui/material';
import { Spinner } from './spinner';
import { cn } from '@/lib/utils';

interface LoadingProps {
  message?: string;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  fullHeight?: boolean;
}

export function Loading({
  message,
  size = 'medium',
  className,
  fullHeight = true,
}: LoadingProps) {
  return (
    <Box
      className={cn(
        'flex flex-col items-center justify-center w-full gap-2',
        fullHeight && 'min-h-[300px]',
        className
      )}
    >
      <Spinner size={size} />
      {message && <p className="text-muted-foreground">{message}</p>}
    </Box>
  );
}

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import ExternalContextStage from './ExternalContextStage';
import InternalContextStage from './InternalContextStage';
import AIObjectivesStage from './AIObjectivesStage';
import AIManagementStage from './AIManagementStage';
import AIActivitiesStage from './AIActivitiesStage';
import { scrollbarSx } from '../components/StyledScrollable';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { AiDefinition, OrganizationQuery } from '../__generated__/gql/graphql';
import { toast } from 'sonner';
import { colorThemes } from '../theme';
import { TabContext, TabList } from '@mui/lab';

const GET_QUESTIONNAIRE = gql(`
query Organization {
  organization {
    id
    profileQuestionnaire {
      id
      organizationProfileId
      reviewPeriod
      
      # external context
      headquarterCountry
      countries
      regulations
      externalContext
      externalParties {
        id
        name
        requirements
      }

      # internal context
      valueNorms {
        id
        organizationValue
        description
      }
      internalContext
      internalParties {
        id
        name
        requirements
      }
      riskProfile
      internalPolicies

      # AI objectives stage
      aiStrategy
      aiStrategyLink
      objectives {
        id
        objectiveName
        description
        measure
        customObjectiveName
      }
      principles {
        id
        principleName
        description
        measure
        customPrincipleName
      }

      # AI management stage
      aiManagementSystem {
        id
        available
        internalProcess
      }
      approvalOfAiUseCases
      scopeInPolicy
      systemScope
      resources
      prohibitedCategories {
        id
        name
        euAiAct
      }
      prohibitedSystems {
        id
        systemName
        provider
        reason
      }

      # AI activities stage
      definitionOfAi {
        id
        predefinedDefinition
        customDefinition
      }
      aiUseCases {
        id
        developFinetune
        thirdParty
        adaptingThirdParty
        selling
      }
      purposeOfAiPolicy {
        id
        outlineUseCases
        governingDevelopment
        alignAiWithOrganisation
        safeAiUsage
        promoteAiLiteracy
        otherPurpose
        customPurpose
        customPurposeText
      }
      applicability
      enforcement
      exceptions
      violationResponsible
      nonCompliance
    }
  }
}
`);

const UPDATE_ORGANIZATION_PROFILE = gql`
  mutation UpdateOrganizationProfile(
    $profileQuestionnaire: ProfileQuestionnaireInput
  ) {
    updateOrganizationProfile(profileQuestionnaire: $profileQuestionnaire) {
      organization {
        name
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dictionary = Record<string, any>;
function removeTypenameKey(arrayOfDictionaries: Dictionary[]): Dictionary[] {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return arrayOfDictionaries.map(({ __typename, ...rest }) => rest);
}

export default function OrganizationProfileQuestionnaire() {
  const { data } = useSuspenseQuery<OrganizationQuery>(GET_QUESTIONNAIRE, {
    fetchPolicy: 'network-only',
  });

  const questionnaire = data?.organization?.profileQuestionnaire;

  const regulations =
    questionnaire?.regulations?.map(reg => ({ regulation: reg })) || [];

  const internalPolicies =
    questionnaire?.internalPolicies?.map(reg => ({ regulation: reg })) || [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formSchema: any = {
    // overall
    reviewPeriod: questionnaire?.reviewPeriod || 3,

    // external context
    headquarterCountry: questionnaire?.headquarterCountry || '',
    countries: questionnaire?.countries || [],
    regulations,
    externalContext: questionnaire?.externalContext || '',
    externalParties: questionnaire?.externalParties || [
      { id: '', name: '', requirements: '' },
    ],

    // internal context
    valueNorms: questionnaire?.valueNorms || [
      { id: '', organizationValue: '', description: '' },
    ],
    internalParties: questionnaire?.internalParties || [
      { id: '', name: '', requirements: '' },
    ],
    riskProfile: questionnaire?.riskProfile || '',
    internalPolicies,
    internalContext: questionnaire?.internalContext || '',

    // AI objectives stage
    aiStrategy: questionnaire?.aiStrategy || '',
    aiStrategyLink: questionnaire?.aiStrategyLink || '',
    objectives: questionnaire?.objectives || [
      {
        id: '',
        objectiveName: '',
        description: '',
        measure: '',
        customObjectiveName: '',
      },
    ],
    principles: questionnaire?.principles || [
      {
        id: '',
        principleName: '',
        description: '',
        measure: '',
        customPrincipleName: '',
      },
    ],

    // AI management stage
    aiManagementSystem: questionnaire?.aiManagementSystem || {
      id: '',
      available: 'yes',
      internalProcess: '',
    },
    approvalOfAiUseCases: questionnaire?.approvalOfAiUseCases || '',
    scopeInPolicy: questionnaire?.scopeInPolicy || false,
    systemScope: questionnaire?.systemScope || '',
    resources: questionnaire?.resources || [''],
    prohibitedCategories: questionnaire?.prohibitedCategories || [
      { id: '', name: '', euAiAct: false },
    ],
    prohibitedSystems: questionnaire?.prohibitedSystems || [
      { id: '', systemName: '', provider: '', reason: '' },
    ],

    // AI activities stage
    definitionOfAi: questionnaire?.definitionOfAi || {
      id: '',
      predefinedDefinition: AiDefinition.Oecd,
      customDefinition: '',
    },
    aiUseCases: questionnaire?.aiUseCases || {
      id: '',
      developFinetune: false,
      selling: false,
      thirdParty: false,
      adaptingThirdParty: false,
    },
    purposeOfAiPolicy: questionnaire?.purposeOfAiPolicy || {
      id: '',
      outlineUseCases: false,
      governingDevelopment: false,
      promoteAiLiteracy: false,
      alignAiWithOrganisation: false,
      safeAiUsage: false,
      otherPurpose: '',
      customPurpose: false,
      customPurposeText: '',
    },
    applicability: questionnaire?.applicability || '',
    enforcement: questionnaire?.enforcement || '',
    exceptions: questionnaire?.exceptions || '',
    violationResponsible: questionnaire?.violationResponsible || '',
    nonCompliance: questionnaire?.nonCompliance || '',
  };
  const methods = useForm({ defaultValues: formSchema });

  const [updateOrganizationProfile] = useMutation(UPDATE_ORGANIZATION_PROFILE, {
    onCompleted: () => {
      toast.success('Organization profile saved successfully');
    },
    onError: error => {
      toast.error(`Error saving organization profile: ${error.message}`);
    },
  });

  function SaveButton() {
    const getValidEnumValue = (value: string) => (value === '' ? null : value);
    function handleSave() {
      updateOrganizationProfile({
        variables: {
          profileQuestionnaire: {
            // overall
            reviewPeriod: methods.getValues('reviewPeriod'),

            // external context
            headquarterCountry: methods.getValues('headquarterCountry'),
            countries: methods.getValues('countries'),
            regulations: methods.getValues('regulations'),
            externalContext: methods.getValues('externalContext'),
            externalParties: removeTypenameKey(
              methods.getValues('externalParties')
            ),

            // internal context
            valueNorms: removeTypenameKey(methods.getValues('valueNorms')),
            internalParties: removeTypenameKey(
              methods.getValues('internalParties')
            ),
            riskProfile: methods.getValues('riskProfile'),
            internalPolicies: methods.getValues('internalPolicies'),
            internalContext: methods.getValues('internalContext'),

            // AI objectives stage
            aiStrategy: methods.getValues('aiStrategy'),
            aiStrategyLink: methods.getValues('aiStrategyLink'),
            objectives: removeTypenameKey(methods.getValues('objectives')),
            principles: removeTypenameKey(methods.getValues('principles')),

            // AI management stage
            aiManagementSystem: methods.getValues('aiManagementSystem'),
            approvalOfAiUseCases: methods.getValues('approvalOfAiUseCases'),
            scopeInPolicy: methods.getValues('scopeInPolicy'),
            systemScope: methods.getValues('systemScope'),
            resources: methods.getValues('resources'),
            prohibitedCategories: methods.getValues('prohibitedCategories'),
            prohibitedSystems: methods.getValues('prohibitedSystems'),

            // AI activities stage
            definitionOfAi: {
              predefinedDefinition: getValidEnumValue(
                methods.getValues('definitionOfAi.predefinedDefinition')
              ),
              customDefinition: methods.getValues(
                'definitionOfAi.customDefinition'
              ),
            },
            aiUseCases: methods.getValues('aiUseCases'),
            purposeOfAiPolicy: methods.getValues('purposeOfAiPolicy'),
            applicability: methods.getValues('applicability'),
            enforcement: methods.getValues('enforcement'),
            exceptions: methods.getValues('exceptions'),
            violationResponsible: methods.getValues('violationResponsible'),
            nonCompliance: methods.getValues('nonCompliance'),
          },
        },
      });
    }
    return (
      <Button variant="contained" onClick={handleSave}>
        Save
      </Button>
    );
  }

  const [value, setValue] = useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          overflowY: 'hidden',
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: '2rem' }}>
          Organization Profile
        </Typography>
        <TabContext value={value}>
          <Box sx={{ display: 'inline-block' }}>
            <TabList
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                background: colorThemes.DARK_BLUE_800,
              }}
            >
              <Tab
                label="External Context"
                {...a11yProps(0)}
                sx={{ background: colorThemes.DARK_BLUE_800 }}
              />
              <Tab
                label="Internal Context"
                {...a11yProps(1)}
                sx={{ background: colorThemes.DARK_BLUE_800 }}
              />
              <Tab
                label="Objectives & Principles"
                {...a11yProps(2)}
                sx={{ background: colorThemes.DARK_BLUE_800 }}
              />
              <Tab
                label="AI Management"
                {...a11yProps(3)}
                sx={{ background: colorThemes.DARK_BLUE_800 }}
              />
              <Tab
                label="AI Activities & Other"
                {...a11yProps(4)}
                sx={{ background: colorThemes.DARK_BLUE_800 }}
              />
            </TabList>
          </Box>
          <FormProvider {...methods}>
            <Box
              sx={{
                overflowY: 'auto',
                overflowX: 'hidden',
                paddingX: '2rem',
                width: '100%',
                height: '80vh',
                paddingBottom: '3rem',
                marginTop: '1.5rem',
                ...scrollbarSx,
              }}
            >
              <CustomTabPanel value={value} index={0}>
                <ExternalContextStage />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <InternalContextStage />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <AIObjectivesStage />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <AIManagementStage />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <AIActivitiesStage />
              </CustomTabPanel>
            </Box>
          </FormProvider>
        </TabContext>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          display: 'flex',
          top: '16px',
          right: '16px',
          gap: '1rem',
        }}
      >
        <Box sx={{ width: '150px' }}>
          <Controller
            name="reviewPeriod"
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <InputLabel>Review Period</InputLabel>
                <Select
                  labelId="simple-select-period"
                  label="Review Period"
                  value={value}
                  onChange={onChange}
                  size="small"
                >
                  {ReviewPeriodOptions.map(option => (
                    <MenuItem key={option} value={option}>
                      {option} Months
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            control={methods.control}
            rules={{ required: true }}
          />
        </Box>
        <SaveButton />
      </Box>
    </>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const ReviewPeriodOptions = [3, 6, 9, 12];

import { Tooltip, Typography } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip';
import { colorThemes } from '../theme';

export const formatTextWithFormatting = (text: string) => {
  if (!text) return text;

  const formattedText = text;
  const boldParts = formattedText.split(/(\*[^*]+\*)/g);

  const boldFormatted = (
    <>
      {boldParts.map((part, index) => {
        if (part.startsWith('*') && part.endsWith('*') && part.length > 2) {
          const boldText = part.substring(1, part.length - 1);
          return <strong key={index}>{boldText}</strong>;
        }
        return part;
      })}
    </>
  );

  if (boldParts.length === 1) {
    const italicParts = text.split(/(_[^_]+_)/g);

    return (
      <>
        {italicParts.map((part, index) => {
          if (part.startsWith('_') && part.endsWith('_') && part.length > 2) {
            const italicText = part.substring(1, part.length - 1);
            return <em key={index}>{italicText}</em>;
          }
          return part;
        })}
      </>
    );
  }

  return boldFormatted;
};

export const formatTextWithItalics = (text: string) => {
  if (!text) return text;

  const parts = text.split(/(_[^_]+_)/g);

  return (
    <>
      {parts.map((part, index) => {
        if (part.startsWith('_') && part.endsWith('_') && part.length > 2) {
          const italicText = part.substring(1, part.length - 1);
          return <em key={index}>{italicText}</em>;
        }
        return part;
      })}
    </>
  );
};

export const FormattedTooltip = ({ title, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    sx={{
      marginLeft: '5px',
      fontSize: '1.25rem',
      ...props.sx,
    }}
    placement="right-start"
    componentsProps={{
      tooltip: {
        sx: {
          width: 'auto',
          minWidth: '300px',
          maxWidth: '600px',
          fontSize: '1.25rem',
        },
      },
    }}
    title={
      <Typography style={{ whiteSpace: 'pre-line' }}>
        {formatTextWithFormatting(title as string)}
      </Typography>
    }
  />
);

export const orgProfileHeaderTextStyle = {
  marginTop: '1.5rem',
  marginBottom: '10px',
  fontWeight: 600,
};

export const orgProfileSubHeaderTextStyle = {
  marginBottom: '10px',
  marginTop: '15px',
  fontWeight: 500,
  fontSize: '1rem',
};

export const orgProfileButtonStyle = {
  color: 'white',
  fontWeight: 'bold',
  gap: '4px',
  paddingRight: '1rem',
  backgroundColor: colorThemes.ACTION_BLUE,
};

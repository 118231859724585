import { Drawer } from '@mui/material';
import { Experiment } from '@/__generated__/gql/graphql';
import ExperimentDetailView from './ExperimentDetailView';
import { Suspense } from 'react';
import { useRecoilState } from 'recoil';
import { detailExperimentState } from '../TreeSettingsAtoms';
import { Loading } from '@/components/ui/loading';

type Props = {
  projectId: string;
  experiment: Experiment | undefined;
  businessMeasurement?: any;
  gitSyncEnabled: boolean;
};

function ExperimentDetailViewSidebar({
  projectId,
  experiment,
  businessMeasurement,
  gitSyncEnabled,
}: Props) {
  const [, setDetailExperiment] = useRecoilState(detailExperimentState);

  const onClose = () => {
    setDetailExperiment(undefined);
  };
  return (
    <Drawer
      anchor="right"
      open={experiment !== undefined}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 2 / 3,
        },
      }}
    >
      <Suspense fallback={<Loading message="Loading experiment ..." />}>
        <ExperimentDetailView
          projectId={projectId}
          experiment={experiment}
          businessMeasurement={businessMeasurement}
          gitSyncEnabled={gitSyncEnabled}
        />
      </Suspense>
    </Drawer>
  );
}

export default ExperimentDetailViewSidebar;

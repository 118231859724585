import { FC, useState } from 'react';
import { LiteracyTraining } from '../../__generated__/gql/graphql';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Progress } from '@/components/ui/progress';
import { CheckCircle2, XCircle } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';

interface QuestionOption {
  optionText: string;
  isCorrect: boolean;
}

interface Question {
  questionText: string;
  description: string;
  options: QuestionOption[];
}

interface QuestionsScreenProps {
  training: LiteracyTraining | null;
  onComplete: () => void;
  onFail: () => void;
}

const QuestionsScreen: FC<QuestionsScreenProps> = ({
  training,
  onComplete,
  onFail,
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
  const [showResult, setShowResult] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(0);

  const questions = (training?.questions as Question[]) || [];
  const currentQuestionData = questions[currentQuestion];

  if (!currentQuestionData || !questions.length) {
    return (
      <Alert variant="destructive">
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>No questions available.</AlertDescription>
      </Alert>
    );
  }

  const handleAnswerSelect = (optionIndex: number) => {
    if (!showResult && currentQuestionData.options) {
      setSelectedAnswer(optionIndex);
      setShowResult(true);

      if (currentQuestionData.options[optionIndex]?.isCorrect) {
        setCorrectAnswers(prev => prev + 1);
      }
    }
  };

  const handleNext = () => {
    setShowResult(false);
    setSelectedAnswer(null);

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(prev => prev + 1);
    } else {
      const finalScore = correctAnswers / questions.length;
      if (finalScore >= 0.7) {
        onComplete();
      } else {
        onFail();
      }
    }
  };

  const progress =
    ((currentQuestion + (showResult ? 1 : 0)) / questions.length) * 100;

  return (
    <Card className="max-w-3xl border-2 mx-auto w-full">
      <CardContent className="p-6">
        <div className="mb-8">
          <div className="flex justify-between items-center mb-2">
            <span className="text-sm text-muted-foreground">
              Question {currentQuestion + 1} of {questions.length}
            </span>
            <span className="text-sm text-muted-foreground">
              Score: {correctAnswers}/{currentQuestion + (showResult ? 1 : 0)}
            </span>
          </div>
          <Progress value={progress} className="w-full" />
        </div>

        <div className="mb-8 flex flex-col gap-4">
          <h2 className="text-xl font-medium">
            {currentQuestionData.questionText}
          </h2>
          <p className="text-muted-foreground whitespace-pre-line mb-6">
            {currentQuestionData.description}
          </p>
          <div className="space-y-3">
            {currentQuestionData.options?.map(
              (option: QuestionOption, index: number) => {
                if (!option) return null;

                const isSelected = selectedAnswer === index;
                const isCorrect = option.isCorrect;

                return (
                  <Button
                    key={index}
                    onClick={() => handleAnswerSelect(index)}
                    disabled={showResult}
                    variant={
                      !showResult
                        ? isSelected
                          ? 'default'
                          : 'outline'
                        : isCorrect
                        ? 'success'
                        : isSelected && !isCorrect
                        ? 'destructive'
                        : 'outline'
                    }
                    className="w-full h-auto p-4 min-h-[60px] relative"
                  >
                    <div className="flex items-start w-full gap-3">
                      <div className="flex-1 text-left overflow-hidden">
                        <span className="inline-block break-words whitespace-normal overflow-wrap-anywhere">
                          {option.optionText}
                        </span>
                      </div>
                      <div className="flex-shrink-0 ml-2">
                        {showResult && isCorrect && (
                          <CheckCircle2 className="w-5 h-5" />
                        )}
                        {showResult && isSelected && !isCorrect && (
                          <XCircle className="w-5 h-5" />
                        )}
                      </div>
                    </div>
                  </Button>
                );
              }
            )}
          </div>
        </div>

        {showResult && (
          <Button onClick={handleNext} className="w-full">
            {currentQuestion === questions.length - 1
              ? 'Finish Quiz'
              : 'Next Question'}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default QuestionsScreen;

import React, { Fragment } from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ButtonGroup,
  Tooltip,
  Grid,
} from '@mui/material';
import { gql, useSuspenseQuery } from '@apollo/client';
import { Link as RouterLink } from 'react-router-dom';
import TrashIcon from '@mui/icons-material/Delete';
import { Button } from '../components/ui/button';
import theme from '../theme';
import ProjectGridItem from './ProjectGridItem';
import AddNewItemCard from '../components/AddNewItemCard';
import NewGovernanceFrameworkModal from './NewGovernanceFrameworkModal';
import { ProjectGovernanceFrameworkTemplate } from '../__generated__/gql/graphql';

export interface ProjectGovernanceTabContentProps {
  projectId: string;
  pgfDeletePerms: boolean;
  isDeletingFramework: boolean;
  setDeleteConfirmOpen: (open: boolean) => void;
  deleteConfirmOpen: boolean;
  confirmDelete: (frameworkId: string, projectId: string) => void;
  recommendedGovernanceFrameworks: ProjectGovernanceFrameworkTemplate[];
  setIsGovernanceFrameworkModelOpen: (open: boolean) => void;
  isGovernanceFrameworkModelOpen: boolean;
}

export default function ProjectGovernanceTabContent({
  projectId,
  pgfDeletePerms,
  isDeletingFramework,
  setDeleteConfirmOpen,
  deleteConfirmOpen,
  confirmDelete,
  recommendedGovernanceFrameworks,
  setIsGovernanceFrameworkModelOpen,
  isGovernanceFrameworkModelOpen,
}: ProjectGovernanceTabContentProps) {
  const { data } = useSuspenseQuery(
    gql`
      query ProjectGovernanceFrameworks($projectId: String!) {
        project(id: $projectId) {
          id
          projectGovernanceFrameworks {
            __typename
            id
            name
          }
        }
      }
    `,
    { variables: { projectId } }
  );

  return (
    <Grid item xs={1} sm={1}>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h5">Project Governance Frameworks</Typography>
        {data?.project?.projectGovernanceFrameworks?.map(frameworkData => {
          return (
            <Fragment key={frameworkData?.id}>
              <Dialog
                open={deleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
              >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete this project governance
                    framework? This action cannot be undone.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setDeleteConfirmOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      confirmDelete(frameworkData?.id ?? '', projectId);
                    }}
                    color="error"
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
              <ProjectGridItem
                title=""
                content={
                  <Box
                    sx={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <RouterLink
                      to={`/project_governance/${projectId}/${frameworkData?.id}`}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        display: 'block',
                        height: '100%',
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h4"
                          style={{ marginBottom: '4px' }}
                        >
                          {frameworkData?.name}
                        </Typography>
                        <Typography>{frameworkData?.id}</Typography>
                      </Box>
                    </RouterLink>
                  </Box>
                }
                icon={
                  <ButtonGroup>
                    <Tooltip
                      title={
                        !pgfDeletePerms
                          ? 'Must be an admin or manager to perform this action.'
                          : ''
                      }
                    >
                      <span>
                        {pgfDeletePerms && (
                          <Button
                            variant="outline"
                            size="icon"
                            onClick={() => setDeleteConfirmOpen(true)}
                            isLoading={isDeletingFramework}
                          >
                            <TrashIcon
                              sx={{
                                color: theme.palette.error.dark,
                                '&:hover': {
                                  color: theme.palette.error.main,
                                  cursor: 'pointer',
                                },
                                transition: 'color 0.3s',
                                marginRight: '5px',
                                marginTop: '5px',
                              }}
                            />
                          </Button>
                        )}
                      </span>
                    </Tooltip>
                  </ButtonGroup>
                }
              />
            </Fragment>
          );
        })}

        <AddNewItemCard
          onClick={() => {
            setIsGovernanceFrameworkModelOpen(true);
          }}
          sx={{
            width: '100%',
            height: '100%',
            marginTop: '15px',
          }}
        />
        <NewGovernanceFrameworkModal
          open={isGovernanceFrameworkModelOpen}
          onClose={() => {
            setIsGovernanceFrameworkModelOpen(false);
          }}
          projectId={projectId}
          recommendedGovernanceFrameworks={
            recommendedGovernanceFrameworks ?? []
          }
        />
      </Box>
    </Grid>
  );
}

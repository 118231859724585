import { Input } from '@/components/ui/input';
import { Button } from '../../components/ui/button';

interface TrainingDrawerHeaderProps {
  title: string;
  isEditable: boolean;
  onTitleChange: (title: string) => void;
  onEdit: () => void;
  onSave: () => void;
  onDelete: () => void;
  saveDisabled?: boolean;
}

export function TrainingDrawerHeader({
  title,
  isEditable,
  onTitleChange,
  onEdit,
  onSave,
  onDelete,
  saveDisabled,
}: TrainingDrawerHeaderProps) {
  return (
    <div className="flex justify-between items-center p-2">
      {isEditable ? (
        <Input
          value={title}
          onChange={e => onTitleChange(e.target.value)}
          className="text-xl font-bold w-1/2"
        />
      ) : (
        <h2 className="text-xl font-bold">{title}</h2>
      )}
      <div className="flex gap-2 mr-4">
        {isEditable ? (
          <>
            <Button variant="outline" onClick={onEdit}>
              Cancel
            </Button>
            <Button onClick={onSave} disabled={saveDisabled}>
              Save
            </Button>
          </>
        ) : (
          <>
            <Button variant="outline" onClick={onEdit}>
              Edit Training
            </Button>
            <Button variant="outline" onClick={onDelete}>
              Delete
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

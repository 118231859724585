import { useState, useCallback } from 'react';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import {
  UserResponsibility,
  UserRole,
  UsersItemsQuery,
} from '@/__generated__/gql/graphql';
import { toast } from 'sonner';
import { useUnsavedChanges } from '@/hooks/useUnsavedChanges';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import UserRow from './UserRow';
import {
  FileBadge,
  IdCardIcon,
  ListIcon,
  SearchIcon,
  UserIcon,
} from 'lucide-react';
import { Input } from '@/components/ui/input';

export const GET_USERS = gql(`
  query UsersItems{
  allUsers{
    id
    name
    email
    responsibilities
    roles
    proofOfQualification
  }
}
`);

export const UPDATE_USERS = gql(`
  mutation UpdateUserRoles($updatedUsers: [RolesAndResponsibilitiesInput!]!) {
  updateUserRoles(updatedUsers: $updatedUsers) {
    users {
      id
      name
      roles
      responsibilities
    }
  }
}
`);

export const RoleResponsibilityMap: Record<UserRole, UserResponsibility[]> = {
  DEVELOPER: [
    UserResponsibility.RiskManagement,
    UserResponsibility.Safety,
    UserResponsibility.Privacy,
    UserResponsibility.Development,
    UserResponsibility.Performance,
    UserResponsibility.DataQa,
  ],
  AI_LEAD: [
    UserResponsibility.AiSystem,
    UserResponsibility.Security,
    UserResponsibility.HumanOver,
    UserResponsibility.AiPolicy,
  ],
  PROJECT_MANAGEMENT: [
    UserResponsibility.AssetRscManage,
    UserResponsibility.OrgContext,
  ],
  MANAGEMENT: [UserResponsibility.Approval],
  COMPLIANCE: [
    UserResponsibility.SupplierRel,
    UserResponsibility.LegalReq,
    UserResponsibility.AiPolicy,
  ],
};

export interface UserProps {
  id: string;
  name: string;
  email: string;
  responsibilities: UserResponsibility[];
  roles: UserRole[];
  proofOfQualification: string;
}

export default function UserManagementPage() {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { data: rawData } = useSuspenseQuery<UsersItemsQuery>(GET_USERS);

  const userData: UserProps[] = [];
  rawData?.allUsers?.forEach(user => {
    if (user) {
      userData.push({
        id: user.id,
        name: user.name ?? '',
        email: user.email,
        responsibilities:
          user.responsibilities?.filter(
            (resp): resp is UserResponsibility => resp !== null
          ) ?? [],
        roles:
          user.roles?.filter((role): role is UserRole => role !== null) ?? [],
        proofOfQualification: user.proofOfQualification ?? '',
      });
    }
  });

  const [users, setUsers] = useState(userData);

  const [updateUsersRoles, { loading }] = useMutation(UPDATE_USERS, {
    onCompleted: () => {
      toast.success('Users saved');
    },
    onError: error => {
      toast.error(`Error saving Users: ${error.message}`);
    },
    variables: {
      updatedUsers: users.map(user => ({
        userId: user.id,
        roles: user.roles,
        responsibilities: user.responsibilities,
        proofOfQualification: user.proofOfQualification,
      })),
    },
  });

  let initialAvailableResponsibilities = Object.values(UserResponsibility);
  users?.forEach(user => {
    user?.responsibilities?.forEach(resp => {
      initialAvailableResponsibilities =
        initialAvailableResponsibilities.filter(
          responsibility => responsibility !== resp
        );
    });
  });

  const [availableResponsibilities, setAvailableResponsibilities] = useState(
    initialAvailableResponsibilities
  );

  function toggleRole(role: UserRole, index: number) {
    const newUsers = [...users];
    const user = newUsers[index];
    // Guard Clauses
    if (!user) {
      return;
    }
    if (user.roles === undefined || user.roles === null) {
      user.roles = [];
    }
    if (user.responsibilities === undefined || user.responsibilities === null) {
      user.responsibilities = [];
    }
    if (!user.roles.includes(role)) {
      user.roles.push(role);
      RoleResponsibilityMap[role].forEach(responsibility => {
        if (
          availableResponsibilities.includes(responsibility) &&
          !user.responsibilities?.includes(responsibility)
        ) {
          user.responsibilities?.push(responsibility);
          setAvailableResponsibilities(prev =>
            prev.filter(resp => resp !== responsibility)
          );
        }
      });
    } else {
      user.roles = user.roles.filter(currentRole => currentRole !== role);
      RoleResponsibilityMap[role].forEach(responsibility => {
        if (user.responsibilities?.includes(responsibility)) {
          user.responsibilities = user.responsibilities.filter(
            currentResponsibility => currentResponsibility !== responsibility
          );
          setAvailableResponsibilities(prev => [...prev, responsibility]);
        }
      });
    }
    newUsers[index] = { ...user };
    setUsers(newUsers);
  }

  function removeResponsibility(
    index: number,
    responsibility: UserResponsibility
  ) {
    const newUsers = [...users];
    const user = newUsers[index];
    if (!user) {
      return;
    }
    user.responsibilities = user.responsibilities?.filter(
      currentResponsibility => currentResponsibility !== responsibility
    );
    setAvailableResponsibilities(prev => [...prev, responsibility]);
    newUsers[index] = { ...user };
    setUsers(newUsers);
  }

  function addResponsibility(
    index: number,
    responsibility: UserResponsibility
  ) {
    const newUsers = [...users];
    const user = newUsers[index];
    if (!user) {
      return;
    }
    user.responsibilities?.push(responsibility);
    setAvailableResponsibilities(prev =>
      prev.filter(resp => resp !== responsibility)
    );
    newUsers[index] = { ...user };
    setUsers(newUsers);
  }

  function onWrite(index: number, input: string) {
    const newUsers = [...users];
    const user = newUsers[index];
    if (!user) {
      return;
    }
    user.proofOfQualification = input;
    newUsers[index] = { ...user };
    setUsers(newUsers);
  }

  const isDirty = useCallback(() => {
    return JSON.stringify(users) !== JSON.stringify(userData);
  }, [users, userData]);

  useUnsavedChanges({ isDirty: isDirty() });

  return (
    <>
      <h3>Roles & Permissions</h3>
      <div className="mt-10 w-full flex justify-end ">
        <div className="flex items-center w-[400px] border rounded-md px-2">
          <SearchIcon />
          <Input
            id="search"
            type="text"
            className="w-[400px] border-none focus-visible:ring-0 focus-visible:outline-none"
            placeholder="Search for a user, role or responsibility"
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-4  w-full">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>
                <div className="flex items-center gap-2">
                  <UserIcon className="w-4 h-4" />
                  Person
                </div>
              </TableHead>
              <TableHead>
                <div className="flex items-center gap-2">
                  <IdCardIcon className="w-4 h-4" />
                  Roles
                </div>
              </TableHead>
              <TableHead>
                <div className="flex items-center gap-2">
                  <ListIcon className="w-4 h-4" />
                  Responsibilities
                </div>
              </TableHead>
              <TableHead>
                <div className="flex items-center gap-2">
                  <FileBadge className="w-4 h-4" />
                  Proof of Qualification
                </div>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {users
              .filter(
                user =>
                  user?.name
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                  user?.email
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                  user?.roles?.some(role =>
                    role?.toLowerCase().includes(searchTerm.toLowerCase())
                  )
              )
              .map(
                (user, index) =>
                  user && (
                    <UserRow
                      user={user}
                      index={index}
                      key={index}
                      addResponsibility={addResponsibility}
                      toggleRole={toggleRole}
                      removeResponsibility={removeResponsibility}
                      availableResponsibilities={availableResponsibilities}
                      onWrite={onWrite}
                    />
                  )
              )}
          </TableBody>
        </Table>
      </div>
      <Button
        onClick={() => updateUsersRoles()}
        disabled={loading}
        className="absolute bottom-4 right-4"
      >
        {loading && <Spinner className="text-black" />}
        {loading ? 'Saving...' : 'Save Changes'}
      </Button>
    </>
  );
}

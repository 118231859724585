import Markdown, { Components } from 'react-markdown';
import rehypeRaw from 'rehype-raw';

interface Props {
  mdString?: string;
}

export function MarkdownRendering({ mdString }: Props): JSX.Element {
  const markdownComponents: Components = {
    p: ({ ...props }) => {
      return <p>{props.children}</p>;
    },
    h1: ({ ...props }) => (
      <h1 className="text-2xl font-bold">{props.children}</h1>
    ),
    h2: ({ ...props }) => (
      <h2 className="text-xl font-semibold ">{props.children}</h2>
    ),

    // Lists
    ul: ({ ...props }) => <ul className="list-disc pl-5">{props.children}</ul>,
    ol: ({ ...props }) => (
      <ol className="list-decimal pl-5">{props.children}</ol>
    ),
    li: ({ ...props }) => <li className="mb-1">{props.children}</li>,

    sup: ({ ...props }) => (
      <sup className="text-xs no-underline mx-px">{props.children}</sup>
    ),

    code: ({ ...props }) => (
      <code className="break-words whitespace-pre-wrap font-mono bg-grey-100 px-1 rounded">
        {props.children}
      </code>
    ),
  };
  return (
    <div className="text-black">
      <Markdown components={markdownComponents} rehypePlugins={[rehypeRaw]}>
        {mdString}
      </Markdown>
    </div>
  );
}

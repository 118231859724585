import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import { useMutation, gql } from '@apollo/client';
import { toast } from 'sonner';
import { GET_LOCATIONS } from './TreeVisualizerGraph';

const ADD_EXPERIMENT = gql`
  mutation AddExperiment(
    $projectId: String!
    $parentExperimentId: String!
    $title: String!
    $comments: String!
    $hypothesis: String
  ) {
    addExperiment(
      projectId: $projectId
      parentExperimentId: $parentExperimentId
      title: $title
      comments: $comments
      hypothesis: $hypothesis
      instanceRuns: {
        comment: ""
        parameters: {}
        metrics: {}
        isComplete: true
      }
    ) {
      experiment {
        id
        title
        comments
      }
    }
  }
`;

type NewExperimentModalProps = {
  open: boolean;
  onClose: () => void;
  projectId: string;
  parentExperimentId: string;
};

export default function NewExperimentModal({
  open,
  onClose,
  projectId,
  parentExperimentId,
}: NewExperimentModalProps) {
  const [title, setTitle] = useState('');
  const [comments, setComments] = useState('');
  const [hypothesis, setHypothesis] = useState('');

  const [addExperiment] = useMutation(ADD_EXPERIMENT, {
    refetchQueries: [
      {
        query: GET_LOCATIONS,
        variables: { project_id: projectId },
      },
    ],
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await addExperiment({
        variables: {
          projectId,
          parentExperimentId,
          title,
          comments,
          hypothesis,
        },
      });
      toast.success('Experiment created successfully!');
      onClose();
    } catch (error) {
      console.error('Error creating experiment:', error);
      toast.error('Failed to create experiment. Please try again.');
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          borderRadius: '10px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Create New Experiment
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Title"
            value={title}
            onChange={e => setTitle(e.target.value)}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Comments"
            value={comments}
            onChange={e => setComments(e.target.value)}
            margin="normal"
            multiline
            rows={3}
          />
          <TextField
            fullWidth
            label="Hypothesis"
            value={hypothesis}
            onChange={e => setHypothesis(e.target.value)}
            margin="normal"
            multiline
            rows={2}
          />
          <Button type="submit" variant="contained" sx={{ mt: 2 }}>
            Create Experiment
          </Button>
        </form>
      </Box>
    </Modal>
  );
}

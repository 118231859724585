import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFieldArray, useFormContext, Controller } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  OrganizationObjective,
  OrganizationPrinciple,
} from '../__generated__/gql/graphql';
import {
  FormattedTooltip,
  orgProfileButtonStyle,
  orgProfileHeaderTextStyle,
} from './OrganizationProfileFormatting';

export default function AIObjectivesStage() {
  const { register, control, watch } = useFormContext();

  const {
    fields: fieldsObjectives,
    append: appendObjective,
    remove: removeObjective,
  } = useFieldArray({
    control,
    name: 'objectives',
  });

  if (fieldsObjectives.length === 0) {
    appendObjective({ objectiveName: '', description: '', measure: '' });
  }

  const addFieldObjective = () => {
    appendObjective({ objectiveName: '', description: '', measure: '' });
  };

  const {
    fields: fieldsPrinciples,
    append: appendPrinciple,
    remove: removePrinciple,
  } = useFieldArray({
    control,
    name: 'principles',
  });

  if (fieldsPrinciples.length === 0) {
    appendPrinciple({ principleName: '', description: '', measure: '' });
  }

  const addFieldPrinciple = () => {
    appendPrinciple({ principleName: '', description: '', measure: '' });
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Typography
          variant="h4"
          {...orgProfileHeaderTextStyle}
          sx={{ marginTop: '20px', marginBottom: '20px' }}
        >
          AI Strategy
        </Typography>
        <FormattedTooltip
          title={aiStrategyTooltipText}
          sx={{
            marginBottom: '20px',
          }}
        >
          <InfoOutlinedIcon fontSize="large" />
        </FormattedTooltip>
      </div>
      <TextField
        id="outlined-basic"
        label="What is your AI strategy?"
        variant="outlined"
        multiline
        rows={5}
        {...register('aiStrategy')}
        sx={{ width: '100%', marginBottom: '1rem' }}
      />
      <TextField
        id="ai-strategy-link"
        label="Link to your AI Strategy"
        variant="outlined"
        {...register('aiStrategyLink')}
        sx={{ width: '100%', marginBottom: '1rem' }}
        placeholder="https://..."
      />

      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Typography variant="h4" {...orgProfileHeaderTextStyle}>
          Objectives
        </Typography>
        <FormattedTooltip
          title={aiObjectivesTooltipText}
          sx={{ marginBottom: '10px' }}
        >
          <InfoOutlinedIcon fontSize="large" />
        </FormattedTooltip>
      </div>
      <Button
        sx={{
          ...orgProfileButtonStyle,
          marginTop: '20px',
        }}
        onClick={addFieldObjective}
      >
        <AddIcon />
        Add Objective
      </Button>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {fieldsObjectives.map((_item, index) => (
          <Grid
            container
            spacing={2}
            key={index + '_objective'}
            sx={{ marginTop: '10px', marginBottom: '10px' }}
          >
            <Grid item xs={4} sx={{ display: 'flex' }}>
              <Controller
                name={`objectives.${index}.objectiveName`}
                render={({ field: { value, onChange } }) => (
                  <FormControl fullWidth>
                    <InputLabel>Objective #{index + 1}</InputLabel>
                    <Select
                      labelId="simple-select-objective"
                      label="Objective"
                      sx={{ width: '100%' }}
                      value={value}
                      onChange={onChange}
                    >
                      {Object.entries(OrganizationObjective).map(
                        ([key, val]) => (
                          <MenuItem key={val} value={val}>
                            {ObjectiveEnumMapping[key] || key}
                          </MenuItem>
                        )
                      )}
                      <MenuItem value="CUSTOM">Custom Objective</MenuItem>
                    </Select>
                  </FormControl>
                )}
                control={control}
                rules={{ required: false }}
              />
              <IconButton
                onClick={() => removeObjective(index)}
                sx={{ height: '100%' }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
            {watch(`objectives.${index}.objectiveName`) === 'CUSTOM' && (
              <Grid item xs={12} sx={{ marginTop: '10px' }}>
                <TextField
                  label="Custom Objective Name"
                  variant="outlined"
                  {...register(`objectives.${index}.customObjectiveName`)}
                  sx={{ width: '100%' }}
                />
              </Grid>
            )}
            <Grid item xs={12} sx={{ position: 'relative' }}>
              <TextField
                id="outlined-basic-description"
                label="Describe this objective in the specific context of your organization and why it is important."
                variant="outlined"
                multiline
                rows={5}
                {...register(`objectives.${index}.description`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sx={{ position: 'relative' }}>
              <TextField
                id="outlined-basic-measure"
                label="State how you are planning to achieve this objective. If possible list key performance indicators."
                variant="outlined"
                {...register(`objectives.${index}.measure`)}
                sx={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        ))}
      </Box>

      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Typography
          variant="h4"
          {...orgProfileHeaderTextStyle}
          sx={{ marginTop: '20px' }}
        >
          Guiding Principles
        </Typography>
        <FormattedTooltip
          title={aiPrinciplesTooltipText}
          sx={{ marginBottom: '10px' }}
        >
          <InfoOutlinedIcon fontSize="large" />
        </FormattedTooltip>
      </div>
      <Button
        sx={{
          ...orgProfileButtonStyle,
          marginTop: '20px',
        }}
        onClick={addFieldPrinciple}
      >
        <AddIcon />
        Add Principle
      </Button>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {fieldsPrinciples.map((_item, index) => (
          <Grid
            container
            spacing={2}
            key={index + '_principle'}
            sx={{ marginTop: '10px', marginBottom: '10px' }}
          >
            <Grid item xs={4} sx={{ display: 'flex' }}>
              <Controller
                name={`principles.${index}.principleName`}
                render={({ field: { value, onChange } }) => (
                  <FormControl fullWidth>
                    <InputLabel>Principle #{index + 1}</InputLabel>
                    <Select
                      labelId="simple-select-objective"
                      label="Principle"
                      sx={{ width: '100%' }}
                      value={value}
                      onChange={onChange}
                    >
                      {Object.entries(OrganizationPrinciple).map(
                        ([key, val]) => (
                          <MenuItem key={val} value={val}>
                            {PrincipleEnumMapping[key] || key}
                          </MenuItem>
                        )
                      )}
                      <MenuItem value="CUSTOM">Custom Principle</MenuItem>
                    </Select>
                  </FormControl>
                )}
                control={control}
                rules={{ required: false }}
              />
              <IconButton
                onClick={() => removePrinciple(index)}
                sx={{ height: '100%' }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
            {watch(`principles.${index}.principleName`) === 'CUSTOM' && (
              <Grid item xs={12} sx={{ marginTop: '10px' }}>
                <TextField
                  label="Custom Principle Name"
                  variant="outlined"
                  {...register(`principles.${index}.customPrincipleName`)}
                  sx={{ width: '100%' }}
                />
              </Grid>
            )}
            <Grid item xs={12} sx={{ position: 'relative' }}>
              <TextField
                id="outlined-basic-description"
                label="Describe this principle in the specific context of your organization and why it is important. Give examples if possible."
                variant="outlined"
                multiline
                rows={5}
                {...register(`principles.${index}.description`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sx={{ position: 'relative' }}>
              <TextField
                id="outlined-basic-measure"
                label="State how you are planning to achieve this principle. If possible list key performance indicators."
                variant="outlined"
                {...register(`principles.${index}.measure`)}
                sx={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
}

const ObjectiveEnumMapping: { [key: string]: string } = {
  EfficientProcesses: 'Efficient Processes',
  ImproveProductivity: 'Improve Productivity',
  OpenUpNewBusinessFields: 'Open Up New Business Fields',
  CreateCompetitiveAdvantage: 'Create Competitive Advantage',
  TechnicalInnovation: 'Technical Innovation',
  ImproveClientInteractions: 'Improve Client Interactions',
};

const PrincipleEnumMapping: { [key: string]: string } = {
  RobustnessAndReliability: 'Robustness & Reliability',
  Privacy: 'Privacy',
  SecurityAndSafety: 'Security & Safety',
  AccountabilityLiabilityAndResponsibility:
    'Accountability, Liability & Responsibility',
  FairnessAndNonDiscrimination: 'Fairness & Non-Discrimination',
  TransparencyExplainabilityAndAuditability:
    'Transparency, Explainability & Auditability',
  EconomicalDeployment: 'Economical Deployment',
  Sustainability: 'Sustainability',
};

const aiStrategyTooltipText =
  "Outline your organization's AI strategy in 3-5 sentences";

const aiObjectivesTooltipText =
  'This section covers your objectives regarding AI and closely ties in with your AI strategy. ' +
  'List the key objectives/ reasons why you are using or developing AI in your organization, describe them and add a measure on how to achieve it.';

const aiPrinciplesTooltipText =
  'This section covers the principles that are guiding the use and development of AI. The principles will be useful when deciding on governance' +
  "measures to take. Try to keep it simple: don't add too many principles that will be too challenging to maintain later.";

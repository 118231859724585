import { useFeatureFlagEnabled } from 'posthog-js/react';
import { Standard } from '@/__generated__/pydantic_parsers';
import { MarkdownRendering } from '@/components/MarkdownRendering';

interface TextResultCardProps {
  parsedOutput: Standard;
}

export default function TextResultCard({ parsedOutput }: TextResultCardProps) {
  const enableDefaultMarkdownFormatting =
    useFeatureFlagEnabled('markdown_rendering');

  return (
    <div className="p-4">
      {enableDefaultMarkdownFormatting ? (
        <MarkdownRendering mdString={parsedOutput.text} />
      ) : (
        <div className="text-base">{parsedOutput.text}</div>
      )}
    </div>
  );
}

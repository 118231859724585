import { Card, CardContent } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import {
  TrainingsInterval,
  User,
  Language,
} from '../__generated__/gql/graphql';
import UserTag from '../components/UserTag';
import { FC, ReactNode } from 'react';
import { Globe } from 'lucide-react';
import { formatEnumValue } from '@/utils/formatEnumUtils';

type TrainingAdminDetailCardProps = {
  id: string;
  title: string;
  onClick: () => void;
  isSelected?: boolean;
  user: User;
  interval: TrainingsInterval;
  updatedAt: Date | string;
  required: boolean;
  language: Language;
};

const InfoItem = ({
  title,
  content,
}: {
  title: string;
  content: ReactNode;
}) => (
  <div className="mt-4">
    <p className="text-sm text-muted-foreground truncate">{title}:</p>
    {content}
  </div>
);

const TrainingAdminDetailCard: FC<TrainingAdminDetailCardProps> = ({
  title,
  onClick,
  isSelected,
  user,
  interval,
  updatedAt,
  required,
  language,
}) => {
  const formatDate = (date: Date | string) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  return (
    <Card
      className={`h-56 duration-75 cursor-pointer hover:bg-dark-blue-300
        ${
          isSelected
            ? 'border-2 border-yellow-500 shadow-[0_0_0_1px] shadow-yellow-500'
            : ''
        }`}
      onClick={event => {
        event.stopPropagation();
        onClick();
      }}
    >
      <CardContent className="flex flex-col justify-between h-full p-6">
        <div>
          <div className="flex justify-between items-center mb-1">
            <p className="text-xs text-muted-foreground font-medium">
              Updated: {formatDate(updatedAt)}
            </p>
            <div className="flex gap-2">
              <Badge
                className="flex items-center gap-1 bg-gray-100 text-gray-700 hover:bg-gray-100"
                variant="outline"
              >
                <Globe className="h-3 w-3" />
                {formatEnumValue(language)}
              </Badge>
              <Badge
                className={
                  required
                    ? 'bg-action-blue hover:bg-action-blue text-grey-100'
                    : 'bg-dark-blue-200 hover:bg-dark-blue-200'
                }
              >
                {required ? 'Required' : 'Optional'}
              </Badge>
            </div>
          </div>
          <h4 className="truncate">{title}</h4>
        </div>

        <div className="flex justify-between">
          <InfoItem
            title="Interval"
            content={
              <Badge variant="outline" className="text-sx">
                {interval ?? 'Not Defined'}
              </Badge>
            }
          />
          <InfoItem
            title="Responsible Person"
            content={
              <UserTag
                key={user.id}
                user={user}
                variant="text"
                size="medium"
                noWrap={true}
              />
            }
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default TrainingAdminDetailCard;

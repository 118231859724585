import { useAuth } from 'react-oidc-context';
import { useRecoilCallback, RecoilState } from 'recoil';
import { LogOut } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

type LogoutButtonProps = {
  collapsed?: boolean;
  className?: string;
  /**
   * If true, styles the button for use in the sidebar
   */
  sidebar?: boolean;
};

export function LogoutButton({
  collapsed = false,
  sidebar = false,
  className,
}: LogoutButtonProps) {
  const { removeUser, signoutRedirect, clearStaleState } = useAuth();

  const resetAllRecoilState = useRecoilCallback(({ reset, snapshot }) => () => {
    Array.from(snapshot.getNodes_UNSTABLE()).forEach(node => {
      const isResettable = (node: unknown): node is RecoilState<unknown> => {
        return typeof node === 'object' && node !== null && '__cTag' in node;
      };

      if (isResettable(node)) {
        reset(node);
      }
    });
  });

  const handleLogout = () => {
    resetAllRecoilState();
    removeUser();
    signoutRedirect();
    clearStaleState();
    localStorage.clear();
    sessionStorage.clear();
  };

  if (sidebar) {
    return (
      <div className={cn('flex items-center', className)}>
        <button
          onClick={handleLogout}
          className="flex items-center gap-2 w-full border-0 bg-transparent hover:bg-transparent text-white cursor-pointer p-0 pl-1"
        >
          <LogOut className="h-5 w-5 min-w-5" />
          {!collapsed && (
            <span className="whitespace-nowrap text-sm font-medium">
              Sign Out
            </span>
          )}
        </button>
      </div>
    );
  }

  return (
    <Button
      variant="destructive"
      onClick={handleLogout}
      className={cn('', className)}
    >
      <LogOut className="mr-2 h-4 w-4" />
      Sign Out
    </Button>
  );
}

import '@toast-ui/editor/dist/toastui-editor.css';
import {
  BaseMutationOptions,
  OperationVariables,
  DefaultContext,
  gql,
  useMutation,
  useQuery,
  ApolloCache,
} from '@apollo/client';
import { useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { GetVersionedFileQuery } from '@/__generated__/gql/graphql';
import {
  documentIdAtom,
  documentTitleAtom,
  editableFileIdAtom,
  majorVersionAtom,
} from '../../DocumentationPageStates';
import { toast } from 'sonner';
import VersionSelector from '@/components/VersionSelector';
import { Editor } from '@toast-ui/react-editor';
import { Loading } from '@/components/ui/loading';
import { Button } from '@/components/ui/button';
import { Ellipsis, Upload } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

const GET_VERSIONED_FILE = gql(`
    query GetVersionedFile($versionedFileId: String!, $versionNumber: Int!) {
      versionedFile(versionedFileId: $versionedFileId) {
        id
        lastVersion
        content(versionNumber: $versionNumber)
        url(versionNumber: $versionNumber)
      }
    }
  `);

const UPDATE_VERSIONED_FILE = gql(`
    mutation UpdateVersionedS3File($versionedFileId: String!, $versionNumber: Int!, $editableFileContent: String!) {
    updateVersionedS3File(
      versionedFileId: $versionedFileId
      versionNumber: $versionNumber
      editableFileContent: $editableFileContent
    ) {
      versionedFile {
        id
      }
    }
  }
    `);

const CREATE_NEW_VERSIONED_FILE = gql(`
    mutation CreateNewVersionedS3File($versionedFileId: String!, $editableFileContent: String!) {
    createVersionedS3File(
      versionedFileId: $versionedFileId
      editableFileContent: $editableFileContent
    ) {
      versionedFile {
        id
        lastVersion
      }
    }
  }
    `);

const UPSERT_EXPORTED_PDF_FILE = gql(`
  mutation UpsertExportedPdfFile($fileName: String!, $documentationId: String!, $htmlString: String!) {
    upsertExportedPdfFile(fileName: $fileName, documentationId: $documentationId, htmlString: $htmlString) {
      pdfFile {
        id
        versionedFile {
          id
          url
          contentBase64
        }
      }
    }
  }
`);

const CREATE_EVIDENCE = gql(`
  mutation CreateEvidenceInDocumentation($title: String!, $description: String, $fileName: String!, $fileContent: String!) {
    createEvidence(title: $title, description: $description, fileName: $fileName, fileContent: $fileContent) {
      evidence {
        id
        title
      }
    }
  }
`);

export default function EditorTab() {
  const editorRef = useRef<Editor>(null);

  const [minorVersions, setMinorVersions] = useState<number[]>([]);
  const [minorVersion, setMinorVersion] = useState<number>(0);
  const majorVersion = useRecoilValue(majorVersionAtom);
  const versionedFileId = useRecoilValue(editableFileIdAtom);
  const documentId = useRecoilValue(documentIdAtom);
  const documentTitle = useRecoilValue(documentTitleAtom);

  const { loading, refetch, data } = useQuery<GetVersionedFileQuery>(
    GET_VERSIONED_FILE,
    {
      variables: {
        versionedFileId,
        versionNumber: 0,
      },
      onCompleted: data => {
        editorRef.current
          ?.getInstance()
          .setMarkdown(data?.versionedFile?.content || '');

        setMinorVersions(
          Array.from(
            { length: (data?.versionedFile?.lastVersion || 0) + 1 },
            (_, i) => i
          )
        );
      },
      skip: !versionedFileId,
    }
  );
  const handleVersionChange = async (newVersion: number) => {
    setMinorVersion(newVersion);
    const { data } = await refetch({
      versionedFileId,
      versionNumber: newVersion,
    });

    if (data?.versionedFile?.content) {
      editorRef.current?.getInstance().setMarkdown(data.versionedFile.content);
    }
  };

  const [createNewVersionedS3File, { loading: createLoading }] = useMutation(
    CREATE_NEW_VERSIONED_FILE,
    {
      onCompleted: data => {
        setMinorVersions(
          Array.from(
            { length: data?.versionedFile?.lastVersion || 1 },
            (_, i) => i
          )
        );
      },
    }
  );

  const handleCreateVersionedS3File = async () => {
    const content = editorRef.current?.getInstance().getMarkdown();
    try {
      await createNewVersionedS3File({
        variables: {
          versionedFileId,
          editableFileContent: content,
        },
      });
      toast.success('File created successfully');

      const { data } = await refetch({
        versionedFileId,
        versionNumber: minorVersion,
      });

      if (data?.versionedFile?.lastVersion !== undefined) {
        setMinorVersions(
          Array.from(
            { length: data.versionedFile.lastVersion + 1 },
            (_, i) => i
          )
        );
      }
    } catch (e) {
      toast.error('Error creating file');
      console.error('Error creating file:', e);
    }
  };

  const [updateVersionedS3File, { loading: updateLoading }] = useMutation(
    UPDATE_VERSIONED_FILE
  );
  const handleSave = async () => {
    const content = editorRef.current?.getInstance().getMarkdown();
    try {
      updateVersionedS3File({
        variables: {
          versionedFileId,
          versionNumber: minorVersion,
          editableFileContent: content,
        },
      });
      toast.success('File updated successfully');
    } catch (error) {
      toast.error('Error updating file');
      console.error('Error updating file:', error);
    }
  };

  const [exportAnchorEl, setExportAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const openExportMenu = Boolean(exportAnchorEl);

  interface CustomContext extends DefaultContext {
    skipDownload?: boolean;
  }

  const [upsertExportedPdfFile, { loading: exportLoading }] = useMutation(
    UPSERT_EXPORTED_PDF_FILE,
    {
      onCompleted: (
        data,
        clientOptions?: BaseMutationOptions<
          any,
          OperationVariables,
          CustomContext,
          ApolloCache<any>
        >
      ) => {
        if (!clientOptions?.context?.skipDownload) {
          const fileUrl =
            data.upsertExportedPdfFile.pdfFile?.versionedFile?.url;
          if (fileUrl) {
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', 'document.pdf');
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      },
    }
  );

  const handleExport = async () => {
    try {
      upsertExportedPdfFile({
        variables: {
          fileName: documentTitle,
          documentationId: documentId,
          htmlString: editorRef.current?.getInstance().getHTML(),
        },
      });
    } catch (error) {
      toast.error('Error exporting file');
      console.error('Error exporting file:', error);
    }
  };

  const [createEvidence, { loading: createEvidenceLoading }] =
    useMutation(CREATE_EVIDENCE);

  const handleExportAsEvidence = async () => {
    try {
      const pdfResult = await upsertExportedPdfFile({
        variables: {
          fileName: documentTitle,
          documentationId: documentId,
          htmlString: editorRef.current?.getInstance().getHTML(),
        },
        context: { skipDownload: true },
      });

      const pdfContent =
        pdfResult.data?.upsertExportedPdfFile.pdfFile?.versionedFile
          ?.contentBase64;
      if (!pdfContent) {
        throw new Error('Failed to get PDF content');
      }

      await createEvidence({
        variables: {
          title: documentTitle,
          description: 'Generated from documentation',
          fileName: `${documentTitle}.pdf`,
          fileContent: pdfContent,
        },
      });

      toast.success('PDF exported and evidence created successfully');
    } catch (error) {
      toast.error('Error exporting file as evidence');
      console.error('Error exporting file as evidence:', error);
    }
  };

  return (
    <div className="w-full">
      <div className="flex items-center gap-2 justify-between pb-4">
        <h5>Edit</h5>
        <div className="flex items-center gap-2 flex-nowrap">
          <p className="text-grey-300 text-sm">Version:</p>
          <VersionSelector
            majorVersion={majorVersion ?? 0}
            elements={minorVersions}
            selectedElement={minorVersion}
            setSelectedElement={handleVersionChange}
          />
          <div className="flex flex-nowrap">
            <Button
              onClick={handleSave}
              disabled={updateLoading}
              className="rounded-r-none"
              size="sm"
            >
              Save
            </Button>
            <Button
              onClick={handleCreateVersionedS3File}
              disabled={createLoading}
              className="rounded-none shadow-lg"
              size="sm"
            >
              Save As New Version
            </Button>
            <Button
              onClick={handleExport}
              disabled={exportLoading || createEvidenceLoading}
              className="rounded-none shadow-md flex items-center gap-2"
              size="sm"
            >
              <Upload className="h-4 w-4" />
              Export As PDF
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button
                  size="sm"
                  className="rounded-l-none shadow-md"
                  aria-controls={openExportMenu ? 'export-menu' : undefined}
                  aria-haspopup="menu"
                  aria-expanded={openExportMenu ? 'true' : undefined}
                  onClick={e => setExportAnchorEl(e.currentTarget)}
                >
                  <Ellipsis className="h-5 w-5" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="border-dark-blue-300">
                <DropdownMenuItem
                  onClick={handleExportAsEvidence}
                  className="focus:bg-dark-blue-200 cursor-pointer"
                >
                  Export As Evidence
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
      {loading ? (
        <Loading message="loading" />
      ) : data ? (
        <Editor
          ref={editorRef}
          initialValue=""
          height="calc(100vh - 230px)"
          previewStyle="vertical"
          initialEditType="wysiwyg"
          useCommandShortcut={true}
        />
      ) : (
        <p className="text-center text-lg my-4">
          No documentation generated yet. Generate Documentation to get started.
        </p>
      )}
    </div>
  );
}

import { Button } from '@/components/ui/button';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Textarea } from '@/components/ui/textarea';
import { Dot, Plus, Trash2 } from 'lucide-react';

interface Question {
  id?: string;
  questionText: string;
  description?: string;
  options: {
    id?: string;
    optionText: string;
    isCorrect: boolean;
  }[];
}

interface QuestionsSectionProps {
  questions: Question[];
  setQuestions: (questions: Question[]) => void;
  isEditable: boolean;
}

export default function QuestionsSection({
  questions,
  setQuestions,
  isEditable,
}: QuestionsSectionProps) {
  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        questionText: '',
        description: '',
        options: [
          { optionText: '', isCorrect: false },
          { optionText: '', isCorrect: false },
        ],
      },
    ]);
  };

  const updateQuestion = (index: number, updatedQuestion: Question) => {
    const newQuestions = [...questions];
    newQuestions[index] = updatedQuestion;
    setQuestions(newQuestions);
  };

  const removeQuestion = (index: number) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  return (
    <div className="mx-auto">
      <Card className="mt-6 bg-dark-blue-500 border-0 shadow-none">
        <CardHeader>
          <CardTitle className="text-2xl font-semibold">Questions</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          {questions.length === 0 && !isEditable && (
            <p className="text-gray-500 text-center">No questions available.</p>
          )}
          {questions.map((question, qIndex) => (
            <div
              key={question.id || qIndex}
              className="border border-gray-500 p-6 rounded-md space-y-4 shadow"
            >
              {isEditable ? (
                <>
                  <div>
                    <Label
                      htmlFor={`questionText-${qIndex}`}
                      className="font-medium"
                    >
                      Question Text
                    </Label>
                    <Input
                      id={`questionText-${qIndex}`}
                      value={question.questionText}
                      onChange={e =>
                        updateQuestion(qIndex, {
                          ...question,
                          questionText: e.target.value,
                        })
                      }
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <Label
                      htmlFor={`description-${qIndex}`}
                      className="font-medium"
                    >
                      Description (Optional)
                    </Label>
                    <Textarea
                      id={`description-${qIndex}`}
                      value={question.description || ''}
                      onChange={e =>
                        updateQuestion(qIndex, {
                          ...question,
                          description: e.target.value,
                        })
                      }
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <Label className="font-medium">Options</Label>
                    <div className="mt-2 space-y-3">
                      <RadioGroup
                        value={question.options
                          .findIndex(o => o.isCorrect)
                          .toString()}
                        onValueChange={value => {
                          const newOptions = question.options.map((o, i) => ({
                            ...o,
                            isCorrect: i === parseInt(value),
                          }));
                          updateQuestion(qIndex, {
                            ...question,
                            options: newOptions,
                          });
                        }}
                      >
                        {question.options.map((option, oIndex) => (
                          <div
                            key={option.id || oIndex}
                            className="flex items-center space-x-2 mt-2"
                          >
                            <RadioGroupItem
                              value={oIndex.toString()}
                              id={`option-${qIndex}-${oIndex}`}
                            />
                            <Input
                              value={option.optionText}
                              onChange={e => {
                                const newOptions = [...question.options];
                                newOptions[oIndex] = {
                                  ...newOptions[oIndex],
                                  optionText: e.target.value,
                                };
                                updateQuestion(qIndex, {
                                  ...question,
                                  options: newOptions,
                                });
                              }}
                              className="flex-grow"
                            />
                            {isEditable && question.options.length > 2 && (
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => {
                                  const newOptions = question.options.filter(
                                    (_, i) => i !== oIndex
                                  );
                                  updateQuestion(qIndex, {
                                    ...question,
                                    options: newOptions,
                                  });
                                }}
                              >
                                <Trash2 className="h-4 w-4" />
                              </Button>
                            )}
                          </div>
                        ))}
                      </RadioGroup>
                    </div>
                    <Button
                      variant="outline"
                      size="sm"
                      className="mt-4"
                      onClick={() => {
                        const newOptions = [
                          ...question.options,
                          { optionText: '', isCorrect: false },
                        ];
                        updateQuestion(qIndex, {
                          ...question,
                          options: newOptions,
                        });
                      }}
                    >
                      <Plus className="h-4 w-4 mr-2" />
                      Add Option
                    </Button>
                  </div>
                  <div className="flex justify-end mt-4">
                    <Button
                      variant="destructive"
                      onClick={() => removeQuestion(qIndex)}
                    >
                      Remove Question
                    </Button>
                  </div>
                </>
              ) : (
                <div>
                  <p className="font-semibold text-xl">
                    {question.questionText}
                  </p>
                  {question.description && (
                    <p className="text-gray-600 mt-2">{question.description}</p>
                  )}
                  <div className="mt-4 space-y-2">
                    {question.options.map((option, oIndex) => (
                      <div
                        key={option.id || oIndex}
                        className={`flex py-1 px-1 rounded ${
                          option.isCorrect ? 'text-green-600' : ''
                        }`}
                      >
                        <Dot className="mr-2 text-white" />
                        {option.optionText} {option.isCorrect && '(Correct)'}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
          {isEditable && (
            <Button
              onClick={addQuestion}
              className="w-full h-12 mt-4 border-2 border-dashed hover:border-yellow-500"
              variant="outline"
            >
              <Plus className="w-5 h-5 mr-2" />
              Add New Question
            </Button>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

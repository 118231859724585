import * as React from 'react';
import { X, Plus } from 'lucide-react';
import { cn } from '@/lib/utils';

import { Badge } from '@/components/ui/badge';
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Command as CommandPrimitive } from 'cmdk';

export type Option = {
  value: string;
  label: string;
};

interface MultiSelectProps {
  options: Option[];
  selected: Option[];
  onChange: (selected: Option[]) => void;
  placeholder?: string;
  className?: string;
  onAddNew?: (value: string) => void;
  disabled?: boolean;
}

export function MultiSelect({
  options,
  selected,
  onChange,
  placeholder = 'Select options...',
  className,
  onAddNew,
  disabled = false,
}: MultiSelectProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');

  const handleUnselect = React.useCallback(
    (option: Option) => {
      if (!disabled) {
        onChange(selected.filter(s => s.value !== option.value));
      }
    },
    [onChange, selected, disabled]
  );

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (disabled) return;
      const input = inputRef.current;
      if (input) {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          if (input.value === '') {
            onChange(selected.slice(0, -1));
          }
        }
        if (e.key === 'Escape') {
          input.blur();
        }
      }
    },
    [onChange, selected, disabled]
  );

  const selectables = options.filter(
    option => !selected.find(s => s.value === option.value)
  );

  const handleAddNew = () => {
    if (disabled || !inputValue.trim() || !onAddNew) return;
    onAddNew(inputValue.trim());
    setInputValue('');
  };

  const isNewValue =
    inputValue.trim() !== '' &&
    !options.some(
      option => option.label.toLowerCase() === inputValue.trim().toLowerCase()
    );

  return (
    <Command
      onKeyDown={handleKeyDown}
      className={cn('overflow-visible bg-transparent', className)}
    >
      <div
        className={cn(
          'group rounded-md px-3 py-2 text-[14px]',
          !disabled && 'border border-input mt-2'
        )}
      >
        <div className="flex flex-wrap gap-1">
          {selected.map(option => (
            <Badge key={option.value} variant="outline">
              {option.label}
              {!disabled && (
                <button
                  className="ml-1 rounded-full outline-none"
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      handleUnselect(option);
                    }
                  }}
                  onMouseDown={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onClick={() => handleUnselect(option)}
                >
                  <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                </button>
              )}
            </Badge>
          ))}
          <CommandPrimitive.Input
            ref={inputRef}
            value={inputValue}
            onValueChange={setInputValue}
            onBlur={() => setOpen(false)}
            onFocus={() => !disabled && setOpen(true)}
            placeholder={disabled ? '' : placeholder}
            disabled={disabled}
            className="ml-2 flex-1 bg-transparent outline-none placeholder:text-muted-foreground"
          />
        </div>
      </div>
      <div className="relative mt-2">
        <CommandList>
          {open &&
          !disabled &&
          (selectables.length > 0 || (isNewValue && onAddNew)) ? (
            <div className="absolute top-0 z-10 w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in">
              <CommandGroup className="h-full overflow-auto">
                {isNewValue && onAddNew && (
                  <CommandItem
                    onMouseDown={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    value={inputValue}
                    onSelect={handleAddNew}
                    className="cursor-pointer flex items-center gap-1 text-primary"
                  >
                    <Plus className="h-3.5 w-3.5" />
                    Add "{inputValue.trim()}"
                  </CommandItem>
                )}
                {selectables.map(option => (
                  <CommandItem
                    key={option.value}
                    onMouseDown={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onSelect={() => {
                      setInputValue('');
                      onChange([...selected, option]);
                    }}
                    className="cursor-pointer"
                  >
                    {option.label}
                  </CommandItem>
                ))}
              </CommandGroup>
            </div>
          ) : null}
        </CommandList>
      </div>
    </Command>
  );
}

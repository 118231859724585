import { FC, useState, useRef } from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Trash2, Upload, AlertCircle } from 'lucide-react';
import { Spinner } from '@/components/ui/spinner';
import { toast } from 'sonner';

interface SectionUploadFieldProps {
  section: { id: number; title: string; materialId: string | null };
  onChange: (updatedSection: {
    id: number;
    title: string;
    materialId: string | null;
  }) => void;
  onRemove: (id: number) => void;
  handleUploadFile: (file: File) => Promise<string>;
  existingFileName?: string | null;
}

const SectionUploadField: FC<SectionUploadFieldProps> = ({
  section,
  onChange,
  onRemove,
  handleUploadFile,
  existingFileName,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const currentFileName = uploadedFileName || existingFileName || null;

  const validateFile = (file: File): { valid: boolean; message?: string } => {
    if (!file.type.includes('pdf')) {
      return { valid: false, message: 'Only PDF files are allowed' };
    }

    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    if (file.size > maxSize) {
      return { valid: false, message: 'File size exceeds 10MB limit' };
    }

    return { valid: true };
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    if (!file) return;

    const validation = validateFile(file);
    if (!validation.valid) {
      setUploadError(validation.message || 'Invalid file');
      toast.error(validation.message);
      return;
    }

    setIsUploading(true);
    setUploadError(null);

    try {
      const fileId = await handleUploadFile(file);
      setUploadedFileName(file.name);
      onChange({ ...section, materialId: fileId });
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadError('Failed to upload file');
      toast.error('Failed to upload file');
    } finally {
      setIsUploading(false);
    }
  };

  const triggerFileSelection = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="border border-gray-500 p-4 rounded-md space-y-2">
      <Input
        value={section.title}
        onChange={e => onChange({ ...section, title: e.target.value })}
        placeholder="Section Title"
      />
      <div className="flex items-center gap-2">
        {isUploading ? (
          <div className="flex-1 border rounded p-2 flex items-center">
            <Spinner size="small" className="mr-2" />
            <span>Uploading...</span>
          </div>
        ) : uploadError ? (
          <div className="flex-1 border border-red-500 rounded p-2 flex items-center bg-red-100/10">
            <AlertCircle className="h-4 w-4 mr-2 text-red-500" />
            <span className="text-sm text-red-500">{uploadError}</span>
            <Button
              variant="ghost"
              size="sm"
              className="ml-auto"
              onClick={triggerFileSelection}
            >
              Try Again
            </Button>
          </div>
        ) : section.materialId && currentFileName ? (
          <div
            className="flex-1 border rounded p-2 cursor-pointer hover:bg-dark-blue-200/30 transition-colors group"
            onClick={triggerFileSelection}
          >
            <div className="flex items-center justify-between">
              <span
                className="text-sm truncate max-w-[70%]"
                title={currentFileName}
              >
                {currentFileName}
              </span>
              <span className="text-xs text-muted-foreground group-hover:text-primary transition-colors">
                Click to replace
              </span>
            </div>
          </div>
        ) : (
          <div
            className="flex-1 border rounded p-2 cursor-pointer hover:bg-dark-blue-200/30 transition-colors"
            onClick={triggerFileSelection}
          >
            <div className="flex items-center">
              <Upload className="h-4 w-4 mr-2 text-muted-foreground" />
              <span className="text-sm text-muted-foreground">
                Click to upload PDF file (max 10MB)
              </span>
            </div>
          </div>
        )}

        {/* Hidden file input */}
        <input
          ref={fileInputRef}
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
          className="hidden"
        />

        <Button
          variant="destructive"
          onClick={() => onRemove(section.id)}
          disabled={isUploading}
          title="Remove section"
        >
          <Trash2 className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

export default SectionUploadField;

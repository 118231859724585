import { UserResponsibility, UserRole } from '../__generated__/gql/graphql';
import { UserProps } from './UserManagementPage';
import { TableCell, TableRow } from '@/components/ui/table';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { ChevronDown, PlusIcon, XIcon } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Textarea } from '@/components/ui/textarea';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';

export default function UserRow({
  user,
  index,
  toggleRole,
  removeResponsibility,
  addResponsibility,
  onWrite,
  availableResponsibilities,
}: {
  user: UserProps;
  index: number;
  toggleRole: (role: UserRole, index: number) => void;
  removeResponsibility: (
    index: number,
    responsibility: UserResponsibility
  ) => void;
  addResponsibility: (
    index: number,
    responsibility: UserResponsibility
  ) => void;
  onWrite: (index: number, name: string) => void;
  availableResponsibilities: UserResponsibility[];
}) {
  if (user.roles === undefined || user.roles === null) {
    user.roles = [];
  }
  const handleRoleChange = (role: UserRole) => {
    toggleRole(role, index);
  };
  const handleDeleteResponsibilitiy = (responsibility: UserResponsibility) => {
    removeResponsibility(index, responsibility);
  };

  const handleAddResponsibility = (responibility: UserResponsibility) => {
    addResponsibility(index, responibility);
  };

  return (
    <TableRow key={index}>
      <TableCell>
        <p>{user.name || user.email}</p>
      </TableCell>
      <TableCell>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Button
              size="sm"
              variant="outline"
              className="w-full flex justify-between min-w-[180px] max-w-[180px]"
            >
              <p className="max-w-32 overflow-hidden text-ellipsis justify-start text-[13px]">
                {user.roles?.map(role => RoleDisplayMap[role]).join(', ')}
              </p>
              <ChevronDown className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="border-dark-blue-200">
            {Object.values(UserRole).map(role => (
              <DropdownMenuCheckboxItem
                className="focus:bg-dark-blue-200 cursor-pointer"
                key={role}
                checked={user.roles?.includes(role)}
                onCheckedChange={() => handleRoleChange(role)}
              >
                {RoleDisplayMap[role]}
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
      <TableCell className="max-w-[500px]">
        <div className="flex flex-wrap gap-2">
          {user.responsibilities?.map((responsibility, index) => (
            <Badge
              key={index}
              className="gap-1.5 py-1 select-none bg-action-blue my-1  hover:bg-action-blue "
            >
              {ResponsibilityDisplayMap[responsibility]}
              <XIcon
                className="w-4.5 h-4.5 rounded-full p-0.5 bg-white text-action-blue hover:bg-action-blue hover:text-white transition-all duration-100 cursor-pointer"
                onClick={() => handleDeleteResponsibilitiy(responsibility)}
              />
            </Badge>
          ))}
          {availableResponsibilities.length > 0 && (
            <Dialog>
              <DialogTrigger>
                <Badge
                  variant="outline"
                  className="gap-1 py-1 select-none mr-2 my-1 cursor-pointer hover:bg-white hover:text-black transition-all duration-100"
                >
                  Add
                  <PlusIcon className="w-4.5 h-4.5 " />
                </Badge>
              </DialogTrigger>
              <DialogContent className="w-[700px]">
                <DialogTitle>
                  <p className="text-[1.5rem]">Add Responsibilities</p>
                </DialogTitle>
                <div className=" gap-2">
                  {user.responsibilities?.map((responsibility, index) => (
                    <Badge
                      key={index}
                      className="gap-1.5 py-1 select-none mx-1 bg-action-blue my-1  hover:bg-action-blue "
                    >
                      {ResponsibilityDisplayMap[responsibility]}
                      <XIcon
                        className="w-4.5 h-4.5 rounded-full p-0.5 bg-white text-action-blue hover:bg-action-blue hover:text-white transition-all duration-100 cursor-pointer"
                        onClick={() =>
                          handleDeleteResponsibilitiy(responsibility)
                        }
                      />
                    </Badge>
                  ))}
                  {availableResponsibilities
                    .filter(resp => !user.responsibilities?.includes(resp))
                    .map((responsibility, index) => (
                      <Badge
                        key={index}
                        variant="outline"
                        onClick={() => handleAddResponsibility(responsibility)}
                        className="gap-1 py-1 select-none mx-1 my-1 hover:bg-white hover:text-black cursor-pointer"
                      >
                        {ResponsibilityDisplayMap[responsibility]}
                        <PlusIcon className="w-4 h-4 " />
                      </Badge>
                    ))}
                </div>
              </DialogContent>
            </Dialog>
          )}
        </div>
      </TableCell>
      <TableCell>
        <Textarea
          className="w-full resize-none"
          placeholder="Proof of Qualification"
          value={user.proofOfQualification}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            onWrite(index, event.target.value);
          }}
        />
      </TableCell>
    </TableRow>
  );
}

export const RoleDisplayMap = {
  AI_LEAD: 'AI Lead',
  COMPLIANCE: 'Compliance',
  DEVELOPER: 'Developer',
  MANAGEMENT: 'Management',
  PROJECT_MANAGEMENT: 'Project Management',
};

export const ResponsibilityDisplayMap = {
  AI_SYSTEM: 'AI System',
  APPROVAL: 'Approval',
  ASSET_RSC_MANAGE: 'Asset & Resource Management',
  DATA_QA: 'Data Quality Management',
  DEVELOPMENT: 'Development',
  HUMAN_OVER: 'Human Oversight',
  LEGAL_REQ: 'Fulfil Legal Requirements',
  PERFORMANCE: 'Performance',
  PRIVACY: 'Privacy',
  RISK_MANAGEMENT: 'Risk Management',
  SAFETY: 'Safety',
  SECURITY: 'Security',
  SUPPLIER_REL: 'Supplier Relationships',
  ORG_CONTEXT: 'Organizational Context',
  AI_POLICY: 'AI Policy',
  ROLES_RESPONSIBILITIES: 'Roles & Responsibilities',
  AI_SYSTEM_LIFECYCLE: 'Ai System Lifecycle',
  AI_USAGE: 'AI Usage',
  INFORMATION_TRANSPARENCY: 'Information Transparency',
  MANAGEMENT_SYSTEM_PERFORMANCE: 'Management System Performance',
  SUPPORT: 'Support',
  LEADERSHIP: 'Leadership',
};

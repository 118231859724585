import { Badge } from '@/components/ui/badge';
interface Props {
  factResult: string;
}

export function FactViewer({ factResult }: Props): JSX.Element {
  return (
    <Badge
      variant="outline"
      className="rounded-sm px-2 py-1 bg-dark-blue-300 border-none"
    >
      {factResult}
    </Badge>
  );
}

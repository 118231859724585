import { v4 as uuidv4 } from 'uuid';
import { formatDateTime } from '@/utils/dateTimeHelpers';
import { DocumentationResultObject } from '@/__generated__/gql/graphql';

import DocumentationResultCard from './documentation_result/DocumentationResultCard';

export const DocumentationResultViewer = ({
  creationTimestamp,
  documentationResult,
}: {
  creationTimestamp: string;
  documentationResult:
    | DocumentationResultObject['generatedDocumentation']
    | null
    | undefined;
}) => {
  const formattedDateTime = formatDateTime(creationTimestamp);

  return (
    <>
      {creationTimestamp && (
        <p className="text-grey-300 text-sm my-1">
          Generated: {formattedDateTime}
        </p>
      )}
      <div className="flex flex-col items-start gap-3 max-h-[calc(100vh-230px)] overflow-auto relative w-full">
        {documentationResult ? (
          documentationResult
            .filter(componentResult => componentResult !== null)
            .map(componentResult => (
              <DocumentationResultCard
                component={componentResult!}
                key={`componentResult-${componentResult?.name}-${uuidv4()}`}
              />
            ))
        ) : (
          <p className="text-center text-lg my-4">
            No documentation generated yet. Click the Generate Documentation
            button to get started.
          </p>
        )}
      </div>
    </>
  );
};

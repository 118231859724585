import { DateTime } from 'luxon';
import { TrainingsInterval } from '../__generated__/gql/graphql';
import { INTERVAL_DURATIONS } from '@/constants/intervals';

export const calculateMaxDueDate = (
  startDate: DateTime,
  interval: TrainingsInterval
): DateTime => {
  if (interval === TrainingsInterval.Once) {
    return startDate.plus({ years: 100 });
  }

  return startDate.plus(INTERVAL_DURATIONS[interval]);
};

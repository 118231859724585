import React, { useState } from 'react';
import { Check, Copy } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { toast } from 'sonner';

interface FriendlyIdProps {
  id: string;
  showFull?: boolean;
  copyable?: boolean;
  className?: string;
  subtle?: boolean;
  alwaysShowButton?: boolean;
  inline?: boolean;
}

const FriendlyId: React.FC<FriendlyIdProps> = ({
  id,
  showFull = false,
  copyable = true,
  className = '',
  subtle = false,
  alwaysShowButton = false,
  inline = false,
}) => {
  const [copied, setCopied] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  if (!id) return null;

  const shortId = id.substring(0, 8);
  const displayId = showFull ? id : shortId;

  const handleCopy = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event bubbling
    navigator.clipboard.writeText(id);
    setCopied(true);

    toast('Copied to clipboard', {
      description: 'The full ID has been copied to your clipboard.',
      duration: 2000,
    });

    setTimeout(() => setCopied(false), 2000);
  };

  // If the ID is subtle, make it clickable to copy directly
  const handleIdClick = subtle && copyable ? handleCopy : undefined;

  const codeClassName = subtle
    ? `text-sm font-mono text-muted-foreground ${
        copyable ? 'cursor-pointer hover:text-foreground' : ''
      }`
    : 'text-sm font-mono bg-muted px-1 py-0.5 rounded';

  // For inline usage, we'll just return the clickable code element without the copy button
  if (inline) {
    return (
      <code className={`${codeClassName} ${className}`} onClick={handleIdClick}>
        {displayId}
      </code>
    );
  }

  return (
    <div
      className={`flex items-center h-6 ${className}`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <code className={codeClassName} onClick={handleIdClick}>
        {displayId}
      </code>

      <div className="w-6 h-6 flex-shrink-0 ml-1">
        {copyable && (isHovering || copied || alwaysShowButton) && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-6 w-6 p-0 hover:bg-muted"
                  onClick={handleCopy}
                >
                  {copied ? (
                    <Check className="h-3 w-3" />
                  ) : (
                    <Copy className="h-3 w-3" />
                  )}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Copy full ID</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
    </div>
  );
};

export default FriendlyId;

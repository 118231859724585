import {
  ComponentCategory,
  DataSource,
  DocumentationScope,
  DocumentationType,
  DocumentComponentInput,
  DocumentComponentType,
} from '../../../../__generated__/gql/graphql';

export interface DocumentationTemplate {
  id: number;
  documentationScope: DocumentationScope;
  documentationType: DocumentationType;
  title: string;
  description: string;
  components: DocumentComponentInput[];
}

export const DocumentationTemplates: DocumentationTemplate[] = [
  {
    id: 0,
    title: 'Custom Organizational',
    documentationScope: DocumentationScope.Organization,
    documentationType: DocumentationType.Generated,
    description: 'Organization Specific Custom Documentation',
    components: [],
  },
  {
    id: 1,
    title: 'Gen Customizable Organizational',
    documentationScope: DocumentationScope.Project,
    documentationType: DocumentationType.Generated,
    description: '',
    components: [
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Abstract',
        query: '',
        componentType: DocumentComponentType.Abstract,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Use Case',
        query: '',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Risk Classification',
        query: '',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Architecture',
        query: '',
        componentType: DocumentComponentType.Architecture,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Data Quality Metrics',
        query: '',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Data Bias Checks',
        query: '',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Data Sources',
        query: '',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Model Architecture',
        query:
          'Describe the model architecture along with any inference time input data adjustments',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Model Quality Metrics',
        query: '',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Features',
        query: '',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Feature Importances/Model Bias',
        query: '',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Limitations and Risks',
        query: '',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
    ],
  },
  {
    id: 3,
    title: 'AI Policy',
    documentationScope: DocumentationScope.Organization,
    documentationType: DocumentationType.Generated,
    description:
      'This template is based on the EU AI Act and can be customized to fit your organization',
    components: [
      {
        args: [],
        dataSource: DataSource.Text,
        name: 'Legal Disclaimer',
        query: '',
        componentType: DocumentComponentType.LegalDisclaimer,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Text,
        name: '',
        query: '',
        componentType: DocumentComponentType.PurposeAndGoal,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Text,
        name: 'Scope Of AI Policy',
        query: '',
        componentType: DocumentComponentType.ScopeOfPolicy,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Text,
        name: 'Coming into Force',
        query: '',
        componentType: DocumentComponentType.ComingIntoForce,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Text,
        name: 'Contact of AI Policy',
        query: '',
        componentType: DocumentComponentType.ContactOPolicy,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Text,
        name: 'Definition of AI',
        query: '',
        componentType: DocumentComponentType.DefinitionOfAi,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Text,
        name: 'Alignment with Organisation',
        query: '',
        componentType: DocumentComponentType.AlignmentWithOrganisation,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Text,
        name: 'Risk Profile',
        query: '',
        componentType: DocumentComponentType.RiskProfile,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Text,
        name: 'AI Objectives',
        query: '',
        componentType: DocumentComponentType.AiObjectives,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Text,
        name: 'AI Principles',
        query: '',
        componentType: DocumentComponentType.AiPrinciples,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Text,
        name: 'AI Systems',
        query: '',
        componentType: DocumentComponentType.AiSystems,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Text,
        name: 'AI Governance System',
        query: '',
        componentType: DocumentComponentType.AiGovernanceSystem,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Text,
        name: 'Other Policies and Regulations',
        query: '',
        componentType: DocumentComponentType.OtherPolicies,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Text,
        name: 'Obligation Requirements',
        query: '',
        componentType: DocumentComponentType.ObligationsRequirements,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Text,
        name: 'Non-Compliance Clause',
        query: '',
        componentType: DocumentComponentType.NonCompliance,
        category: ComponentCategory.Project,
      },
    ],
  },
  {
    id: 5,
    title: 'Model Documentation',
    documentationScope: DocumentationScope.Experiment,
    documentationType: DocumentationType.Generated,
    description: '',
    components: [
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'System Components',
        query:
          'Document the System Components that were used in the provided source code',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'System Diagram',
        query: '',
        componentType: DocumentComponentType.ArchitectureDiagram,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Model Framework',
        query: '',
        componentType: DocumentComponentType.MlFramework,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Algorithm type',
        query: 'What type of algorithm was used?',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Tools',
        query: 'What kind of tools were used?',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Hardware Ressources',
        query: 'What are the compute ressources (hardware)?',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Storage',
        query: 'Which tool is used for the storage of the data?',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Human Ressources',
        query:
          'What are the human ressources involved in this model development process?',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
    ],
  },
  {
    id: 6,
    title: 'Custom Project',
    documentationScope: DocumentationScope.Project,
    documentationType: DocumentationType.Proxy,
    description: 'Project Specific Custom Documentation',
    components: [],
  },
  {
    id: 7,
    title: 'Custom Experiment',
    documentationScope: DocumentationScope.Experiment,
    documentationType: DocumentationType.Proxy,
    description: 'Experiment Specific Custom Documentation',
    components: [],
  },
  {
    id: 8,
    title: 'Data Documentation',
    documentationScope: DocumentationScope.Experiment,
    documentationType: DocumentationType.Proxy,
    description: '',
    components: [
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Data Provenance',
        query:
          'Show me the steps the data went through (Data Lineage/provenance)',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Last updated/modified',
        query: 'When was the dataset last updated/modified?',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Data Categories',
        query:
          'Which of the dataset categories: training, validation, test? are present? If otheres are present, also describe them',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Labelling Process',
        query: 'Describe the datalabeling process',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Intended Use of Data',
        query: 'Describe the intended use of the data',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Data Quality & Bias',
        query: 'Describe the data quality & bias',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Data Preparation',
        query: 'Describe the data preparation steps',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
      {
        args: [],
        dataSource: DataSource.Code,
        name: 'Data Retention & Disposal Policy',
        query: 'Describe the data retention & disposal policy',
        componentType: DocumentComponentType.CustomText,
        category: ComponentCategory.Project,
      },
    ],
  },
];

import React, { useState, useCallback, useRef, useEffect } from 'react';
import { toast } from 'sonner';
import { Tree, type TreeDataItem } from './ui/Tree';
import { Folder, File, Upload } from 'lucide-react';
import { Button } from './ui/button';
import { cn } from '../lib/utils';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from './ui/dialog';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { createPortal } from 'react-dom';

export interface FileNode {
  id: string;
  name: string;
  parentId?: string | null;
  children?: FileNode[];
  url?: string;
}

interface Props {
  files: FileNode[];
  nonSelectableNodes?: string[];
  isDeleteEnabled?: boolean;
  onDeleteFiles: (selectedFiles: string[]) => Promise<void>;
  onUploadFiles?: (files: File[], selectedPath?: string) => Promise<void>;
  isUploading?: boolean;
  onFolderSelect?: (folderId: string | undefined) => void;
  selectedFolder?: string;
}

export default function FileTreeView({
  files,
  nonSelectableNodes = [],
  isDeleteEnabled = true,
  onDeleteFiles,
  onUploadFiles,
  isUploading = false,
  onFolderSelect,
  selectedFolder,
}: Props) {
  const fileTree = buildFileTree(files);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [uploadPath, setUploadPath] = useState<string>('');

  useEffect(() => {
    if (selectedFolder) {
      setUploadPath(selectedFolder);
    }
  }, [selectedFolder]);

  const convertToTreeData = (nodes: FileNode[]): TreeDataItem[] => {
    return nodes.map(node => {
      const hasChildren =
        Array.isArray(node.children) && node.children.length > 0;
      return {
        id: node.id,
        name: node.name,
        icon: hasChildren ? Folder : File,
        children: hasChildren
          ? convertToTreeData(node.children || [])
          : undefined,
        url: node.url,
      };
    });
  };

  const treeData = convertToTreeData(fileTree);

  const handleSelectChange = useCallback(
    (items: TreeDataItem[]) => {
      const selectableItems = items.filter(
        item => !nonSelectableNodes.includes(item.id)
      );
      setSelectedFiles(selectableItems.map(item => item.id));

      if (selectableItems.length === 1) {
        const selectedItem = selectableItems[0];
        const isFolder =
          !selectedItem.url ||
          (selectedItem.children && selectedItem.children.length > 0);
        if (isFolder && onFolderSelect) {
          onFolderSelect(selectedItem.id);
        }
      } else if (onFolderSelect) {
        onFolderSelect(undefined);
      }
    },
    [nonSelectableNodes, onFolderSelect]
  );

  const handleDelete = async () => {
    if (isDeleteEnabled && selectedFiles.length > 0) {
      setDeleteLoading(true);
      try {
        await onDeleteFiles(selectedFiles);
        toast.success('Files deleted successfully');
        setSelectedFiles([]);
      } catch (e) {
        console.error('Error deleting files:', e);
        toast.error('Failed to delete files');
      }
      setDeleteLoading(false);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    if (selectedFiles.length > 0 && onUploadFiles) {
      setFilesToUpload(selectedFiles);
      setUploadDialogOpen(true);

      if (selectedFolder) {
        setUploadPath(selectedFolder);
      } else {
        setUploadPath('');
      }
    }
  };

  const handleUploadConfirm = async () => {
    if (filesToUpload.length > 0 && onUploadFiles) {
      await onUploadFiles(filesToUpload, uploadPath);
      setUploadDialogOpen(false);
      setFilesToUpload([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <>
      {fileTree.length === 0 ? (
        <p className="text-base">No files uploaded yet</p>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="w-full overflow-auto" style={{ maxHeight: '400px' }}>
            <Tree
              data={treeData}
              onSelectChange={handleSelectChange}
              selectedIds={selectedFiles}
              multiSelect={true}
              expandAll={true}
              className="min-h-[100px]"
            />
          </div>
          <div className="flex gap-2">
            {onUploadFiles && (
              <>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  className="hidden"
                  multiple
                />
                <Button
                  variant="outline"
                  className={cn(isUploading && 'opacity-70 cursor-not-allowed')}
                  disabled={isUploading}
                  onClick={handleUploadClick}
                >
                  <Upload className="h-4 w-4 mr-2" />
                  {isUploading ? 'Uploading...' : 'Upload Files'}
                </Button>
              </>
            )}
            {isDeleteEnabled && (
              <Button
                variant="outline"
                className={cn(
                  'w-fit',
                  deleteLoading && 'opacity-70 cursor-not-allowed'
                )}
                disabled={selectedFiles.length === 0 || deleteLoading}
                onClick={handleDelete}
              >
                {deleteLoading ? 'Deleting...' : 'Delete Selected Files'}
              </Button>
            )}
          </div>
        </div>
      )}

      {typeof document !== 'undefined' &&
        createPortal(
          <Dialog open={uploadDialogOpen} onOpenChange={setUploadDialogOpen}>
            <DialogContent className="z-[9999] max-w-md w-full">
              <DialogHeader>
                <DialogTitle>Specify Upload Path</DialogTitle>
              </DialogHeader>
              <div className="py-4">
                <Label htmlFor="upload-path">File Path</Label>
                <Input
                  id="upload-path"
                  value={uploadPath}
                  onChange={e => setUploadPath(e.target.value)}
                  placeholder="Enter path (e.g., folder/subfolder)"
                  className="mt-2"
                />
                <p className="text-sm text-muted-foreground mt-2">
                  Files to upload: {filesToUpload.map(f => f.name).join(', ')}
                </p>
              </div>
              <DialogFooter>
                <Button
                  variant="outline"
                  onClick={() => setUploadDialogOpen(false)}
                >
                  Cancel
                </Button>
                <Button onClick={handleUploadConfirm} disabled={isUploading}>
                  {isUploading ? 'Uploading...' : 'Upload'}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>,
          document.body
        )}
    </>
  );
}

function buildFileTree(files: FileNode[]): FileNode[] {
  const tree: FileNode[] = [];
  const map = new Map<string, FileNode>();

  files.forEach(file => {
    map.set(file.id, { ...file, children: [] });
  });

  files.forEach(file => {
    const node = map.get(file.id);
    if (!node) return;
    if (file.parentId) {
      const parentNode = map.get(file.parentId);
      if (parentNode) {
        parentNode.children = [...(parentNode.children || []), node];
      }
    } else {
      tree.push(node);
    }
  });

  return tree.filter(node => node);
}

import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { DateTime } from 'luxon';
import {
  Language,
  TrainingsInterval,
  User,
} from '../../../__generated__/gql/graphql';
import UserTag from '../../../components/UserTag';
import { Calendar } from '@/components/ui/calendar';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { CalendarIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { calculateMaxDueDate } from '@/utils/dateUtils';

interface BasicInfoFormData {
  title: string;
  description: string;
  responsiblePersonId: string;
  interval: TrainingsInterval;
  isRequired: boolean;
  language: Language;
  startDate?: DateTime;
  dueDate?: DateTime;
}

interface BasicInfoStepProps {
  allUsers: User[];
}

const BasicInfoStep: FC<BasicInfoStepProps> = ({ allUsers }) => {
  const { control, setValue, getValues } = useFormContext<BasicInfoFormData>();

  const startDate = useWatch({ control, name: 'startDate' });
  const interval = useWatch({ control, name: 'interval' });

  const handleDueDateSelect = (date: Date | undefined) => {
    if (!date) {
      setValue('dueDate', undefined);
      return;
    }

    const selectedDate = DateTime.fromJSDate(date);
    const currentStartDate = getValues('startDate') || DateTime.now();
    const currentInterval = getValues('interval');

    const maxDueDate = calculateMaxDueDate(currentStartDate, currentInterval);

    if (selectedDate > maxDueDate) {
      setValue('dueDate', maxDueDate);
    } else {
      setValue('dueDate', selectedDate);
    }
  };

  return (
    <div className="space-y-6 p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Title Field with Language Selector */}
        <div className="col-span-full">
          <FormLabel>Title</FormLabel>
          <div className="flex space-x-6 mt-2">
            <div className="flex-1">
              <FormField
                control={control}
                name="title"
                rules={{
                  required: 'Title is required',
                  minLength: {
                    value: 3,
                    message: 'Title must be at least 3 characters long',
                  },
                  maxLength: {
                    value: 100,
                    message: 'Title must be less than 100 characters',
                  },
                }}
                render={({ field }) => (
                  <FormItem className="space-y-0">
                    <FormControl>
                      <Input
                        placeholder="Enter training title"
                        {...field}
                        className="bg-background"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="w-44">
              <FormField
                control={control}
                name="language"
                defaultValue={Language.English}
                render={({ field }) => (
                  <FormItem className="space-y-0">
                    <FormControl>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Language" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.values(Language).map(languageValue => (
                            <SelectItem
                              key={languageValue}
                              value={languageValue}
                            >
                              {languageValue.charAt(0).toUpperCase() +
                                languageValue.slice(1).toLowerCase()}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>

        {/* Description Field */}
        <div className="col-span-full">
          <FormField
            control={control}
            name="description"
            rules={{
              required: 'Description is required',
              minLength: {
                value: 10,
                message: 'Description must be at least 10 characters long',
              },
              maxLength: {
                value: 1000,
                message: 'Description must be less than 1000 characters',
              },
            }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Enter training description"
                    rows={4}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* Responsible Person Field */}
        <div className="col-span-full md:col-span-1">
          <FormField
            control={control}
            name="responsiblePersonId"
            rules={{ required: 'Responsible person is required' }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Responsible Person</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl className="mt-2">
                    <SelectTrigger>
                      <SelectValue placeholder="Select responsible person" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {allUsers.map(user => (
                      <SelectItem key={user.id} value={user.id}>
                        <UserTag user={user} variant="text" size="medium" />
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormDescription>
                  Select the person responsible for managing this training
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* Start Date Field */}
        <div className="col-span-full md:col-span-1">
          <FormField
            control={control}
            name="startDate"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Start Date</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant="outline"
                        className={cn(
                          'mt-2 w-full justify-start text-left font-normal',
                          !field.value && 'text-muted-foreground'
                        )}
                      >
                        {field.value instanceof DateTime
                          ? field.value.toFormat('yyyy-MM-dd')
                          : 'Pick a start date'}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={
                        field.value instanceof DateTime
                          ? field.value.toJSDate()
                          : undefined
                      }
                      onSelect={date => {
                        const newStartDate = date
                          ? DateTime.fromJSDate(date)
                          : undefined;
                        field.onChange(newStartDate);

                        const currentDueDate = getValues('dueDate');
                        if (currentDueDate && newStartDate) {
                          const currentInterval = getValues('interval');
                          const maxDueDate = calculateMaxDueDate(
                            newStartDate,
                            currentInterval
                          );

                          if (
                            currentDueDate < newStartDate ||
                            currentDueDate > maxDueDate
                          ) {
                            setValue(
                              'dueDate',
                              maxDueDate.diff(newStartDate).days > 7
                                ? newStartDate.plus({ days: 7 })
                                : maxDueDate
                            );
                          }
                        }
                      }}
                      disabled={date =>
                        date < DateTime.now().startOf('day').toJSDate()
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormDescription>
                  If not selected, current date will be used as start date
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* Interval Field */}
        <div className="col-span-full md:col-span-1">
          <FormField
            control={control}
            name="interval"
            defaultValue={TrainingsInterval.Yearly}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Interval</FormLabel>
                <Select
                  onValueChange={value => {
                    field.onChange(value);

                    const currentStartDate = getValues('startDate');
                    const currentDueDate = getValues('dueDate');

                    if (currentStartDate && currentDueDate) {
                      const newInterval = value as TrainingsInterval;
                      const maxDueDate = calculateMaxDueDate(
                        currentStartDate,
                        newInterval
                      );

                      if (currentDueDate > maxDueDate) {
                        setValue('dueDate', maxDueDate);
                      }
                    }
                  }}
                  value={field.value}
                >
                  <FormControl className="mt-2">
                    <SelectTrigger>
                      <SelectValue placeholder="Select interval" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Object.values(TrainingsInterval).map(intervalValue => (
                      <SelectItem key={intervalValue} value={intervalValue}>
                        {intervalValue.charAt(0).toUpperCase() +
                          intervalValue.slice(1).toLowerCase()}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormDescription>
                  How often should this training be repeated?
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* Due Date Field */}
        <div className="col-span-full md:col-span-1">
          <FormField
            control={control}
            name="dueDate"
            rules={{
              required: 'Due date is required',
              validate: value => {
                if (!value) return 'Due date is required';

                const formStartDate = getValues('startDate') || DateTime.now();
                if (value.diff(formStartDate).milliseconds <= 0) {
                  return 'Due date must be after start date';
                }

                const formInterval = getValues('interval');
                const maxDueDate = calculateMaxDueDate(
                  formStartDate,
                  formInterval
                );
                if (value > maxDueDate) {
                  return `Due date must be before ${maxDueDate.toFormat(
                    'yyyy-MM-dd'
                  )} (based on interval)`;
                }

                return true;
              },
            }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Due Date *</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant="outline"
                        className={cn(
                          'mt-2 w-full justify-start text-left font-normal',
                          !field.value && 'text-muted-foreground'
                        )}
                      >
                        {field.value instanceof DateTime
                          ? field.value.toFormat('yyyy-MM-dd')
                          : 'Pick a date'}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={
                        field.value instanceof DateTime
                          ? field.value.toJSDate()
                          : undefined
                      }
                      onSelect={handleDueDateSelect}
                      disabled={date => {
                        const minDate = startDate
                          ? startDate.startOf('day').toJSDate()
                          : DateTime.now().startOf('day').toJSDate();

                        if (date < minDate) return true;

                        if (interval) {
                          const effectiveStartDate =
                            startDate || DateTime.now();
                          const maxDate = calculateMaxDueDate(
                            effectiveStartDate,
                            interval
                          ).toJSDate();
                          if (date > maxDate) return true;
                        }

                        return false;
                      }}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormDescription>
                  Due date must be between start date and the end of the
                  interval period
                  {startDate && interval && (
                    <>
                      {' '}
                      (before{' '}
                      {calculateMaxDueDate(startDate, interval).toFormat(
                        'yyyy-MM-dd'
                      )}
                      )
                    </>
                  )}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* Required Training Switch */}
        <div className="col-span-full md:col-span-1">
          <FormField
            control={control}
            name="isRequired"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                <div className="space-y-0.5">
                  <FormLabel className="text-base">Required Training</FormLabel>
                  <FormDescription>
                    Toggle if this training is mandatory for all users
                  </FormDescription>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicInfoStep;

import { useCallback, useReducer } from 'react';
import {
  useQuery,
  QueryHookOptions,
  OperationVariables,
  DocumentNode,
} from '@apollo/client';

export type FilterOptions = Record<string, unknown>;

export type PaginationOptions<TData, TVariables extends OperationVariables> = {
  query: DocumentNode;
  itemsPerPage: number;
  initialPage?: number;
  queryOptions?: Omit<QueryHookOptions<TData, TVariables>, 'variables'>;
  getVariables: (
    page: number,
    itemsPerPage: number,
    filters?: FilterOptions,
    cursor?: string | null
  ) => TVariables;
  getTotalItems: (data: TData) => number;
  getHasNextPage: (data: TData) => boolean;
  getCursor?: (data: TData) => string | null;
  getResponseItems?: (data: TData) => Array<unknown>;
};

export type PaginationResult<TData> = {
  data: TData | undefined;
  loading: boolean;
  error: unknown;
  currentPage: number;
  totalPages: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  totalItems: number;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  goToPage: (page: number) => void;
  applyFilters: (newFilters: FilterOptions) => void;
  filters: FilterOptions;
  isPageAccessible: (pageNumber: number) => boolean;
};

type PaginationState = {
  currentPage: number;
  totalPages: number;
  filters: FilterOptions;
  cursors: Record<number, string>;
  visitedPages: Set<number>;
};

type PaginationAction =
  | { type: 'SET_TOTAL_PAGES'; payload: number }
  | { type: 'SET_CURRENT_PAGE'; payload: number }
  | { type: 'SET_FILTERS'; payload: FilterOptions }
  | { type: 'ADD_CURSOR'; payload: { page: number; cursor: string } }
  | { type: 'MARK_PAGE_VISITED'; payload: number }
  | { type: 'RESET_CURSORS' };

function paginationReducer(
  state: PaginationState,
  action: PaginationAction
): PaginationState {
  switch (action.type) {
    case 'SET_TOTAL_PAGES':
      return { ...state, totalPages: action.payload };
    case 'SET_CURRENT_PAGE':
      return { ...state, currentPage: action.payload };
    case 'SET_FILTERS':
      return {
        ...state,
        filters: { ...action.payload },
        currentPage: 1,
        cursors: {},
        visitedPages: new Set([1]),
      };
    case 'ADD_CURSOR':
      return {
        ...state,
        cursors: {
          ...state.cursors,
          [action.payload.page]: action.payload.cursor,
        },
      };
    case 'MARK_PAGE_VISITED': {
      const newVisitedPages = new Set(state.visitedPages);
      newVisitedPages.add(action.payload);
      return {
        ...state,
        visitedPages: newVisitedPages,
      };
    }
    case 'RESET_CURSORS':
      return { ...state, cursors: {}, visitedPages: new Set([1]) };
    default:
      return state;
  }
}

export function usePagination<
  TData,
  TVariables extends OperationVariables = OperationVariables
>(options: PaginationOptions<TData, TVariables>): PaginationResult<TData> {
  const {
    query,
    itemsPerPage,
    initialPage = 1,
    queryOptions = {},
    getVariables,
    getTotalItems,
    getHasNextPage,
    getCursor,
    getResponseItems: _getResponseItems,
  } = options;

  const [state, dispatch] = useReducer(paginationReducer, {
    currentPage: initialPage,
    totalPages: 0,
    filters: {},
    cursors: {},
    visitedPages: new Set([initialPage]),
  });

  const { currentPage, totalPages, filters, cursors, visitedPages } = state;

  const currentCursor = currentPage > 1 ? cursors[currentPage] : null;

  const variables = getVariables(
    currentPage,
    itemsPerPage,
    filters,
    currentCursor
  );

  const { data, loading, error, fetchMore } = useQuery<TData, TVariables>(
    query,
    {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      ...queryOptions,
      onCompleted: data => {
        if (data) {
          dispatch({ type: 'MARK_PAGE_VISITED', payload: currentPage });

          const totalItems = getTotalItems(data);
          const calculatedTotalPages = Math.max(
            1,
            Math.ceil(totalItems / itemsPerPage)
          );
          dispatch({ type: 'SET_TOTAL_PAGES', payload: calculatedTotalPages });

          if (getCursor && getHasNextPage(data)) {
            const cursor = getCursor(data);
            if (cursor) {
              dispatch({
                type: 'ADD_CURSOR',
                payload: { page: currentPage + 1, cursor },
              });
            }
          }

          if (queryOptions.onCompleted) {
            queryOptions.onCompleted(data);
          }
        }
      },
    }
  );

  const totalItems = data ? getTotalItems(data) : 0;
  const hasNextPage = data ? getHasNextPage(data) : false;
  const hasPreviousPage = currentPage > 1;

  const isPageCached = useCallback(
    (page: number) => {
      return visitedPages.has(page);
    },
    [visitedPages]
  );

  const isPageAccessible = useCallback(
    (pageNumber: number) => {
      if (pageNumber === 1) return true;
      if (pageNumber === currentPage) return true;
      if (pageNumber === currentPage + 1 && hasNextPage) return true;
      if (pageNumber === currentPage - 1) return true;

      return isPageCached(pageNumber);
    },
    [currentPage, hasNextPage, isPageCached]
  );

  const handleNextPage = useCallback(() => {
    if (hasNextPage && getCursor && data) {
      const cursor = getCursor(data);
      if (cursor) {
        const newPage = currentPage + 1;

        dispatch({
          type: 'ADD_CURSOR',
          payload: { page: newPage, cursor },
        });

        dispatch({ type: 'SET_CURRENT_PAGE', payload: newPage });

        if (!isPageCached(newPage)) {
          fetchMore({
            variables: {
              ...variables,
              after: cursor,
              before: null,
              first: itemsPerPage,
              last: null,
            },
          });
        }
      }
    }
  }, [
    hasNextPage,
    getCursor,
    data,
    currentPage,
    fetchMore,
    variables,
    itemsPerPage,
    isPageCached,
  ]);

  const handlePreviousPage = useCallback(() => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      const prevCursor = newPage > 1 ? cursors[newPage] : null;

      dispatch({ type: 'SET_CURRENT_PAGE', payload: newPage });

      if (!isPageCached(newPage)) {
        fetchMore({
          variables: {
            ...variables,
            after: prevCursor,
            before: null,
            first: itemsPerPage,
            last: null,
          },
        });
      }
    }
  }, [currentPage, cursors, fetchMore, variables, itemsPerPage, isPageCached]);

  const goToPage = useCallback(
    (page: number) => {
      if (
        page >= 1 &&
        page <= totalPages &&
        page !== currentPage &&
        isPageAccessible(page)
      ) {
        if (page === 1) {
          dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 });

          if (!isPageCached(1)) {
            fetchMore({
              variables: {
                ...variables,
                after: null,
                before: null,
                first: itemsPerPage,
                last: null,
              },
            });
          }
        } else if (page === currentPage + 1) {
          handleNextPage();
        } else if (page === currentPage - 1) {
          handlePreviousPage();
        } else if (cursors[page]) {
          dispatch({ type: 'SET_CURRENT_PAGE', payload: page });

          // Only fetch if we haven't visited this page before
          if (!isPageCached(page)) {
            fetchMore({
              variables: {
                ...variables,
                after: cursors[page],
                before: null,
                first: itemsPerPage,
                last: null,
              },
            });
          }
        }
      }
    },
    [
      totalPages,
      currentPage,
      cursors,
      fetchMore,
      variables,
      itemsPerPage,
      handleNextPage,
      handlePreviousPage,
      isPageAccessible,
      isPageCached,
    ]
  );

  const applyFilters = useCallback((newFilters: FilterOptions) => {
    dispatch({ type: 'SET_FILTERS', payload: newFilters });
  }, []);

  return {
    data,
    loading,
    error,
    currentPage,
    totalPages,
    hasNextPage,
    hasPreviousPage,
    totalItems,
    handleNextPage,
    handlePreviousPage,
    goToPage,
    applyFilters,
    filters,
    isPageAccessible,
  };
}

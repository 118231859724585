import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Label } from '@/components/ui/label';
import { Language } from '@/__generated__/gql/graphql';
import {
  ClipboardList,
  Database,
  GitMerge,
  AlertTriangle,
  FileText,
  FileCheck,
  ArrowRight,
} from 'lucide-react';

const INITIALIZE_USER_AND_ORGANIZATION = gql`
  mutation InitializeUserAndOrganization($language: Language!) {
    initializeUserAndOrganization(language: $language) {
      user {
        id
        name
        email
      }
      organization {
        id
        name
      }
    }
  }
`;

export default function WelcomePage() {
  const navigate = useNavigate();
  const [language, setLanguage] = useState<Language | null>(null);
  const [initializeUserAndOrganization] = useMutation(
    INITIALIZE_USER_AND_ORGANIZATION
  );

  const registerNewUser = async () => {
    if (!language) {
      toast.error('Please select a language before proceeding');
      return;
    }

    try {
      await initializeUserAndOrganization({
        variables: { language },
      });
      toast.success('User and organization initialized successfully!');
      navigate('/projects');
    } catch (err) {
      toast.error(`An unexpected error occurred: ${err}`);
    }
  };

  const features = [
    {
      title: 'AI Policy',
      description:
        'Build your own AI policy based on latest standards. Guided by a practical questionnaire that leverages your organizational information.',
      icon: <ClipboardList className="h-5 w-5 text-primary mb-2" />,
    },
    {
      title: 'AI Registry',
      description:
        'Collect and manage all your AI use cases. Classify, compare and evaluate use cases based on risk and impact.',
      icon: <Database className="h-5 w-5 text-primary mb-2" />,
    },
    {
      title: 'Workflows',
      description:
        'Automatically translate requirements into actionable tasks and have your compliance status at sight.',
      icon: <GitMerge className="h-5 w-5 text-primary mb-2" />,
    },
    {
      title: 'Risk Management',
      description:
        'Manage AI risks based on the latest standards and risk assessments — within trail or integrated into your existing Risk Management process.',
      icon: <AlertTriangle className="h-5 w-5 text-primary mb-2" />,
    },
    {
      title: 'Audit Trail',
      description:
        'Create transparency during AI development. Combine data, models, hypotheses and results in one central place for increased auditability.',
      icon: <FileText className="h-5 w-5 text-primary mb-2" />,
    },
    {
      title: 'Automated Documentation',
      description:
        'Save time through accurate, automated documentation — both for technical and compliance purposes.',
      icon: <FileCheck className="h-5 w-5 text-primary mb-2" />,
    },
  ];

  return (
    <div className="min-h-screen w-full overflow-y-auto">
      <div className="container mx-auto px-4 sm:px-6 md:px-8 py-6 sm:py-8">
        <div className="flex flex-col items-center gap-4 sm:gap-8 mb-6">
          <h1 className="text-2xl sm:text-3xl md:text-4xl text-center mt-6">
            Welcome to Trail &nbsp;🚀
          </h1>
          <p className="text-base sm:text-lg md:text-xl text-center max-w-3xl text-muted-foreground">
            Trail offers the simplest way to operationalize AI Governance and
            the EU AI Act, empowering developers and businesses to create
            trustworthy AI solutions.
          </p>
        </div>

        <div className="w-full mb-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
            {features.map((feature, index) => (
              <Card key={index} className="h-full">
                <CardContent className="p-4 sm:p-6">
                  <div className="flex flex-col">
                    <div className="flex items-center gap-4 mb-2">
                      <div className="mt-2">{feature.icon}</div>
                      <h4>{feature.title}</h4>
                    </div>
                    <p className="text-sm sm:text-base text-muted-foreground">
                      {feature.description}
                    </p>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>

        <div className="w-full text-center mb-10">
          <h2 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-3 text-foreground">
            Let's start building AI you can trust.
          </h2>
          <p className="max-w-3xl mx-auto mb-6 sm:mb-8 text-sm sm:text-base text-muted-foreground">
            Stop worrying about documentation, knowledge loss, or governance and
            start leveraging AI.
          </p>

          <div className="flex flex-col items-center gap-4 sm:gap-6">
            <div className="flex flex-col sm:flex-row items-center gap-2 sm:gap-3">
              <Label
                htmlFor="language"
                className="text-sm sm:text-base text-foreground"
              >
                Select your preferred language:{' '}
              </Label>
              <Select
                value={language || ''}
                onValueChange={value => setLanguage(value as Language)}
              >
                <SelectTrigger id="language" className="w-[180px]">
                  <SelectValue placeholder="Select language" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={Language.English}>English</SelectItem>
                  <SelectItem value={Language.German}>German</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <Button
              onClick={registerNewUser}
              size="lg"
              className="px-6 sm:px-8 w-full sm:w-auto max-w-xs mt-4"
              disabled={!language}
            >
              Get Started <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

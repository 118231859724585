import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { gql } from '@/__generated__/gql';
import { Experiment } from '@/__generated__/gql/graphql';
import ExperimentMetricTable from '../ExperimentMetricTable';
import ExperimentDeltaTable from '../ExperimentDeltaTable';
import ChartViewModal from '../ChartViewModal';
import ExperimentMetricChart from '../ExperimentMetricChart';
import theme from '@/theme';
import NotFound from '@/components/NotFound';
import FriendlyId from '@/components/ui/friendly-id';
import { LoadingButton } from '@mui/lab';

type RunFactsTabProps = {
  projectId: string;
  experiment: Experiment;
  businessMeasurement?: any;
};

const EDIT_EXPERIMENT_HYPOTHESIS = gql(`
mutation editHypothesis($experimentId: String!, $hypothesis: String!) {
  editExperimentHypothesis(experimentId: $experimentId, hypothesis: $hypothesis) {
    experiment {
      id
      hypothesis
    }
  }
}
`);

const ADD_COMMENT_TO_EXPERIMENT = gql(`
  mutation addCommentToExperiment($experimentId: String!, $comment: String!) {
    addExperimentComment(experimentId: $experimentId, comment: $comment) {
      experiment {
        id
        comments
      }
    }
  }
`);

const RunFactsTab: React.FC<RunFactsTabProps> = ({
  experiment,
  businessMeasurement,
}) => {
  const [comments, setComments] = useState(experiment?.comments ?? '');
  const [isHypothesisEditOpen, setIsHypothesisEditOpen] = useState(false);
  const [chartView, setChartView] = useState<
    [string, Array<any>] | undefined
  >();
  const [selectedMetric, setSelectedMetric] = useState('');
  const [hypothesis, setHypothesis] = useState(experiment?.hypothesis ?? '');

  const [commitCommentsMutation, { loading: commentsAreUpdating }] =
    useMutation(ADD_COMMENT_TO_EXPERIMENT);
  const [commitHypothesisMutation] = useMutation(EDIT_EXPERIMENT_HYPOTHESIS);

  useEffect(() => {
    setComments(experiment?.comments ?? '');
  }, [experiment]);

  const instanceRuns = experiment?.instanceRuns ?? [];
  const sampleInstanceRun = instanceRuns[0];
  const param = sampleInstanceRun?.parameters ?? [];
  const fetchedHypothesis = experiment?.hypothesis ?? '';
  const metrics = sampleInstanceRun?.metrics ?? [];
  const paramDelta = experiment?.paramDelta ?? [];
  const metricDelta = experiment?.metricDelta ?? [];
  const metricsHistory = sampleInstanceRun?.metricsHistory ?? [];
  const gitCommitHash = experiment?.gitCommitHash ?? '';
  const businessEstimation = (() => {
    if (!businessMeasurement || !metrics) {
      return undefined;
    }
    return [
      'truePositive',
      'falsePositive',
      'trueNegative',
      'falseNegative',
    ].reduce<number | undefined>(
      (estimation: number | undefined, measurement: string) => {
        const estimatedFactor = businessMeasurement[measurement];
        const value = metrics[measurement];
        let reducedEstimation = estimation;
        if (estimatedFactor !== undefined && value !== undefined) {
          reducedEstimation =
            (reducedEstimation !== undefined ? reducedEstimation : 0) +
            estimatedFactor * value;
        }
        return reducedEstimation;
      },
      undefined
    );
  })();

  const handleCommentsChangeEvent = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setComments(e?.target?.value);
  };

  const handleHypothesisEditCancel = () => {
    setHypothesis(fetchedHypothesis);
    setIsHypothesisEditOpen(false);
  };

  const notifyHypothesisSaveSuccess = () =>
    toast.success('Hypothesis saved.', {
      position: 'top-center',
    });

  const notifyHypothesisSaveFailed = () =>
    toast.error('Error saving changes.', {
      position: 'top-center',
    });

  return (
    <Grid container gap={2} justifyContent="space-between">
      <Grid
        item
        xs={businessEstimation ? 8 : 12}
        sx={{
          bgcolor: '#001529',
          padding: 2,
          borderRadius: theme.spacing(1),
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">Hypothesis</Typography>
          {!isHypothesisEditOpen && (
            <IconButton
              style={{ marginTop: '-8px' }}
              color="primary"
              aria-label="edit"
              size="small"
              onClick={() => setIsHypothesisEditOpen(true)}
            >
              <ModeEditIcon />
            </IconButton>
          )}
        </Box>
        {isHypothesisEditOpen ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              label="Change Hypothesis"
              value={hypothesis}
              multiline
              rows={2}
              sx={{
                marginTop: '14px',
              }}
              onChange={e => setHypothesis(e.target.value)}
            />
            <Box
              sx={{
                mt: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Button
                fullWidth
                variant="outlined"
                onClick={() => {
                  commitHypothesisMutation({
                    variables: {
                      experimentId: experiment.id,
                      hypothesis,
                    },
                    onCompleted: () => {
                      notifyHypothesisSaveSuccess();
                      setIsHypothesisEditOpen(false);
                    },
                    onError: () => {
                      notifyHypothesisSaveFailed();
                    },
                  });
                }}
              >
                Save
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="warning"
                onClick={handleHypothesisEditCancel}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        ) : (
          <Typography>{hypothesis}</Typography>
        )}
      </Grid>
      {gitCommitHash ? (
        <Box
          sx={{
            bgcolor: '#001529',
            borderRadius: theme.spacing(1),
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 2,
            width: '100%',
          }}
        >
          <Typography variant="h6">Git Commit Hash</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FriendlyId
              id={gitCommitHash as string}
              showFull={false}
              subtle={true}
            />
          </Box>
        </Box>
      ) : (
        <NotFound subject="commit-hash" />
      )}
      {businessEstimation !== undefined && (
        <Grid
          item
          xs={3}
          sx={{
            bgcolor: '#001529',
            padding: 2,
            borderRadius: theme.spacing(1),
          }}
        >
          <Typography>Business Value Estimation</Typography>
          <Typography variant="h5">{businessEstimation.toFixed(3)}</Typography>
        </Grid>
      )}
      <Grid
        item
        xs={3.8}
        sx={{
          bgcolor: '#001529',
          padding: 2,
          borderRadius: theme.spacing(1),
        }}
      >
        <Typography variant="h6">Parameters</Typography>
        <ExperimentMetricTable data={param} displayTrend delta={paramDelta} />
      </Grid>
      <Grid
        item
        xs={3.8}
        sx={{
          bgcolor: '#001529',
          borderRadius: theme.spacing(1),
          padding: 2,
        }}
      >
        <Typography variant="h6">Metrics</Typography>
        <ExperimentMetricTable
          data={metrics}
          displayTrend
          delta={metricDelta}
        />
      </Grid>
      <Grid
        item
        xs={3.8}
        sx={{
          bgcolor: '#001529',
          borderRadius: theme.spacing(1),
          padding: 2,
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Changed Variables
        </Typography>
        <ExperimentDeltaTable
          title="Parameter Delta"
          experimentId={experiment.id}
          isMetric={false}
        />
        <ExperimentDeltaTable
          title="Metric Delta"
          experimentId={experiment.id}
          isMetric
        />
      </Grid>
      {metricsHistory.length > 0 && (
        <Grid
          item
          xs={12}
          sx={{
            bgcolor: '#001529',
            borderRadius: theme.spacing(1),
            padding: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginBottom: '10px',
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Metrics History
            </Typography>
            <Select
              labelId="comparing-metric-select-label"
              id="comparing-metric-select"
              value={selectedMetric}
              label="Metric"
              onChange={e => setSelectedMetric(e.target.value as string)}
              sx={{ marginTop: '-5px' }}
            >
              {metricsHistory.map(historyWrapper => (
                <MenuItem
                  key={historyWrapper?.metricName}
                  value={historyWrapper?.metricName as string}
                >
                  {historyWrapper?.metricName}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            style={{
              gap: '12px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {metricsHistory
              .filter(
                historyEntry => historyEntry?.metricName === selectedMetric
              )
              .map(historyWrapper => (
                <Card
                  key={historyWrapper?.metricName}
                  sx={{
                    width: '90%',
                    height: 250,
                  }}
                  onClick={() =>
                    setChartView([
                      historyWrapper?.metricName as string,
                      historyWrapper?.history as Array<any>,
                    ])
                  }
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <ExperimentMetricChart
                      metric={
                        historyWrapper?.metricName?.toLowerCase() as string
                      }
                      data={historyWrapper?.history as Array<any>}
                      preview
                    />
                  </CardContent>
                </Card>
              ))}
            <ChartViewModal
              data={chartView}
              onClose={() => setChartView(undefined)}
            />
          </Box>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sx={{
          bgcolor: '#001529',
          borderRadius: theme.spacing(1),
          padding: 2,
        }}
      >
        <TextField
          label="Comments"
          fullWidth
          multiline
          rows={4}
          value={comments}
          onChange={handleCommentsChangeEvent}
        />
        <LoadingButton
          loading={commentsAreUpdating}
          sx={{ mt: '10px' }}
          fullWidth
          variant="outlined"
          onClick={async () => {
            await commitCommentsMutation({
              variables: {
                experimentId: experiment.id,
                comment: comments,
              },
            });
            toast.success('Comments Updated', {
              position: 'top-center',
            });
          }}
        >
          Save
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default RunFactsTab;

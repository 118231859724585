import React from 'react';
import {
  Card,
  CardContent,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useRecoilState } from 'recoil';
import { EnabledFeatures, enabledFeaturesAtom } from '../EnabledFeatures';
import { toast } from 'sonner';
import { useFeatureFlagEnabled } from 'posthog-js/react';

const UPDATE_ENABLED_FEATURES = gql`
  mutation UpdateOrganizationEnabledFeatures(
    $showFrameworkManagement: Boolean
    $sidebarShowCompliance: Boolean
    $sidebarShowDevelop: Boolean
    $sidebarShowQuestionnaire: Boolean
    $sidebarShowReports: Boolean
    $sidebarShowRiskManagement: Boolean
    $sidebarShowRolesAndPermissions: Boolean
    $sidebarShowLiteracy: Boolean
  ) {
    updateOrganizationEnabledFeatures(
      showFrameworkManagement: $showFrameworkManagement
      sidebarShowCompliance: $sidebarShowCompliance
      sidebarShowDevelop: $sidebarShowDevelop
      sidebarShowQuestionnaire: $sidebarShowQuestionnaire
      sidebarShowReports: $sidebarShowReports
      sidebarShowRiskManagement: $sidebarShowRiskManagement
      sidebarShowRolesAndPermissions: $sidebarShowRolesAndPermissions
      sidebarShowLiteracy: $sidebarShowLiteracy
    ) {
      organization {
        enabledFeatures {
          showFrameworkManagement
          sidebarShowCompliance
          sidebarShowReports
          sidebarShowQuestionnaire
          sidebarShowRolesAndPermissions
          sidebarShowDevelop
          sidebarShowRiskManagement
          sidebarShowLiteracy
        }
      }
    }
  }
`;

const featureDisplayNames = {
  sidebarShowRegistry: 'AI Registry',
  showFrameworkManagement: 'Framework Management',
  sidebarShowCompliance: 'Compliance',
  sidebarShowReports: 'Reports',
  sidebarShowQuestionnaire: 'Questionnaire',
  sidebarShowRolesAndPermissions: 'Roles and Permissions',
  sidebarShowDevelop: 'Development',
  sidebarShowRiskManagement: 'Risk Management',
  sidebarShowLiteracy: 'Literacy Training',
};

const FeatureToggleManagement: React.FC = () => {
  const [enabledFeatures, setEnabledFeatures] =
    useRecoilState(enabledFeaturesAtom);
  const showBetaFeatures = useFeatureFlagEnabled('beta-tester');
  const [updateEnabledFeatures] = useMutation(UPDATE_ENABLED_FEATURES);

  const handleToggle = async (feature: keyof EnabledFeatures) => {
    const updatedFeatures = {
      ...enabledFeatures,
      [feature]: !enabledFeatures[feature],
    };
    try {
      await updateEnabledFeatures({
        variables: updatedFeatures,
      });
      setEnabledFeatures(updatedFeatures);
      const action = updatedFeatures[feature] ? 'enabled' : 'disabled';
      const featureName = featureDisplayNames[feature] || feature;
      toast.success(`${featureName} ${action} successfully`);
    } catch (error) {
      console.error('Error updating feature toggle:', error);
      toast.error(
        `Failed to update ${featureDisplayNames[feature] || feature}`
      );
    }
  };

  return (
    <Card>
      <CardContent>
        <h2>Features</h2>
        <FormGroup>
          {Object.entries(enabledFeatures)
            .filter(
              ([feature]) =>
                feature !== 'sidebarShowRegistry' &&
                feature !== 'checkPermission'
            )
            .map(([feature, enabled]) => {
              if (
                feature === 'sidebarShowRiskManagement' &&
                !showBetaFeatures
              ) {
                return null;
              }
              return (
                <FormControlLabel
                  key={feature}
                  control={
                    <Switch
                      checked={enabled}
                      onChange={() =>
                        handleToggle(feature as keyof EnabledFeatures)
                      }
                      name={feature}
                    />
                  }
                  label={
                    featureDisplayNames[feature as keyof EnabledFeatures] ||
                    feature
                  }
                />
              );
            })}
        </FormGroup>
      </CardContent>
    </Card>
  );
};

export default FeatureToggleManagement;

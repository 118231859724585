import { gql, useMutation } from '@apollo/client';
import { Box, Button, Card, CardContent, TextField } from '@mui/material';

import { useState } from 'react';
import { toast } from 'sonner';
import { GET_DETAILS } from '../DashboardDetails';

const EDIT_DESC = gql(`
mutation update_project_description($projectId: String!, $description: String) {
  updateProject(projectId: $projectId, description: $description) {
  project {
    id
  }
  }
}
`);

interface Props {
  description: string;
  projectId: string;
  onClose: () => void;
}
export default function EditProjectDescription({
  description,
  projectId,
  onClose,
}: Props) {
  const [commitDescriptionChange] = useMutation(EDIT_DESC, {
    refetchQueries: [{ query: GET_DETAILS, variables: { projectId } }],
  });

  const [projectDescription, setProjectDescription] = useState(description);
  return (
    <Card sx={{ width: '50%' }}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            justifyContent: 'space-between',
          }}
        >
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            value={projectDescription}
            multiline
            label="Project Description"
            variant="standard"
            onChange={e => setProjectDescription(e.target.value)}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Button sx={{ color: 'red' }} onClick={onClose}>
              Discard
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                commitDescriptionChange({
                  variables: {
                    projectId,
                    description: projectDescription,
                  },
                  onCompleted: () => {
                    onClose();
                  },
                  onError: () => {
                    toast.error('Error saving changes.', {
                      position: 'top-center',
                    });
                  },
                });
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

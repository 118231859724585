import { FC } from 'react';
import { Container, Typography, Grid } from '@mui/material';
import ShieldIcon from '@mui/icons-material/Shield';
import SchoolIcon from '@mui/icons-material/School';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  AddOnType,
  GetCurrentUserForPricingQuery,
  GetOrganizationNameQuery,
} from '../__generated__/gql/graphql';
import AddOn from './AddOn';

const GET_CURRENT_USER = gql`
  query getCurrentUserForPricing {
    currentUser {
      email
    }
  }
`;

const GET_ORGANIZATION_NAME = gql`
  query getOrganizationName {
    organization {
      name
    }
  }
`;

export const PricingPage: FC = () => {
  const { data: userData } =
    useSuspenseQuery<GetCurrentUserForPricingQuery>(GET_CURRENT_USER);
  const { data: organizationData } = useSuspenseQuery<GetOrganizationNameQuery>(
    GET_ORGANIZATION_NAME
  );

  return (
    <>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h3" align="center" gutterBottom>
          Enhance Your Trail Experience
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary" mb={6}>
          Choose the add-ons that best fit your organization's needs
        </Typography>

        <Grid container spacing={4} sx={{ maxWidth: 1200, mx: 'auto' }}>
          <Grid item xs={12} md={6}>
            <AddOn
              title="ISO 42001 Framework Add-on"
              icon={<ShieldIcon color="primary" sx={{ fontSize: 40 }} />}
              subtitle="Elevate Your AI Management"
              description="Implement a robust Artificial Intelligence Management System (AIMS) with our ISO 42001 framework"
              benefits={[
                'Mitigate AI-related risks effectively',
                'Build trust among customers and stakeholders',
                'Facilitate compliance with AI regulations',
                'Structured approach to AI policy development',
              ]}
              buttonText="Request ISO 42001 Consultation"
              addOnType={AddOnType.Iso42001}
              userEmail={userData?.currentUser?.email}
              organizationName={organizationData?.organization?.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AddOn
              title="Training Templates Add-on"
              icon={<SchoolIcon color="primary" sx={{ fontSize: 40 }} />}
              subtitle="Accelerate Team Learning"
              description="Access professional training templates and streamline your team's learning process"
              benefits={[
                'Pre-made training templates library',
                'Industry-standard training materials',
                'Regular content updates',
                'Customizable to your needs',
              ]}
              buttonText="Request Training Template Access"
              addOnType={AddOnType.Literacy}
              userEmail={userData?.currentUser?.email}
              organizationName={organizationData?.organization?.name}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PricingPage;

import React, { useState } from 'react';
import { User } from '../__generated__/gql/graphql';
import {
  ResponsibilityDisplayMap,
  RoleDisplayMap,
} from '../user_management/UserRow';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Input } from '@/components/ui/input';
import { PencilLine } from 'lucide-react';
import { Badge } from '@/components/ui/badge';

const UPDATE_USER_ATTRIBUTES = gql`
  mutation UpdateUserAttributes($name: String) {
    updateUserAttributes(name: $name) {
      user {
        id
        name
        email
      }
    }
  }
`;

export const Profile = ({ user }: { user: User }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(user.name || '');
  const [updateUserAttributes] = useMutation(UPDATE_USER_ATTRIBUTES);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      await updateUserAttributes({ variables: { name } });
      setIsEditing(false);
      toast.success('Name updated successfully');
    } catch (error) {
      console.error('Error updating name:', error);
      toast.error('Failed to update name');
    }
  };

  return (
    <Card className="w-full p-4">
      <div className="flex justify-between items-center mb-3">
        <h4 className="font-bold">Profile</h4>
        {!isEditing && (
          <Button onClick={() => setIsEditing(true)}>
            Edit Profile
            <PencilLine className="w-5 h-5 mr-1" />
          </Button>
        )}
      </div>

      <div className="flex gap-8">
        <div className="flex-0-0-auto">
          <Avatar className="w-32 h-32 text-6xl">
            <AvatarFallback>{user.name?.slice(0, 2)}</AvatarFallback>
          </Avatar>
        </div>
        <div className="flex flex-col gap-4">
          {isEditing ? (
            <div>
              <p className="font-bold text-[15px]">Name:</p>
              <div className="flex items-center gap-2">
                <Input value={name} onChange={handleNameChange} />
                <Button onClick={handleSubmit}>Save</Button>
                <Button onClick={() => setIsEditing(false)}>Cancel</Button>
              </div>
            </div>
          ) : (
            <div>
              <p className="font-bold text-[15px]">Name:</p>
              <p>{user.name}</p>
            </div>
          )}
          <div>
            <p className="font-bold text-[15px]">Email:</p>
            <p>{user.email}</p>
          </div>
          <div>
            <p className="font-bold text-[15px]">Organization:</p>
            <p>{user.organizationName}</p>
          </div>
          <div>
            <p className="font-bold text-[15px]">Roles:</p>
            <p>
              {user.roles && user.roles.length > 0
                ? user.roles
                    .map(role => (role ? RoleDisplayMap[role] : ''))
                    .filter(Boolean)
                    .join(', ')
                : 'None'}
            </p>
          </div>
          <div>
            <p className="font-bold text-[15px]">Responsibilities:</p>
            <div className="flex flex-wrap gap-2 mt-1">
              {user.responsibilities?.map(
                (responsibility, index) =>
                  responsibility && (
                    <Badge key={index} className="bg-action-blue">
                      {ResponsibilityDisplayMap[responsibility]}
                    </Badge>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

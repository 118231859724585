import React, { FC } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useNavigate } from 'react-router-dom';
import {
  gql,
  useMutation,
  useSuspenseQuery,
  useApolloClient,
} from '@apollo/client';
import { colorThemes } from '../theme';
import { toast } from 'sonner';
import {
  CreateMaterializedProjectGovernanceMutation,
  EnabledProjectGovernanceFrameworksQuery,
  ProjectGovernanceFrameworkTemplate,
} from '../__generated__/gql/graphql';
import ProjectGridItem from './ProjectGridItem';
import { GET_PROJECT } from './ProjectDetailDrawer';

interface NewGovernanceFrameworkModalProps {
  open: boolean;
  onClose: () => void;
  projectId: string;
  recommendedGovernanceFrameworks: ProjectGovernanceFrameworkTemplate[];
}

export const GET_ENABLED_RISK_PROJECT_GOVERNANCE_FRAMEWORKS = gql`
  query enabledProjectGovernanceFrameworks($projectId: String!) {
    enabledProjectGovernanceFrameworks(projectId: $projectId) {
      id
      templateId
      name
      description
    }
  }
`;

const CREATE_MATERIALIZED_PROJECT_GOVERNANCE = gql`
  mutation CreateMaterializedProjectGovernance(
    $templateId: ID!
    $projectId: ID!
  ) {
    createMaterializedProjectGovernanceFramework(
      templateId: $templateId
      projectId: $projectId
    ) {
      framework {
        id
        templateId
        name
      }
    }
  }
`;

const NewGovernanceFrameworkModal: FC<NewGovernanceFrameworkModalProps> = ({
  open,
  onClose,
  projectId,
  recommendedGovernanceFrameworks,
}) => {
  const navigate = useNavigate();
  const client = useApolloClient();

  const { data: enabledGovernanceFrameworkTemplates } =
    useSuspenseQuery<EnabledProjectGovernanceFrameworksQuery>(
      GET_ENABLED_RISK_PROJECT_GOVERNANCE_FRAMEWORKS,
      {
        variables: {
          projectId,
        },
      }
    );

  const [createAssessmentFrameworkCopy] =
    useMutation<CreateMaterializedProjectGovernanceMutation>(
      CREATE_MATERIALIZED_PROJECT_GOVERNANCE,
      {
        onCompleted: data => {
          toast.success('Framework created successfully.');
          navigate(
            `/project_governance/${projectId}/${
              data!.createMaterializedProjectGovernanceFramework!.framework!.id
            }`
          );
          onClose();

          client.cache.updateQuery(
            {
              query: GET_PROJECT,
              variables: {
                projectId,
                targetQueryMutation: 'deleteProjectGovernanceFramework',
                action: 'mutation',
              },
            },
            dataCache => {
              const projectGovernanceFrameworks =
                dataCache.project.projectGovernanceFrameworks;
              return {
                ...dataCache,
                project: {
                  ...dataCache.project,
                  projectGovernanceFrameworks: [
                    ...projectGovernanceFrameworks,
                    data.createMaterializedProjectGovernanceFramework
                      ?.framework,
                  ],
                },
              };
            }
          );
          client.cache.updateQuery(
            {
              query: GET_ENABLED_RISK_PROJECT_GOVERNANCE_FRAMEWORKS,
              variables: { projectId },
            },
            dataCache => {
              const governanceFrameworks =
                dataCache.enabledProjectGovernanceFrameworks;
              return {
                ...dataCache,
                enabledProjectGovernanceFrameworks: [
                  ...governanceFrameworks,
                  data.createMaterializedProjectGovernanceFramework?.framework,
                ],
              };
            }
          );
        },
        onError: error => {
          toast.error(`An unexpected error occurred: ${error.message}`);
        },
      }
    );

  const handleSelect = (templateId: string) => {
    createAssessmentFrameworkCopy({
      variables: {
        templateId,
        projectId,
      },
    });
  };

  const governanceFrameworks =
    enabledGovernanceFrameworkTemplates.enabledProjectGovernanceFrameworks?.filter(
      (framework): framework is NonNullable<typeof framework> =>
        framework !== null
    ) ?? [];

  const recommendedGovernanceFrameworksFiltered =
    governanceFrameworks.filter(framework =>
      recommendedGovernanceFrameworks.some(f => f?.id === framework.id)
    ) ?? [];

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Select Project Governance Framework</DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{ minHeight: '200px', minWidth: '400px' }}
          >
            {governanceFrameworks.length === 0 ? (
              <Typography variant="body1" color="textSecondary">
                All enabled Project Governance Frameworks have been created.
                Please enable additional frameworks on the{' '}
                <Link href="/organization_settings">Organization Settings</Link>{' '}
                page.
              </Typography>
            ) : (
              governanceFrameworks.map(({ id, name, description }) => (
                <Card
                  key={id}
                  sx={{ backgroundColor: colorThemes.DARK_BLUE_700 }}
                >
                  <CardActionArea onClick={() => id && handleSelect(id)}>
                    <CardContent>
                      <Typography variant="h6" sx={{ color: 'white' }}>
                        {name || 'Unknown Type'}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {description || 'Unknown Description'}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <Typography variant="h5" sx={{ mt: 3, mb: 1 }}>
        Recommended Project Governance Frameworks
      </Typography>
      {recommendedGovernanceFrameworksFiltered.length === 0 ? (
        <Typography variant="body1" color="textSecondary">
          All recommended Project Governance Frameworks have been created.
        </Typography>
      ) : (
        recommendedGovernanceFrameworksFiltered.map(
          ({ id, name, description }) => {
            return (
              <Box
                onClick={() => id && handleSelect(id)}
                sx={{
                  width: '100%',
                  height: '100%',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <ProjectGridItem
                  title=""
                  content={
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <Typography variant="h4" style={{ marginBottom: '4px' }}>
                        {name}
                      </Typography>
                      <Typography>{description}</Typography>
                    </Box>
                  }
                  icon={<ArrowOutwardIcon />}
                />
              </Box>
            );
          }
        )
      )}
    </Box>
  );
};

export default NewGovernanceFrameworkModal;

import { FormValues } from '@/literacy_training/training_creation_modal/TrainingCreationModal';

export const isQuestionsValid = (
  questions?: Array<{
    questionText: string;
    description?: string;
    options: {
      optionText: string;
      isCorrect: boolean;
      id?: string;
    }[];
    id?: string;
  }>
): boolean => {
  if (!questions?.length) return false;

  return questions.every(question => {
    const hasCorrectAnswer = question.options.some(option => option.isCorrect);
    const allOptionsHaveText = question.options.every(option =>
      option.optionText.trim()
    );
    return (
      question.questionText.trim() && hasCorrectAnswer && allOptionsHaveText
    );
  });
};

export const isBasicInfoValid = (formValues: Partial<FormValues>): boolean => {
  return !!(
    formValues.title &&
    formValues.description &&
    formValues.responsiblePersonId &&
    formValues.dueDate
  );
};

import { Modal } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { v4 as uuidv4 } from 'uuid';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import React, { useState } from 'react';
import { Reorder } from 'framer-motion';
import { RemoveCircle } from '@mui/icons-material';
import PendingRoundedIcon from '@mui/icons-material/PendingRounded';
import {
  DocumentationEntry,
  documentComponentsAtom,
  documentTemplateAtom,
} from '@/documentations/documentation_view/DocumentationPageStates';
import { colorThemes } from '@/theme';
import {
  CUSTOM_COMPONENT_TYPES,
  DocumentComponentNameMap,
} from './ComponentNameMapping';
import DocumentComponentType, {
  ComponentTypeCategories,
} from '../../../../__generated__/document_component_type';
import {
  DocumentationScope,
  ComponentCategory,
} from '@/__generated__/gql/graphql';
import { Input } from '@/components/ui/input';
import { Card } from '@/components/ui/card';
import { ArrowRightIcon } from 'lucide-react';
import DefaultComponentConfiguration from './DefaultComponentConfiguration';
import ExperimentStatsConfig from './ExperimentStatsConfiguration';

const MemoizedComponentCard = React.memo(ComponentCard);

export default function ComponentsTab() {
  const currentTemplate = useRecoilValue(documentTemplateAtom);
  const [currentComponents, setDocumentComponents] = useRecoilState(
    documentComponentsAtom
  );

  const filterComponentsByScope = (components: DocumentationEntry[]) => {
    const allowedCategories = [ComponentCategory.General];

    switch (currentTemplate?.documentationScope) {
      case DocumentationScope.Organization:
        allowedCategories.push(ComponentCategory.Organization);
        break;
      case DocumentationScope.Project:
        allowedCategories.push(ComponentCategory.Project);
        break;
      case DocumentationScope.Experiment:
        allowedCategories.push(ComponentCategory.Technical);
        break;
    }

    return components.filter(
      component =>
        component.category &&
        allowedCategories.includes(
          component.category as unknown as ComponentCategory
        )
    );
  };

  const predefinedComponents: DocumentationEntry[] = Object.values(
    DocumentComponentType
  )
    .filter(enumValue => !currentComponents.find(c => c.type === enumValue))
    .map(enumValue => ({
      id: uuidv4(),
      name: enumValue,
      query: '',
      type: enumValue,
      dataSource: undefined,
      category:
        ComponentTypeCategories[enumValue as unknown as DocumentComponentType],
    }));

  const filteredComponents = filterComponentsByScope(
    predefinedComponents || []
  );

  const [searchTerm, setSearchTerm] = useState<string>('');
  return (
    <div className="w-full">
      <div className="grid grid-cols-12 gap-10">
        <div className="col-span-5">
          <div className="flex justify-between mb-2">
            <h5 className="text-white">Components</h5>
            <div className="flex items-center border rounded-md px-2">
              <SearchIcon />
              <Input
                id="search"
                type="text"
                className="w-[200px]  border-none focus-visible:ring-0 focus-visible:outline-none"
                placeholder="Search components..."
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="max-h-[calc(100vh-230px)] overflow-y-auto">
            {filteredComponents
              .filter(component =>
                component?.name
                  ?.toLowerCase()
                  .includes(searchTerm.toLowerCase())
              )
              .map(component => (
                <MemoizedComponentCard
                  key={component.id}
                  component={component}
                />
              ))}
          </div>
        </div>
        <div className="col-span-7">
          <div className="flex justify-between mb-2">
            <h5>Preview</h5>
          </div>
          <div className="max-h-[calc(100vh-230px)] overflow-y-auto z-20">
            {currentComponents.length !== 0 ? (
              <Reorder.Group
                axis="y"
                values={currentComponents}
                onReorder={setDocumentComponents}
                as="div"
                style={{ pointerEvents: 'none' }}
              >
                {currentComponents.map(component => (
                  <Reorder.Item key={component.name} value={component} as="div">
                    <TemplateComponentCard component={component} />
                  </Reorder.Item>
                ))}
              </Reorder.Group>
            ) : (
              <NoComponentsMessage />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export const NoComponentsMessage = () => {
  return (
    <div className="flex justify-center items-center h-full">
      <p className="text-gray-500">No components selected</p>
    </div>
  );
};

function ComponentCard({ component }: { component: DocumentationEntry }) {
  const setDocumentComponents = useSetRecoilState(documentComponentsAtom);
  const addComponent = () => {
    setDocumentComponents(components => [...components, component]);
  };
  const displayName =
    DocumentComponentNameMap[
      component.type as keyof typeof DocumentComponentNameMap
    ];
  return (
    <Card
      className="p-2 w-full flex items-center group justify-between h-12 transition-all duration-200 mb-2 rounded-md border-2 border-dark-blue-200 hover:bg-dark-blue-400 cursor-pointer bg-transparent"
      onClick={addComponent}
    >
      <p className="text-md font-medium">{displayName}</p>
      <ArrowRightIcon className="w-5 h-5 mr-1 group-hover:translate-x-1 transition-all duration-200 opacity-0 group-hover:opacity-100" />
    </Card>
  );
}

function TemplateComponentCard({
  component,
}: {
  component: DocumentationEntry;
}) {
  const setDocumentComponents = useSetRecoilState(documentComponentsAtom);
  const removeComponent = () => {
    setDocumentComponents(components =>
      components.filter(c =>
        c.type === DocumentComponentType.CustomText
          ? c.id !== component.id
          : c.name !== component.name
      )
    );
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const displayName =
    DocumentComponentNameMap[
      component.type as keyof typeof DocumentComponentNameMap
    ];

  const handleClose = () => {
    setOpen(false);
  };

  const [localName, setLocalName] = useState(component.name || '');
  const [localQuery, setLocalQuery] = useState(component.query || '');

  const handleSaveComponent = (
    updatedComponent: Partial<DocumentationEntry>
  ) => {
    setDocumentComponents(components =>
      components.map(c =>
        c.id === component.id
          ? {
              ...c,
              ...updatedComponent,
            }
          : c
      )
    );
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card className="absolute right-1/2 min-w-[30vw] max-w-[50vw] top-1/2 transform -translate-y-1/2 translate-x-1/2 p-4">
          {component.type === DocumentComponentType.ExperimentStats ? (
            <ExperimentStatsConfig
              component={component}
              localName={localName}
              setLocalName={setLocalName}
              onSave={handleSaveComponent}
            />
          ) : (
            <DefaultComponentConfiguration
              localName={localName}
              setLocalName={setLocalName}
              localQuery={localQuery}
              setLocalQuery={setLocalQuery}
              onSave={(name, query) => handleSaveComponent({ name, query })}
            />
          )}
        </Card>
      </Modal>
      <Card
        className="p-2 w-full flex items-center justify-between h-12 transition-all duration-200 mb-2 rounded-md border-2 border-dark-blue-200 hover:bg-dark-blue-400 cursor-pointer bg-dark-blue-300"
        style={{ position: 'relative', pointerEvents: 'auto' }}
      >
        <div className="flex items-center gap-2">
          <p className="text-md font-medium">{displayName}</p>
          {component.type === DocumentComponentType.CustomText && (
            <p className="text-sm text-gray-400"> (Custom)</p>
          )}
        </div>
        <div className="flex items-center gap-2">
          {CUSTOM_COMPONENT_TYPES.includes(component.type) && (
            <div
              className="cursor-pointer"
              style={{ position: 'relative', pointerEvents: 'auto' }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                handleOpen();
              }}
            >
              <PendingRoundedIcon sx={{ color: colorThemes.GREY_200 }} />
            </div>
          )}
          <div
            className="cursor-pointer"
            style={{ position: 'relative', pointerEvents: 'auto' }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              removeComponent();
            }}
          >
            <RemoveCircle sx={{ color: colorThemes.GREY_200 }} />
          </div>
        </div>
      </Card>
    </>
  );
}

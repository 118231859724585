import { FC, useState } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { CheckCircle, Award } from 'lucide-react';
import {
  LiteracyTraining,
  TrainingCompletion,
} from '../../__generated__/gql/graphql';
import IMAGES from '@/images/Images';
import { buildLinkedInAddToProfileUrl } from '@/utils/linkedin-utils';

interface CompletionScreenProps {
  training: LiteracyTraining | null;
  completionData: TrainingCompletion | null;
  onComplete: () => Promise<void>;
}

const CompletionScreen: FC<CompletionScreenProps> = ({
  training,
  completionData,
  onComplete,
}) => {
  const [isCompleting, setIsCompleting] = useState(false);

  const handleComplete = async () => {
    setIsCompleting(true);
    try {
      await onComplete();
    } catch (error) {
      console.error('Error in onComplete:', error);
    } finally {
      setIsCompleting(false);
    }
  };

  return (
    <div className="flex flex-col items-center gap-4 pt-12 w-full h-full relative">
      <Card className="w-full max-w-md border-2">
        <CardContent className="flex flex-col items-center text-center pt-6">
          <div className="bg-green-100 p-4 rounded-full mb-4">
            <CheckCircle className="w-12 h-12 text-green-500" />
          </div>
          <h3 className="text-2xl font-bold mb-2">Congratulations!</h3>
          <p className="mb-2 text-base text-muted-foreground">
            You have successfully completed
          </p>
          <p className="text-xl font-semibold mb-4">{training?.title}</p>

          <div className="w-full border-t border-gray-200 my-4 pt-4">
            <div className="flex flex-col gap-5">
              {!completionData ? (
                <Button
                  onClick={handleComplete}
                  disabled={isCompleting}
                  type="button"
                  className="w-full"
                >
                  {isCompleting ? 'Completing...' : 'Complete Training'}
                </Button>
              ) : (
                <>
                  <div className="flex items-center justify-center">
                    <Award className="w-5 h-5 mr-2 text-[#0A66C2]" />
                    <span className="text-sm font-medium">
                      Share your achievement
                    </span>
                  </div>

                  <a
                    href={buildLinkedInAddToProfileUrl(completionData)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full"
                  >
                    <Button
                      type="button"
                      className="w-full bg-[#0A66C2] hover:bg-[#084e96] text-white"
                    >
                      <img
                        src={IMAGES.linkedInLogo}
                        alt="LinkedIn"
                        className="w-5 h-5 mr-2"
                      />
                      Add Certificate to LinkedIn
                    </Button>
                  </a>
                </>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CompletionScreen;

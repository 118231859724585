import { useState } from 'react';
import { DocumentationEntry } from '../../DocumentationPageStates';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { Button } from '@/components/ui/button';
import { BarChart3, FileText } from 'lucide-react';

interface ExperimentStatsConfigProps {
  component: DocumentationEntry;
  localName: string;
  setLocalName: (name: string) => void;
  onSave: (updatedComponent: Partial<DocumentationEntry>) => void;
}

export default function ExperimentStatsConfig({
  component,
  localName,
  setLocalName,
  onSave,
}: ExperimentStatsConfigProps) {
  const [includeTitle, setIncludeTitle] = useState(
    component.customArguments?.include_title !== false
  );
  const [includeComments, setIncludeComments] = useState(
    component.customArguments?.include_comments !== false
  );
  const [includeHypothesis, setIncludeHypothesis] = useState(
    component.customArguments?.include_hypothesis !== false
  );
  const [includeCreationDate, setIncludeCreationDate] = useState(
    component.customArguments?.include_creation_date !== false
  );
  const [includeMetrics, setIncludeMetrics] = useState(
    component.customArguments?.include_metrics !== false
  );
  const [includeParameters, setIncludeParameters] = useState(
    component.customArguments?.include_parameters !== false
  );
  const [customSections] = useState<Record<string, string>>(
    component.customArguments?.custom_sections || {}
  );

  const handleSave = () => {
    onSave({
      name: localName,
      query: '',
      customArguments: {
        include_title: includeTitle,
        include_comments: includeComments,
        include_hypothesis: includeHypothesis,
        include_creation_date: includeCreationDate,
        include_metrics: includeMetrics,
        include_parameters: includeParameters,
        custom_sections: customSections,
      },
    });
  };

  return (
    <Card className="bg-card border-border shadow-md">
      <CardHeader className="pb-4 border-b border-border/40">
        <div className="flex items-center gap-2 mb-2">
          <BarChart3 className="h-5 w-5 text-primary" />
          <Label
            htmlFor="section-name"
            className="text-sm font-medium text-muted-foreground"
          >
            Section Name
          </Label>
        </div>
        <CardTitle>
          <Input
            id="section-name"
            value={localName}
            onChange={e => setLocalName(e.target.value)}
            placeholder="Enter Section Name"
            className="text-xl font-semibold bg-background/50 border border-border/40 rounded-md px-3 py-2 focus-visible:ring-1 focus-visible:ring-primary"
          />
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-6 pt-5">
        <div className="flex items-center gap-2 mb-1">
          <FileText className="h-5 w-5 text-primary" />
          <h3 className="text-sm font-medium text-muted-foreground">
            Content Options
          </h3>
        </div>

        <div className="grid gap-4 bg-background/40 p-4 rounded-lg border border-border/30">
          <div className="flex items-center justify-between">
            <Label htmlFor="include-title" className="cursor-pointer text-sm">
              Include Title
            </Label>
            <Switch
              id="include-title"
              checked={includeTitle}
              onCheckedChange={setIncludeTitle}
            />
          </div>

          <div className="flex items-center justify-between">
            <Label
              htmlFor="include-comments"
              className="cursor-pointer text-sm"
            >
              Include Comments
            </Label>
            <Switch
              id="include-comments"
              checked={includeComments}
              onCheckedChange={setIncludeComments}
            />
          </div>

          <div className="flex items-center justify-between">
            <Label
              htmlFor="include-hypothesis"
              className="cursor-pointer text-sm"
            >
              Include Hypothesis
            </Label>
            <Switch
              id="include-hypothesis"
              checked={includeHypothesis}
              onCheckedChange={setIncludeHypothesis}
            />
          </div>

          <div className="flex items-center justify-between">
            <Label
              htmlFor="include-creation-date"
              className="cursor-pointer text-sm"
            >
              Include Creation Date
            </Label>
            <Switch
              id="include-creation-date"
              checked={includeCreationDate}
              onCheckedChange={setIncludeCreationDate}
            />
          </div>

          <div className="flex items-center justify-between">
            <Label htmlFor="include-metrics" className="cursor-pointer text-sm">
              Include Metrics
            </Label>
            <Switch
              id="include-metrics"
              checked={includeMetrics}
              onCheckedChange={setIncludeMetrics}
            />
          </div>

          <div className="flex items-center justify-between">
            <Label
              htmlFor="include-parameters"
              className="cursor-pointer text-sm"
            >
              Include Parameters
            </Label>
            <Switch
              id="include-parameters"
              checked={includeParameters}
              onCheckedChange={setIncludeParameters}
            />
          </div>
        </div>

        <div className="flex justify-end pt-4">
          <Button
            onClick={handleSave}
            className="w-28 bg-primary hover:bg-primary/90"
          >
            Save Changes
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

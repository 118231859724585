import { gql, useMutation, useQuery } from '@apollo/client';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  editableFileIdAtom,
  experimentIdAtom,
  majorVersionAtom,
  projectIdAtom,
} from '../../DocumentationPageStates';
import { DocumentationResultQuery } from '@/__generated__/gql/graphql';
import VersionSelector from '@/components/VersionSelector';
import { useMemo, useState } from 'react';
import { DocumentationResultViewer } from './DocumentationResultViewer';
import { toast } from 'sonner';
import { Loading } from '@/components/ui/loading';
import { useParams } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import { Ellipsis, Sparkles } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

const GET_DOCUMENTATION = gql(`
    query documentationResult($documentationId: String!, $experimentId: String, $position: Int) {
      documentation(id: $documentationId) {
        latestVersion
        project {
            id
            title
            experiments {
              id
            }
          }
        documentationResult(experimentId: $experimentId, position: $position) {
          id
          creationTimestamp
          editableFile {
              id
          }
          generatedDocumentation {
            name
            text
            outputFormat
            sources {
              highlights {
                lines
                name
              }
              sourceFile {
                id
                fileName
                filePath
                url
                fileHash
                content
              }
            }
            artifacts {
              id
              url
              ansiText
              contentType
              creationTimestamp
              name
              tags
              fullPath
              size
              html
            }
          }
        }
      }
    }
`);

const GENERATE_DOCUMENTATION_MUTATION = gql(`
    mutation GenerateDocumentation($projectId: String!, $documentationId: String!, $experimentId: String) {
      generateDocumentationResult(projectId: $projectId, documentationId: $documentationId, experimentId: $experimentId) {
        documentationResult {
          id,
          generatedDocumentation {
            name,
            text,
            outputFormat,
            artifacts{
              id
              url
              contentType
              creationTimestamp
              name
              tags
              fullPath
              size
              html
              ansiText
            }
          }
        }
      }
    }
  `);

const EXPORT_DOCUMENTATION_TO_CONFLUENCE_MUTATION = gql(`
    mutation ExportDocumentationResultToConfluence($resultId: String!) {
      exportDocumentationResultToConfluence(resultId: $resultId) {
        link
      }
    }
  `);

export default function DocumentResultView() {
  const { documentationId } = useParams();

  const [documentationResultId, setDocumentationResultId] = useState<
    string | null
  >();

  const [versions, setVersions] = useState<number[]>([]);
  const [version, setVersion] = useState<number | null>(null);
  const setMajorVersion = useSetRecoilState(majorVersionAtom);

  const setEditableFileId = useSetRecoilState(editableFileIdAtom);
  const { data, refetch, loading } = useQuery<DocumentationResultQuery>(
    GET_DOCUMENTATION,
    {
      onCompleted: data => {
        setVersion(data.documentation?.latestVersion ?? 0);
        setVersions(
          Array.from(
            { length: data.documentation?.latestVersion ?? 1 },
            (_, i) => i + 1
          )
        );
        setMajorVersion(data.documentation?.latestVersion ?? 0);
        setDocumentationResultId(
          data.documentation?.documentationResult?.id ?? null
        );
        setEditableFileId(
          data.documentation?.documentationResult?.editableFile?.id ?? ''
        );
      },
      variables: {
        documentationId: documentationId ?? '',
      },
      skip: !documentationId,
    }
  );

  const [generateDocumentationMutation, { loading: isGenerationLoading }] =
    useMutation(GENERATE_DOCUMENTATION_MUTATION);

  const handleVersionChange = (version: number) => {
    setVersion(version);
    refetch({
      documentationId,
      position: version,
    });
    setMajorVersion(version);
  };

  const projectId = useRecoilValue(projectIdAtom);
  const experimentId = useRecoilValue(experimentIdAtom);

  const handleGenerateDocumentation = async () =>
    await generateDocumentationMutation({
      onCompleted: data => {
        toast.success('Documentation generated');
        const newLastVersion = (data?.documentation?.lastVersion ?? 0) + 1;
        refetch({
          documentationId,
        });
        setVersions(prevVersions => [...prevVersions, newLastVersion]);
        setVersion(newLastVersion);
        setMajorVersion(newLastVersion);
        setDocumentationResultId(data?.documentation?.documentationResult?.id);
      },
      onError: error => {
        toast.error(`Failed to generate documentation: ${error.message}`);
        console.error(error);
      },
      variables: {
        documentationId: documentationId!,
        projectId,
        experimentId,
      },
    });

  const [exportDocumentationToConfluence, { loading: exportingToConfluence }] =
    useMutation(EXPORT_DOCUMENTATION_TO_CONFLUENCE_MUTATION, {
      onCompleted: () => {
        toast.success('Exported to Confluence successfully!');
      },
      onError: error => {
        toast.error(`Failed to export: ${error.message}`);
      },
    });

  const memoizedResult = useMemo(() => {
    return (
      <DocumentationResultViewer
        creationTimestamp={
          data?.documentation?.documentationResult?.creationTimestamp
        }
        documentationResult={
          data?.documentation?.documentationResult?.generatedDocumentation ??
          null
        }
      />
    );
  }, [data?.documentation?.documentationResult?.creationTimestamp]);

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center gap-4">
          <h5>Generation Result</h5>
          <div className="flex items-center gap-2">
            <p className="text-grey-300 text-sm">Version:</p>
            <VersionSelector
              elements={versions ?? []}
              selectedElement={version ?? 1}
              setSelectedElement={handleVersionChange}
            />
          </div>
        </div>
        <div className="flex items-center gap-0">
          <Button
            onClick={() => handleGenerateDocumentation()}
            className="rounded-r-none"
            disabled={isGenerationLoading}
            size="sm"
          >
            {isGenerationLoading && (
              <Spinner size="small" className="text-black" />
            )}
            <Sparkles className="w-4 h-4" />
            Regenerate
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button
                variant="default"
                className="rounded-l-none shadow-md"
                size="sm"
              >
                <Ellipsis className="w-5 h-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="border-dark-blue-200">
              <DropdownMenuItem
                className="focus:bg-dark-blue-200 cursor-pointer"
                onClick={() => {
                  if (!exportingToConfluence) {
                    exportDocumentationToConfluence({
                      variables: {
                        resultId: documentationResultId ?? '',
                      },
                    });
                  }
                }}
              >
                {exportingToConfluence && (
                  <Spinner size="small" className="text-white" />
                )}
                Export to Confluence
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      {loading ? <Loading message="Loading" /> : memoizedResult}
    </div>
  );
}

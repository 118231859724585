import { Modal } from '@mui/material';
import { useState } from 'react';
import { useProject } from '../contexts/project';
import { Link, useNavigate } from 'react-router-dom';
import { DocumentationSummaryFragment } from '../__generated__/gql/graphql';
import { LabelAndValue } from '../components/LabelAndValue';
import { useResetDocumentState } from './documentation_view/DocumentationPageStates';
import { gql, useMutation } from '@apollo/client';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import { ChevronRight, Trash2 } from 'lucide-react';
import { Skeleton } from '@/components/ui/skeleton';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';

export enum GridCategory {
  ORG = 'ORG',
  PROJECT = 'PROJECT',
  EXPERIMENT = 'EXPERIMENT',
}

const GridTitle = {
  ORG: 'Organizational Documents',
  PROJECT: 'Project Specific Documents',
  EXPERIMENT: 'Experiment Specific Documents',
};

interface CollapsableGridViewProps {
  documents: DocumentationSummaryFragment[];
  gridCategory: GridCategory;
  loading?: boolean;
  onDelete?: (documentId: string) => void;
}

const DELETE_DOCUMENTATION = gql(`
  mutation deleteDocumentation($documentationId: String!) {
    deleteDocumentation(documentationId: $documentationId) {
      documentationId
    }
  }
`);

export default function CollapsableGridView({
  documents,
  gridCategory,
  loading = true,
  onDelete,
}: CollapsableGridViewProps) {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Separator className="mt-8 bg-grey-500" />
      <div className="flex justify-between items-center">
        <div className="flex items-end gap-2">
          <h5
            className="mt-1 hover:cursor-pointer select-none"
            onClick={() => setExpanded(!expanded)}
          >
            {documents.length} {GridTitle[gridCategory]}
          </h5>
          {gridCategory !== GridCategory.ORG ? <ProjectDetails /> : <> </>}
        </div>
        <div
          className={cn(
            'transition-transform duration-100 ease-in-out cursor-pointer',
            expanded ? 'rotate-90 transition-all' : 'rotate-0 transition-all'
          )}
          onClick={() => setExpanded(!expanded)}
        >
          <ChevronRight size={20} />
        </div>
      </div>
      {loading ? (
        <div className="flex gap-2 mt-4 max-h-[220px] overflow-y-auto transition-all [&::-webkit-scrollbar]:hidden duration-300">
          {[...Array(4)].map((_, index) => (
            <div key={index} className="min-w-[calc(25%-12px)] pb-2">
              <Skeleton className="w-[100%] h-[200px] rounded-md" />
            </div>
          ))}
        </div>
      ) : (
        <div
          className={cn(
            'flex gap-2 mt-4 max-h-[220px] overflow-y-auto transition-all [&::-webkit-scrollbar]:hidden duration-300',
            expanded
              ? 'flex-wrap max-h-[1000px]'
              : 'overflow-x-auto [-ms-overflow-style:none] [scrollbar-width:none]'
          )}
        >
          {documents.map(document => (
            <div key={document.id} className="min-w-[calc(25%-12px)] pb-2">
              <DocumentCard document={document} onDelete={onDelete} />
            </div>
          ))}
        </div>
      )}
    </>
  );
}

function DocumentCard({
  document,
  onDelete,
}: {
  document: DocumentationSummaryFragment;
  onDelete?: (documentId: string) => void;
}) {
  const navigate = useNavigate();
  const resetAll = useResetDocumentState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [deleteDocumentation] = useMutation(DELETE_DOCUMENTATION, {
    update(cache, { data }) {
      const deletedId = data?.deleteDocumentation.documentationId;
      cache.modify({
        fields: {
          allDocumentations(existingDocs = [], { readField }) {
            return existingDocs.filter(
              (docRef: any) => readField('id', docRef) !== deletedId
            );
          },
        },
      });
    },
    onCompleted: () => {
      onDelete?.(document.id);
      setDeleteModalOpen(false);
    },
  });

  return (
    <>
      <Card
        className="p-4 w-full h-[200px] group transition-all  relative hover:cursor-pointer hover:bg-dark-blue-300"
        onClick={() => {
          resetAll();
          navigate(`/documentation/${document.id}`);
        }}
      >
        <LabelAndValue label="Document Title" value={document.name} />
        <Button
          className="absolute top-2 right-2 hover:bg-dark-blue-400"
          size="icon"
          variant="ghost"
          onClick={e => {
            e.stopPropagation();
            setDeleteModalOpen(true);
          }}
        >
          <Trash2
            className="text-white opacity-0 group-hover:opacity-100 transition-all duration-100"
            size={22}
          />
        </Button>
      </Card>

      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="delete-modal-title"
      >
        <div className="bg-dark-blue-400 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-background-paper box-shadow-24 p-4 rounded-md w-[500px]">
          <h5 className="mb-2">Confirm Deletion</h5>
          <p className="mb-2 font-[300]">
            Are you sure you want to delete this documentation? This will also
            delete all associated documentation results.
          </p>
          <div className="flex justify-end gap-2">
            <Button
              onClick={() => setDeleteModalOpen(false)}
              className="bg-white"
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={() => {
                deleteDocumentation({
                  variables: { documentationId: document.id },
                });
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

function ProjectDetails() {
  const { project } = useProject();
  if (!project) {
    return (
      <Link to={'/projects'} className="hover:cursor-pointer">
        <p className="text-yellow-200">Please Select a Project</p>
      </Link>
    );
  }
  return (
    <Link to={'/projects'} className="hover:cursor-pointer">
      <p className="text-yellow-200">Project: {project?.title}</p>
    </Link>
  );
}

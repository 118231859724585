import { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';

interface ProjectImportModalProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const IMPORT_PROJECTS_FROM_CSV = gql`
  mutation ImportProjectsFromCSV($csvContent: String!) {
    importProjectsFromCsv(csvContent: $csvContent) {
      taskId
    }
  }
`;

export function ProjectImportModal({
  isOpen,
  onOpenChange,
}: ProjectImportModalProps) {
  const [dataSource, setDataSource] = useState<string>('');
  const [isLinkInput, setIsLinkInput] = useState(false);
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [link, setLink] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [importProjects] = useMutation(IMPORT_PROJECTS_FROM_CSV, {
    onError: error => {
      toast.error('Import failed', {
        description: error.message || 'An unexpected error occurred',
      });
    },
  });

  const handleImport = async () => {
    setIsLoading(true);

    if (dataSource !== 'csv') {
      toast.error('Feature not available', {
        description: 'This import option is not enabled for your organization.',
      });
      setIsLoading(false);
      return;
    }

    if (!csvFile) {
      toast.error('No file selected', {
        description: 'Please select a CSV file to import',
      });
      setIsLoading(false);
      return;
    }

    try {
      const csvContent = await csvFile.text();
      const { data } = await importProjects({
        variables: {
          csvContent,
        },
      });

      if (!data?.importProjectsFromCsv?.taskId) {
        toast.error('Import failed', {
          description: 'No task ID was returned',
        });
        return;
      }

      toast.success('Import scheduled', {
        description: `Your CSV import has been scheduled with task ID: ${data.importProjectsFromCsv.taskId}`,
      });
      onOpenChange?.(false);
    } catch (error) {
      toast.error('Import failed', {
        description:
          error instanceof Error
            ? error.message
            : 'An error occurred during import',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (file.type !== 'text/csv') {
        toast.error('Invalid file type', {
          description: 'Please select a CSV file',
        });
        return;
      }
      setCsvFile(file);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent
        className="sm:max-w-[425px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50"
        onInteractOutside={e => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle>Import Data</DialogTitle>
          <DialogDescription>
            Import data from external sources to populate the AI registry.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="dataSource" className="text-right">
              Data Source
            </Label>
            <Select onValueChange={setDataSource}>
              <SelectTrigger className="col-span-3">
                <SelectValue placeholder="Select data source" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="csv">CSV File</SelectItem>
                <SelectItem value="notion">Notion</SelectItem>
                <SelectItem value="google-docs">Google Docs</SelectItem>
                <SelectItem value="dropbox">Dropbox</SelectItem>
              </SelectContent>
            </Select>
          </div>
          {dataSource === 'csv' ? (
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="file" className="text-right">
                CSV File
              </Label>
              <Input
                id="file"
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                className="col-span-3"
              />
            </div>
          ) : (
            <>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="inputType" className="text-right">
                  Use Link
                </Label>
                <Switch
                  id="inputType"
                  checked={isLinkInput}
                  onCheckedChange={setIsLinkInput}
                  className="col-span-3"
                />
              </div>
              {isLinkInput && (
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="link" className="text-right">
                    Link
                  </Label>
                  <Input
                    id="link"
                    value={link}
                    onChange={e => setLink(e.target.value)}
                    className="col-span-3"
                  />
                </div>
              )}
            </>
          )}
        </div>
        <DialogFooter>
          <Button
            onClick={handleImport}
            disabled={isLoading || (dataSource === 'csv' && !csvFile)}
          >
            {isLoading ? 'Importing...' : 'Import'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

import { useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { BoardColumn, BoardContainer } from './BoardColumn';
import { TaskCard, type Task } from './TaskCard';
import {
  DndContext,
  type DragEndEvent,
  type DragOverEvent,
  DragOverlay,
  type DragStartEvent,
  useSensor,
  useSensors,
  KeyboardSensor,
  Announcements,
  UniqueIdentifier,
  TouchSensor,
  MouseSensor,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import type { Column } from './BoardColumn';
import { hasDraggableData } from '@/utils/hasDraggableData';
import { coordinateGetter } from '@/utils/multipleContainersKeyboardPreset';

export type ColumnId = string;

interface KanbanBoardProps {
  columns: Column[];
  tasks: Task[];
  activeColumn: Column | null;
  activeTask: Task | null;
  onColumnChange: (newColumns: Column[]) => void;
  onTaskChange: (
    newTasks: Task[],
    isFinalDrop: boolean,
    taskId?: UniqueIdentifier
  ) => void;
  onActiveColumnChange: (column: Column | null) => void;
  onActiveTaskChange: (task: Task | null) => void;
  onTaskClick?: (task: Task) => void;
}

export function KanbanBoard({
  columns,
  tasks,
  activeColumn,
  activeTask,
  onColumnChange,
  onTaskChange,
  onActiveColumnChange,
  onActiveTaskChange,
  onTaskClick,
}: KanbanBoardProps) {
  const pickedUpTaskColumn = useRef<ColumnId | null>(null);
  const columnsId = useMemo(() => columns.map(col => col.id), [columns]);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, { coordinateGetter })
  );

  function getDraggingTaskData(
    taskId: UniqueIdentifier,
    columnId: ColumnId | null
  ) {
    if (!columnId) {
      return { tasksInColumn: [], taskPosition: -1, column: null };
    }
    const tasksInColumn = tasks.filter(task => task.columnId === columnId);
    const taskPosition = tasksInColumn.findIndex(task => task.id === taskId);
    const column = columns.find(col => col.id === columnId);
    return { tasksInColumn, taskPosition, column };
  }

  const announcements: Announcements = {
    onDragStart({ active }) {
      if (!hasDraggableData(active)) return;
      if (active.data.current?.type === 'Column') {
        const startColumnIdx = columnsId.findIndex(id => id === active.id);
        const startColumn = columns[startColumnIdx];
        return `Picked up Column ${startColumn?.title} at position: ${
          startColumnIdx + 1
        } of ${columnsId.length}`;
      } else if (active.data.current?.type === 'Task') {
        pickedUpTaskColumn.current = active.data.current.task.columnId;
        if (!pickedUpTaskColumn.current) return;
        const { tasksInColumn, taskPosition, column } = getDraggingTaskData(
          active.id,
          pickedUpTaskColumn.current
        );
        if (!column) return;
        return `Picked up Task ${
          active.data.current.task.content
        } at position: ${taskPosition + 1} of ${
          tasksInColumn.length
        } in column ${column.title}`;
      }
    },
    onDragOver({ active, over }) {
      if (!hasDraggableData(active) || !hasDraggableData(over)) return;
      if (
        active.data.current?.type === 'Column' &&
        over.data.current?.type === 'Column'
      ) {
        const overColumnIdx = columnsId.findIndex(id => id === over.id);
        return `Column ${active.data.current.column.title} was moved over ${
          over.data.current.column.title
        } at position ${overColumnIdx + 1} of ${columnsId.length}`;
      } else if (
        active.data.current?.type === 'Task' &&
        over.data.current?.type === 'Task'
      ) {
        const { tasksInColumn, taskPosition, column } = getDraggingTaskData(
          over.id,
          over.data.current.task.columnId
        );
        if (!column) return;
        if (over.data.current.task.columnId !== pickedUpTaskColumn.current) {
          return `Task ${
            active.data.current.task.content
          } was moved over column ${column.title} in position ${
            taskPosition + 1
          } of ${tasksInColumn.length}`;
        }
        return `Task was moved over position ${taskPosition + 1} of ${
          tasksInColumn.length
        } in column ${column.title}`;
      }
    },
    onDragEnd({ active, over }) {
      if (!hasDraggableData(active) || !hasDraggableData(over)) {
        pickedUpTaskColumn.current = null;
        return;
      }
      if (
        active.data.current?.type === 'Column' &&
        over.data.current?.type === 'Column'
      ) {
        const overColumnPosition = columnsId.findIndex(id => id === over.id);
        return `Column ${
          active.data.current.column.title
        } was dropped into position ${overColumnPosition + 1} of ${
          columnsId.length
        }`;
      } else if (
        active.data.current?.type === 'Task' &&
        over.data.current?.type === 'Task'
      ) {
        const { tasksInColumn, taskPosition, column } = getDraggingTaskData(
          over.id,
          over.data.current.task.columnId
        );
        if (over.data.current.task.columnId !== pickedUpTaskColumn.current) {
          return `Task was dropped into column ${column?.title} in position ${
            taskPosition + 1
          } of ${tasksInColumn.length}`;
        }
        return `Task was dropped into position ${taskPosition + 1} of ${
          tasksInColumn.length
        } in column ${column?.title}`;
      }
      pickedUpTaskColumn.current = null;
    },
    onDragCancel({ active }) {
      pickedUpTaskColumn.current = null;
      if (!hasDraggableData(active)) return;
      return `Dragging ${active.data.current?.type} cancelled.`;
    },
  };

  function onDragStart(event: DragStartEvent) {
    if (!hasDraggableData(event.active)) return;
    const data = event.active.data.current;
    if (data?.type === 'Column') {
      onActiveColumnChange(data.column);
    } else if (data?.type === 'Task') {
      onActiveTaskChange(data.task);
    }
  }

  function onDragEnd(event: DragEndEvent) {
    onActiveColumnChange(null);
    onActiveTaskChange(null);
    pickedUpTaskColumn.current = null;
    const { active } = event;
    if (!hasDraggableData(active)) return;
    const activeData = active.data.current;

    if (activeData?.type === 'Column') {
      const { over } = event;
      if (!over || !hasDraggableData(over)) return;
      const activeId = active.id;
      const overId = over.id;
      if (activeId === overId) return;
      const overData = over.data.current;
      const targetColumnId =
        overData?.type === 'Task'
          ? overData.task.columnId
          : overData?.column?.id;
      if (targetColumnId) {
        const activeColumnIndex = columns.findIndex(col => col.id === activeId);
        const overColumnIndex = columns.findIndex(
          col => col.id === targetColumnId
        );
        if (activeColumnIndex !== -1 && overColumnIndex !== -1) {
          const newColumns = [...columns];
          onColumnChange(
            arrayMove(newColumns, activeColumnIndex, overColumnIndex)
          );
        }
      }
    } else if (activeData?.type === 'Task') {
      onTaskChange(tasks, true, active.id);
    }
  }

  function onDragOver(event: DragOverEvent) {
    const { active, over } = event;
    if (!over) return;
    const activeId = active.id;
    const overId = over.id;
    if (!hasDraggableData(active) || !hasDraggableData(over)) return;
    const activeData = active.data.current;
    const overData = over.data.current;
    const isActiveATask = activeData?.type === 'Task';
    const isOverATask = overData?.type === 'Task';
    const isOverAColumn = overData?.type === 'Column';
    if (!isActiveATask) return;

    const newTasks = [...tasks];
    const activeIndex = newTasks.findIndex(t => t.id === activeId);
    if (activeIndex === -1) return;

    let destinationColumnId: string;

    if (isOverATask) {
      destinationColumnId = overData.task.columnId;
      const overIndex = newTasks.findIndex(t => t.id === overId);
      if (overIndex !== -1) {
        newTasks[activeIndex].columnId = destinationColumnId;
        onTaskChange(arrayMove(newTasks, activeIndex, overIndex), false);
      }
    }
    if (isOverAColumn) {
      destinationColumnId = overId as string;
      newTasks[activeIndex].columnId = destinationColumnId;
      onTaskChange(newTasks, false, active.id);
    }
  }

  return (
    <DndContext
      accessibility={{ announcements }}
      sensors={sensors}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
    >
      <BoardContainer>
        <SortableContext
          items={columnsId}
          strategy={horizontalListSortingStrategy}
        >
          <div className="flex justify-start gap-4 p-4">
            {columns.map(col => (
              <BoardColumn
                key={col.id}
                column={col}
                tasks={tasks.filter(task => task.columnId === col.id)}
                onTaskClick={onTaskClick}
              />
            ))}
          </div>
        </SortableContext>
      </BoardContainer>

      {typeof document !== 'undefined' &&
        createPortal(
          <DragOverlay>
            {activeColumn && (
              <BoardColumn
                isOverlay
                column={activeColumn}
                tasks={tasks.filter(task => task.columnId === activeColumn.id)}
              />
            )}
            {activeTask && <TaskCard task={activeTask} isOverlay />}
          </DragOverlay>,
          document.body
        )}
    </DndContext>
  );
}

import { useRecoilValue } from 'recoil';
import {
  DocumentationEntry,
  documentComponentsAtom,
} from '../../DocumentationPageStates';
import DocumentResultView from './DocumentResultView';
import { NoComponentsMessage } from '../components/ComponentsTab';
import { DocumentComponentNameMap } from '../components/ComponentNameMapping';
import { Card } from '@/components/ui/card';

export default function GenerationTab() {
  const currentComponents = useRecoilValue(documentComponentsAtom);

  return (
    <div className="w-full">
      <div className="grid grid-cols-12 gap-10">
        <div className="col-span-3">
          <div className="flex justify-between mb-2">
            <h5>Preview</h5>
          </div>
          <div className="max-h-[calc(100vh-230px)] overflow-y-auto">
            {currentComponents.length !== 0 ? (
              currentComponents.map(component => (
                <ComponentCard component={component} key={component.id} />
              ))
            ) : (
              <NoComponentsMessage />
            )}
          </div>
        </div>
        <div className="col-span-9">
          <DocumentResultView />
        </div>
      </div>
    </div>
  );
}

function ComponentCard({ component }: { component: DocumentationEntry }) {
  const displayName = DocumentComponentNameMap[component.type];
  return (
    <Card className="p-2 w-full flex items-center justify-between h-12 transition-all duration-200 mb-2 rounded-md border-2 border-dark-blue-200   bg-dark-blue-300">
      <p className="text-md font-medium">{displayName}</p>
    </Card>
  );
}

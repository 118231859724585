import {
  Language,
  TrainingsInterval,
  User,
} from '../../__generated__/gql/graphql';
import ProjectGridItem from '../../project_view/ProjectGridItem';
import UserTag from '../../components/UserTag';
import EditableTypography from '../../project_view/EditableTypography';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select';
import { FormState } from '../TrainingsDrawer';
import { DateTime } from 'luxon';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { CalendarIcon } from 'lucide-react';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { calculateMaxDueDate } from '@/utils/dateUtils';

interface DetailsSectionProps {
  formState: FormState;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
  isEditable: boolean;
  allUsers: User[];
}

export function DetailsSection({
  formState,
  setFormState,
  isEditable,
  allUsers,
}: DetailsSectionProps) {
  const startDate = formState.startDate
    ? DateTime.fromISO(formState.startDate)
    : null;
  const dueDate = formState.dueDate
    ? DateTime.fromISO(formState.dueDate)
    : null;

  const handleStartDateSelect = (date: Date | undefined) => {
    const newStartDate = date ? DateTime.fromJSDate(date) : null;
    setFormState(prev => ({
      ...prev,
      startDate: newStartDate ? newStartDate.toISO() : null,
    }));

    if (newStartDate && formState.dueDate) {
      const currentDueDate = DateTime.fromISO(formState.dueDate);
      if (currentDueDate < newStartDate) {
        setFormState(prev => ({
          ...prev,
          dueDate: newStartDate.plus({ days: 7 }).toISO(),
        }));
      }

      const maxDueDate = calculateMaxDueDate(newStartDate, formState.interval);
      if (currentDueDate > maxDueDate) {
        setFormState(prev => ({
          ...prev,
          dueDate: maxDueDate.toISO(),
        }));
      }
    }
  };

  const handleDueDateSelect = (date: Date | undefined) => {
    if (!date) {
      setFormState(prev => ({ ...prev, dueDate: null }));
      return;
    }

    const selectedDate = DateTime.fromJSDate(date);
    const currentStartDate = startDate || DateTime.now();

    if (selectedDate < currentStartDate) {
      return;
    }

    const maxDueDate = calculateMaxDueDate(
      currentStartDate,
      formState.interval
    );

    if (selectedDate > maxDueDate) {
      setFormState(prev => ({ ...prev, dueDate: maxDueDate.toISO() }));
    } else {
      setFormState(prev => ({ ...prev, dueDate: selectedDate.toISO() }));
    }
  };

  return (
    <div className="grid grid-cols-12 gap-3">
      {/* Description (full width) */}
      <div className="col-span-12">
        <ProjectGridItem
          title="Description"
          content={
            <EditableTypography
              isEditable={isEditable}
              onChange={e =>
                setFormState(prev => ({ ...prev, description: e as string }))
              }
            >
              {formState.description}
            </EditableTypography>
          }
        />
      </div>

      {/* Responsible Person (6 columns) */}
      <div className="col-span-6">
        <ProjectGridItem
          title="Responsible Person"
          content={
            <Select
              disabled={!isEditable}
              value={formState.responsiblePerson}
              onValueChange={value =>
                setFormState(prev => ({ ...prev, responsiblePerson: value }))
              }
            >
              <SelectTrigger className="w-full" disabled={!isEditable}>
                <SelectValue placeholder="Select a person" />
              </SelectTrigger>
              <SelectContent>
                {allUsers.map(user => (
                  <SelectItem key={user.id} value={user.id}>
                    <UserTag user={user} variant="text" size="medium" />
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          }
        />
      </div>

      {/* Required (3 columns) */}
      <div className="col-span-3">
        <ProjectGridItem
          title="Required"
          content={
            <Select
              disabled={!isEditable}
              value={formState.isRequired ? 'true' : 'false'}
              onValueChange={value =>
                setFormState(prev => ({
                  ...prev,
                  isRequired: value === 'true',
                }))
              }
            >
              <SelectTrigger className="w-full" disabled={!isEditable}>
                <SelectValue placeholder="Select Yes/No" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="true">Yes</SelectItem>
                <SelectItem value="false">No</SelectItem>
              </SelectContent>
            </Select>
          }
        />
      </div>

      {/* Interval (3 columns) */}
      <div className="col-span-3">
        <ProjectGridItem
          title="Interval"
          content={
            <Select
              disabled={!isEditable}
              value={formState.interval}
              onValueChange={value => {
                const newInterval = value as TrainingsInterval;
                setFormState(prev => ({
                  ...prev,
                  interval: newInterval,
                }));

                if (startDate && dueDate) {
                  const maxDueDate = calculateMaxDueDate(
                    startDate,
                    newInterval
                  );
                  if (dueDate > maxDueDate) {
                    setFormState(prev => ({
                      ...prev,
                      interval: newInterval,
                      dueDate: maxDueDate.toISO(),
                    }));
                  }
                }
              }}
            >
              <SelectTrigger className="w-full" disabled={!isEditable}>
                <SelectValue placeholder="Select interval" />
              </SelectTrigger>
              <SelectContent>
                {Object.values(TrainingsInterval).map(intervalValue => (
                  <SelectItem key={intervalValue} value={intervalValue}>
                    {intervalValue.charAt(0).toUpperCase() +
                      intervalValue.slice(1).toLowerCase()}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          }
        />
      </div>

      {/* Language */}
      <div className="col-span-4">
        <ProjectGridItem
          title="Language"
          content={
            <Select
              disabled={!isEditable}
              value={formState.language}
              onValueChange={value =>
                setFormState(prev => ({
                  ...prev,
                  language: value as Language,
                }))
              }
            >
              <SelectTrigger className="w-full" disabled={!isEditable}>
                <SelectValue placeholder="Select language" />
              </SelectTrigger>
              <SelectContent>
                {Object.values(Language).map(languageValue => (
                  <SelectItem key={languageValue} value={languageValue}>
                    {languageValue.charAt(0).toUpperCase() +
                      languageValue.slice(1).toLowerCase()}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          }
        />
      </div>

      {/* Start Date */}
      <div className="col-span-4">
        <ProjectGridItem
          title="Start Date"
          content={
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className={cn(
                    'w-full justify-start text-left font-normal',
                    !startDate && 'text-muted-foreground'
                  )}
                  disabled={!isEditable}
                >
                  {startDate
                    ? startDate.toFormat('yyyy-MM-dd')
                    : 'Pick a start date'}
                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={startDate ? startDate.toJSDate() : undefined}
                  onSelect={handleStartDateSelect}
                  disabled={date =>
                    !isEditable ||
                    date < DateTime.now().startOf('day').toJSDate()
                  }
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          }
        />
      </div>

      {/* Due Date */}
      <div className="col-span-4">
        <ProjectGridItem
          title="Due Date"
          content={
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className={cn(
                    'w-full justify-start text-left font-normal',
                    !dueDate && 'text-muted-foreground'
                  )}
                  disabled={!isEditable}
                >
                  {dueDate ? dueDate.toFormat('yyyy-MM-dd') : 'Pick a due date'}
                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={dueDate ? dueDate.toJSDate() : undefined}
                  onSelect={handleDueDateSelect}
                  disabled={date => {
                    if (!isEditable) return true;

                    const minDate = startDate
                      ? startDate.startOf('day').toJSDate()
                      : DateTime.now().startOf('day').toJSDate();

                    if (date < minDate) return true;

                    const effectiveStartDate = startDate || DateTime.now();
                    const maxDate = calculateMaxDueDate(
                      effectiveStartDate,
                      formState.interval
                    ).toJSDate();

                    return date > maxDate;
                  }}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          }
        />
      </div>
    </div>
  );
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import IMAGES from '../images/Images';
import { LogoutButton } from './LogoutButton';

export default function UnknownErrorFallback() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div
      className={cn(
        'flex flex-col justify-center items-center h-screen w-full gap-6 bg-background'
      )}
    >
      <h1 className="text-3xl font-bold text-white mb-2">
        Something went wrong
      </h1>

      <p className="text-gray-300 text-center max-w-lg mb-2">
        We encountered an unexpected error. You can try going back or signing
        out.
      </p>

      <img src={IMAGES.errorImage} alt="Error Image" className="w-[500px]" />

      <div className="flex gap-4 mt-4">
        <Button
          onClick={handleGoBack}
          className="bg-white/10 text-white hover:bg-white/20 hover:text-white"
        >
          Go Back
        </Button>
        <LogoutButton />
      </div>
    </div>
  );
}

import React from 'react';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { Experiment } from '@/__generated__/gql/graphql';
import NotFound from '@/components/NotFound';

type ArtifactsTabProps = {
  experiment: Experiment;
};

const ArtifactsTab: React.FC<ArtifactsTabProps> = ({ experiment }) => {
  return (
    <>
      {(experiment?.artifacts?.length ?? 0) > 0 ? (
        <List>
          {experiment.artifacts!.map(artifact => (
            <React.Fragment key={artifact?.id}>
              <ListItem>
                <ListItemText
                  primary={artifact?.name}
                  secondary={
                    <>
                      <Typography>{`${artifact?.contentType} - ${artifact?.size} B`}</Typography>
                      {artifact?.tags && (
                        <Typography>
                          Tags: {artifact?.tags?.join(', ')}
                        </Typography>
                      )}
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="Download"
                    onClick={() => window.open(artifact?.url)}
                  >
                    <DownloadIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider
                sx={{
                  borderBottomWidth: 1,
                  bgcolor: '#FFFFFF',
                  margin: '4px 0',
                }}
              />
            </React.Fragment>
          ))}
        </List>
      ) : (
        <NotFound subject="artifacts" />
      )}
    </>
  );
};

export default ArtifactsTab;

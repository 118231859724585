import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  Autocomplete,
  AutocompleteRenderInputParams,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import {
  FormattedTooltip,
  orgProfileHeaderTextStyle,
  orgProfileButtonStyle,
  formatTextWithFormatting,
} from './OrganizationProfileFormatting';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { colorThemes } from '@/theme';

export default function InternalContextStage() {
  const { register, control } = useFormContext();

  const {
    fields: fieldsInternalParties,
    append: appendInternalParty,
    remove: removeInternalParty,
  } = useFieldArray({
    control,
    name: 'internalParties',
  });
  const addFieldParty = () => {
    appendInternalParty({ name: '', requirements: '' });
  };

  const {
    fields: fieldsNorms,
    append: appendNorm,
    remove: removeNorm,
  } = useFieldArray({
    control,
    name: 'valueNorms',
  });
  const addFieldNorm = () => {
    appendNorm({ organizationValue: '', description: '' });
  };
  const {
    fields: fieldsInternalPolicies,
    append: appendInternalPolicy,
    remove: removeInternalPolicy,
  } = useFieldArray({
    control,
    name: 'internalPolicies',
  });
  const addFieldInternalPolicy = () => {
    appendInternalPolicy({ regulation: '' });
  };

  const partyOptions = [
    'All Employees',
    'Developers of AI systems',
    'Users of AI systems',
    'Management',
  ];

  const riskProfileLabels = {
    CONSERVATIVE: '*Conservative*: Low risk appetite',
    MODERATE:
      '*Moderate*: Medium risk appetite with mainly low risk and limited risk use cases, but some experimental high risk ',
    AGGRESSIVE: '*Aggressive*: High risk appetite and experimental AI cases',
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
        <Typography variant="h4" {...orgProfileHeaderTextStyle}>
          Values & Norms
        </Typography>
        <FormattedTooltip
          title={valuesAndNormsTooltipText}
          sx={{ marginBottom: '10px' }}
        >
          <InfoOutlinedIcon fontSize="large" />
        </FormattedTooltip>
      </div>
      <Grid container width="100%">
        {fieldsNorms.map((field, index) => (
          <Grid
            container
            spacing={2}
            key={field.id}
            marginTop={'5px'}
            width="100%"
          >
            <Grid item xs={4} md={4}>
              <TextField
                id="outlined-basic"
                label="Value/Norm"
                variant="outlined"
                aria-placeholder={'Value/Norm'}
                {...register(`valueNorms.${index}.organizationValue`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={7} md={7.75}>
              <TextField
                id="outlined-basic"
                label="Description"
                variant="outlined"
                aria-placeholder={
                  'Describe this value/norm in the context of your organization'
                }
                {...register(`valueNorms.${index}.description`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={1} md={0.25}>
              <IconButton
                onClick={() => removeNorm(index)}
                sx={{ height: '100%' }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Button
        sx={{
          ...orgProfileButtonStyle,
          marginTop: '15px',
        }}
        onClick={addFieldNorm}
      >
        <AddIcon />
        Add Value/Norm
      </Button>
      <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
        <Typography variant="h4" {...orgProfileHeaderTextStyle}>
          Internal Parties
        </Typography>
        <FormattedTooltip
          title={internalPartiesTooltipText}
          sx={{ marginBottom: '10px' }}
        >
          <InfoOutlinedIcon fontSize="large" />
        </FormattedTooltip>
      </div>
      <Typography variant="body1">
        Select a pre-defined party or type your own
      </Typography>
      <Grid container width="100%">
        {fieldsInternalParties.map((field, index) => (
          <Grid
            container
            spacing={2}
            key={field.id}
            marginTop={'2px'}
            width="100%"
          >
            <Grid item xs={4} md={4}>
              <Controller
                name={`internalParties.${index}.name`}
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={partyOptions}
                    multiple
                    freeSolo
                    value={field.value ? [field.value] : []}
                    onChange={(_, newValue) =>
                      field.onChange(
                        newValue.length > 1
                          ? newValue[1]
                          : newValue.length === 1
                          ? newValue[0]
                          : ''
                      )
                    }
                    renderTags={(values, getTagProps) =>
                      values.map((value, index) => {
                        const tagProps = getTagProps({ index });
                        return (
                          <Chip
                            variant="filled"
                            label={value}
                            {...tagProps}
                            style={{
                              backgroundColor: colorThemes.DARK_BLUE_600,
                              color: 'white',
                            }}
                          />
                        );
                      })
                    }
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField {...params} label="Party" variant="outlined" />
                    )}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={7} md={7.75}>
              <TextField
                id="outlined-basic"
                label="Requirements"
                variant="outlined"
                {...register(`internalParties.${index}.requirements`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={1} md={0.25}>
              <IconButton
                onClick={() => removeInternalParty(index)}
                sx={{ height: '100%' }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Button
        sx={{
          ...orgProfileButtonStyle,
          marginTop: '15px',
        }}
        onClick={addFieldParty}
      >
        <AddIcon />
        Add Party
      </Button>
      <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
        <Typography
          variant="h4"
          {...orgProfileHeaderTextStyle}
          sx={{ marginBottom: '20px' }}
        >
          Risk Profile
        </Typography>
        <FormattedTooltip
          title={riskProfileTooltipText}
          sx={{ marginBottom: '20px' }}
        >
          <InfoOutlinedIcon fontSize="large" />
        </FormattedTooltip>
      </div>
      <Controller
        name="riskProfile"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel>
              What level of risk are you comfortable with?
            </InputLabel>
            <Select
              {...field}
              label="What level of risk are you comfortable with?"
              sx={{ width: '100%', marginBottom: '1rem' }}
            >
              {Object.entries(riskProfileLabels).map(([key, label]) => (
                <MenuItem key={key} value={key}>
                  {formatTextWithFormatting(label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        rules={{ required: false }}
      />
      <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
        <Typography variant="h4" {...orgProfileHeaderTextStyle}>
          Internal Policies
        </Typography>
        <FormattedTooltip
          title={internalPoliciesTooltipText}
          sx={{ marginBottom: '10px' }}
        >
          <InfoOutlinedIcon fontSize="large" />
        </FormattedTooltip>
      </div>
      <Grid container width="100%">
        {fieldsInternalPolicies.map((field, index) => (
          <Grid
            container
            spacing={2}
            key={field.id}
            marginTop={'1px'}
            width="100%"
          >
            <Grid item xs={11} md={11.75}>
              <TextField
                id="outlined-basic"
                label="Regulation"
                variant="outlined"
                {...register(`internalPolicies.${index}.regulation`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={1} md={0.25}>
              <IconButton
                onClick={() => removeInternalPolicy(index)}
                sx={{ height: '100%' }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Button
        sx={{
          ...orgProfileButtonStyle,
          marginTop: '15px',
          marginBottom: '10px',
        }}
        onClick={addFieldInternalPolicy}
      >
        <AddIcon />
        Add Regulation
      </Button>
      <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
        <Typography
          variant="h4"
          {...orgProfileHeaderTextStyle}
          sx={{ marginTop: '20px', marginBottom: '20px' }}
        >
          Other Internal Context
        </Typography>
        <FormattedTooltip
          title={internalContextTooltipText}
          sx={{
            marginBottom: '20px',
          }}
        >
          <InfoOutlinedIcon fontSize="large" />
        </FormattedTooltip>
      </div>
      <TextField
        id="outlined-basic"
        label="Other Internal Context"
        variant="outlined"
        multiline
        rows={6}
        {...register('internalContext')}
        sx={{ width: '100%' }}
      />
    </>
  );
}

const valuesAndNormsTooltipText =
  "This section covers your organization's key values and norms. They can usually be found on the website or intranet accessible to all employees " +
  'to guide how business is conducted. Include them to make sure that AI development and usage is aligned with the overall values of the organization. ' +
  'Give a description for each of them in your context.';

const internalPartiesTooltipText =
  'Add all relevant internal parties that interact with your AI systems, and describe how they should adhere to your AI policy and obligations.' +
  '\n_For example: external consultants need to adhere to your AI policy or conduct an AI training._';

const riskProfileTooltipText =
  'Describe what risk appetite you have in your organization.' +
  '\n- *Conservative* (Only low risk)' +
  '\n- *Moderate* (Open to take risks after careful consideration)' +
  '\n- *Aggressive* (Very high risk appetite with close to zero unacceptable risks)';

const internalPoliciesTooltipText =
  'Name and describe important applicable internal policies that apply to your AI products and use cases.' +
  '\n_For example, certain security or data protection policies that are relevant to your use or development of AI systems._';

const internalContextTooltipText =
  'Other internal context could be anything that has an internal influence on the development and use of AI systems for your organization. For example, contractual obligations.';

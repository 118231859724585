import { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  DocumentationEntry,
  documentComponentsAtom,
  documentIdAtom,
  documentTemplateAtom,
  documentTitleAtom,
  editableFileIdAtom,
  experimentIdAtom,
  initializeTemplateFromDocument,
  projectIdAtom,
} from './DocumentationPageStates';
import { v4 as uuidv4 } from 'uuid';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Link, Navigate, useParams, useSearchParams } from 'react-router-dom';
import {
  ComponentCategory,
  DataSource,
  DocumentationQuery,
} from '../../__generated__/gql/graphql';
import GenerationTab from './tabs/generation/GenerationTab';
import { toast } from 'sonner';
import { Loading } from '@/components/ui/loading';
import EditorTab from './tabs/editor/EditorTab';
import ComponentsTab from './tabs/components/ComponentsTab';
import { ChevronLeft } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import FriendlyId from '@/components/ui/friendly-id';

export const GET_DOCUMENTATION = gql(`
  query Documentation($documentationId:String!) {
    documentation(id:$documentationId) {
      __typename
      documentationType
      documentationScope
      latestVersion
      components {
        type
        name
        dataSource
        query
        args
        category
        customArguments
      }
      name
      id
      documentationResult {
        id
        editableFile {
          id
        }
      }
      project {
        id
        title
        experiments {
          id
        }
      }
    }
  }`);

export const UPDATE_DOCUMENTATION_MUTATION = gql(`
    mutation UpdateDocumentation($components: [DocumentComponentInput], $id: String!, $name: String!) {
        updateDocumentation(components: $components, documentationId: $id, name: $name) {
        __typename
        documentation {
          __typename
          id
        }
      }
    }
  `);

export default function DocumentationPage() {
  const { documentationId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [documentComponents, setDocumentComponents] = useRecoilState(
    documentComponentsAtom
  );
  const setDocumentId = useSetRecoilState(documentIdAtom);
  const setEditableFileId = useSetRecoilState(editableFileIdAtom);
  const setProjectId = useSetRecoilState(projectIdAtom);
  const setDocumentTemplate = useSetRecoilState(documentTemplateAtom);

  const [documentTitle, setDocumentTitle] = useRecoilState(documentTitleAtom);

  if (!searchParams.get('view')) {
    setSearchParams({ view: 'components' });
  }
  const [tabValue, setTabValue] = useState(
    searchParams.get('view') || 'components'
  );

  const [experimentIds, setExperimentIds] = useState<string[]>([]);

  const [selectedExperiment, setSelectedExperiment] =
    useRecoilState(experimentIdAtom);

  const { loading } = useQuery<DocumentationQuery>(GET_DOCUMENTATION, {
    variables: {
      documentationId: documentationId ?? '',
    },
    onCompleted: data => {
      setDocumentTitle(data.documentation?.name ?? '');
      setDocumentComponents(
        (data.documentation?.components as DocumentationEntry[]).map(
          component => {
            return {
              ...component,
              id: uuidv4(),
            };
          }
        )
      );
      if (data.documentation) {
        initializeTemplateFromDocument(data.documentation, setDocumentTemplate);
      }
      setExperimentIds(
        data.documentation?.project?.experiments?.map(e => e?.id || '') ?? []
      );
      setSelectedExperiment(
        data.documentation?.project?.experiments?.[0]?.id ?? ''
      );
      setProjectId(data.documentation?.project?.id ?? '');
      setDocumentId(data.documentation?.id ?? '');
      if (data.documentation?.documentationResult?.editableFile?.id) {
        setEditableFileId(
          data.documentation.documentationResult.editableFile.id
        );
      }
    },
    skip: !documentationId,
    notifyOnNetworkStatusChange: false,
  });

  const [updateDocumentation, { loading: isSavingLoading }] = useMutation(
    UPDATE_DOCUMENTATION_MUTATION,
    {
      onCompleted: () => {
        toast.success('Documentation saved successfully');
      },
    }
  );

  const handleSaveDocumentation = async () => {
    try {
      await updateDocumentation({
        variables: {
          id: documentationId,
          name: documentTitle,
          components: documentComponents.map(item => ({
            query: item.query ?? '',
            dataSource: item.dataSource ?? DataSource.Code,
            category:
              (item.category as ComponentCategory) ?? ComponentCategory.General,
            componentType: item.type,
            args: item.args ?? [],
            name: item.name ?? '',
            customArguments: item.customArguments
              ? JSON.stringify(item.customArguments)
              : '',
          })),
        },
      });
    } catch (error) {
      console.error('Error updating documentation:', error);
      toast.error('Failed to save documentation');
    }
  };

  const handleTabChange = (newValue: string) => {
    setTabValue(newValue);
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set('view', newValue);
    setSearchParams(newSearchParams);
  };

  const tabTriggerStyle = 'z-[999] text-white min-w-[150px]';

  if (!documentationId) {
    return <Navigate to="/documentations" />;
  }

  if (loading) {
    return <Loading message="Loading documentation details" />;
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/documentations">
            <ChevronLeft size={24} />
          </Link>
          <DocumentTitle />
        </div>
        <div className="flex items-center">
          <div className="mr-6 flex gap-2">
            {experimentIds.length > 0 && (
              <div className="flex items-center gap-2">
                <h6>Experiment:</h6>
                <Select
                  onValueChange={value => setSelectedExperiment(value)}
                  value={selectedExperiment}
                >
                  <SelectTrigger className="h-9">
                    <SelectValue placeholder="Select Experiment">
                      {selectedExperiment && (
                        <FriendlyId id={selectedExperiment} inline subtle />
                      )}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent className="border border-dark-blue-300">
                    {experimentIds.map(id => (
                      <SelectItem
                        key={id}
                        value={id}
                        className="focus:bg-dark-blue-200 cursor-pointer"
                      >
                        <FriendlyId id={id} inline subtle />
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            )}
          </div>
          <Button
            variant="default"
            disabled={isSavingLoading}
            onClick={handleSaveDocumentation}
          >
            {isSavingLoading && <Spinner size="small" className="text-black" />}
            Save Changes
          </Button>
        </div>
      </div>

      <Tabs value={tabValue} onValueChange={handleTabChange}>
        <TabsList className="mt-4 mb-4 bg-dark-blue-300">
          <TabsTrigger value="components" className={tabTriggerStyle}>
            Components
          </TabsTrigger>
          <TabsTrigger value="generation" className={tabTriggerStyle}>
            Generation
          </TabsTrigger>
          <TabsTrigger className={tabTriggerStyle} value="editor">
            Edit
          </TabsTrigger>
        </TabsList>

        <div>
          <TabsContent value="components">
            <ComponentsTab />
          </TabsContent>
          <TabsContent value="generation">
            <GenerationTab />
          </TabsContent>
          <TabsContent value="editor">
            <EditorTab />
          </TabsContent>
        </div>
      </Tabs>
    </div>
  );
}

function DocumentTitle() {
  const [searchParams] = useSearchParams();
  const [documentTitle, setDocumentTitle] = useRecoilState(documentTitleAtom);

  const editable = searchParams.get('view') === 'components';

  return (
    <div className="relative">
      <input
        type="text"
        value={documentTitle}
        onChange={e => setDocumentTitle(e.target.value)}
        placeholder={'Document Title'}
        disabled={!editable}
        className="border-none outline-none resize-none bg-transparent text-white p-2 font-bold text-2xl"
      />
    </div>
  );
}

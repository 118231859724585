import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { Component, FileText } from 'lucide-react';

interface DefaultComponentConfigurationProps {
  localName: string;
  setLocalName: (name: string) => void;
  localQuery: string;
  setLocalQuery: (query: string) => void;
  onSave?: (name: string, query: string) => void;
}

export default function DefaultComponentConfiguration({
  localName,
  setLocalName,
  localQuery,
  setLocalQuery,
  onSave,
}: DefaultComponentConfigurationProps) {
  const handleSave = () => {
    if (onSave) {
      onSave(localName, localQuery);
    }
  };

  return (
    <Card className="bg-card border-border shadow-md">
      <CardHeader className="pb-4 border-b border-border/40">
        <div className="flex items-center gap-2 mb-2">
          <Component className="h-5 w-5 text-primary" />
          <Label
            htmlFor="component-name"
            className="text-sm font-medium text-muted-foreground"
          >
            Component Name
          </Label>
        </div>
        <CardTitle>
          <Input
            id="component-name"
            value={localName}
            onChange={e => setLocalName(e.target.value)}
            placeholder="Enter Component Name"
            className="text-xl font-semibold bg-background/50 border border-border/40 rounded-md px-3 py-2 focus-visible:ring-1 focus-visible:ring-primary"
          />
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-6 pt-5">
        <div className="flex items-center gap-2 mb-1">
          <FileText className="h-5 w-5 text-primary" />
          <h3 className="text-sm font-medium text-muted-foreground">
            Query Options
          </h3>
        </div>

        <div className="bg-background/40 p-4 rounded-lg border border-border/30">
          <Label htmlFor="component-query" className="text-sm mb-2 block">
            Custom Query
          </Label>
          <Input
            id="component-query"
            value={localQuery}
            onChange={e => setLocalQuery(e.target.value)}
            placeholder="Enter your custom query"
            className="bg-background/50 border border-border/40"
          />
          <p className="text-xs text-muted-foreground mt-2">
            Specify a custom query for the LLM documentation engine to complete
          </p>
        </div>

        <div className="flex justify-end pt-4">
          <Button
            onClick={handleSave}
            className="w-28 bg-primary hover:bg-primary/90"
          >
            Save Changes
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

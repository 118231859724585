import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  AccessRole,
  GetPaginatedUsersQuery,
} from '../__generated__/gql/graphql';
import { toast } from 'sonner';
import { X } from 'lucide-react';
import DeleteConfirmationDialog from '@/components/DeleteConfirmationDialog';

import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  PaginationEllipsis,
} from '@/components/ui/pagination';
import { usePagination } from '@/hooks/usePagination';
import SkeletonTable from '@/components/SkeletonTable';

export const PERMISSION_FIELDS = gql`
  fragment PermissionFields on PermissionType {
    hasPermission
    userId
  }
`;

export const GET_PAGINATED_USERS = gql`
  query GetPaginatedUsers(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $includeAuditors: Boolean
    $search: String
    $role: String
    $registeredOnly: Boolean
  ) {
    paginatedUsers(
      first: $first
      after: $after
      last: $last
      before: $before
      includeAuditors: $includeAuditors
      search: $search
      role: $role
      registeredOnly: $registeredOnly
    ) {
      items {
        id
        email
        name
        accessRole
        isRegistered
        hasAdminPermission: checkPermission(
          action: "mutation"
          targetQueryMutation: "updateAccessRole"
        ) {
          hasPermission
          userId
        }
      }
      hasNextPage
      hasPreviousPage
      totalCount
    }
    invitePermission: checkPermission(
      action: "mutation"
      targetQueryMutation: "inviteNewUser"
    ) {
      hasPermission
    }
  }
`;

const DELETE_USER = gql`
  mutation DeleteUser($email: String!) {
    deleteUser(email: $email) {
      user {
        email
      }
    }
  }
`;

const UPDATE_USER_ROLE = gql`
  mutation updateUserAccessRole(
    $userEmail: String!
    $updatedAccessRole: String!
  ) {
    updateAccessRole(
      userEmail: $userEmail
      updatedAccessRole: $updatedAccessRole
    ) {
      user {
        email
        accessRole
      }
    }
  }
`;

interface UsersTableProps {
  search?: string;
  roleFilter?: string | null;
  registrationFilter?: boolean | null;
  onPermissionCheck?: (hasPermission: boolean) => void;
}

const UsersTable: React.FC<UsersTableProps> = ({
  search = '',
  roleFilter = null,
  registrationFilter = null,
  onPermissionCheck,
}) => {
  const pageSize = 10;
  const pagination = usePagination<GetPaginatedUsersQuery>({
    query: GET_PAGINATED_USERS,
    itemsPerPage: pageSize,
    getVariables: (_page, itemsPerPage, filters, cursor) => {
      return {
        first: itemsPerPage,
        after: cursor,
        last: null,
        before: null,
        includeAuditors: true,
        search: filters?.search || null,
        role: filters?.role || null,
        registeredOnly:
          filters?.registeredOnly !== undefined ? filters.registeredOnly : null,
      };
    },
    getTotalItems: data => data?.paginatedUsers?.totalCount || 0,
    getHasNextPage: data => !!data?.paginatedUsers?.hasNextPage,
    getCursor: data =>
      data?.paginatedUsers?.items?.length
        ? data.paginatedUsers.items[data.paginatedUsers.items.length - 1]?.id
        : null,
    getResponseItems: data => data?.paginatedUsers?.items || [],
  });

  const {
    data,
    loading,
    currentPage,
    totalPages,
    hasNextPage,
    hasPreviousPage,
    handleNextPage,
    handlePreviousPage,
    goToPage,
    applyFilters,
    isPageAccessible,
  } = pagination;

  useEffect(() => {
    applyFilters({
      search: search || null,
      role: roleFilter,
      registeredOnly: registrationFilter,
    });
  }, [search, roleFilter, registrationFilter, applyFilters]);

  const users = data?.paginatedUsers?.items || [];

  const [deleteUser] = useMutation(DELETE_USER, {
    refetchQueries: [
      {
        query: GET_PAGINATED_USERS,
        variables: {
          first: pageSize,
          after: null,
          last: null,
          before: null,
          includeAuditors: true,
          search: search || null,
          role: roleFilter,
          registeredOnly: registrationFilter,
        },
      },
    ],
    onCompleted: () => toast.success('User deleted successfully!'),
    onError: error => toast.error(`Error deleting user: ${error.message}`),
  });

  const [updateUserRole] = useMutation(UPDATE_USER_ROLE, {
    refetchQueries: [
      {
        query: GET_PAGINATED_USERS,
        variables: {
          first: pageSize,
          after: null,
          last: null,
          before: null,
          includeAuditors: true,
          search: search || null,
          role: roleFilter,
          registeredOnly: registrationFilter,
        },
      },
    ],
    onCompleted: () => toast.success('User role updated successfully!'),
    onError: error => toast.error(`Error updating user role: ${error.message}`),
  });

  const [userToDelete, setUserToDelete] = useState<{
    email: string;
    name?: string | null;
  } | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleDeleteClick = (email: string, name?: string | null) => {
    setUserToDelete({ email, name: name || email });
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (userToDelete) {
      deleteUser({ variables: { email: userToDelete.email } });
      setIsDeleteDialogOpen(false);
      setUserToDelete(null);
    }
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setUserToDelete(null);
  };

  const handleRoleChange = (userEmail: string, newRole: string) => {
    updateUserRole({ variables: { userEmail, updatedAccessRole: newRole } });
  };

  const calculatePageNumbers = () => {
    const maxVisiblePages = 5;
    if (totalPages <= maxVisiblePages) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    const halfVisible = Math.floor(maxVisiblePages / 2);
    let startPage = Math.max(1, currentPage - halfVisible);
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage === totalPages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }
    if (startPage === 1) {
      endPage = Math.min(totalPages, maxVisiblePages);
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  const pageNumbers = calculatePageNumbers();

  const hasAdminPermission =
    users.length > 0 && users[0]?.hasAdminPermission?.hasPermission;

  useEffect(() => {
    if (users.length > 0 && onPermissionCheck) {
      onPermissionCheck(hasAdminPermission);
    }
  }, [hasAdminPermission, users.length, onPermissionCheck]);

  useEffect(() => {
    if (data?.invitePermission && onPermissionCheck) {
      onPermissionCheck(data.invitePermission.hasPermission);
    }
  }, [data, onPermissionCheck]);

  return (
    <div>
      <div className="rounded-md border">
        {loading && !data ? (
          <SkeletonTable rowCount={pageSize} />
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Email</TableHead>
                <TableHead>Name</TableHead>
                <TableHead>Access Role</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {users.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} className="text-center py-4">
                    No users found.
                  </TableCell>
                </TableRow>
              ) : (
                users.map(user => (
                  <TableRow key={user.email}>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>
                      <Select
                        value={user.accessRole || ''}
                        onValueChange={value => {
                          const selectedKey = Object.entries(AccessRole).find(
                            ([_, val]) => val === value
                          )?.[0];
                          handleRoleChange(user.email, selectedKey || '');
                        }}
                        disabled={!hasAdminPermission}
                      >
                        <SelectTrigger
                          className="w-[120px]"
                          disabled={!hasAdminPermission}
                        >
                          <SelectValue placeholder="Select a role" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.entries(AccessRole).map(([key, value]) => (
                            <SelectItem key={key} value={value}>
                              {key}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </TableCell>
                    <TableCell>
                      <span
                        className={`text-sm ${
                          user.isRegistered ? 'text-success' : 'text-primary'
                        }`}
                      >
                        {user.isRegistered ? 'Registered' : 'Pending'}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleDeleteClick(user.email, user.name)}
                        disabled={!hasAdminPermission}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        )}
      </div>

      {userToDelete && (
        <DeleteConfirmationDialog
          open={isDeleteDialogOpen}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleConfirmDelete}
          itemToDelete={{
            name: userToDelete.name || userToDelete.email,
            type: 'User',
          }}
          requireTypedConfirmation={false}
        />
      )}

      {(totalPages > 1 || loading) && (
        <div className="flex items-center justify-center mt-4">
          <Pagination>
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious
                  onClick={handlePreviousPage}
                  className={
                    !hasPreviousPage || loading
                      ? 'pointer-events-none opacity-50'
                      : 'cursor-pointer'
                  }
                  aria-disabled={!hasPreviousPage || loading}
                />
              </PaginationItem>

              {currentPage > 2 && !pageNumbers.includes(1) && (
                <>
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => goToPage(1)}
                      className={
                        loading
                          ? 'pointer-events-none opacity-50'
                          : 'cursor-pointer'
                      }
                    >
                      1
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationEllipsis />
                  </PaginationItem>
                </>
              )}

              {pageNumbers.map(pageNum => (
                <PaginationItem key={pageNum}>
                  <PaginationLink
                    isActive={pageNum === currentPage}
                    onClick={() => goToPage(pageNum)}
                    className={
                      (!isPageAccessible(pageNum) && pageNum !== currentPage) ||
                      loading
                        ? 'pointer-events-none opacity-50'
                        : 'cursor-pointer'
                    }
                    aria-disabled={
                      (!isPageAccessible(pageNum) && pageNum !== currentPage) ||
                      loading
                    }
                  >
                    {pageNum}
                  </PaginationLink>
                </PaginationItem>
              ))}

              {currentPage < totalPages - 1 &&
                !pageNumbers.includes(totalPages) && (
                  <>
                    <PaginationItem>
                      <PaginationEllipsis />
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        className="pointer-events-none opacity-50"
                        title="Direct navigation to last page not available with cursor pagination"
                        aria-disabled={true}
                      >
                        {totalPages}
                      </PaginationLink>
                    </PaginationItem>
                  </>
                )}

              <PaginationItem>
                <PaginationNext
                  onClick={handleNextPage}
                  className={
                    !hasNextPage || loading
                      ? 'pointer-events-none opacity-50'
                      : 'cursor-pointer'
                  }
                  aria-disabled={!hasNextPage || loading}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        </div>
      )}
    </div>
  );
};

export default UsersTable;

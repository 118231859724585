import DocumentComponentType from '@/__generated__/document_component_type';

export const DocumentComponentNameMap = {
  [DocumentComponentType.Abstract]: 'Abstract',
  [DocumentComponentType.Architecture]: 'Architecture',
  [DocumentComponentType.ArchitectureDiagram]: 'Architecture Diagram',
  [DocumentComponentType.CustomText]: 'Custom Text',
  [DocumentComponentType.BiasMitigationStrategy]: 'Bias Mitigation Strategy',
  [DocumentComponentType.ClassificationMetrics]: 'Classification Metrics',
  [DocumentComponentType.CrossValidation]: 'Cross Validation',
  [DocumentComponentType.CustomArtifact]: 'Custom Artifact',
  [DocumentComponentType.DataIntegrity]: 'Data Integrity',
  [DocumentComponentType.DataProcessing]: 'Data Processing',
  [DocumentComponentType.DataSource]: 'Data Sources',
  [DocumentComponentType.DataSummary]: 'Data Summary',
  [DocumentComponentType.EasyAlgorithmExplanation]:
    'Easy Algorithm Explanation',
  [DocumentComponentType.HoldoutTestset]: 'Holdout Testset',
  [DocumentComponentType.InputDataType]: 'Input Data Type',
  [DocumentComponentType.IntendedUseCase]: 'Intended Use Case',
  [DocumentComponentType.LegalDisclaimer]: 'Legal Disclaimer',
  [DocumentComponentType.MlFramework]: 'ML Framework',
  [DocumentComponentType.ModelParameters]: 'Model Parameters',
  [DocumentComponentType.OutputDataType]: 'Output Data Type',
  [DocumentComponentType.PerformanceSummary]: 'Performance Summary',
  [DocumentComponentType.PurposeAndGoal]: 'Purpose and Goal of AI Policy',
  [DocumentComponentType.QualitativePerformanceMetrics]:
    'Qualitative Performance Metrics',
  [DocumentComponentType.QuantitativePerformanceMetrics]:
    'Quantitative Performance Metrics',
  [DocumentComponentType.ReferencePapers]: 'Reference Papers',
  [DocumentComponentType.RepresentativeData]: 'Representative Data',
  [DocumentComponentType.RolesResponsibilities]: 'Roles and Responsibilities',
  [DocumentComponentType.ScopeOfPolicy]: 'Scope of Policy',
  [DocumentComponentType.StratifiedSampling]: 'Stratified Sampling',
  [DocumentComponentType.TaskType]: 'Task Type',
  [DocumentComponentType.TrainTestSplit]: 'Train Test Split',
  [DocumentComponentType.UnintendedUseCases]: 'Unintended Use Cases',
  [DocumentComponentType.UnittestModelConversion]: 'Unittest Model Conversion',
  [DocumentComponentType.ComingIntoForce]: 'Coming Into Force',
  [DocumentComponentType.ContactOPolicy]: 'Contact of Policy',
  [DocumentComponentType.DefinitionOfAi]: 'Definition of AI',
  [DocumentComponentType.AlignmentWithOrganisation]:
    'Alignment with Organisation',
  [DocumentComponentType.AiObjectives]: 'AI Objectives',
  [DocumentComponentType.AiPrinciples]: 'AI Principles',
  [DocumentComponentType.NonCompliance]: 'Non-Compliance Clause',
  [DocumentComponentType.AiGovernanceSystem]: 'AI Governance System',
  [DocumentComponentType.ObligationsRequirements]: 'Obligations/ Requirements',
  [DocumentComponentType.OtherPolicies]: 'Other Policies & Regulations',
  [DocumentComponentType.RiskProfile]: 'Risk Profile',
  [DocumentComponentType.AiSystems]: 'AI Systems',
  [DocumentComponentType.ExperimentStats]: 'Experiment Stats',
};

export const CUSTOM_COMPONENT_TYPES = [
  DocumentComponentType.CustomText,
  DocumentComponentType.CustomArtifact,
  DocumentComponentType.ExperimentStats,
] as readonly DocumentComponentType[];

import { Card, CardContent } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { cn } from '../lib/utils';
import { ReactNode } from 'react';
import { GripVertical } from 'lucide-react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { Stage } from '../__generated__/gql/graphql';
import { cva } from 'class-variance-authority';

export interface Task {
  id: UniqueIdentifier;
  columnId: string | Stage;
  content: string;
  project?: {
    id: string;
    title: string;
    description?: string;
    department?: string;
    riskClass?: {
      riskClassCategory: string;
    };
    isPurchased?: boolean;
  };
}

interface TaskCardProps {
  task: Task;
  isOverlay?: boolean;
  onClick?: () => void;
}

const InfoItem = ({
  title,
  content,
}: {
  title: string;
  content: ReactNode;
}) => (
  <div className="mt-4">
    <p className="text-sm text-muted-foreground truncate">{title}:</p>
    {content}
  </div>
);

export function TaskCard({ task, isOverlay, onClick }: TaskCardProps) {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: task.id,
    data: {
      type: 'Task',
      task,
    },
    attributes: {
      roleDescription: 'Task',
    },
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  const variants = cva('', {
    variants: {
      dragging: {
        over: 'ring-2 opacity-30',
        overlay: 'ring-2 ring-primary',
      },
    },
  });

  return (
    <Card
      ref={setNodeRef}
      style={style}
      className={cn(
        ' h-[200px] transition-all duration-200 relative hover:bg-dark-blue-400 cursor-pointer',
        variants({
          dragging: isOverlay ? 'overlay' : isDragging ? 'over' : undefined,
        })
      )}
      onClick={onClick}
    >
      <CardContent className="flex flex-col justify-between h-full p-5">
        <div className="flex items-center justify-between">
          {task.project?.isPurchased !== undefined && (
            <Badge
              variant="default"
              className="bg-dark-blue-200 hover:bg-dark-blue-200"
            >
              {task.project.isPurchased ? 'Procured' : 'Internally Built'}
            </Badge>
          )}
          <Button
            variant={'ghost'}
            {...attributes}
            {...listeners}
            className="p-1 text-secondary-foreground/50 h-auto cursor-grab"
          >
            <span className="sr-only">Move task</span>
            <GripVertical />
          </Button>
        </div>

        <div>
          <h5 className="truncate">{task.content}</h5>
        </div>

        <div className="grid grid-cols-2 gap-8">
          <div>
            <InfoItem
              title="Department"
              content={
                <Badge variant="outline" className="text-xs">
                  {task.project?.department || 'Not Defined'}
                </Badge>
              }
            />
          </div>
          <div>
            <InfoItem
              title="Risk Class"
              content={
                <Badge variant="outline" className="text-xs">
                  {task.project?.riskClass?.riskClassCategory || 'Not Defined'}
                </Badge>
              }
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

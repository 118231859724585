import { Profile } from './Profile';
import { Integrations } from './Integrations';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  GetCurrentUserQuery,
  Notification,
  User,
} from '../__generated__/gql/graphql';
import UserNotifications from './UserNotifications';
import ManageApiKeys from './ManageApiKeys';
import { ScrollArea } from '@/components/ui/scroll-area';

export const GET_USER_DETAILS = gql`
  query GetCurrentUser {
    currentUser {
      name
      email
      id
      apiKeys {
        name
        apiKeyHash
        creationTimestamp
      }
      roles
      organizationName
      responsibilities
      notification {
        id
        name
        description
        actionUrl
        isRead
      }
    }
  }
`;

export const ProfilePage = () => {
  const { data: userData } =
    useSuspenseQuery<GetCurrentUserQuery>(GET_USER_DETAILS);

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 min-h-0 pt-0">
        <h3 className="text-2xl font-bold mb-6">Profile</h3>
        <div className="grid grid-cols-12 gap-2 h-[calc(100%-4rem)]">
          {/* Left Column */}
          <div className="col-span-7 h-full">
            <ScrollArea className="h-full">
              <div className="space-y-6 pr-4 pb-6">
                {userData.currentUser && (
                  <Profile user={userData.currentUser as User} />
                )}
                <Integrations />
                <ManageApiKeys />
              </div>
            </ScrollArea>
          </div>

          {/* Right Column */}
          <div className="col-span-5 h-full overflow-hidden pb-6">
            {userData.currentUser && (
              <UserNotifications
                notifications={
                  userData?.currentUser?.notification?.filter(
                    (notification): notification is Notification =>
                      notification !== null && notification !== undefined
                  ) ?? []
                }
                userId={userData.currentUser.id}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

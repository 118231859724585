import { FC } from 'react';
import { DateTime } from 'luxon';
import { Card, CardContent } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Clock, Calendar } from 'lucide-react';

interface TrainingDetailCardProps {
  id: string;
  onClick?: () => void;
  title: string;
  isSelected: boolean;
  required: boolean;
  done: boolean;
  nextDue: DateTime | null;
  availableFrom?: DateTime | null;
  description: string;
  upcoming?: boolean;
}

const TrainingDetailCard: FC<TrainingDetailCardProps> = ({
  onClick,
  title,
  isSelected,
  required,
  done,
  nextDue,
  availableFrom,
  description,
  upcoming = false,
}) => {
  const formatDate = (dateTime: DateTime | null) => {
    if (!dateTime) return 'No due date';
    return dateTime.toLocaleString({
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const isOverdue = nextDue ? nextDue < DateTime.now() : false;

  return (
    <Card
      onClick={upcoming ? undefined : onClick}
      className={`
        ${done ? 'opacity-70' : ''}
        ${upcoming ? 'opacity-50 bg-dark-blue-100' : ''}
        ${isSelected ? 'border-2 border-yellow-600 shadow-lg' : 'shadow'}
        p-4 h-56 ${upcoming ? '' : 'cursor-pointer hover:bg-dark-blue-300'}
      `}
    >
      <CardContent className="flex flex-col h-full justify-between space-y-4 p-0">
        <div className="space-y-2">
          <div className="flex items-start justify-between">
            <h4 className="leading-tight overflow-hidden text-ellipsis">
              {title}
            </h4>
            {upcoming ? (
              <Badge className="bg-purple-500 hover:bg-purple-600 text-white">
                Upcoming
              </Badge>
            ) : (
              !done && (
                <Badge
                  className={
                    required
                      ? 'bg-action-blue hover:bg-action-blue text-grey-100'
                      : 'bg-dark-blue-200 hover:bg-dark-blue-200'
                  }
                >
                  {required ? 'Required' : 'Optional'}
                </Badge>
              )
            )}
          </div>
          {description && (
            <p className="text-sm text-muted-foreground overflow-hidden text-ellipsis">
              {description}
            </p>
          )}
        </div>

        <div className="border-t border-gray-500 pt-2 flex items-center justify-between">
          {upcoming ? (
            <Badge variant="outline">Not Available Yet</Badge>
          ) : (
            <Badge
              className={done ? 'bg-green-500 text-white' : ''}
              variant={done ? 'default' : 'outline'}
            >
              {done ? 'Completed' : 'Not Started'}
            </Badge>
          )}
          <div className="flex items-center space-x-1">
            {availableFrom ? (
              <>
                <Calendar size={16} />
                <span className="text-xs text-muted-foreground">
                  Available {formatDate(availableFrom)}
                </span>
              </>
            ) : (
              nextDue && (
                <>
                  <Clock
                    size={16}
                    color={isOverdue ? ' oklch(0.55 0.25 25)' : 'currentColor'}
                  />
                  <span
                    className={
                      isOverdue
                        ? 'text-red-500 text-xs'
                        : 'text-xs text-muted-foreground'
                    }
                  >
                    {formatDate(nextDue)}
                  </span>
                </>
              )
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default TrainingDetailCard;

import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  Checkbox,
  Box,
} from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  orgProfileHeaderTextStyle,
  FormattedTooltip,
  orgProfileSubHeaderTextStyle,
  orgProfileButtonStyle,
} from './OrganizationProfileFormatting';

export default function AIManagementStage() {
  const { register, control, setValue, watch } = useFormContext();
  const {
    fields: fieldsProhibitedCategories,
    append: appendProhibitedCategory,
    remove: removeProhibitedCategory,
  } = useFieldArray({
    control,
    name: 'prohibitedCategories',
  });
  const addProhibitedCategory = () => {
    appendProhibitedCategory({
      name: '',
      euAiAct: false,
    });
  };

  const {
    fields: fieldsProhibitedSystems,
    append: appendProhibitedSystem,
    remove: removeProhibitedSystem,
  } = useFieldArray({
    control,
    name: 'prohibitedSystems',
  });
  const addProhibitedSystem = () => {
    appendProhibitedSystem({
      systemName: '',
      provider: '',
      reason: '',
    });
  };

  const {
    fields: fieldsResources,
    append: appendResource,
    remove: removeResource,
  } = useFieldArray({
    control,
    name: 'resources',
  });
  const addFieldResource = () => {
    appendResource('');
  };

  const aiManagementSystem = watch('aiManagementSystem');
  const handleCheckboxChange = (type: string) => {
    if (type === 'yes') {
      setValue('aiManagementSystem.available', true);
      setValue('aiManagementSystem.internalProcess', '');
    } else if (type === 'no') {
      setValue('aiManagementSystem.available', false);
      setValue('aiManagementSystem.internalProcess', '');
    } else if (type === 'custom') {
      setValue('aiManagementSystem.available', true);
      setValue(
        'aiManagementSystem.internalProcess',
        'Describe the internal process'
      );
    }
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Typography variant="h4" {...orgProfileHeaderTextStyle}>
          Scope of AI Management System
        </Typography>
        <FormattedTooltip title={AIMSTooltipText} sx={{ marginBottom: '10px' }}>
          <InfoOutlinedIcon fontSize="large" />
        </FormattedTooltip>
      </div>
      <Typography variant="h6" {...orgProfileSubHeaderTextStyle}>
        Do you have an AI management system in place (to ensure proper
        governance of the AI systems used and developed)?
      </Typography>
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <Checkbox
          onChange={() => handleCheckboxChange('yes')}
          checked={
            aiManagementSystem.available === true &&
            aiManagementSystem.internalProcess === ''
          }
        />
        <Typography sx={{ fontSize: '1rem' }}>
          Yes, the dedicated software trail
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <Checkbox
          onChange={() => handleCheckboxChange('no')}
          checked={
            aiManagementSystem.available === false &&
            aiManagementSystem.internalProcess === ''
          }
        />
        <Typography sx={{ fontSize: '1rem' }}>No</Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <Checkbox
          onChange={() => handleCheckboxChange('custom')}
          checked={
            aiManagementSystem.available === true &&
            aiManagementSystem.internalProcess !== ''
          }
        />
        <Typography sx={{ fontSize: '1rem' }}>Yes, internal process</Typography>
      </Box>
      {aiManagementSystem.available === true &&
        aiManagementSystem.internalProcess !== '' && (
          <Box sx={{ mt: 2 }}>
            <TextField
              {...register('aiManagementSystem.internalProcess')}
              label="Describe the internal process"
              fullWidth
              onChange={e =>
                setValue('aiManagementSystem.internalProcess', e.target.value)
              }
              sx={{ width: '100%', marginTop: '0.5rem' }}
            />
          </Box>
        )}
      <Typography variant="h6" {...orgProfileSubHeaderTextStyle}>
        Where do you collect and keep track of your AI systems?
      </Typography>
      <TextField
        id="outlined-basic"
        label="Where do you collect and keep track of your AI systems?"
        variant="outlined"
        {...register('resources')}
        sx={{ width: '100%', marginTop: '0.5rem', marginBottom: '1rem' }}
      />
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Typography variant="h6" {...orgProfileSubHeaderTextStyle}>
          What is the scope of the AI Management System?
        </Typography>
        <FormattedTooltip
          title={AIMSScopeTooltipText}
          sx={{ marginBottom: '10px' }}
        >
          <InfoOutlinedIcon fontSize="large" />
        </FormattedTooltip>
      </div>
      <TextField
        id="outlined-basic"
        label="What is the scope of the AI Management System?"
        variant="outlined"
        {...register('systemScope')}
        sx={{ width: '100%', marginTop: '0.5rem', marginBottom: '1rem' }}
      />
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <Controller
          name="scopeInPolicy"
          render={({ field: { value, onChange } }) => (
            <Checkbox
              onChange={e => onChange(e.target.checked)}
              checked={value}
            />
          )}
        />
        <Typography sx={{ fontSize: '1rem' }}>
          Scope also applies to and is represented in AI policy
        </Typography>
      </Box>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Typography variant="h4" {...orgProfileHeaderTextStyle}>
          Approval of AI Use Cases
        </Typography>
        <FormattedTooltip
          title={AIMSScopeTooltipText}
          sx={{ marginBottom: '10px' }}
        >
          <InfoOutlinedIcon fontSize="large" />
        </FormattedTooltip>
      </div>
      <TextField
        id="outlined-basic"
        label="Describe your process of approving new AI systems or use cases."
        variant="outlined"
        multiline
        rows={4}
        {...register('approvalOfAiUseCases')}
        sx={{ width: '100%', marginTop: '1rem' }}
      />
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Typography
          variant="h4"
          {...orgProfileHeaderTextStyle}
          marginBottom={'10px'}
        >
          Resources
        </Typography>
        <FormattedTooltip
          title={ResourcesTooltipText}
          sx={{ marginBottom: '10px' }}
        >
          <InfoOutlinedIcon fontSize="large" />
        </FormattedTooltip>
      </div>
      {/* <TextField
        id="outlined-basic"
        label="Describe all resources that are allocated to ensure proper AI management."
        variant="outlined"
        multiline
        rows={6}
        {...register('resources')}
        sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
      /> */}
      {fieldsResources.map((field, index) => (
        <Grid
          container
          spacing={2}
          key={field.id}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <Grid item width={'97%'}>
            <TextField
              id="outlined-basic"
              label={'Resource ' + (index + 1)}
              variant="outlined"
              {...register(`resources.${index}`)}
              sx={{ width: '100%' }}
            />
          </Grid>

          <Grid
            item
            width={'3%'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => removeResource(index)}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button
        sx={{
          ...orgProfileButtonStyle,
          marginTop: '10px',
        }}
        onClick={addFieldResource}
      >
        <AddIcon />
        Add Resource
      </Button>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Typography
          variant="h4"
          {...orgProfileHeaderTextStyle}
          sx={{ marginBottom: '0px' }}
        >
          Prohibited Systems
        </Typography>
        <FormattedTooltip title={ProhibitedSystemsTooltipText}>
          <InfoOutlinedIcon fontSize="large" />
        </FormattedTooltip>
      </div>
      <Typography
        variant="h6"
        {...orgProfileSubHeaderTextStyle}
        sx={{ marginTop: '10px' }}
      >
        Prohibited Categories
      </Typography>
      {fieldsProhibitedCategories.map((field, index) => (
        <Grid
          container
          spacing={2}
          key={field.id}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <Grid item width={'82%'}>
            <TextField
              id="outlined-basic"
              label={'Name of the prohibited category'}
              variant="outlined"
              {...register(`prohibitedCategories.${index}.name`)}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item width={'15%'}>
            <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
              <Controller
                name={`prohibitedCategories.${index}.euAiAct`}
                control={control}
                defaultValue={false}
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    onChange={e => onChange(e.target.checked)}
                    checked={value}
                  />
                )}
              />
              <Typography sx={{ fontSize: '1rem' }}>
                Prohibited AI system or use case by the EU AI Act
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            width={'3%'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => removeProhibitedCategory(index)}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button
        sx={{
          ...orgProfileButtonStyle,
          marginBottom: '10px',
        }}
        onClick={addProhibitedCategory}
      >
        <AddIcon />
        Add Prohibited Category
      </Button>
      <Typography variant="h6" {...orgProfileSubHeaderTextStyle}>
        Blacklist: Prohibited Systems
      </Typography>
      {fieldsProhibitedSystems.map((field, index) => (
        <Grid
          container
          spacing={2}
          key={field.id}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <Grid item width={'30%'}>
            <TextField
              id="outlined-basic"
              label={'Name of the prohibited system'}
              variant="outlined"
              {...register(`prohibitedSystems.${index}.systemName`)}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item width={'67%'}>
            <TextField
              id="outlined-basic"
              label={'Who provides the AI System?'}
              variant="outlined"
              {...register(`prohibitedSystems.${index}.provider`)}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid
            item
            width={'3%'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => removeProhibitedSystem(index)}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button
        sx={{
          ...orgProfileButtonStyle,
          marginBottom: '10px',
        }}
        onClick={addProhibitedSystem}
      >
        <AddIcon />
        Add Prohibited System
      </Button>
    </>
  );
}

const AIMSTooltipText =
  'Your AI Management System (AIMS) should help guide the organization through all AI activities and ensure that all AI systems are governed appropriately.' +
  'By using the AI Governance tool trail, you build up an AIMS. Please define the scope of AI systems that should fall under the AIMS.';

const AIMSScopeTooltipText =
  'The scope is really unique to your organization. Consider whether the AIMS applies to all AI systems or only specific ones. ' +
  'You can also give a description of your AI initiatives here (e.g. internal or customer-facing) and what should be covered by the AI Management System.';

const ResourcesTooltipText =
  ' Describe all resources that are allocated to ensure proper AI management. That can include data, tooling, system and computing resources, as well as ' +
  'human resources.';

const ProhibitedSystemsTooltipText =
  'This refers to generally prohibited AI system categories (e.g. no AI systems in employee surveillance), prohibitions by law (i.e. all AI systems that ' +
  'are prohibited by applicable law, e.g. the EU AI Act) and specific tools or systems that are "blacklisted" in your organization. ';

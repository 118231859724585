import { Box, Button, Link, Typography } from '@mui/material';
import { FC } from 'react';
import { risk, role, specialCase } from './RiskClassificationQuestions';
import { colorThemes } from '../theme';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import {
  RiskClass,
  RiskClassCategory,
  RiskClassInput,
  RiskClassRole,
  RiskClassSpecialCase,
} from '../__generated__/gql/graphql';

interface QuestionaireResultProps {
  projectId?: string;
  setRiskClass?: (riskClass: RiskClass) => void;
  nextStepRiskClass?: () => void;
  language?: 'EN' | 'DE';
}

const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateRiskClassInProject(
    $riskClass: RiskClassInput
    $projectId: String!
  ) {
    updateProject(projectId: $projectId, riskClass: $riskClass) {
      __typename
      project {
        __typename
        riskClass {
          __typename
          riskClassRole
          riskClassCategory
          riskClassSpecialCase
        }
        id
      }
    }
  }
`;

export const getRiskClassCategoryText = (
  category: RiskClassCategory | undefined | null | string
) => {
  switch (category) {
    case 'MINIMAL':
      return 'Minimal';
    case 'LIMITED':
      return 'Limited';
    case 'HIGH_EXEMPTIONS':
      return 'High (Exemptions)';
    case 'HIGH':
      return 'High';
    case 'PROHIBITED':
      return 'Prohibited';
    case 'HIGH_AND_LIMITED':
      return 'High and Limited';
    case null:
      return 'Not Defined';
    default:
      return '';
  }
};
export const getRiskClassRoleText = (
  role: RiskClassRole | undefined | null
) => {
  switch (role) {
    case 'PROVIDER':
      return 'Provider';
    case 'DEPLOYER':
      return 'Deployer';
    case 'PROVIDER_AND_DEPLOYER':
      return 'Provider and Deployer';
    case 'AUTHORIZED_REPRESENTATIVE':
      return 'Authorized Representative';
    case 'IMPORTER':
      return 'Importer';
    case 'DISTRIBUTOR':
      return 'Distributor';
    case 'IMPORTER_AND_DISTRIBUTOR':
      return 'Importer and Distributor';
    case 'OUT_OF_SCOPE':
      return 'Out of Scope';
    default:
      return '';
  }
};

export const getRiskClassSpecialCaseText = (
  specialCase: RiskClassSpecialCase | undefined | null
) => {
  switch (specialCase) {
    case RiskClassSpecialCase.Gpai:
      return 'GPAI System';
    case RiskClassSpecialCase.SystemicRiskGpai:
      return 'GPAI System with Systemic Risk';
    case RiskClassSpecialCase.FreeAndOpenSourceGpai:
      return 'Free & Open-Source GPAI System';
    case RiskClassSpecialCase.NonGpai:
      return 'Non GPAI System';
    case null:
      return 'GPAI Status Undefined';
    default:
      return '';
  }
};

const QuestionaireResult: FC<QuestionaireResultProps> = ({
  projectId,
  setRiskClass,
  nextStepRiskClass,
  language,
}) => {
  const riskClass: RiskClassInput = {
    riskClassCategory: risk,
    riskClassRole: role,
    riskClassSpecialCase: specialCase,
  };

  const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION, {
    variables: {
      projectId,
      riskClass,
    },
  });
  const submitUpdateRiskClass = async () => {
    try {
      await updateProject();
      toast.success('Successfully saved Risk Class');
    } catch {
      toast.error('Saving Risk Class failed');
    }
  };

  const submitCreateRiskClass = () => {
    if (setRiskClass) {
      setRiskClass(riskClass as RiskClass);
    }
    if (nextStepRiskClass) {
      nextStepRiskClass();
    }
  };

  return (
    <Box
      bgcolor={'rgba(0, 123, 255, 0.2)'}
      width={'80%'}
      p={4}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      flexDirection={'column'}
      borderRadius={'20px'}
      gap={2}
      border={'1px solid'}
      borderColor={colorThemes.ACTION_BLUE}
    >
      <Typography fontSize={'24px'}>
        Role: {getRiskClassRoleText(role)}
      </Typography>
      {role !== 'OUT_OF_SCOPE' && risk && (
        <Typography fontSize={'24px'}>
          Risk: {getRiskClassCategoryText(risk)}
        </Typography>
      )}
      {specialCase && (
        <Typography fontSize={'24px'}>
          {getRiskClassSpecialCaseText(specialCase)}
        </Typography>
      )}
      {setRiskClass ? (
        <Button
          sx={{ mt: '40px' }}
          variant={'contained'}
          color={'primary'}
          onClick={submitCreateRiskClass}
        >
          {language === 'DE' ? 'Speichern' : 'Save'}
        </Button>
      ) : (
        <Button
          sx={{ mt: '40px' }}
          variant={'contained'}
          color={'primary'}
          onClick={submitUpdateRiskClass}
          component={Link}
          href={'/projects'}
        >
          {language === 'DE' ? 'Speichern' : 'Save'}
        </Button>
      )}
    </Box>
  );
};

export default QuestionaireResult;

import { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { ConfirmationDialog } from './ConfirmationDialog';
import { toast } from 'sonner';

interface DeleteConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  itemToDelete: {
    name: string;
    type: string;
  };
  /**
   * If true, requires the user to type the name to confirm deletion
   * If false, uses a simple confirmation button
   * @default true
   */
  requireTypedConfirmation?: boolean;
}

export default function DeleteConfirmationDialog({
  open,
  onClose,
  onConfirm,
  itemToDelete,
  requireTypedConfirmation = true,
}: DeleteConfirmationDialogProps) {
  const [confirmationText, setConfirmationText] = useState('');

  const handleConfirm = () => {
    if (!requireTypedConfirmation || confirmationText === itemToDelete.name) {
      onConfirm();
      setConfirmationText('');
    } else if (requireTypedConfirmation) {
      toast.error('The entered name does not match');
    }
  };

  const handleClose = () => {
    setConfirmationText('');
    onClose();
  };

  const dialogActions = (
    <>
      <Button onClick={handleClose} color="primary" variant="outlined">
        Cancel
      </Button>
      <Button
        onClick={handleConfirm}
        color="error"
        variant="contained"
        disabled={
          requireTypedConfirmation && confirmationText !== itemToDelete.name
        }
      >
        Delete
      </Button>
    </>
  );

  return (
    <ConfirmationDialog
      showConfirmationDialog={open}
      onClose={handleClose}
      title={`Delete ${itemToDelete.type}`}
      content={
        <>
          <p>
            Are you sure you want to delete <strong>{itemToDelete.name}</strong>
            ?
          </p>
          <p>This action cannot be undone.</p>

          {requireTypedConfirmation && (
            <>
              <p>To confirm, please type "{itemToDelete.name}":</p>
              <TextField
                fullWidth
                value={confirmationText}
                onChange={e => setConfirmationText(e.target.value)}
                placeholder={`Type ${itemToDelete.type.toLowerCase()} name here`}
                autoFocus
                sx={{ mt: 2 }}
              />
            </>
          )}
        </>
      }
      actions={dialogActions}
    />
  );
}

import { FC, useState } from 'react';
import { toast } from 'sonner';
import { MessageSquare, HelpCircle, Send } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Label } from '@/components/ui/label';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '@/components/ui/dialog';
import { cn } from '@/lib/utils';
import { gql, useMutation } from '@apollo/client';

const SUBMIT_FEEDBACK_MUTATION = gql`
  mutation SubmitFeedback(
    $feedbackType: FeedbackType!
    $feedbackText: String!
  ) {
    submitFeedback(feedbackType: $feedbackType, feedbackText: $feedbackText) {
      success
      message
    }
  }
`;

enum FeedbackType {
  Help = 'HELP',
  Feedback = 'FEEDBACK',
}

interface FeedbackButtonProps {
  position?: 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left';
  className?: string;
}

const FeedbackButton: FC<FeedbackButtonProps> = ({
  position = 'bottom-right',
  className,
}) => {
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackType, setFeedbackType] = useState<FeedbackType>(
    FeedbackType.Feedback
  );
  const [feedbackText, setFeedbackText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [submitFeedback] = useMutation(SUBMIT_FEEDBACK_MUTATION);

  const positionClasses = {
    'bottom-right': 'bottom-6 right-6',
    'bottom-left': 'bottom-6 left-6',
    'top-right': 'top-6 right-6',
    'top-left': 'top-6 left-6',
  };

  const handleFeedbackSubmit = async () => {
    if (!feedbackText.trim()) return;

    setIsSubmitting(true);

    try {
      // Call the mutation to submit feedback
      const { data } = await submitFeedback({
        variables: {
          feedbackType,
          feedbackText: feedbackText.trim(),
        },
      });

      if (data.submitFeedback.success) {
        toast.success(
          `${
            feedbackType === FeedbackType.Feedback ? 'Feedback' : 'Help request'
          } submitted successfully`,
          {
            description:
              data.submitFeedback.message || 'Thank you for your submission!',
          }
        );

        setFeedbackModalOpen(false);
        setFeedbackText('');
      } else {
        toast.error('Failed to submit', {
          description: data.submitFeedback.message || 'Please try again later.',
        });
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error('Failed to submit', {
        description: 'An unexpected error occurred. Please try again later.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setFeedbackText('');
  };

  const characterLimit = 500;
  const characterCount = feedbackText.length;
  const isOverLimit = characterCount > characterLimit;

  return (
    <>
      <Button
        onClick={() => setFeedbackModalOpen(true)}
        className={cn(
          `fixed ${positionClasses[position]} shadow-lg z-50 rounded-full bg-card`,
          className
        )}
        variant="outline"
        aria-label="Open feedback form"
      >
        <div className="flex items-center gap-2">
          {feedbackType === FeedbackType.Feedback ? (
            <MessageSquare className="h-5 w-5" />
          ) : (
            <HelpCircle className="h-5 w-5" />
          )}
          <span className="hidden sm:inline">Give Feedback or Get Help</span>
        </div>
      </Button>

      <Dialog
        open={feedbackModalOpen}
        onOpenChange={open => {
          setFeedbackModalOpen(open);
          if (!open) resetForm();
        }}
      >
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle className="text-xl">
              {feedbackType === FeedbackType.Feedback
                ? 'Share Your Feedback'
                : 'Request Help'}
            </DialogTitle>
            <DialogDescription>
              {feedbackType === FeedbackType.Feedback
                ? 'We appreciate your thoughts on how we can improve this experience.'
                : 'Let us know what you need help with.'}
            </DialogDescription>
          </DialogHeader>

          <div className="space-y-6 py-4">
            <div className="space-y-3">
              <Label htmlFor="feedback-type" className="text-sm font-medium">
                What would you like to do?
              </Label>
              <RadioGroup
                value={feedbackType}
                onValueChange={value => setFeedbackType(value as FeedbackType)}
                className="flex flex-col sm:flex-row gap-3 mt-2"
              >
                <div
                  className={cn(
                    'flex items-center space-x-2 rounded-md border p-3 cursor-pointer transition-colors',
                    feedbackType === FeedbackType.Feedback
                      ? 'border-primary bg-primary/5'
                      : 'hover:bg-muted'
                  )}
                  onClick={() => setFeedbackType(FeedbackType.Feedback)}
                >
                  <RadioGroupItem value={FeedbackType.Feedback} id="feedback" />
                  <Label
                    htmlFor="feedback"
                    className="cursor-pointer flex items-center gap-2"
                  >
                    Give Feedback
                  </Label>
                </div>

                <div
                  className={cn(
                    'flex items-center space-x-2 rounded-md border p-3 cursor-pointer transition-colors',
                    feedbackType === FeedbackType.Help
                      ? 'border-primary bg-primary/5'
                      : 'hover:bg-muted'
                  )}
                  onClick={() => setFeedbackType(FeedbackType.Help)}
                >
                  <RadioGroupItem value={FeedbackType.Help} id="help" />
                  <Label
                    htmlFor="help"
                    className="cursor-pointer flex items-center gap-2"
                  >
                    Get Help
                  </Label>
                </div>
              </RadioGroup>
            </div>

            <div className="space-y-3">
              <div className="flex justify-between">
                <Label htmlFor="message" className="text-sm font-medium">
                  {feedbackType === FeedbackType.Feedback
                    ? 'Your Feedback'
                    : 'What do you need help with?'}
                </Label>
                <span
                  className={cn(
                    'text-xs',
                    isOverLimit ? 'text-destructive' : 'text-muted-foreground'
                  )}
                >
                  {characterCount}/{characterLimit}
                </span>
              </div>
              <Textarea
                id="message"
                placeholder={
                  feedbackType === FeedbackType.Feedback
                    ? 'Share your thoughts, suggestions, or concerns...'
                    : "Describe the issue you're experiencing..."
                }
                className={cn(
                  'min-h-[120px] resize-none',
                  isOverLimit &&
                    'border-destructive focus-visible:ring-destructive'
                )}
                value={feedbackText}
                onChange={e => setFeedbackText(e.target.value)}
                maxLength={characterLimit}
              />
              {isOverLimit && (
                <p className="text-xs text-destructive">
                  Please keep your message under {characterLimit} characters.
                </p>
              )}
            </div>
          </div>

          <DialogFooter className="flex justify-between sm:justify-end gap-2">
            <Button
              variant="outline"
              onClick={() => {
                setFeedbackModalOpen(false);
                resetForm();
              }}
              className="flex items-center gap-1"
            >
              Cancel
            </Button>
            <Button
              onClick={handleFeedbackSubmit}
              disabled={!feedbackText.trim() || isSubmitting || isOverLimit}
              className="flex items-center gap-1"
            >
              {isSubmitting ? (
                <div className="h-4 w-4 border-2 border-current border-t-transparent rounded-full animate-spin mr-1" />
              ) : (
                <Send className="h-4 w-4" />
              )}
              Submit
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FeedbackButton;

import { FC, useState } from 'react';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  GetLiteracyTrainingsforUserQuery,
  LiteracyTraining,
} from '../__generated__/gql/graphql';
import CardGrid from '../components/CardGrid';
import TrainingDetailCard from './TrainingDetailCard';
import TrainingModal from './TrainingModal';
import { DateTime } from 'luxon';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { Settings } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { generateIntervals } from '../utils/generateIntervals';
import { INTERVAL_DURATIONS } from '@/constants/intervals';

export const GET_LITERACY_TRAININGS = gql`
  query GetLiteracyTrainingsforUser(
    $targetQueryMutation: String!
    $action: String!
  ) {
    checkPermission(
      targetQueryMutation: $targetQueryMutation
      action: $action
    ) {
      hasPermission
    }
    allLiteracyTrainings(adminView: false) {
      id
      title
      language
      description
      isRequired
      interval
      trainingMaterial {
        id
        fileName
      }
      trainingMaterialSections {
        title
        material {
          id
          fileName
        }
      }
      dueDate
      startDate
      lastModified
      created
      currentUserCompletion {
        id
        completionDate
      }
      questions {
        questionText
        description
        options {
          optionText
          isCorrect
        }
      }
    }
  }
`;

interface TrainingWithDueDate extends LiteracyTraining {
  nextDueDate: string | null;
  isDone: boolean;
  availableDate?: string | null;
}

const TrainingOverviewPage: FC = () => {
  const { data: allTrainingData } =
    useSuspenseQuery<GetLiteracyTrainingsforUserQuery>(GET_LITERACY_TRAININGS, {
      variables: {
        targetQueryMutation: 'literacyAdminButton',
        action: 'query',
      },
    });

  const [selectedTraining, setSelectedTraining] =
    useState<LiteracyTraining | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [redoDialogOpen, setRedoDialogOpen] = useState(false);
  const [trainingToRedo, setTrainingToRedo] = useState<LiteracyTraining | null>(
    null
  );

  const now = DateTime.now();

  const { pendingTrainings, doneTrainings, upcomingTrainings } =
    allTrainingData?.allLiteracyTrainings.reduce(
      (acc, training) => {
        const startDate = training.startDate || training.created;
        const startDateDt = DateTime.fromISO(startDate);

        if (startDateDt > now) {
          const upcomingTraining: TrainingWithDueDate = {
            ...training,
            nextDueDate: null,
            isDone: false,
            availableDate: startDate,
          } as TrainingWithDueDate;

          acc.upcomingTrainings.push(upcomingTraining);
          return acc;
        }

        const intervals = generateIntervals(startDate, training.interval, true);
        const currentInterval = intervals.find(
          interval => interval.status === 'current'
        );

        if (!currentInterval) {
          return acc;
        }

        const completionDate = training.currentUserCompletion?.completionDate;
        const completionDt = completionDate
          ? DateTime.fromISO(completionDate)
          : null;
        const isDone =
          completionDt &&
          completionDt >= currentInterval.start &&
          completionDt <= currentInterval.end;

        let nextDueDate: string | null = null;

        if (training.dueDate) {
          if (!isDone) {
            nextDueDate = training.dueDate;
          } else if (training.interval !== 'ONCE') {
            nextDueDate = DateTime.fromISO(training.dueDate)
              .plus(INTERVAL_DURATIONS[training.interval])
              .toString();
          }
        } else {
          if (training.interval === 'ONCE' && isDone) {
            nextDueDate = null;
          } else {
            nextDueDate = isDone
              ? intervals.find(i => i.status === 'upcoming')?.end.toString() ??
                null
              : currentInterval.end.toString();
          }
        }

        const trainingWithData = {
          ...training,
          nextDueDate,
          isDone,
        };

        if (isDone) {
          acc.doneTrainings.push(trainingWithData as TrainingWithDueDate);
        } else {
          acc.pendingTrainings.push(trainingWithData as TrainingWithDueDate);
        }

        return acc;
      },
      {
        pendingTrainings: [] as TrainingWithDueDate[],
        doneTrainings: [] as TrainingWithDueDate[],
        upcomingTrainings: [] as TrainingWithDueDate[],
      }
    ) || { pendingTrainings: [], doneTrainings: [], upcomingTrainings: [] };

  const sortByDueDate = (trainings: TrainingWithDueDate[]) =>
    [...trainings].sort((a, b) => {
      if (!a.nextDueDate) return 1;
      if (!b.nextDueDate) return -1;
      return (
        DateTime.fromISO(a.nextDueDate).toMillis() -
        DateTime.fromISO(b.nextDueDate).toMillis()
      );
    });

  const sortByAvailableDate = (trainings: TrainingWithDueDate[]) =>
    [...trainings].sort((a, b) => {
      if (!a.availableDate) return 1;
      if (!b.availableDate) return -1;
      return (
        DateTime.fromISO(a.availableDate).toMillis() -
        DateTime.fromISO(b.availableDate).toMillis()
      );
    });

  const sortedPendingTrainings = sortByDueDate(pendingTrainings);
  const sortedDoneTrainings = sortByDueDate(doneTrainings);
  const sortedUpcomingTrainings = sortByAvailableDate(upcomingTrainings);

  // Event handlers
  const handleTrainingSelect = (training: LiteracyTraining) => {
    setSelectedTraining(training);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedTraining(null);
  };

  const handleTrainingClick = (training: TrainingWithDueDate) => {
    if (training.isDone) {
      setTrainingToRedo(training);
      setRedoDialogOpen(true);
    } else {
      handleTrainingSelect(training);
    }
  };

  const handleRedoConfirm = () => {
    if (trainingToRedo) {
      handleTrainingSelect(trainingToRedo);
      setRedoDialogOpen(false);
      setTrainingToRedo(null);
    }
  };

  const hasAdminPermission = allTrainingData?.checkPermission?.hasPermission;

  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1>Training Overview</h1>
        {hasAdminPermission && (
          <Link to="/literacy-admin">
            <Button variant="outline" className="flex items-center gap-2">
              <Settings className="h-4 w-4" />
              Admin View
            </Button>
          </Link>
        )}
      </div>

      {sortedPendingTrainings.length === 0 &&
      sortedDoneTrainings.length === 0 &&
      sortedUpcomingTrainings.length === 0 ? (
        <h2 className="text-2xl font-semibold">No Trainings available</h2>
      ) : (
        <>
          {sortedPendingTrainings.length > 0 && (
            <CardGrid
              items={sortedPendingTrainings}
              title={`Pending Trainings (${sortedPendingTrainings.length})`}
              renderCard={training => (
                <TrainingDetailCard
                  key={training.id}
                  id={training.id}
                  onClick={() => handleTrainingClick(training)}
                  title={training.title}
                  isSelected={selectedTraining?.id === training.id}
                  required={training?.isRequired ?? false}
                  done={false}
                  nextDue={
                    training.nextDueDate
                      ? DateTime.fromISO(training.nextDueDate)
                      : null
                  }
                  description={training.description ?? ''}
                />
              )}
            />
          )}

          {sortedUpcomingTrainings.length > 0 && (
            <div className="mt-8">
              <CardGrid
                items={sortedUpcomingTrainings}
                title={`Upcoming Trainings (${sortedUpcomingTrainings.length})`}
                renderCard={training => (
                  <TrainingDetailCard
                    key={training.id}
                    id={training.id}
                    onClick={() => {}} // Upcoming trainings shouldn't be clickable
                    title={training.title}
                    isSelected={false}
                    required={training?.isRequired ?? false}
                    done={false}
                    nextDue={null}
                    availableFrom={
                      training.availableDate
                        ? DateTime.fromISO(training.availableDate)
                        : null
                    }
                    description={training.description ?? ''}
                    upcoming={true}
                  />
                )}
              />
            </div>
          )}

          {sortedDoneTrainings.length > 0 && (
            <div className="mt-8">
              <CardGrid
                items={sortedDoneTrainings}
                title={`Completed Trainings (${sortedDoneTrainings.length})`}
                renderCard={training => (
                  <TrainingDetailCard
                    key={training.id}
                    id={training.id}
                    onClick={() => handleTrainingClick(training)}
                    title={training.title}
                    isSelected={selectedTraining?.id === training.id}
                    required={training?.isRequired ?? false}
                    done={true}
                    nextDue={
                      training.nextDueDate
                        ? DateTime.fromISO(training.nextDueDate)
                        : null
                    }
                    description={training.description ?? ''}
                  />
                )}
              />
            </div>
          )}
        </>
      )}

      <TrainingModal
        open={isModalOpen}
        onClose={handleModalClose}
        training={selectedTraining}
      />

      <AlertDialog open={redoDialogOpen} onOpenChange={setRedoDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Redo Training?</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to redo the training "
              {trainingToRedo?.title}"? This will allow you to complete it again
              and add a new entry to the completion log.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleRedoConfirm}>
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default TrainingOverviewPage;

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
  Autocomplete,
  ListItemText,
  Chip,
  AutocompleteRenderInputParams,
} from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { colorThemes } from '../theme';
import { countries, getCountryCode } from 'countries-list';
import {
  FormattedTooltip,
  orgProfileHeaderTextStyle,
  orgProfileSubHeaderTextStyle,
  orgProfileButtonStyle,
} from './OrganizationProfileFormatting';

export default function ExternalContextStage() {
  const { register, control } = useFormContext();

  const {
    fields: fieldsRegulations,
    append: appendRegulations,
    remove: removeRegulations,
  } = useFieldArray({
    control,
    name: 'regulations',
  });
  const addFieldRegulation = () => {
    appendRegulations({ regulation: '' });
  };

  const {
    fields: fieldsExternalParties,
    append: appendExternalParty,
    remove: removeExternalParty,
  } = useFieldArray({
    control,
    name: 'externalParties',
  });
  const addFieldParty = () => {
    appendExternalParty({ name: '', requirements: '' });
  };

  const countryList: string[] = Object.values(countries).map(
    country => country.name
  );

  const countryTextStyle = { fontSize: '0.875rem' };

  const partyOptions = [
    'External Consultants',
    'External Service Providers',
    'Permanent External Employees',
    'Vendors',
    'Investors',
    'Customers',
  ];

  return (
    <>
      <Typography variant="h4" {...orgProfileHeaderTextStyle}>
        Location
      </Typography>
      <Grid container>
        <Grid item xs={5}>
          <Typography variant="h6" {...orgProfileSubHeaderTextStyle}>
            Organization Headquarters
          </Typography>
          <Controller
            name="headquarterCountry"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                <Autocomplete
                  options={countryList}
                  multiple
                  value={value ? [value] : []}
                  onChange={(_, newValue) =>
                    onChange(
                      newValue.length > 1
                        ? newValue[1]
                        : newValue.length === 1
                        ? newValue[0]
                        : ''
                    )
                  }
                  renderTags={(values: readonly string[], getTagProps) =>
                    values.map((value, index) => {
                      const tagProps = getTagProps({ index });
                      return (
                        <Chip
                          variant="filled"
                          label={value}
                          {...tagProps}
                          icon={
                            <img
                              loading="eager"
                              width="20px"
                              srcSet={`https://flagcdn.com/w40/${(
                                getCountryCode(value) as string
                              ).toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${(
                                getCountryCode(value) as string
                              ).toLowerCase()}.png`}
                              alt=""
                            />
                          }
                          style={{
                            backgroundColor: colorThemes.DARK_BLUE_600,
                            color: 'white',
                          }}
                        />
                      );
                    })
                  }
                  renderOption={(props, option) => (
                    <li {...props}>
                      <img
                        loading="eager"
                        width="20px"
                        style={{
                          marginLeft: '20px',
                          marginRight: '10px',
                        }}
                        srcSet={`https://flagcdn.com/w40/${(
                          getCountryCode(option) as string
                        ).toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${(
                          getCountryCode(option) as string
                        ).toLowerCase()}.png`}
                        alt=""
                      />
                      <ListItemText
                        primary={option}
                        primaryTypographyProps={countryTextStyle}
                      />
                    </li>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Headquarter Location"
                      sx={{
                        '& .MuiInputBase-input': {
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          fontSize: '0.875rem',
                        },
                      }}
                    ></TextField>
                  )}
                />
              </FormControl>
            )}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h6"
              {...orgProfileSubHeaderTextStyle}
              sx={{ marginBottom: '15px' }}
            >
              Additional Locations
            </Typography>
            <FormattedTooltip title={additionalLocationsTooltipText}>
              <InfoOutlinedIcon fontSize="large" />
            </FormattedTooltip>
          </div>
          <Controller
            name="countries"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  options={countryList}
                  value={value || []}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Operating Countries"
                      sx={{
                        '& .MuiInputBase-input': {
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          fontSize: '0.875rem',
                        },
                      }}
                    ></TextField>
                  )}
                  renderTags={(values: readonly string[], getTagProps) =>
                    values.map((value, index) => {
                      const tagProps = getTagProps({ index });
                      return (
                        <Chip
                          variant="filled"
                          label={value}
                          {...tagProps}
                          icon={
                            <img
                              loading="eager"
                              width="20px"
                              srcSet={`https://flagcdn.com/w40/${(
                                getCountryCode(value) as string
                              ).toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${(
                                getCountryCode(value) as string
                              ).toLowerCase()}.png`}
                              alt=""
                            />
                          }
                          style={{
                            backgroundColor: colorThemes.DARK_BLUE_600,
                            color: 'white',
                          }}
                        />
                      );
                    })
                  }
                  renderOption={(props, option) => (
                    <li {...props}>
                      <img
                        loading="eager"
                        width="20px"
                        style={{ marginLeft: '20px', marginRight: '5px' }}
                        srcSet={`https://flagcdn.com/w40/${(
                          getCountryCode(option) as string
                        ).toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${(
                          getCountryCode(option) as string
                        ).toLowerCase()}.png`}
                        alt=""
                      />
                      <ListItemText
                        primary={option}
                        primaryTypographyProps={countryTextStyle}
                      />
                    </li>
                  )}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item sm={8}>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant="h4" {...orgProfileHeaderTextStyle}>
              Regulations
            </Typography>
            <FormattedTooltip
              title={regulationsTooltipText}
              sx={{
                marginBottom: '10px',
              }}
            >
              <InfoOutlinedIcon fontSize="large" />
            </FormattedTooltip>
          </div>
        </Grid>
        <Grid container>
          {fieldsRegulations.map((field, index) => (
            <Grid container spacing={2} key={field.id} marginTop={'1px'}>
              <Grid item xs={10}>
                <TextField
                  id="outlined-basic"
                  label="Regulation"
                  variant="outlined"
                  {...register(`regulations.${index}.regulation`)}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={1} sm={1}>
                <IconButton
                  onClick={() => removeRegulations(index)}
                  sx={{ height: '100%' }}
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Button
        sx={{
          ...orgProfileButtonStyle,
          marginTop: '20px',
        }}
        onClick={addFieldRegulation}
      >
        <AddIcon />
        Add Regulation
      </Button>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Typography
          variant="h4"
          {...orgProfileHeaderTextStyle}
          sx={{ marginBottom: '10px' }}
        >
          External Parties
        </Typography>
        <FormattedTooltip
          title={externalPartiesTooltipText}
          sx={{ marginBottom: '10px' }}
        >
          <InfoOutlinedIcon fontSize="large" />
        </FormattedTooltip>
      </div>
      <Typography variant="body1" marginBottom={'10px'}>
        Select a pre-defined party or type your own
      </Typography>
      <Grid container>
        {fieldsExternalParties.map((field, index) => (
          <Grid container spacing={2} key={field.id} marginBottom={'10px'}>
            <Grid item xs={4}>
              <Controller
                name={`externalParties.${index}.name`}
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={partyOptions}
                    multiple
                    freeSolo
                    value={field.value ? [field.value] : []}
                    onChange={(_, newValue) =>
                      field.onChange(
                        newValue.length > 1
                          ? newValue[1]
                          : newValue.length === 1
                          ? newValue[0]
                          : ''
                      )
                    }
                    renderTags={(values, getTagProps) =>
                      values.map((value, index) => {
                        const tagProps = getTagProps({ index });
                        return (
                          <Chip
                            variant="filled"
                            label={value}
                            {...tagProps}
                            style={{
                              backgroundColor: colorThemes.DARK_BLUE_600,
                              color: 'white',
                            }}
                          />
                        );
                      })
                    }
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField {...params} label="Party" variant="outlined" />
                    )}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                id="outlined-basic"
                label="Requirements"
                variant="outlined"
                {...register(`externalParties.${index}.requirements`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={1} sm={1}>
              <IconButton
                onClick={() => removeExternalParty(index)}
                sx={{ height: '100%' }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Button
        sx={{
          ...orgProfileButtonStyle,
          marginTop: '5px',
          marginBottom: '20px',
        }}
        onClick={addFieldParty}
      >
        <AddIcon />
        Add Party
      </Button>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Typography
          variant="h4"
          {...orgProfileHeaderTextStyle}
          sx={{ marginTop: '20px', marginBottom: '20px' }}
        >
          Other External Context
        </Typography>
        <FormattedTooltip
          title={externalContentTooltipText}
          sx={{
            marginBottom: '20px',
          }}
        >
          <InfoOutlinedIcon fontSize="large" />
        </FormattedTooltip>
      </div>
      <TextField
        id="outlined-basic"
        label="Other External Context"
        variant="outlined"
        multiline
        rows={6}
        {...register('externalContext')}
        sx={{ width: '100%' }}
      />
    </>
  );
}

const additionalLocationsTooltipText =
  'In case there are any additional subsidiaries or countries you are operating in, please add them here.';

const regulationsTooltipText =
  'Please list all regulations (product, data, industry, etc.) that are already applicable to you considering your location, industry and business model.' +
  '\n_For example: if you are operating in the European Union, the EU AI Act is applicable._';

const externalPartiesTooltipText =
  'Add all relevant external parties that interact with your AI systems, and describe how they should adhere to your AI policy and obligations.' +
  '\n_For example: external consultants need to adhere to your AI policy or conduct an AI training._';

const externalContentTooltipText =
  'Other external context could be anything that has an external influence on the development and use of AI systems for your organization.' +
  '\n_For example: decisions from regulators, court orders, policies, culture, traditions, ethics, competitive landscape, trends._';

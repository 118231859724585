import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { XCircle } from 'lucide-react';

interface FailureScreenProps {
  onRetry: () => void;
}

const FailureScreen: React.FC<FailureScreenProps> = ({ onRetry }) => {
  return (
    <div className="flex flex-col items-center gap-4 pt-12 w-full h-full">
      <Card className="w-full max-w-xl border-2">
        <CardContent className="flex flex-col items-center text-center pt-6">
          <XCircle className="w-12 h-12 text-destructive mb-4" />

          <h3 className="text-2xl font-bold mb-4">
            Unfortunately, you did not pass
          </h3>

          <p className="mb-2 text-base text-muted-foreground">
            You'll need to get 70% correct answers to complete the assessment
          </p>

          <ul className="text-sm text-muted-foreground mb-6 text-left">
            <li>• Previous attempts do not count</li>
            <li>• Review the material before trying again</li>
            <li>• Training does not count towards completion</li>
          </ul>

          <Button onClick={onRetry}>Try Again</Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default FailureScreen;

import {
  gql,
  useLazyQuery,
  useMutation,
  useSuspenseQuery,
} from '@apollo/client';
import FileTreeView, { FileNode } from '../../components/FileTreeView';
import {
  AddSourceFilesMutation,
  AddSourceFilesMutationVariables,
  SourceFile,
  UploadedFilesQuery,
} from '../../__generated__/gql/graphql';
import ChipInputList from '../../components/ChipInputList';
import { useState } from 'react';
import SidebarContentField from '../../components/SidebarContentField';
import { Button } from '../../components/ui/button';
import { Alert, AlertDescription } from '../../components/ui/alert';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../components/ui/tooltip';
import { toast } from 'sonner';

const UPDATE_CONFLUENCE_LABELS = gql`
  mutation UpdateExperimentConfluenceLabels(
    $experimentId: String!
    $confluenceLabels: [String]
  ) {
    updateExperiment(
      experimentId: $experimentId
      confluenceLabels: $confluenceLabels
    ) {
      experiment {
        confluenceLabels
      }
    }
  }
`;

const UPLOAD_CONFLUENCE_PAGES = gql`
  mutation AddConfluencePages(
    $projectId: String!
    $experimentId: String!
    $confluencePageIds: [String!]!
  ) {
    addConfluencePages(
      projectId: $projectId
      experimentId: $experimentId
      confluencePageIds: $confluencePageIds
    ) {
      experiment {
        id
      }
    }
  }
`;

const GET_PROJECT_FILES = gql`
  query UploadedFiles($experimentId: String!) {
    experiment(id: $experimentId) {
      id
      confluenceLabels
      confluencePages {
        id
        fileName
      }
      sourceFiles {
        id
        filePath
        fileName
        url
      }
    }
  }
`;

const DELETE_SOURCE_FILES = gql`
  mutation DeleteSourceFiles(
    $fileIds: [String!]!
    $projectId: String!
    $experimentId: String!
  ) {
    deleteSourceFiles(
      fileIds: $fileIds
      projectId: $projectId
      experimentId: $experimentId
    ) {
      deletedSourceFiles {
        id
        fileName
      }
    }
  }
`;

const GET_CONFLUENCE_FILES = gql`
  query ConfluenceFilesByLabel($projectId: String!, $labels: [String]!) {
    confluenceFilesByLabel(projectId: $projectId, labels: $labels) {
      id
      title
    }
  }
`;
const SYNC_REMOTE_GIT_FILES = gql`
  mutation SyncRemoteGitFilesWithExperiment(
    $projectId: String!
    $experimentId: String!
  ) {
    syncRemoteGitFilesWithExperiment(
      projectId: $projectId
      experimentId: $experimentId
    ) {
      experiment {
        sourceFiles {
          id
          filePath
          fileName
          url
        }
      }
    }
  }
`;

const ADD_SOURCE_FILES = gql`
  mutation AddSourceFiles(
    $files: [SourceFileInput!]!
    $projectId: String!
    $experimentId: String!
  ) {
    addSourceFiles(
      files: $files
      projectId: $projectId
      experimentId: $experimentId
    ) {
      sourceFiles {
        id
        filePath
        fileName
        url
      }
      presignedUrls
    }
  }
`;

interface ConfluencePage {
  id: string;
  title: string;
}

interface Props {
  projectId: string;
  experimentId: string;
  gitSyncEnabled: boolean;
}

export default function FilesTreeView({
  projectId,
  experimentId,
  gitSyncEnabled,
}: Props) {
  const { data } = useSuspenseQuery<UploadedFilesQuery>(GET_PROJECT_FILES, {
    variables: { experimentId },
  });
  const [deleteFiles] = useMutation(DELETE_SOURCE_FILES);

  const isDeleteClickable = true;

  const handleDeleteFiles = async (selectedIds: string[]) => {
    const filesToDelete = new Set<string>();

    selectedIds.forEach(selectedId => {
      files.forEach(file => {
        if ((file?.filePath ?? '').startsWith(selectedId)) {
          filesToDelete.add(file!.id as string);
        }
      });
    });

    if (filesToDelete.size > 0) {
      await deleteFiles({
        variables: {
          fileIds: Array.from(filesToDelete),
          projectId,
          experimentId,
        },
        update(cache) {
          cache.modify({
            id: cache.identify({ __typename: 'Experiment', id: experimentId }),
            fields: {
              sourceFiles(existingFiles = []) {
                return existingFiles.filter((fileRef: any) => {
                  const fileId = fileRef?.__ref?.split(':')[1];
                  return fileId && !filesToDelete.has(fileId);
                });
              },
            },
          });
        },
      });
    }
  };

  const [syncRemoteGitFiles, { loading: isSyncing }] = useMutation(
    SYNC_REMOTE_GIT_FILES,
    {
      variables: { projectId, experimentId },
      update(cache, { data: mutationData }) {
        if (
          mutationData?.syncRemoteGitFilesWithExperiment?.experiment
            ?.sourceFiles
        ) {
          const newSourceFiles =
            mutationData.syncRemoteGitFilesWithExperiment.experiment
              .sourceFiles;

          cache.modify({
            id: cache.identify({ __typename: 'Experiment', id: experimentId }),
            fields: {
              sourceFiles(existingFiles = []) {
                const updatedFiles = newSourceFiles.map(
                  (newFile: SourceFile) => ({
                    ...newFile,
                    __typename: 'SourceFile',
                  })
                );
                return [...existingFiles, ...updatedFiles];
              },
            },
          });
          toast.success('Git files synced successfully');
        }
      },
      onError: error => {
        toast.error(`Failed to sync Git files: ${error.message}`);
      },
    }
  );

  const files =
    (data?.experiment?.sourceFiles ?? []).map(file => ({
      id: file?.id,
      name: file?.fileName,
      filePath: file?.filePath,
      url: file?.url,
    })) || [];
  // const existingConfluencePages = data?.experiment?.confluencePages ?? [];
  const existingConfluencePages =
    (data?.experiment?.confluencePages ?? []).map(file => ({
      id: file?.id ?? '',
      name: file?.fileName ?? '',
    })) || [];

  const { fileTree } = buildFileTree(files);
  const [confluenceLabels, setConfluenceLabels] = useState<string[]>(
    (data?.experiment?.confluenceLabels as string[]) ?? []
  );
  const [updateConfluenceLabels, { loading: isUpdatingConfluenceLabels }] =
    useMutation(UPDATE_CONFLUENCE_LABELS);

  const [associatedFiles, setAssociatedFiles] = useState<ConfluencePage[]>([]);
  const [
    getConfluenceFiles,
    { loading: isFetchingConfluenceFiles, error: confluenceFilesError },
  ] = useLazyQuery(GET_CONFLUENCE_FILES, {
    onCompleted: data => {
      setAssociatedFiles(data?.confluenceFilesByLabel ?? []);
    },
  });

  const [uploadConfluencePages, { loading: isUpdatingConfluencePages }] =
    useMutation(UPLOAD_CONFLUENCE_PAGES);

  const [addSourceFiles, { loading: isUploading }] = useMutation<
    AddSourceFilesMutation,
    AddSourceFilesMutationVariables
  >(ADD_SOURCE_FILES, {
    onError: error => {
      toast.error(`Failed to upload files: ${error.message}`);
    },
    update(cache, { data: mutationData }) {
      if (mutationData?.addSourceFiles?.sourceFiles) {
        const newSourceFiles = mutationData.addSourceFiles.sourceFiles;

        cache.modify({
          id: cache.identify({ __typename: 'Experiment', id: experimentId }),
          fields: {
            sourceFiles(existingFiles = []) {
              const newFileRefs = newSourceFiles.map(
                (
                  file: AddSourceFilesMutation['addSourceFiles']['sourceFiles'][0]
                ) =>
                  cache.writeFragment({
                    data: { ...file, __typename: 'SourceFile' },
                    fragment: gql`
                      fragment NewSourceFile on SourceFile {
                        id
                        filePath
                        fileName
                        url
                      }
                    `,
                  })
              );
              return [...existingFiles, ...newFileRefs];
            },
          },
        });
      }
    },
  });

  const handleFileUpload = async (files: File[], selectedPath?: string) => {
    if (files.length === 0) return;

    try {
      const cleanPath = selectedPath
        ? selectedPath.replace(/^\/+|\/+$/g, '')
        : '';

      const fileInputs = files.map(file => ({
        fileName: file.name,
        filePath: cleanPath ? `${cleanPath}/${file.name}` : file.name,
        fileHash: Date.now().toString(), // date in this case is sufficient as hash
      }));

      const result = await addSourceFiles({
        variables: {
          files: fileInputs,
          projectId,
          experimentId,
        },
      });

      const presignedUrls = result.data?.addSourceFiles?.presignedUrls || [];

      const uploadPromises = files.map((file, index) => {
        if (index < presignedUrls.length) {
          return fetch(presignedUrls[index], {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': file.type || 'application/octet-stream',
            },
          });
        }
        return Promise.resolve();
      });

      await Promise.all(uploadPromises);
      toast.success('Files uploaded successfully');
    } catch (error) {
      console.error('Error uploading files:', error);
      toast.error('Failed to upload files');
    }
  };

  const [selectedFolder, setSelectedFolder] = useState<string | undefined>();

  const handleFolderSelect = (folderId: string | undefined) => {
    setSelectedFolder(folderId);
  };

  return (
    <div className="flex flex-col gap-5">
      <SidebarContentField title={'Uploaded Code Files'}>
        <FileTreeView
          files={fileTree}
          isDeleteEnabled={isDeleteClickable}
          onDeleteFiles={handleDeleteFiles}
          onUploadFiles={handleFileUpload}
          isUploading={isUploading}
          onFolderSelect={handleFolderSelect}
          selectedFolder={selectedFolder}
        />
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <span>
                <Button
                  variant="outline"
                  onClick={() => syncRemoteGitFiles()}
                  disabled={!gitSyncEnabled || isSyncing}
                  className="mt-2"
                >
                  {isSyncing ? 'Syncing...' : 'Sync Git Files'}
                </Button>
              </span>
            </TooltipTrigger>
            {!gitSyncEnabled && (
              <TooltipContent>
                <p>No Git repository is connected</p>
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>
      </SidebarContentField>

      <SidebarContentField title={'Confluence Sources'}>
        <div className="flex flex-col gap-5">
          <FileTreeView
            files={existingConfluencePages}
            nonSelectableNodes={[]}
            isDeleteEnabled={false}
            onDeleteFiles={async () => {}}
          />
          <h6 className="text-base font-semibold">Confluence Labels</h6>
          <ChipInputList
            items={confluenceLabels}
            setItems={setConfluenceLabels}
          />
          <div className="flex gap-2">
            <Button
              variant="outline"
              disabled={
                confluenceLabels.length === 0 || isFetchingConfluenceFiles
              }
              onClick={() => {
                setAssociatedFiles([]);
                getConfluenceFiles({
                  variables: {
                    experimentId,
                    projectId,
                    labels: confluenceLabels,
                  },
                });
              }}
            >
              {isFetchingConfluenceFiles ? 'Fetching...' : 'Fetch Files'}
            </Button>
            <Button
              variant="outline"
              disabled={isUpdatingConfluenceLabels}
              onClick={async () => {
                try {
                  await updateConfluenceLabels({
                    variables: { experimentId, confluenceLabels },
                  });
                  toast.success('Confluence labels updated successfully');
                } catch {
                  toast.error('Failed to update confluence labels');
                }
              }}
            >
              {isUpdatingConfluenceLabels ? 'Saving...' : 'Save Labels'}
            </Button>
          </div>

          {confluenceFilesError && (
            <Alert variant="destructive">
              <AlertDescription>
                Failed to fetch associated Confluence files:{' '}
                {confluenceFilesError.message}
              </AlertDescription>
            </Alert>
          )}

          {(associatedFiles ?? []).map(({ title, id }) => (
            <p key={id} className="text-sm">
              {title}
            </p>
          ))}

          {associatedFiles && associatedFiles.length > 0 && (
            <Button
              disabled={isUpdatingConfluencePages}
              onClick={async () => {
                try {
                  await uploadConfluencePages({
                    variables: {
                      projectId,
                      experimentId,
                      confluencePageIds: associatedFiles.map(page => page.id),
                    },
                  });
                  toast.success('Confluence files uploaded successfully');
                } catch {
                  toast.error('Failed to upload Confluence files');
                }
              }}
            >
              {isUpdatingConfluencePages ? 'Adding...' : 'Add Pages'}
            </Button>
          )}
        </div>
      </SidebarContentField>
    </div>
  );
}

function buildFileTree(files: any[]): {
  fileTree: FileNode[];
} {
  const nodesMap = new Map<string, FileNode>();

  files.forEach(file => {
    const segments = file.filePath.split('/');
    segments.reduce((parentId: string, segment: string, index: number) => {
      const isFile = index === segments.length - 1; // Assume last segment is a file if it has an extension
      const id = parentId ? `${parentId}/${segment}` : segment;
      const existingNode = nodesMap.get(id);

      if (!existingNode) {
        nodesMap.set(id, {
          id,
          name: segment,
          parentId: index === 0 ? undefined : parentId,
          url: isFile ? file.url : null,
        });
      }

      return id;
    }, '');
  });

  const fileTree = Array.from(nodesMap.values());

  return { fileTree };
}

import React, { useState } from 'react';
import { DocumentationResultSource } from '@/__generated__/gql/graphql';
import { SourceRenderer } from './SourceRenderer';

export const SourcesAccordion = ({
  sources,
}: {
  sources: DocumentationResultSource[];
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div className="bg-transparent border-0 shadow-none w-full cursor-default min-h-min">
      <div
        onClick={() => setExpanded(!expanded)}
        className={`
          bg-[#1e293b] 
          ${expanded ? 'rounded-t-[10px]' : 'rounded-[10px]'}
          flex p-[10px_16px] items-center gap-2
          ${expanded ? 'w-full' : 'w-[120px]'}
          ml-auto cursor-pointer
          transition-all duration-500 ease-[cubic-bezier(0.4,0,0.2,1)]
          hover:bg-[#2c3e50]
        `}
      >
        <span className="whitespace-nowrap">Sources ({sources.length})</span>
        <svg
          className={`w-6 h-6 transition-all duration-500 ease-[cubic-bezier(0.4,0,0.2,1)] ${
            expanded ? 'rotate-180' : ''
          }`}
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M7 10l5 5 5-5z" />
        </svg>
      </div>

      <div
        className={`
          bg-[#1e293b]
          rounded-b-[10px]
          ${expanded ? 'p-4' : 'p-0'}
          flex flex-col gap-3
          ${expanded ? 'w-full' : 'w-[120px]'}
          ${expanded ? 'h-auto' : 'h-0'}
          ml-auto
          ${expanded ? 'overflow-visible' : 'overflow-hidden'}
          transition-all duration-500 ease-[cubic-bezier(0.4,0,0.2,1)]
        `}
      >
        {sources.map((source, index) => (
          <SourceRenderer key={index} sourceFile={source} expanded={expanded} />
        ))}
      </div>
    </div>
  );
};

import { FC, Fragment, useState, useEffect, useMemo, useRef } from 'react';
import ProjectGridItem from '../../project_view/ProjectGridItem';
import {
  LiteracyTraining,
  TrainingsInterval,
} from '../../__generated__/gql/graphql';
import { generateIntervals } from '../../utils/generateIntervals';
import CompletionProgress from './CompletionProgress';
import CompletionTable from './CompletionTable';
import {
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '../../components/ui/table';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

interface CompletionLogsSectionProps {
  training: LiteracyTraining;
}

const CompletionLogsSection: FC<CompletionLogsSectionProps> = ({
  training,
}) => {
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('All');
  const [intervalTotalUsers, setIntervalTotalUsers] = useState<
    Record<number, number>
  >({});
  const [intervalValidUserIds, setIntervalValidUserIds] = useState<
    Record<number, Set<string>>
  >({});

  const isInitialized = useRef(false);

  const intervals = useMemo(() => {
    const baseIntervals = generateIntervals(
      training.startDate ? training.startDate : training.created,
      training.interval || TrainingsInterval.Yearly,
      true,
      training.dueDate
    );

    return baseIntervals.map(interval => ({
      ...interval,
      trainingId: training.id,
    }));
  }, [
    training.startDate,
    training.created,
    training.interval,
    training.dueDate,
    training.id,
  ]);

  const handleRowClick = (index: number) =>
    setExpandedRow(expandedRow === index ? null : index);

  useEffect(() => {
    if (!isInitialized.current) {
      const currentIntervalIndex = intervals.findIndex(
        interval => interval.status === 'current'
      );

      if (currentIntervalIndex !== -1) {
        setExpandedRow(currentIntervalIndex);
      }

      isInitialized.current = true;
    }
  }, [intervals]);

  function handleTotalUsersChange(index: number, count: number) {
    if (count > 0) {
      setIntervalTotalUsers(prev => {
        if (prev[index] === count) return prev;

        return { ...prev, [index]: count };
      });
    }
  }

  function handleValidUserIdsChange(index: number, userIds: Set<string>) {
    setIntervalValidUserIds(prev => {
      const current = prev[index] || new Set();

      if (
        current.size === userIds.size &&
        Array.from(current).every(id => userIds.has(id))
      ) {
        return prev;
      }
      return { ...prev, [index]: userIds };
    });
  }
  return (
    <div className="mt-8">
      <ProjectGridItem
        title="Completion Logs"
        content={
          <div>
            <div className="flex flex-wrap gap-4 mb-4 p-1 justify-between">
              <Input
                type="search"
                placeholder="Search users by name or email..."
                value={searchInput}
                onChange={e => setSearchInput(e.target.value)}
                className="max-w-md"
              />
              <Select value={statusFilter} onValueChange={setStatusFilter}>
                <SelectTrigger className="w-40">
                  <SelectValue placeholder="Filter by status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="All">All</SelectItem>
                  <SelectItem value="Completed">Completed</SelectItem>
                  <SelectItem value="Pending">Pending</SelectItem>
                  <SelectItem value="Overdue">Overdue</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="overflow-x-auto">
              <Table className="min-w-full text-sm">
                <TableHeader>
                  <TableRow>
                    <TableHead className="py-2 px-4 text-left">
                      Interval
                    </TableHead>
                    <TableHead className="py-2 px-4 text-left">
                      Status
                    </TableHead>
                    <TableHead className="py-2 px-4 text-left">
                      Completions
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {intervals.map((intervalObj, idx) => (
                    <Fragment key={idx}>
                      <TableRow
                        onClick={() => handleRowClick(idx)}
                        className={`cursor-pointer ${
                          intervalObj.status === 'current'
                            ? 'bg-dark-blue-600'
                            : intervalObj.status === 'upcoming'
                            ? 'bg-dark-blue-400'
                            : ''
                        }`}
                        aria-expanded={expandedRow === idx}
                        aria-controls={`interval-details-${idx}`}
                      >
                        <TableCell className="py-2 px-4">
                          {intervalObj.label}
                        </TableCell>
                        <TableCell className="py-2 px-4">
                          <span className="text-gray-200">
                            {intervalObj.status.charAt(0).toUpperCase() +
                              intervalObj.status.slice(1)}
                          </span>
                        </TableCell>
                        <TableCell className="py-2 px-4">
                          <CompletionProgress
                            interval={intervalObj}
                            completionLogs={training.completionLogs}
                            open={expandedRow === idx}
                            totalUsers={intervalTotalUsers[idx] || 0}
                            validUserIds={intervalValidUserIds[idx]}
                          />
                        </TableCell>
                      </TableRow>
                      {expandedRow === idx && (
                        <TableRow className="bg-inherit">
                          <TableCell
                            colSpan={3}
                            className="py-2 px-4"
                            id={`interval-details-${idx}`}
                          >
                            <div className="mt-2">
                              <CompletionTable
                                intervalObj={intervalObj}
                                completionLogs={training.completionLogs}
                                searchTerm={searchInput}
                                statusFilter={statusFilter}
                                onTotalUsersChange={count =>
                                  handleTotalUsersChange(idx, count)
                                }
                                onValidUserIdsChange={userIds =>
                                  handleValidUserIdsChange(idx, userIds)
                                }
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default CompletionLogsSection;

import { Card, CardContent } from '../components/ui/card';
import { Badge } from '../components/ui/badge';
import { cn } from '../lib/utils';
import { ReactNode } from 'react';
import {
  Department,
  RiskClassCategory,
  Stage,
  User,
} from '../__generated__/gql/graphql';
import UserTag from '../components/UserTag';
import { StageDisplayMap } from './ProjectFilter';

type ProjectDetailCardProps = {
  id: string;
  title: string;
  description: string;
  onClick: () => void;
  isSelected?: boolean;
  departments: Department[];
  stage?: Stage | null;
  user?: User | null;
  riskClass: RiskClassCategory | string;
  isPurchased: boolean;
};

const InfoItem = ({
  title,
  content,
}: {
  title: string;
  content: ReactNode;
}) => (
  <div className="mt-4">
    <p className="text-sm text-muted-foreground truncate">{title}:</p>
    {content}
  </div>
);

export default function ProjectDetailCard({
  title,
  onClick,
  isSelected,
  departments,
  stage,
  user,
  riskClass,
  isPurchased,
}: ProjectDetailCardProps) {
  return (
    <Card
      className={cn(
        'w-[400px] transition-all duration-200 hover:cursor-pointer hover:bg-dark-blue-400 relative',
        isSelected && 'border-2 border-yellow-600'
      )}
      onClick={event => {
        event.stopPropagation();
        onClick();
      }}
    >
      <CardContent className="flex flex-col justify-between h-full p-5">
        <Badge
          variant="default"
          className="bg-dark-blue-200 hover:bg-dark-blue-200 absolute top-2 right-2"
        >
          {isPurchased ? 'Procured' : 'Internally Built'}
        </Badge>

        <div className="flex justify-between">
          <h4 className="truncate">{title}</h4>
        </div>

        <div className="grid grid-cols-2 gap-8">
          <div>
            <InfoItem
              title="Department"
              content={
                <Badge variant="outline" className="text-xs">
                  {departments.length === 0
                    ? 'Not Defined'
                    : departments.length <= 1
                    ? departments[0]?.name || 'Not Defined'
                    : `${departments[0]?.name || 'Not Defined'} +${
                        departments.length - 1
                      }`}
                </Badge>
              }
            />
            <InfoItem
              title="Stage"
              content={
                <Badge variant="outline" className="text-xs">
                  {stage != null && stage !== undefined
                    ? StageDisplayMap[stage]
                    : 'Not Defined'}
                </Badge>
              }
            />
          </div>
          <div>
            <InfoItem
              title="Responsible Person"
              content={
                user ? (
                  <UserTag
                    key={user.id}
                    user={user}
                    variant="text"
                    size="medium"
                    noWrap={true}
                  />
                ) : (
                  <span className="text-sm text-muted-foreground">
                    Not assigned
                  </span>
                )
              }
            />
            <InfoItem
              title="Risk Class"
              content={
                <Badge variant="outline" className="text-xs">
                  {riskClass}
                </Badge>
              }
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

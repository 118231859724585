export enum StepLabel {
  Start = 'Start',
  Questions = 'Questions',
  Result = 'Result',
}

interface StepperConfig {
  hasSections: boolean;
  sections?: Array<{ title?: string | null } | null>;
  hasQuestions: boolean;
  totalPages: number;
}

export const getStepLabels = (config: StepperConfig): string[] => {
  const { hasSections, sections, hasQuestions } = config;
  const steps: string[] = [StepLabel.Start];

  if (hasSections && sections) {
    sections.forEach(section => {
      steps.push(section?.title || 'Section');
    });
  } else {
    steps.push('Material');
  }

  if (hasQuestions) {
    steps.push(StepLabel.Questions);
  }

  steps.push(StepLabel.Result);
  return steps;
};

export const getCurrentStep = (
  activeStep: number,
  currentSection: number,
  hasSections: boolean,
  hasQuestions: boolean,
  isComplete: boolean
): number => {
  if (isComplete) {
    const questionStep = hasQuestions ? 1 : 0;
    return (hasSections ? currentSection : 1) + questionStep + 2;
  }

  if (activeStep === 2) {
    return (hasSections ? currentSection + 1 : 1) + 1;
  }

  if (activeStep === 0) {
    return 0;
  }

  return currentSection + 1;
};

import React, { useLayoutEffect, useRef } from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

import { ChevronRight, type LucideIcon } from 'lucide-react';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import { cn } from '@/lib/utils';

interface TreeDataItem {
  id: string;
  name: string;
  icon?: LucideIcon;
  children?: TreeDataItem[];
  url?: string | null;
}

type TreeProps = React.HTMLAttributes<HTMLDivElement> & {
  data: TreeDataItem[] | TreeDataItem;
  selectedIds?: string[];
  onSelectChange?: (items: TreeDataItem[]) => void;
  multiSelect?: boolean;
  expandAll?: boolean;
  folderIcon?: LucideIcon;
  itemIcon?: LucideIcon;
};

const Tree = React.forwardRef<HTMLDivElement, TreeProps>(
  (
    {
      data,
      selectedIds,
      onSelectChange,
      multiSelect = false,
      expandAll,
      folderIcon,
      itemIcon,
      className,
      ...props
    },
    ref
  ) => {
    const [internalSelectedIds, setInternalSelectedIds] = React.useState<
      string[]
    >([]);
    const effectiveSelectedIds = selectedIds ?? internalSelectedIds;

    React.useEffect(() => {
      if (selectedIds !== undefined) {
        setInternalSelectedIds(selectedIds);
      }
    }, [selectedIds]);

    const [expandedIds, setExpandedIds] = React.useState<string[]>(() => {
      if (expandAll) {
        const ids: string[] = [];
        const collectIds = (items: TreeDataItem[] | TreeDataItem) => {
          if (items instanceof Array) {
            items.forEach(item => {
              ids.push(item.id);
              if (item.children) {
                collectIds(item.children);
              }
            });
          } else if (items.children) {
            ids.push(items.id);
            collectIds(items.children);
          }
        };
        collectIds(data);
        return ids;
      }
      return [];
    });

    const getAllItems = React.useCallback(
      (items: TreeDataItem[] | TreeDataItem): TreeDataItem[] => {
        const result: TreeDataItem[] = [];
        const processItem = (item: TreeDataItem) => {
          result.push(item);
          if (item.children) {
            item.children.forEach(processItem);
          }
        };

        if (Array.isArray(items)) {
          items.forEach(processItem);
        } else {
          processItem(items);
        }
        return result;
      },
      []
    );

    const handleSelectChange = React.useCallback(
      (item: TreeDataItem) => {
        let newSelectedIds: string[];
        if (multiSelect) {
          newSelectedIds = effectiveSelectedIds.includes(item.id)
            ? effectiveSelectedIds.filter(id => id !== item.id)
            : [...effectiveSelectedIds, item.id];
        } else {
          newSelectedIds = [item.id];
        }
        setInternalSelectedIds(newSelectedIds);
        if (onSelectChange) {
          const selectedItems = getAllItems(data).filter(i =>
            newSelectedIds.includes(i.id)
          );
          onSelectChange(selectedItems);
        }
      },
      [multiSelect, onSelectChange, effectiveSelectedIds, getAllItems, data]
    );

    const handleExpand = React.useCallback((values: string[]) => {
      setExpandedIds(values);
    }, []);

    const treeRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
      const observer = new ResizeObserver(() => {});

      if (treeRef.current) {
        observer.observe(treeRef.current);
      }

      return () => {
        observer.disconnect();
      };
    }, [data]);

    return (
      <div
        ref={treeRef}
        className={cn('overflow-hidden w-full', className)}
        style={{
          minHeight: '100px',
          height: 'auto',
        }}
      >
        <ScrollArea
          style={{ width: '100%', height: 'auto', maxHeight: '400px' }}
        >
          <div className="relative p-2">
            <TreeItem
              data={data}
              ref={ref}
              selectedIds={effectiveSelectedIds}
              handleSelectChange={handleSelectChange}
              expandedItemIds={expandedIds}
              onExpand={handleExpand}
              FolderIcon={folderIcon}
              ItemIcon={itemIcon}
              {...props}
            />
          </div>
        </ScrollArea>
      </div>
    );
  }
);

type TreeItemProps = TreeProps & {
  selectedIds: string[];
  handleSelectChange: (item: TreeDataItem) => void;
  expandedItemIds: string[];
  onExpand: (values: string[]) => void;
  FolderIcon?: LucideIcon;
  ItemIcon?: LucideIcon;
};

const TreeItem = React.forwardRef<HTMLDivElement, TreeItemProps>(
  (
    {
      className,
      data,
      selectedIds,
      handleSelectChange,
      expandedItemIds,
      onExpand,
      FolderIcon,
      ItemIcon,
      ...props
    },
    ref
  ) => {
    return (
      <div ref={ref} role="tree" className={className} {...props}>
        <ul>
          {data instanceof Array ? (
            data.map(item => {
              return (
                <li key={item.id}>
                  {item.children ? (
                    <AccordionPrimitive.Root
                      type="multiple"
                      value={expandedItemIds}
                      onValueChange={onExpand}
                    >
                      <AccordionPrimitive.Item value={item.id}>
                        <AccordionTrigger
                          className={cn(
                            'relative px-2 py-1 hover:bg-muted/80 transition-colors',
                            selectedIds.includes(item.id) && 'bg-muted/50'
                          )}
                          onClick={() => handleSelectChange(item)}
                        >
                          {item.icon && (
                            <item.icon
                              className="h-4 w-4 shrink-0 mr-2 text-accent-foreground/50"
                              aria-hidden="true"
                            />
                          )}
                          {!item.icon && FolderIcon && (
                            <FolderIcon
                              className="h-4 w-4 shrink-0 mr-2 text-accent-foreground/50"
                              aria-hidden="true"
                            />
                          )}
                          <span className="text-sm truncate">{item.name}</span>
                        </AccordionTrigger>
                        <AccordionContent className="pl-6">
                          <TreeItem
                            data={item.children ? item.children : item}
                            selectedIds={selectedIds}
                            handleSelectChange={handleSelectChange}
                            expandedItemIds={expandedItemIds}
                            onExpand={onExpand}
                            FolderIcon={FolderIcon}
                            ItemIcon={ItemIcon}
                          />
                        </AccordionContent>
                      </AccordionPrimitive.Item>
                    </AccordionPrimitive.Root>
                  ) : (
                    <Leaf
                      item={item}
                      isSelected={selectedIds.includes(item.id)}
                      onClick={() => handleSelectChange(item)}
                      Icon={ItemIcon}
                    />
                  )}
                </li>
              );
            })
          ) : (
            <li>
              <Leaf
                item={data}
                isSelected={selectedIds.includes(data.id)}
                onClick={() => handleSelectChange(data)}
                Icon={ItemIcon}
              />
            </li>
          )}
        </ul>
      </div>
    );
  }
);

const Leaf = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    item: TreeDataItem;
    isSelected?: boolean;
    Icon?: LucideIcon;
  }
>(({ className, item, isSelected, Icon, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        'flex items-center px-2 py-1 hover:bg-muted/80 transition-colors cursor-pointer relative',
        className,
        isSelected && 'bg-muted/50'
      )}
      {...props}
    >
      {item.icon && (
        <item.icon
          className="h-4 w-4 shrink-0 mr-2 text-accent-foreground/50"
          aria-hidden="true"
        />
      )}
      {!item.icon && Icon && (
        <Icon
          className="h-4 w-4 shrink-0 mr-2 text-accent-foreground/50"
          aria-hidden="true"
        />
      )}
      <span className="flex-grow text-sm truncate">{item.name}</span>
    </div>
  );
});

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header>
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn('flex flex-1 w-full items-center py-2 group', className)}
      {...props}
    >
      {children}
      <ChevronRight className="h-4 w-4 shrink-0 transition-transform duration-200 text-accent-foreground/50 ml-auto group-data-[state=open]:rotate-90" />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      'overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
      className
    )}
    {...props}
  >
    <div className="pb-1 pt-0">{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Tree, type TreeDataItem };

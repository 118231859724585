import { FC, useMemo } from 'react';
import { IntervalData } from '../../utils/generateIntervals';
import { Maybe, TrainingCompletion } from '../../__generated__/gql/graphql';
import { ProgressBar } from '../../components/ProgressBar';
import { Button } from '../../components/ui/button';
import { ChevronUp, ChevronDown } from 'lucide-react';

interface CompletionProgressProps {
  interval: IntervalData;
  completionLogs: Maybe<Maybe<TrainingCompletion>[]> | undefined;
  open: boolean;
  totalUsers: number;
  validUserIds?: Set<string>;
}

const CompletionProgress: FC<CompletionProgressProps> = ({
  open,
  totalUsers = 0,
  validUserIds = new Set(),
}) => {
  const stats = useMemo(() => {
    const uniqueCompletionsCount = validUserIds.size;

    const effectiveTotalUsers =
      totalUsers > 0
        ? totalUsers
        : uniqueCompletionsCount > 0
        ? uniqueCompletionsCount
        : 1;

    const completionPercentage =
      (uniqueCompletionsCount / effectiveTotalUsers) * 100;

    return {
      uniqueCompletionsCount,
      totalUsers: effectiveTotalUsers,
      completionPercentage: Math.min(100, completionPercentage),
    };
  }, [totalUsers, validUserIds]);

  return (
    <div className="flex items-center gap-2">
      <div className="flex flex-col gap-1 w-full">
        <ProgressBar variant="determinate" value={stats.completionPercentage} />
        <span className="text-xs text-gray-500">
          {stats.uniqueCompletionsCount > 0 ? (
            <>
              {stats.uniqueCompletionsCount}
              {totalUsers > 0
                ? ` of ${stats.totalUsers} completed (${Math.round(
                    stats.completionPercentage
                  )}%)`
                : `${Math.round(stats.completionPercentage)}%`}
            </>
          ) : totalUsers > 0 ? (
            `0 of ${stats.totalUsers} completed (0%)`
          ) : (
            ''
          )}
        </span>
      </div>
      <Button
        size="sm"
        variant="ghost"
        aria-label={open ? 'Collapse details' : 'Expand details'}
      >
        {open ? (
          <ChevronUp className="w-4 h-4" />
        ) : (
          <ChevronDown className="w-4 h-4" />
        )}
      </Button>
    </div>
  );
};

export default CompletionProgress;

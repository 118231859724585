import React, { useState } from 'react';
import { gql, useMutation, useFragment } from '@apollo/client';
import { toast } from 'sonner';
import { Box, Button, ButtonGroup, TextField, Typography } from '@mui/material';
import { RequirementAuditStatus } from '../../../__generated__/gql/graphql';
import { formatGraphqlEnumToReadableText } from '../../../utils/stringManipulation';
import { getAuditStatusIcon } from '../../../audit_view/AuditStatusIcon';
import { colorThemes } from '../../../theme';

const UPDATE_REQUIREMENT_AUDIT_STATUS = gql`
  mutation UpdateRequirementAuditStatus(
    $requirementId: String!
    $auditId: ID!
    $sections: [AuditSectionInput]!
  ) {
    updateRequirementAuditStatus(
      requirementId: $requirementId
      auditId: $auditId
      sections: $sections
    ) {
      requirementAuditStatus {
        id
        sections {
          status
          comments
          evidenceNotes
        }
      }
    }
  }
`;

const cardProps = {
  padding: '12px',
  bgcolor: colorThemes.DARK_BLUE_800,
  borderRadius: '8px',
};

const headingProps = {
  component: 'h3' as const,
  fontSize: '20px',
  fontWeight: 600,
};

export const REQUIREMENT_AUDIT_SECTION_FRAGMENT = gql`
  fragment RequirementAuditSectionFragment on GovernanceRequirementStatus {
    auditStatus(auditId: $auditId) {
      id
      sections {
        status
        comments
        evidenceNotes
      }
    }
    evidence {
      id
      title
      description
      externalUrl
      file {
        url
      }
    }
  }
`;

interface RequirementAuditSectionProps {
  requirementId: string;
  auditId: string;
  governanceRequirementStatus: {
    id: string;
    __typename: string;
  };
}

interface AuditSection {
  status: RequirementAuditStatus;
  comments: string;
  evidenceNotes: string[];
}

export default function RequirementAuditSection({
  requirementId,
  auditId,
  governanceRequirementStatus,
}: RequirementAuditSectionProps) {
  const { data } = useFragment({
    fragment: REQUIREMENT_AUDIT_SECTION_FRAGMENT,
    fragmentName: 'RequirementAuditSectionFragment',
    from: governanceRequirementStatus,
    variables: {
      auditId,
    },
  });

  const [sections, setSections] = useState<AuditSection[]>(() => {
    if (data?.auditStatus?.sections && data.auditStatus.sections.length > 0) {
      return data.auditStatus.sections.map(
        (section: {
          status?: RequirementAuditStatus;
          comments?: string;
          evidenceNotes?: (string | null)[];
        }) => ({
          status: section?.status ?? RequirementAuditStatus.NotReviewed,
          comments: section?.comments ?? '',
          evidenceNotes:
            section?.evidenceNotes?.filter(
              (note): note is string => note !== null
            ) ?? [],
        })
      );
    }

    return [
      {
        status: RequirementAuditStatus.NotReviewed,
        comments: '',
        evidenceNotes: Array(data?.evidence?.length ?? 0).fill(''),
      },
    ];
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [updateAuditStatus] = useMutation(UPDATE_REQUIREMENT_AUDIT_STATUS);

  const handleUpdateAuditStatus = async () => {
    try {
      const result = await updateAuditStatus({
        variables: {
          requirementId,
          auditId,
          sections: sections.map(section => ({
            status: section.status,
            comments: section.comments,
            evidenceNotes: section.evidenceNotes,
          })),
        },
      });
      setSections(
        result.data.updateRequirementAuditStatus.requirementAuditStatus.sections
      );
      setHasUnsavedChanges(false);
      toast.success('Audit status updated successfully');
    } catch (error) {
      toast.error('Failed to update audit status');
      console.error(error);
    }
  };

  const addSection = () => {
    setSections([
      ...sections,
      {
        status: RequirementAuditStatus.NotReviewed,
        comments: '',
        evidenceNotes: Array(data?.evidence?.length ?? 0).fill(''),
      },
    ]);
    setHasUnsavedChanges(true);
  };

  const deleteSection = (index: number) => {
    setSections(sections.filter((_, i) => i !== index));
    setHasUnsavedChanges(true);
  };

  return (
    <Box {...cardProps} sx={{ mt: 2, mb: 2 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography {...headingProps}>Audit Review</Typography>
        <Box>
          <Button variant="outlined" onClick={addSection} sx={{ mr: 1 }}>
            Add Section
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!hasUnsavedChanges}
            onClick={handleUpdateAuditStatus}
          >
            Save Changes
          </Button>
        </Box>
      </Box>

      {sections.map((section, sectionIndex) => (
        <Box
          key={sectionIndex}
          sx={{
            mt: 3,
            p: 2,
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6">Section {sectionIndex + 1}</Typography>
            {sections.length > 1 && (
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => deleteSection(sectionIndex)}
              >
                Delete Section
              </Button>
            )}
          </Box>

          <ButtonGroup sx={{ mb: 2 }}>
            {Object.values(RequirementAuditStatus).map(status => (
              <Button
                key={status}
                variant={section.status === status ? 'contained' : 'outlined'}
                onClick={() => {
                  const newSections = [...sections];
                  newSections[sectionIndex].status = status;
                  setSections(newSections);
                  setHasUnsavedChanges(true);
                }}
                startIcon={getAuditStatusIcon(status)}
              >
                {formatGraphqlEnumToReadableText(status)}
              </Button>
            ))}
          </ButtonGroup>

          <TextField
            fullWidth
            multiline
            rows={4}
            label="Audit Comments"
            value={section.comments}
            onChange={e => {
              const newSections = [...sections];
              newSections[sectionIndex].comments = e.target.value;
              setSections(newSections);
              setHasUnsavedChanges(true);
            }}
            sx={{ mb: 2 }}
          />

          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Evidence Notes
          </Typography>
          {data?.evidence.length > 0 ? (
            data.evidence.map(
              (
                evidence: {
                  id: string;
                  title: string;
                  description: string;
                  externalUrl: string;
                  file?: { url: string };
                },
                evidenceIndex: number
              ) => (
                <TextField
                  key={evidence?.id}
                  fullWidth
                  label={`Note for ${evidence?.title}`}
                  value={section.evidenceNotes[evidenceIndex] || ''}
                  onChange={e => {
                    const newSections = [...sections];
                    newSections[sectionIndex].evidenceNotes[evidenceIndex] =
                      e.target.value;
                    setSections(newSections);
                    setHasUnsavedChanges(true);
                  }}
                  sx={{ mb: 2 }}
                />
              )
            )
          ) : (
            <Typography color="text.secondary" sx={{ mb: 1 }}>
              No evidences have been added to this requirement.
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
}

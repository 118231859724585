import React, { useState, Suspense } from 'react';
import {
  useMutation,
  useSuspenseQuery,
  useApolloClient,
  gql,
} from '@apollo/client';
import { toast } from 'sonner';
import { MultiSelect } from '@/components/ui/multi-select';
import {
  Check,
  Edit,
  MoreVertical,
  SaveAllIcon,
  Trash2,
  Flag,
  Download,
  Settings,
  CalendarIcon,
} from 'lucide-react';
import { format } from 'date-fns';
import generateLink from '../utils/genericHelpers';
import RiskClassTag, { RiskClassType } from './RiskClassTag';
import StackEditor from './StackEditor';
import ProjectSettingsModal from './ProjectSettingsModal';
import ReportConcernDialog from './ReportConcernDialog';
import { Link } from 'react-router-dom';
import RemoteGitSyncForm from './RemoteGitSyncModal';
import ProjectGovernanceTabContent from './ProjectGovernanceTabContent';
import { Loading } from '../components/ui/loading';
import FriendlyId from '@/components/ui/friendly-id';
import {
  Stage,
  DataType,
  Project,
  ItAsset,
  GetAllUsersForSelectQuery,
  ProjectDetailDrawerQueryQuery,
  GetCurrentUserForConcernReportQuery,
  RemoteGitSync,
  ProjectGovernanceFrameworkTemplate,
  Capability,
  DataModality,
  Department,
} from '../__generated__/gql/graphql';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { GET_PROJECTS } from './ProjectView';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Calendar } from '@/components/ui/calendar';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { formatEnumValue } from '@/utils/formatEnumUtils';
import { Badge } from '@/components/ui/badge';

const DELETE_PROJECT = gql`
  mutation deleteProject($projectId: String!) {
    deleteProject(projectId: $projectId) {
      id
      remainingProjects {
        id
        title
        description
        dataModalities {
          id
          name
        }
        itAssets {
          id
          name
        }
        departments {
          id
          name
        }
        isPurchased
        riskClass {
          riskClassCategory
          riskClassRole
        }
        stage
        responsible {
          id
          name
          email
          initials
        }
      }
    }
  }
`;

const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateProject(
    $description: String
    $intendedUseCases: [String]
    $limitations: [String]
    $projectId: String!
    $title: String
    $unintendedUseCases: [String]
    $departments: [String]
    $dataSource: String
    $stage: String
    $risks: [RisksInput]
    $usagePolicy: String
    $responsiblePerson: String
    $riskClass: RiskClassInput
    $targetQueryMutation: String!
    $action: String!
    $dataType: DataType
    $users: String
    $itAssets: [String!]
    $operationEntryDate: DateTime
    $startGuide: String
    $capabilities: [Capability!]
    $dataModalities: [String!]
  ) {
    updateProject(
      description: $description
      intendedUseCases: $intendedUseCases
      limitations: $limitations
      projectId: $projectId
      title: $title
      unintendedUseCases: $unintendedUseCases
      departments: $departments
      dataSource: $dataSource
      stage: $stage
      risks: $risks
      usagePolicy: $usagePolicy
      responsiblePerson: $responsiblePerson
      riskClass: $riskClass
      dataType: $dataType
      users: $users
      itAssets: $itAssets
      operationEntryDate: $operationEntryDate
      startGuide: $startGuide
      capabilities: $capabilities
      dataModalities: $dataModalities
    ) {
      __typename
      project {
        __typename
        stage
        id
        isPurchased
        projectType
        vendor
        riskClass {
          __typename
          riskClassRole
          riskClassCategory
          riskClassSpecialCase
        }
        capabilities
        title
        limitations
        startGuide
        description
        intendedUseCases
        remoteGitSync {
          branch
          publicDeploymentKey
          id
          lastSyncedAt
          repoName
          repoUrl
          syncIntervalMinutes
        }
        responsible {
          __typename
          id
          name
          email
          initials
        }
        numberOfDocumentations
        numberOfExperiments
        risks {
          __typename
          impact
          likelihood
          mitigation
          riskDescription
        }
        projectGovernanceFrameworks {
          __typename
          id
          name
        }
        recommendedGovernanceFrameworks {
          __typename
          id
          name
        }
        mostRecentExperiment {
          __typename
          instanceRuns {
            __typename
            metrics
          }
        }

        departments {
          id
          name
        }
        dataModalities {
          id
          name
        }
        itAssets {
          id
          name
        }
        dataSource
        dataType
        users
        operationEntryDate
        usagePolicy
        checkPermission(
          targetQueryMutation: $targetQueryMutation
          action: $action
        ) {
          hasPermission
          userId
        }
      }
    }
  }
`;

export const GET_PROJECT = gql`
  query ProjectDetailDrawerQuery(
    $projectId: String!
    $targetQueryMutation: String!
    $action: String!
  ) {
    project(id: $projectId) {
      __typename
      title
      limitations
      description
      intendedUseCases
      isPurchased
      projectType
      vendor
      riskClass {
        __typename
        riskClassCategory
        riskClassRole
        riskClassSpecialCase
      }
      capabilities
      remoteGitSync {
        __typename
        branch
        publicDeploymentKey
        id
        lastSyncedAt
        repoName
        repoUrl
        syncIntervalMinutes
      }
      responsible {
        __typename
        id
        name
        email
        initials
      }
      numberOfDocumentations
      numberOfExperiments
      risks {
        __typename
        impact
        likelihood
        mitigation
        riskDescription
      }
      projectGovernanceFrameworks {
        id
        name
      }
      recommendedGovernanceFrameworks {
        id
        name
        isCustom
        templateId
      }
      mostRecentExperiment {
        instanceRuns {
          metrics
        }
      }
      dataType
      users
      itAssets {
        id
        name
      }
      operationEntryDate
      stage
      departments {
        id
        name
      }
      dataSource
      dataModalities {
        id
        name
      }
      usagePolicy
      startGuide
      checkPermission(
        targetQueryMutation: $targetQueryMutation
        action: $action
      ) {
        hasPermission
        userId
      }
    }
  }
`;

const GET_CURRENT_USER = gql`
  query GetCurrentUserForConcernReport {
    currentUser {
      email
      name
      initials
    }
  }
`;

const GET_USERS = gql`
  query GetAllUsersForSelect {
    allUsers {
      id
      email
      initials
      name
    }
  }
`;

const DELETE_PROJECT_GOVERNANCE_FRAMEWORK = gql`
  mutation DeleteProjectGovernanceFramework(
    $projectId: String!
    $materializedFrameworkId: String!
  ) {
    deleteProjectGovernanceFramework(
      projectId: $projectId
      materializedFrameworkId: $materializedFrameworkId
    ) {
      project {
        id
        projectGovernanceFrameworks {
          __typename
          id
          name
        }
        recommendedGovernanceFrameworks {
          __typename
          id
          name
        }
      }
    }
  }
`;

export interface ProjectDetailDrawerProps {
  showSidebar: boolean;
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  selectedProject: Project;
  setProject: (project: Project | undefined) => void;
  allItAssets: NonNullable<ItAsset>[];
  allDataModalities: NonNullable<DataModality>[];
  allDepartments: NonNullable<Department>[];
}

const TAB_VALUES = {
  USER: 'user',
  DEV: 'dev',
  GOV: 'gov',
} as const;

type TabValue = (typeof TAB_VALUES)[keyof typeof TAB_VALUES];

export default function ProjectDetailDrawer({
  setShowSidebar,
  selectedProject,
  setProject,
  allItAssets,
  allDataModalities,
  allDepartments,
}: ProjectDetailDrawerProps) {
  const { id: projectId } = selectedProject;
  const client = useApolloClient();

  const { data: drawerDetail } =
    useSuspenseQuery<ProjectDetailDrawerQueryQuery>(GET_PROJECT, {
      variables: {
        projectId,
        targetQueryMutation: 'deleteProjectGovernanceFramework',
        action: 'mutation',
      },
    });
  const { data: allUsersData } =
    useSuspenseQuery<GetAllUsersForSelectQuery>(GET_USERS);
  const { data: currentUserData } =
    useSuspenseQuery<GetCurrentUserForConcernReportQuery>(GET_CURRENT_USER);
  const [deleteProject] = useMutation(DELETE_PROJECT);
  const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION, {
    update: (cache, { data }) => {
      if (!data?.updateProject?.project) return;

      const updatedProject = data.updateProject.project;

      cache.writeQuery({
        query: GET_PROJECT,
        variables: {
          projectId,
          targetQueryMutation: 'deleteProjectGovernanceFramework',
          action: 'mutation',
        },
        data: {
          project: updatedProject,
        },
      });
    },
  });
  const [deleteProjectGovernanceFramework] = useMutation(
    DELETE_PROJECT_GOVERNANCE_FRAMEWORK
  );

  // State Management
  const [isEditing, setIsEditing] = useState(false);
  const [tab, setTab] = useState<TabValue>(TAB_VALUES.USER);
  const [projectDeleteDialogOpen, setProjectDeleteDialogOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [isGovernanceFrameworkModelOpen, setIsGovernanceFrameworkModelOpen] =
    useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [title, setTitle] = useState(drawerDetail?.project?.title ?? '');
  const [description, setDescription] = useState(
    drawerDetail?.project?.description ?? ''
  );
  const [responsiblePerson, setResponsiblePerson] = useState(
    drawerDetail?.project?.responsible?.id ?? ''
  );
  const [departments, setDepartments] = useState<string[]>(
    (drawerDetail?.project?.departments as Department[])?.map(
      dept => dept.name
    ) ?? []
  );
  const [stage, setStage] = useState<Stage>(
    drawerDetail?.project?.stage ?? Stage.Develop
  );
  const [dataType, setDataType] = useState<DataType | undefined>(
    drawerDetail?.project?.dataType ?? undefined
  );
  const [users, setUsers] = useState(drawerDetail?.project?.users ?? '');
  const [itAssets, setItAssets] = useState<string[]>(
    (drawerDetail?.project?.itAssets as ItAsset[])?.map(asset => asset.name) ??
      []
  );
  const [operationEntryDate, setOperationEntryDate] = useState<Date | null>(
    drawerDetail?.project?.operationEntryDate
      ? new Date(drawerDetail.project.operationEntryDate)
      : null
  );
  const [intendedUseCases, setIntendedUseCases] = useState<string[]>(
    drawerDetail?.project?.intendedUseCases ?? []
  );
  const [limitations, setLimitations] = useState<string[]>(
    drawerDetail?.project?.limitations ?? []
  );
  const [usagePolicy, setUsagePolicy] = useState(
    drawerDetail?.project?.usagePolicy ?? ''
  );
  const [dataSource, setDataSource] = useState(
    drawerDetail?.project?.dataSource ?? ''
  );
  const [riskClassRole, setRiskClassRole] = useState(
    drawerDetail?.project?.riskClass?.riskClassRole
  );
  const [riskClassCategory, setRiskClassCategory] = useState(
    drawerDetail?.project?.riskClass?.riskClassCategory
  );
  const [riskClassSpecialCase, setRiskClassSpecialCase] = useState(
    drawerDetail?.project?.riskClass?.riskClassSpecialCase
  );
  const [dataModalities, setDataModalities] = useState<string[]>(
    (drawerDetail?.project?.dataModalities as DataModality[])?.map(
      modality => modality.name
    ) ?? []
  );
  const [capabilities, setCapabilities] = useState<Capability[]>(
    drawerDetail?.project?.capabilities ?? []
  );
  const [startGuide, setStartGuide] = useState<string>(
    drawerDetail?.project?.startGuide ?? ''
  );

  const isPurchased = drawerDetail?.project?.isPurchased ?? false;
  const projectType = drawerDetail?.project?.projectType || 'Unknown';
  const vendor = drawerDetail?.project?.vendor || 'Unknown';
  const allUsers = allUsersData?.allUsers || [];
  const currentUser = currentUserData?.currentUser;

  // Handlers
  const handleSave = async () => {
    try {
      setIsUpdating(true);
      await updateProject({
        variables: {
          projectId,
          title,
          description,
          responsiblePerson,
          departments,
          stage,
          dataType,
          users,
          itAssets,
          operationEntryDate,
          intendedUseCases,
          limitations,
          usagePolicy,
          dataSource,
          riskClass: { riskClassRole, riskClassCategory, riskClassSpecialCase },
          capabilities,
          dataModalities,
          startGuide,
          targetQueryMutation: 'deleteProjectGovernanceFramework',
          action: 'mutation',
        },
      });
      setIsEditing(false);
      toast.success("Successfully updated project's details");
    } catch (error) {
      toast.error('Failed to update project');
      console.error(error);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDeleteProject = async () => {
    try {
      setIsDeleting(true);
      const { data } = await deleteProject({ variables: { projectId } });
      const remainingProjects = data?.deleteProject?.remainingProjects;
      client.cache.writeQuery({
        query: GET_PROJECTS,
        data: { allProjects: remainingProjects },
      });
      setShowSidebar(false);
      setProject(undefined);
      localStorage.removeItem('selectedProject');
    } catch (error) {
      console.error(error);
      toast.error('Failed to delete project');
    } finally {
      setIsDeleting(false);
      setProjectDeleteDialogOpen(false);
    }
  };

  const handleExport = async (format: string) => {
    try {
      const res = await generateLink(`export_project_${format}/${projectId}`);
      const data = await res.blob();
      const url = URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = `project_${projectId}.${format}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      toast.error('An error occurred while exporting the project.');
      console.error(error);
    }
  };
  const resetState = () => {
    setTitle(drawerDetail?.project?.title ?? '');
    setResponsiblePerson(drawerDetail!.project?.responsible?.id ?? '');
    setStage(drawerDetail?.project?.stage ?? Stage.Develop);
    setLimitations(drawerDetail?.project?.limitations ?? []);
    setIntendedUseCases(
      (drawerDetail?.project?.intendedUseCases as string[]) ?? []
    );
    setDataSource(drawerDetail?.project?.dataSource ?? '');
    setDescription(drawerDetail?.project?.description ?? '');
    setUsagePolicy(drawerDetail?.project?.usagePolicy ?? '');
    setRiskClassCategory(
      drawerDetail?.project?.riskClass?.riskClassCategory ?? undefined
    );
    setRiskClassRole(
      drawerDetail?.project?.riskClass?.riskClassRole ?? undefined
    );
    setRiskClassSpecialCase(
      drawerDetail?.project?.riskClass?.riskClassSpecialCase ?? undefined
    );
    setDataType(drawerDetail?.project?.dataType ?? undefined);
    setUsers(drawerDetail?.project?.users ?? '');
    setOperationEntryDate(
      drawerDetail?.project?.operationEntryDate
        ? new Date(drawerDetail.project.operationEntryDate)
        : null
    );
  };

  return (
    <div className="w-full h-full flex flex-col text-foreground">
      {/* Header Section */}
      <div className="p-4 flex justify-between items-center border-b gap-4 ">
        <div className="flex flex-col gap-2">
          {isEditing ? (
            <Input
              value={title}
              onChange={e => setTitle(e.target.value)}
              className="text-2xl font-bold w-96"
            />
          ) : (
            <h2 className="truncate">{title}</h2>
          )}
          <div className="">
            <div className="flex items-center gap-2 text-sm text-muted-foreground">
              Project ID: <FriendlyId id={projectId} subtle />
            </div>
            <div className="flex items-center gap-2">
              <Label className="text-sm text-muted-foreground">
                Entry Date
              </Label>
              {isEditing ? (
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant="outline">
                      {operationEntryDate
                        ? format(operationEntryDate, 'PPP')
                        : 'Pick a date'}
                      <CalendarIcon className="ml-2 h-4 w-4" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <Calendar
                      mode="single"
                      selected={operationEntryDate ?? undefined}
                      onSelect={date => setOperationEntryDate(date ?? null)}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
              ) : (
                <p>
                  {operationEntryDate
                    ? format(operationEntryDate, 'PPP')
                    : 'N/A'}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          {!isEditing && (
            <Button
              variant="outline"
              onClick={() => {
                setProject(selectedProject);
                setShowSidebar(false);
              }}
            >
              <Check className="mr-2 h-4 w-4" />
              Select as current project
            </Button>
          )}
          <Button
            variant="outline"
            onClick={() => {
              if (isEditing) resetState();
              setIsEditing(!isEditing);
            }}
          >
            <Edit className="mr-2 h-4 w-4" />
            {isEditing ? 'Cancel' : 'Edit'}
          </Button>
          {isEditing && (
            <Button
              variant="outline"
              onClick={handleSave}
              disabled={isUpdating}
            >
              <SaveAllIcon className="mr-2 h-4 w-4" />
              Save
            </Button>
          )}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                <MoreVertical className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem
                onClick={() => setProjectDeleteDialogOpen(true)}
              >
                <Trash2 className="mr-2 h-4 w-4" />
                Delete Project
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => setOpenReportDialog(true)}>
                <Flag className="mr-2 h-4 w-4" />
                Report a Concern
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => handleExport('json')}>
                <Download className="mr-2 h-4 w-4" />
                Export as .json
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => setIsSettingsModalOpen(true)}>
                <Settings className="mr-2 h-4 w-4" />
                Settings
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      {/* Tabs */}
      <Tabs
        value={tab}
        onValueChange={value => setTab(value as TabValue)}
        className="flex-1 pt-4"
      >
        <TabsList
          className={`grid w-full ${
            isPurchased ? 'grid-cols-2' : 'grid-cols-3'
          }`}
        >
          <TabsTrigger value="user">User Facing</TabsTrigger>
          {!isPurchased && <TabsTrigger value="dev">Development</TabsTrigger>}
          <TabsTrigger value="gov">Project Governance</TabsTrigger>
        </TabsList>
        {/* User Facing Tab */}
        <TabsContent value="user" className="p-4 overflow-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Project Information */}
            <div className="bg-dark-blue-400 p-5 rounded-lg">
              <h4 className="border-b-[0.5px] pb-2 mb-4">
                Project Information
              </h4>
              <div className="grid grid-cols-1 gap-6">
                {/* Stage */}
                <div className="grid grid-cols-[120px_1fr] gap-4 items-center border-b border-gray-600 pb-4">
                  <Label>Stage</Label>
                  <Select
                    value={stage}
                    onValueChange={value => setStage(value as Stage)}
                    disabled={!isEditing}
                  >
                    <SelectTrigger disabled={!isEditing}>
                      <SelectValue placeholder="Select stage" />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.values(Stage).map(stageValue => (
                        <SelectItem key={stageValue} value={stageValue}>
                          {formatEnumValue(stageValue)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                {/* Service & Vendor */}
                <div className="grid grid-cols-[120px_1fr] gap-4 items-center border-b border-gray-600 pb-4">
                  <Label>Service & Vendor</Label>
                  <div className="flex flex-col pl-3">
                    <p className="text-sm">{formatEnumValue(projectType)}</p>
                    <p className="text-sm text-muted-foreground">
                      Vendor: {vendor}
                    </p>
                  </div>
                </div>

                {/* IT Asset */}
                <div className="grid grid-cols-[120px_1fr] gap-4 items-center border-b border-gray-600 pb-4">
                  <Label>IT Asset</Label>
                  <MultiSelect
                    options={allItAssets.map(asset => ({
                      value: asset.name,
                      label: asset.name,
                    }))}
                    selected={itAssets.map(asset => ({
                      value: asset,
                      label: asset,
                    }))}
                    onChange={selected =>
                      setItAssets(selected.map(s => s.value))
                    }
                    disabled={!isEditing}
                    onAddNew={newAsset => {
                      const newAssets = [...itAssets, newAsset];
                      setItAssets(newAssets);
                    }}
                  />
                </div>

                {/* Data Sensitivity */}
                <div className="grid grid-cols-[120px_1fr] gap-4 items-center">
                  <Label>Data Sensitivity</Label>
                  <Select
                    value={dataType || ''}
                    onValueChange={value => setDataType(value as DataType)}
                    disabled={!isEditing}
                  >
                    <SelectTrigger disabled={!isEditing}>
                      <SelectValue placeholder="Select data sensitivity" />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.values(DataType).map(type => (
                        <SelectItem key={type} value={type}>
                          {formatEnumValue(type)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>

            {/* Team & Department */}
            <div className="bg-dark-blue-400 p-5 rounded-lg">
              <h4 className="border-b-[0.5px] pb-2 mb-4">Team & Department</h4>
              <div className="grid grid-cols-1 gap-6">
                {/* Department */}
                <div className="grid grid-cols-[120px_1fr] gap-4 items-center border-b border-gray-600 pb-3">
                  <Label>Department</Label>
                  <MultiSelect
                    options={allDepartments.map(dept => ({
                      value: dept.name,
                      label: dept.name,
                    }))}
                    selected={departments.map(dept => ({
                      value: dept,
                      label: dept,
                    }))}
                    onChange={selected =>
                      setDepartments(selected.map(s => s.value))
                    }
                    disabled={!isEditing}
                    onAddNew={newDept => {
                      const newDepartments = [...departments, newDept];
                      setDepartments(newDepartments);
                    }}
                  />
                </div>

                {/* Responsible Person */}
                <div className="grid grid-cols-[120px_1fr] gap-4 items-center border-b border-gray-600 pb-4">
                  <Label>Responsible Person</Label>
                  <Select
                    value={responsiblePerson}
                    onValueChange={setResponsiblePerson}
                    disabled={!isEditing}
                  >
                    <SelectTrigger disabled={!isEditing}>
                      <SelectValue placeholder="Select responsible person" />
                    </SelectTrigger>
                    <SelectContent>
                      {allUsers.map(user => (
                        <SelectItem key={user.id} value={user.id}>
                          {user.name || user.email}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                {/* Users */}
                <div className="grid grid-cols-[120px_1fr] gap-4 items-start">
                  <Label>Users</Label>
                  {isEditing ? (
                    <Textarea
                      value={users}
                      onChange={e => setUsers(e.target.value)}
                      className="mt-1"
                    />
                  ) : (
                    <div className="pl-3 whitespace-pre-line">
                      {users || 'N/A'}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Usage Information - Only show section if there's data or if editing mode is on */}
          {(isEditing ||
            dataModalities.length > 0 ||
            capabilities.length > 0 ||
            dataSource) && (
            <div className="bg-dark-blue-400 p-5 rounded-lg mt-6">
              <h4 className="border-b-[0.5px] pb-2 mb-4 flex items-center">
                Usage Information
              </h4>
              <div className="grid grid-cols-1 gap-6">
                {/* Data Modalities - Only show if field has values or editing mode is on */}
                {(isEditing || dataModalities.length > 0) && (
                  <div className="grid grid-cols-[120px_1fr] gap-4 border-b border-gray-600 pb-4 items-center">
                    <Label className="flex items-center">Data Modalities</Label>
                    {isEditing ? (
                      <MultiSelect
                        options={allDataModalities.map(modality => ({
                          value: modality.name,
                          label: modality.name,
                        }))}
                        selected={dataModalities.map(modality => ({
                          value: modality,
                          label: modality,
                        }))}
                        onChange={selected =>
                          setDataModalities(selected.map(s => s.value))
                        }
                        placeholder="Select data modalities..."
                        onAddNew={newModality => {
                          const newModalities = [
                            ...dataModalities,
                            newModality,
                          ];
                          setDataModalities(newModalities);
                        }}
                      />
                    ) : (
                      <div className="pl-3 flex flex-wrap gap-2">
                        {dataModalities.length > 0
                          ? dataModalities.map(modality => (
                              <Badge variant={'outline'}>{modality}</Badge>
                            ))
                          : 'N/A'}
                      </div>
                    )}
                  </div>
                )}

                {/* Capabilities - Only show if field has values or editing mode is on */}
                {(isEditing || capabilities.length > 0) && (
                  <div className="grid grid-cols-[120px_1fr] gap-4 border-b border-gray-600 pb-4 items-center">
                    <Label className="flex items-center">Capabilities</Label>
                    {isEditing ? (
                      <MultiSelect
                        options={Object.values(Capability).map(value => ({
                          value,
                          label: formatEnumValue(value),
                        }))}
                        selected={capabilities.map(cap => ({
                          value: cap,
                          label: formatEnumValue(cap),
                        }))}
                        onChange={selected =>
                          setCapabilities(
                            selected.map(s => s.value as Capability)
                          )
                        }
                        placeholder="Select capabilities..."
                      />
                    ) : (
                      <div className="pl-3 flex flex-wrap gap-2">
                        {capabilities.length > 0
                          ? capabilities.map(cap => (
                              <Badge variant={'outline'}>
                                {formatEnumValue(cap)}
                              </Badge>
                            ))
                          : 'N/A'}
                      </div>
                    )}
                  </div>
                )}

                {/* Data Source - Only show if field has value or editing mode is on */}
                {(isEditing || dataSource) && (
                  <div className="grid grid-cols-[120px_1fr] gap-4 items-center">
                    <Label className="flex items-center">Data Source</Label>
                    {isEditing ? (
                      <Textarea
                        value={dataSource}
                        onChange={e => setDataSource(e.target.value)}
                        className="mt-1"
                        rows={4}
                      />
                    ) : (
                      <div className="pl-3 whitespace-pre-line">
                        {dataSource || 'N/A'}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {/* EU AI ACT */}
          <div className="mt-6 flex flex-col gap-12 bg-dark-blue-400 p-4 rounded-lg">
            <div className="flex flex-col">
              <div className="border-b pb-2 mb-4">
                <h4>EU AI Act</h4>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="flex flex-col gap-2">
                  <Label>Role</Label>
                  <RiskClassTag
                    riskClassRole={riskClassRole}
                    type={RiskClassType.ROLE}
                    isEditable={isEditing}
                    setRiskClassRole={setRiskClassRole}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <Label>Risk Class</Label>
                  <RiskClassTag
                    riskClassCategory={riskClassCategory ?? undefined}
                    riskClassRole={riskClassRole}
                    type={RiskClassType.CATEGORY}
                    isEditable={isEditing}
                    setRiskClassCategory={setRiskClassCategory}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <Label>GPAI Model</Label>
                  <RiskClassTag
                    riskClassRole={riskClassRole}
                    riskClassSpecialCase={riskClassSpecialCase ?? undefined}
                    type={RiskClassType.SPECIAL_CASE}
                    isEditable={isEditing}
                    setRiskClassSpecialCase={setRiskClassSpecialCase}
                  />
                </div>
              </div>
            </div>
            {isEditing && (
              <Button variant="outline" className="w-full">
                <Link to={`/risk_classification/${projectId}`}>
                  {riskClassRole
                    ? 'Redo Classification Questionnaire'
                    : 'Do Classification Questionnaire'}
                </Link>
              </Button>
            )}
          </div>
          {/* Usage Information - Only show section if there's data or if editing mode is on */}
          {(isEditing ||
            description ||
            startGuide ||
            (intendedUseCases && intendedUseCases.length > 0) ||
            (limitations && limitations.length > 0) ||
            usagePolicy) && (
            <div className="bg-dark-blue-400 p-5 rounded-lg mt-6">
              <h4 className="border-b-[0.5px] pb-2 mb-4 flex items-center">
                Usage Information
              </h4>
              <div className="grid grid-cols-1 gap-6">
                {/* Description - Only show if field has value or editing mode is on */}
                {(isEditing || description) && (
                  <div className="grid grid-cols-[120px_1fr] gap-4 border-b border-gray-600 pb-4 items-center">
                    <Label className="flex items-center">Description</Label>
                    {isEditing ? (
                      <Textarea
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        className="mt-1"
                        rows={4}
                      />
                    ) : (
                      <div className="pl-3 whitespace-pre-line flex items-center">
                        {description}
                      </div>
                    )}
                  </div>
                )}

                {/* Start Guide - Only show if field has value or editing mode is on */}
                {(isEditing || startGuide) && (
                  <div className="grid grid-cols-[120px_1fr] gap-4 border-b border-gray-600 pb-4 items-center">
                    <Label className="flex items-center">
                      How to get started
                    </Label>
                    {isEditing ? (
                      <Textarea
                        value={startGuide}
                        onChange={e => setStartGuide(e.target.value)}
                        className="mt-1"
                        rows={4}
                      />
                    ) : (
                      <div className="pl-3 whitespace-pre-line flex items-center">
                        {startGuide}
                      </div>
                    )}
                  </div>
                )}

                {/* Intended Use Cases - Only show if field has values or editing mode is on */}
                {(isEditing ||
                  (intendedUseCases && intendedUseCases.length > 0)) && (
                  <div className="grid grid-cols-[120px_1fr] gap-4 border-b border-gray-600 pb-4 items-center">
                    <Label className="flex items-center">
                      Intended Use Cases
                    </Label>
                    <StackEditor
                      stacks={intendedUseCases}
                      isEditable={isEditing}
                      setStacks={setIntendedUseCases}
                    />
                  </div>
                )}

                {/* Limitations - Only show if field has values or editing mode is on */}
                {(isEditing || (limitations && limitations.length > 0)) && (
                  <div className="grid grid-cols-[120px_1fr] gap-4 border-b border-gray-600 pb-4 items-center">
                    <Label className="flex items-center">Limitations</Label>
                    <StackEditor
                      stacks={limitations}
                      isEditable={isEditing}
                      setStacks={setLimitations}
                    />
                  </div>
                )}

                {/* Usage Policy - Only show if field has value or editing mode is on */}
                {(isEditing || usagePolicy) && (
                  <div className="grid grid-cols-[120px_1fr] gap-4 items-center">
                    <Label className="flex items-center">Usage Policy</Label>
                    {isEditing ? (
                      <Textarea
                        value={usagePolicy}
                        onChange={e => setUsagePolicy(e.target.value)}
                        className="mt-1"
                        rows={4}
                      />
                    ) : (
                      <div className="pl-3 pt-2 whitespace-pre-line flex items-center">
                        {usagePolicy}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </TabsContent>

        {/* Development Tab */}
        {!isPurchased && (
          <TabsContent value="dev" className="p-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <h4>Stage</h4>
                <Select
                  value={stage}
                  onValueChange={value => setStage(value as Stage)}
                  disabled={!isEditing}
                >
                  <SelectTrigger disabled={!isEditing}>
                    <SelectValue placeholder="Select stage" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.values(Stage).map(stageValue => (
                      <SelectItem key={stageValue} value={stageValue}>
                        {stageValue}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-4">
                <h4>Last Experiment</h4>
                <p className="text-2xl">
                  {drawerDetail?.project?.mostRecentExperiment
                    ?.instanceRuns?.[0]?.metrics
                    ? (Object.values(
                        drawerDetail.project.mostRecentExperiment
                          .instanceRuns[0].metrics
                      )[0] as string)
                    : 'N/A'}
                </p>
              </div>
              <div className="space-y-4">
                <h4>Documentation</h4>
                <p className="text-2xl">
                  {drawerDetail?.project?.numberOfDocumentations ?? 0}
                </p>
                <Button variant="outline">
                  <Link to={`/documentation/${projectId}`}>
                    View Documentation
                  </Link>
                </Button>
              </div>
              <div className="space-y-4">
                <h4>Audit Trail</h4>
                <p className="text-2xl">
                  {drawerDetail?.project?.numberOfExperiments ?? 0}
                </p>
                <Button variant="outline">
                  <Link to={`/trail_graph/${projectId}/`}>
                    View Audit Trail
                  </Link>
                </Button>
              </div>
              <div className="space-y-4">
                <h4>Git Integration</h4>
                <RemoteGitSyncForm
                  projectId={projectId}
                  initialData={
                    drawerDetail?.project?.remoteGitSync as RemoteGitSync
                  }
                />
              </div>
            </div>
          </TabsContent>
        )}

        {/* Project Governance Tab */}
        <TabsContent value="gov" className="p-4">
          <Suspense
            fallback={<Loading message="Loading governance frameworks..." />}
          >
            <ProjectGovernanceTabContent
              projectId={projectId}
              pgfDeletePerms={
                drawerDetail?.project?.checkPermission?.hasPermission ?? false
              }
              isDeletingFramework={false}
              setDeleteConfirmOpen={setDeleteConfirmOpen}
              deleteConfirmOpen={deleteConfirmOpen}
              confirmDelete={(materializedFrameworkId, projId) =>
                deleteProjectGovernanceFramework({
                  variables: { projectId: projId, materializedFrameworkId },
                })
              }
              recommendedGovernanceFrameworks={
                (drawerDetail?.project
                  ?.recommendedGovernanceFrameworks as Array<ProjectGovernanceFrameworkTemplate>) ??
                []
              }
              setIsGovernanceFrameworkModelOpen={
                setIsGovernanceFrameworkModelOpen
              }
              isGovernanceFrameworkModelOpen={isGovernanceFrameworkModelOpen}
            />
          </Suspense>
        </TabsContent>
      </Tabs>

      <Dialog
        open={projectDeleteDialogOpen}
        onOpenChange={setProjectDeleteDialogOpen}
      >
        <DialogContent className="w-1/2">
          <DialogHeader>
            <DialogTitle>Confirm Project Deletion</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this project? This will delete all
              experiments, documentations, and project governance frameworks
              associated with this project. This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setProjectDeleteDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={handleDeleteProject}
              disabled={isDeleting}
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <ProjectSettingsModal
        open={isSettingsModalOpen}
        onClose={() => setIsSettingsModalOpen(false)}
        projectId={projectId}
      />
      <ReportConcernDialog
        open={openReportDialog}
        getModelName={title}
        getPerson={currentUser?.email || ''}
        onClose={() => setOpenReportDialog(false)}
      />
    </div>
  );
}

import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'sonner';
import { GET_DETAILS } from '../DashboardDetails';

const EDIT_LIM = gql(`
mutation update_project_limitations($projectId: String!, $limitations: [String!]!) {
  updateProject(projectId: $projectId, limitations: $limitations) {
  project {
    id
  }
  }
}
`);

interface Props {
  limitations: string[];
  projectId: string;
  onClose: () => void;
}
export default function EditLimitations({
  limitations,
  projectId,
  onClose,
}: Props) {
  const [commitLimitationChange] = useMutation(EDIT_LIM, {
    refetchQueries: [{ query: GET_DETAILS, variables: { projectId } }],
  });
  const [projectLimitations, setProjectLimitations] = useState(limitations);
  const handleNewLimitation = () => {
    setProjectLimitations([...projectLimitations, '']);
  };
  const handleDeleteLimitation = (index: number) => {
    setProjectLimitations([
      ...projectLimitations.slice(0, index),

      ...projectLimitations.slice(index + 1),
    ]);
  };
  const handleLimitationChange = (event: string, index: number) => {
    setProjectLimitations([
      ...projectLimitations.slice(0, index),
      event,
      ...projectLimitations.slice(index + 1),
    ]);
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="body2" color="text.secondary">
            Limitations
          </Typography>
          <Button
            variant="outlined"
            endIcon={<AddBoxIcon sx={{ color: 'inherit' }} />}
            onClick={handleNewLimitation}
          >
            NEW
          </Button>
        </Box>
        {projectLimitations.length === 0 && (
          <>
            {' '}
            <br />
            <Typography variant="body2" color="text.secondary">
              Please use the button on top right to add a new limitation.
            </Typography>
            <br />
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            justifyContent: 'space-between',
          }}
        >
          {projectLimitations.map((limitation: any, index: number) => (
            <>
              <Box
                display="flex"
                flexDirection="row"
                paddingX="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={limitation}
                  sx={{ width: '60%' }}
                  multiline
                  variant="standard"
                  onChange={e => handleLimitationChange(e.target.value, index)}
                />
                <IconButton
                  onClick={() => handleDeleteLimitation(index)}
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </>
          ))}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Button sx={{ color: 'red' }} onClick={onClose}>
              Discard
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                commitLimitationChange({
                  variables: {
                    projectId,
                    limitation: projectLimitations,
                  },
                  onCompleted: () => {
                    onClose();
                  },
                  onError: () => {
                    toast.error('Error saving changes.');
                  },
                });
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

import { atom, useResetRecoilState } from 'recoil';
import { DocumentationTemplate } from './tabs/template/DocumentationTemplates';
import {
  ComponentCategory,
  DataSource,
  DocumentationQuery,
  DocumentComponentType,
} from '../../__generated__/gql/graphql';

export interface DocumentationEntry {
  id: string;
  type: DocumentComponentType;
  name?: string;
  query?: string;
  args?: (string | null)[];
  dataSource?: DataSource;
  category?: ComponentCategory;
  customArguments?: Record<string, any>;
}

export const documentTitleAtom = atom<string>({
  key: 'documentTitleAtom',
  default: 'New Document',
});

export const projectIdAtom = atom<string>({
  key: 'projectIdAtom',
  default: '',
});

export const experimentIdAtom = atom<string>({
  key: 'experimentIdAtom',
  default: '',
});

export const documentIdAtom = atom<string>({
  key: 'documentIdAtom',
  default: '',
});

export const documentComponentsAtom = atom<DocumentationEntry[]>({
  key: 'documentComponents',
  default: [],
});

export const majorVersionAtom = atom<number>({
  key: 'majorVersion',
  default: 1,
});

export const editableFileIdAtom = atom<string>({
  key: 'editableFileId',
  default: '',
});

export const documentTemplateAtom = atom<DocumentationTemplate | null>({
  key: 'documentTemplate',
  default: null,
});

export const useResetDocumentState = () => {
  const resetDocumentId = useResetRecoilState(documentIdAtom);
  const resetDocumentComponents = useResetRecoilState(documentComponentsAtom);
  const resetMajorVersion = useResetRecoilState(majorVersionAtom);
  const resetEditableFileId = useResetRecoilState(editableFileIdAtom);
  const resetDocumentTemplate = useResetRecoilState(documentTemplateAtom);
  const resetDocumentTitle = useResetRecoilState(documentTitleAtom);
  const resetProjectId = useResetRecoilState(projectIdAtom);
  const resetExperimentId = useResetRecoilState(experimentIdAtom);

  const resetAll = () => {
    resetDocumentTitle();
    resetDocumentId();
    resetDocumentComponents();
    resetMajorVersion();
    resetEditableFileId();
    resetDocumentTemplate();
    resetProjectId();
    resetExperimentId();
  };

  return resetAll;
};

export const initializeTemplateFromDocument = (
  documentData: DocumentationQuery['documentation'],
  setDocumentTemplate: (template: DocumentationTemplate) => void
) => {
  if (documentData?.documentationType && documentData?.documentationScope) {
    const template: DocumentationTemplate = {
      id: parseInt(documentData.id),
      title: documentData.name || '',
      description: '',
      documentationType: documentData.documentationType,
      documentationScope: documentData.documentationScope,
      components: (documentData.components || [])
        .filter(
          (component): component is NonNullable<typeof component> =>
            component !== null && component !== undefined
        )
        .map(component => {
          const comp = component;
          return {
            componentType: component.type,
            name: component.name || '',
            query: component.query || '',
            dataSource: component.dataSource || DataSource.Code,
            args: component.args || [],
            category: component.category,
            customArguments: comp.customArguments
              ? typeof comp.customArguments === 'string'
                ? JSON.parse(comp.customArguments)
                : comp.customArguments
              : undefined,
          };
        }),
    };
    setDocumentTemplate(template);
  }
};

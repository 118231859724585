import { Controller, useFormContext } from 'react-hook-form';
import {
  DataType,
  RiskClass,
  UsersBasicInfoQuery,
  ItAsset,
  RiskClassCategory,
  RiskClassRole,
  RiskClassSpecialCase,
  Stage,
  Capability,
  DataModality,
} from '../../__generated__/gql/graphql';
import { DateTime } from 'luxon';

import {
  ChevronDownIcon,
  ChevronUpIcon,
  SettingsIcon,
  ArrowRight,
  Info,
} from 'lucide-react';
import { Button } from '../../components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { MultiSelect } from '@/components/ui/multi-select';
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { formatEnumValue } from '@/utils/formatEnumUtils';
import { FormValues } from './ProjectCreationModal.react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@/components/ui/popover';
import { Calendar } from '@/components/ui/calendar';
import RiskClassTag, { RiskClassType } from '../RiskClassTag';
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from '@/components/ui/tooltip';
import StackEditor from '../StackEditor';

interface ModelSpesificationStageProps {
  usersData: UsersBasicInfoQuery;
  departmentsData: string[];
  riskClass?: RiskClass;
  allItAssets: ItAsset[];
  onNextRiskClassQuestionnaire: () => void;
  setShowAdvancedFields: (value: boolean) => void;
  showAdvancedFields: boolean;
  dataModalitiesData: DataModality[];
}

export default function ModelSpesificationStage({
  usersData,
  departmentsData,
  allItAssets,
  onNextRiskClassQuestionnaire,
  showAdvancedFields,
  setShowAdvancedFields,
  dataModalitiesData,
}: ModelSpesificationStageProps) {
  const { register, control, getValues, setValue, watch } =
    useFormContext<FormValues>();
  const users = usersData.allUsers || [];
  const riskClassCategory = watch('riskClassCategory');
  const riskClassRole = watch('riskClassRole');
  const riskClassSpecialCase = watch('riskClassSpecialCase');

  // Function to handle risk class field changes
  const handleRiskClassFieldChange = (
    fieldName: 'riskClassRole' | 'riskClassCategory' | 'riskClassSpecialCase',
    value: string
  ) => {
    setValue(fieldName, value);
  };

  return (
    <div
      className={`w-full xl:max-w-6xl max-w-5xl mx-auto p-4 ${
        showAdvancedFields ? '' : 'pr-6'
      }`}
    >
      <div className="space-y-8">
        {/* Project Overview Section */}
        <section className="space-y-4">
          <div className="border-b pb-2">
            <h4>Project Overview</h4>
          </div>
          <div className="space-y-4">
            <div>
              <Label htmlFor="title">Title *</Label>
              <Input
                id="title"
                placeholder="Title of the project"
                className="mt-1.5"
                required
                {...register('title')}
              />
            </div>
            <div>
              <Label htmlFor="description">Description *</Label>
              <Textarea
                id="description"
                placeholder="Provide a detailed description of the project"
                rows={4}
                className="mt-1.5"
                required
                {...register('description')}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <Label htmlFor="responsiblePerson">Responsible Person *</Label>
              <Controller
                control={control}
                name="responsiblePerson"
                render={({ field: { value, onChange } }) => (
                  <Select value={value} onValueChange={onChange}>
                    <SelectTrigger className="mt-2">
                      <SelectValue placeholder="Select a person" />
                    </SelectTrigger>
                    <SelectContent>
                      {users.map(
                        user =>
                          user && (
                            <SelectItem key={user.id} value={user.id}>
                              {user.name || user.email}
                            </SelectItem>
                          )
                      )}
                    </SelectContent>
                  </Select>
                )}
              />
            </div>
            <div>
              <Label htmlFor="departments">Responsible Departments *</Label>
              <Controller
                control={control}
                name="departments"
                rules={{ required: 'At least one department is required' }}
                render={({ field, fieldState }) => (
                  <>
                    <div className="flex gap-2 items-start">
                      <div className="flex-grow">
                        <MultiSelect
                          options={departmentsData.map(dept => ({
                            value: dept,
                            label: dept,
                          }))}
                          selected={(field.value || []).map(dept => ({
                            value: dept,
                            label: dept,
                          }))}
                          onChange={selected => {
                            field.onChange(selected.map(s => s.value));
                          }}
                          placeholder="Select or Add new departments..."
                          onAddNew={newDept => {
                            const currentDepartments =
                              getValues('departments') || [];
                            const newDepartments = [
                              ...currentDepartments,
                              newDept,
                            ];
                            setValue('departments', newDepartments);
                          }}
                        />
                      </div>
                    </div>
                    {fieldState.error && (
                      <p className="mt-1 text-sm text-red-500">
                        {fieldState.error.message}
                      </p>
                    )}
                  </>
                )}
              />
            </div>
          </div>
        </section>

        {/* Advanced Fields Toggle */}
        <div className="flex items-center justify-center mt-12 mb-8">
          <Button
            variant="outline"
            size="sm"
            className="w-auto flex items-center justify-center gap-2 px-4 py-2 border-dashed"
            onClick={() => setShowAdvancedFields(!showAdvancedFields)}
          >
            <SettingsIcon className="h-4 w-4 text-slate-500" />
            <span className="font-medium text-sm">
              {showAdvancedFields
                ? 'Hide Advanced Options'
                : 'Show Advanced Options'}
            </span>
            {showAdvancedFields ? (
              <ChevronUpIcon className="h-4 w-4 ml-1 text-slate-500" />
            ) : (
              <ChevronDownIcon className="h-4 w-4 ml-1 text-slate-500" />
            )}
          </Button>
        </div>

        {/* Advanced Fields Section */}
        {showAdvancedFields && (
          <div className="flex flex-col gap-14">
            {/* Project Management Section */}
            <section className="space-y-4">
              <div className="border-b pb-2">
                <h4>Project Management</h4>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <Controller
                  name="operationEntryDate"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <div className="flex flex-col gap-[10px] mt-1">
                      <div className="flex items-center gap-1">
                        <Label>Operation Entry Date</Label>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <span className="cursor-help">
                                <Info size={16} />
                              </span>
                            </TooltipTrigger>
                            <TooltipContent className="w-64">
                              <p>
                                This is the date the use case/system was put
                                into operation/production. This might already be
                                a date from the past or still open in the
                                future.
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant="outline"
                            className="w-full justify-start text-left font-normal"
                          >
                            {value
                              ? DateTime.fromJSDate(value).toFormat(
                                  'yyyy-MM-dd'
                                )
                              : 'Pick a date'}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent align="start" className="w-auto p-0">
                          <Calendar
                            mode="single"
                            selected={value ?? undefined}
                            onSelect={date => onChange(date)}
                          />
                        </PopoverContent>
                      </Popover>
                    </div>
                  )}
                />
                <div>
                  <Label htmlFor="stage">Stage</Label>
                  <Controller
                    control={control}
                    name="stage"
                    render={({ field: { value, onChange } }) => (
                      <Select value={value || ''} onValueChange={onChange}>
                        <SelectTrigger className="mt-1.5">
                          <SelectValue placeholder="Select a stage" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.values(Stage).map(stageValue => (
                            <SelectItem key={stageValue} value={stageValue}>
                              {formatEnumValue(stageValue)}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>
              </div>
            </section>

            {/* Technical Configuration Section */}
            <section className="space-y-4">
              <div className="border-b pb-2">
                <h4>Technical Configuration</h4>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {!getValues('isPurchased') && (
                  <>
                    <div>
                      <Label>Data Modality</Label>
                      <Controller
                        control={control}
                        name="dataModalities"
                        render={({ field }) => (
                          <MultiSelect
                            options={dataModalitiesData.map(modality => ({
                              value: modality.name,
                              label: modality.name,
                            }))}
                            selected={(field.value || []).map(value => ({
                              value,
                              label: value,
                            }))}
                            onChange={selected =>
                              field.onChange(selected.map(s => s.value))
                            }
                            placeholder="Select data types..."
                            onAddNew={newModality => {
                              const currentModalities =
                                getValues('dataModalities') || [];
                              const newModalities = [
                                ...currentModalities,
                                newModality,
                              ];
                              setValue('dataModalities', newModalities);
                            }}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <Label>Capability</Label>
                      <Controller
                        control={control}
                        name="capabilities"
                        render={({ field }) => (
                          <MultiSelect
                            options={Object.values(Capability).map(value => ({
                              value,
                              label: formatEnumValue(value),
                            }))}
                            selected={(field.value || []).map(value => ({
                              value,
                              label: formatEnumValue(value),
                            }))}
                            onChange={selected =>
                              field.onChange(
                                selected.map(s => s.value as Capability)
                              )
                            }
                            placeholder="Select capabilities..."
                          />
                        )}
                      />
                    </div>
                  </>
                )}
                <div>
                  <Label>IT Assets</Label>
                  <Controller
                    name="itAssets"
                    control={control}
                    render={({ field }) => (
                      <MultiSelect
                        options={allItAssets.map(asset => ({
                          value: asset.name ?? '',
                          label: asset.name ?? '',
                        }))}
                        selected={(field.value || []).map(value => ({
                          value,
                          label: value,
                        }))}
                        onChange={selected =>
                          field.onChange(selected.map(s => s.value))
                        }
                        placeholder="Select or add IT assets..."
                        onAddNew={newAsset => {
                          const currentAssets = getValues('itAssets') || [];
                          const newAssets = [...currentAssets, newAsset];
                          setValue('itAssets', newAssets);
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Label>Data Sensitivity</Label>
                  <Controller
                    name="dataType"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Select value={value} onValueChange={onChange}>
                        <SelectTrigger className="mt-2">
                          <SelectValue placeholder="Select a data sensitivity level" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.values(DataType).map(type => (
                            <SelectItem key={type} value={type}>
                              {formatEnumValue(type)}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>
                <div>
                  <Label htmlFor="dataSource">Data Source</Label>
                  <Textarea
                    id="dataSource"
                    rows={3}
                    placeholder="Describe the data source for this project"
                    className="mt-1.5"
                    {...register('dataSource')}
                  />
                </div>
              </div>
            </section>

            {/* Usage Information Section */}
            <section className="space-y-4">
              <div className="border-b pb-2">
                <h4>Usage Information</h4>
              </div>
              <div className="space-y-4">
                <div>
                  <Label htmlFor="intendedUseCases">Intended Use Cases</Label>
                  <Controller
                    control={control}
                    name="intendedUseCases"
                    defaultValue={[]}
                    render={({ field }) => (
                      <div className="mt-1.5">
                        <StackEditor
                          stacks={field.value || []}
                          isEditable={true}
                          setStacks={newUseCases => field.onChange(newUseCases)}
                        />
                        <div className="mt-2 text-xs text-slate-500">
                          Add specific use cases for this project, one per line.
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div>
                  <Label htmlFor="usagePolicy">Usage Policy</Label>
                  <Textarea
                    id="usagePolicy"
                    rows={3}
                    placeholder="Define the usage policy for this project"
                    className="mt-1.5"
                    {...register('usagePolicy')}
                  />
                </div>
                <div>
                  <Label htmlFor="howToGetStarted">How to Get Started</Label>
                  <Textarea
                    id="start_guide"
                    rows={3}
                    placeholder="Explain how users can get started with this project"
                    className="mt-1.5"
                    {...register('start_guide')}
                  />
                </div>
                <div>
                  <Label htmlFor="limitations">Limitations</Label>
                  <Controller
                    control={control}
                    name="limitations"
                    defaultValue={[]}
                    render={({ field }) => (
                      <div className="mt-1.5">
                        <StackEditor
                          stacks={field.value || []}
                          isEditable={true}
                          setStacks={newLimitations =>
                            field.onChange(newLimitations)
                          }
                        />
                        <div className="mt-2 text-xs text-slate-500">
                          Add specific limitations about what the model can't do
                          or where it might fail.
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div>
                  <Label htmlFor="users">Users</Label>
                  <Textarea
                    id="users"
                    placeholder="List the intended users here (can be selected people or whole teams)"
                    rows={2}
                    className="mt-1.5"
                    {...register('users')}
                  />
                </div>
              </div>
            </section>

            {/* EU AI Act Section */}
            <section className="space-y-6">
              <div className="border-b pb-2">
                <h4>EU AI Act</h4>
              </div>

              <div className="space-y-4">
                {/* Dropdowns for manual input */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div>
                    <Label htmlFor="riskClassRole">Role</Label>
                    <Controller
                      control={control}
                      name="riskClassRole"
                      render={({ field: { value } }) => (
                        <Select
                          value={value || ''}
                          onValueChange={newValue => {
                            handleRiskClassFieldChange(
                              'riskClassRole',
                              newValue
                            );
                          }}
                        >
                          <SelectTrigger className="mt-1.5">
                            <SelectValue placeholder="Select role" />
                          </SelectTrigger>
                          <SelectContent>
                            {Object.values(RiskClassRole).map(enumValue => (
                              <SelectItem key={enumValue} value={enumValue}>
                                {formatEnumValue(enumValue)}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}
                    />
                    {/* Warning message if other fields are selected but role is not */}
                    {!riskClassRole &&
                      (riskClassCategory || riskClassSpecialCase) && (
                        <p className="mt-1 text-xs text-grey-500">
                          Role is required when setting AI Act classification
                        </p>
                      )}
                  </div>
                  <div>
                    <Label htmlFor="riskClassCategory">Risk Class</Label>
                    <Controller
                      control={control}
                      name="riskClassCategory"
                      render={({ field: { value } }) => (
                        <Select
                          value={value || ''}
                          onValueChange={newValue => {
                            handleRiskClassFieldChange(
                              'riskClassCategory',
                              newValue
                            );
                          }}
                        >
                          <SelectTrigger className="mt-1.5">
                            <SelectValue placeholder="Select risk class" />
                          </SelectTrigger>
                          <SelectContent>
                            {Object.values(RiskClassCategory).map(enumValue => (
                              <SelectItem key={enumValue} value={enumValue}>
                                {formatEnumValue(enumValue)}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </div>
                  <div>
                    <Label htmlFor="riskClassSpecialCase">GPAI Model</Label>
                    <Controller
                      control={control}
                      name="riskClassSpecialCase"
                      render={({ field: { value } }) => (
                        <Select
                          value={value || ''}
                          onValueChange={newValue => {
                            handleRiskClassFieldChange(
                              'riskClassSpecialCase',
                              newValue
                            );
                          }}
                        >
                          <SelectTrigger className="mt-1.5">
                            <SelectValue placeholder="Select GPAI model" />
                          </SelectTrigger>
                          <SelectContent>
                            {Object.values(RiskClassSpecialCase).map(
                              enumValue => (
                                <SelectItem key={enumValue} value={enumValue}>
                                  {formatEnumValue(enumValue)}
                                </SelectItem>
                              )
                            )}
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </div>
                </div>
                {/* EU AI Act Classification Summary */}
                {(riskClassCategory ||
                  riskClassRole ||
                  riskClassSpecialCase) && (
                  <div className="mt-4 space-y-2">
                    <h6>Current Classification</h6>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div className="h-[80px]">
                        <RiskClassTag
                          riskClassRole={riskClassRole as RiskClassRole}
                          type={RiskClassType.ROLE}
                          isEditable={false}
                        />
                      </div>
                      <div className="h-[80px]">
                        <RiskClassTag
                          riskClassCategory={
                            riskClassCategory as RiskClassCategory
                          }
                          riskClassRole={riskClassRole as RiskClassRole}
                          type={RiskClassType.CATEGORY}
                          isEditable={false}
                        />
                      </div>
                      <div className="h-[80px]">
                        <RiskClassTag
                          riskClassRole={riskClassRole as RiskClassRole}
                          riskClassSpecialCase={
                            riskClassSpecialCase as RiskClassSpecialCase
                          }
                          type={RiskClassType.SPECIAL_CASE}
                          isEditable={false}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Option to use questionnaire instead */}
                <div className="flex flex-col gap-4 pt-4">
                  <Button
                    variant="outline"
                    onClick={onNextRiskClassQuestionnaire}
                  >
                    <ArrowRight className="mr-2 h-4 w-4" />
                    Start categorization questionnaire
                  </Button>
                  <p className="text-sm text-gray-500 pl-1">
                    Use the questionnaire to quickly identify the correct EU AI
                    Act classification for your project based on its specific
                    details.
                  </p>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </div>
  );
}

import { TextField, Typography, Checkbox, Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Controller, useFormContext } from 'react-hook-form';
import { AiDefinition } from '../__generated__/gql/graphql';
import {
  orgProfileHeaderTextStyle,
  orgProfileSubHeaderTextStyle,
  FormattedTooltip,
} from './OrganizationProfileFormatting';

export default function AIActivitiesStage() {
  const { register, watch, setValue } = useFormContext();

  const definitionOfAi = watch('definitionOfAi');

  const handleAIDefinitionChange = (type: string) => {
    if (type === 'OECD') {
      setValue('definitionOfAi.predefinedDefinition', AiDefinition.Oecd);
      setValue('definitionOfAi.customDefinition', '');
    } else if (type === 'NIST') {
      setValue('definitionOfAi.predefinedDefinition', AiDefinition.Nist);
      setValue('definitionOfAi.customDefinition', '');
    } else if (type === 'custom') {
      setValue('definitionOfAi.predefinedDefinition', null);
    }
  };

  const aiDefinitionLabels = [
    {
      type: 'OECD',
      text: "OECD's definition of AI (as seen in the EU AI Act)",
      tooltip: oecdDefinitionTooltipText,
      value: AiDefinition.Oecd,
    },
    {
      type: 'NIST',
      text: "NIST's definition of AI",
      tooltip: nistDefinitionTooltipText,
      value: AiDefinition.Nist,
    },
    {
      type: 'custom',
      text: 'Custom Definiton',
      tooltip: '',
      value: null,
    },
  ];

  const switchGroupLabels = [
    {
      name: 'aiUseCases.developFinetune',
      text: 'Developing and/or fine-tuning own AI systems',
    },
    {
      name: 'aiUseCases.selling',
      text: 'Selling or offering AI systems',
    },
    {
      name: 'aiUseCases.thirdParty',
      text: 'Using a third party AI system',
    },
    {
      name: 'aiUseCases.adaptingThirdParty',
      text: 'Adapting a third party AI system',
    },
  ];

  const purposeOfAIPolicyLabels = [
    {
      name: 'purposeOfAiPolicy.outlineUseCases',
      text: 'Outline acceptable use cases of AI in organisation ',
    },
    {
      name: 'purposeOfAiPolicy.governingDevelopment',
      text: 'Governing responsible development of AI ',
    },
    {
      name: 'purposeOfAiPolicy.promoteAiLiteracy',
      text: 'Promote AI literacy in organisation ',
    },
    {
      name: 'purposeOfAiPolicy.alignAiWithOrganisation',
      text: 'Aligning AI usage/principles with organisational values and reputation',
    },
    {
      name: 'purposeOfAiPolicy.safeAiUsage',
      text: 'Fostering responsible and safe AI usage',
    },
    {
      name: 'purposeOfAiPolicy.customPurpose',
      text: 'Custom',
    },
  ];

  return (
    <>
      <Typography variant="h4" {...orgProfileHeaderTextStyle}>
        Definition of AI
      </Typography>

      {aiDefinitionLabels.map((item, index) => (
        <Box key={`ai-def-${index}`}>
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <Checkbox
              onChange={() => handleAIDefinitionChange(item.type)}
              checked={definitionOfAi.predefinedDefinition === item.value}
            />
            <Typography sx={{ fontSize: '1rem' }}>{item.text}</Typography>
          </Box>
          {definitionOfAi.predefinedDefinition === item.value &&
            item.tooltip && (
              <Typography
                sx={{
                  fontSize: '0.9rem',
                  ml: 4,
                  mb: 1,
                  color: 'text.secondary',
                }}
              >
                {item.tooltip}
              </Typography>
            )}
        </Box>
      ))}

      {definitionOfAi.predefinedDefinition === null && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <TextField
            {...register('definitionOfAi.customDefinition')}
            label="Describe the custom definition..."
            fullWidth
            onChange={e =>
              setValue('definitionOfAi.customDefinition', e.target.value)
            }
          />
        </Box>
      )}
      <Typography variant="h4" {...orgProfileHeaderTextStyle}>
        AI Use Cases
      </Typography>
      {switchGroupLabels.map((item, index) => (
        <Box
          sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
          key={index + '_' + item.name}
        >
          <Controller
            name={item.name}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                onChange={e => onChange(e.target.checked)}
                checked={value}
              />
            )}
          />
          <Typography sx={{ fontSize: '1rem' }}>{item.text}</Typography>
        </Box>
      ))}
      <Typography variant="h4" {...orgProfileHeaderTextStyle}>
        What is the purpose and goal of this AI policy?
      </Typography>
      {purposeOfAIPolicyLabels.map((item, index) => (
        <Box
          sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
          key={index + '_' + item.name}
        >
          <Controller
            name={item.name}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                onChange={e => onChange(e.target.checked)}
                checked={value}
              />
            )}
          />
          <Typography sx={{ fontSize: '1rem' }}>{item.text}</Typography>
        </Box>
      ))}

      {watch('purposeOfAiPolicy.customPurpose') === true && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <TextField
            {...register('purposeOfAiPolicy.customPurposeText')}
            label="Describe the purpose of this AI policy..."
            fullWidth
            multiline
            rows={4}
            onChange={e =>
              setValue('purposeOfAiPolicy.customPurposeText', e.target.value)
            }
          />
        </Box>
      )}
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Typography variant="h4" {...orgProfileHeaderTextStyle}>
          Applicability of AI Policy
        </Typography>
        <FormattedTooltip
          title={applicabilityOfAITooltipText}
          sx={{ marginBottom: '10px' }}
        >
          <InfoOutlinedIcon fontSize="large" />
        </FormattedTooltip>
      </div>
      <Typography variant="h6" {...orgProfileSubHeaderTextStyle}>
        The AI policy is applicable to:
      </Typography>
      <TextField
        id="outlined-basic"
        label="Explain to which parties this policy is applicable to (e.g. internal parties and external parties listed)"
        variant="outlined"
        {...register('applicability')}
        sx={{ width: '100%' }}
      />
      <Typography variant="h6" {...orgProfileSubHeaderTextStyle}>
        Enforcement:
      </Typography>
      <TextField
        id="outlined-basic"
        label="Outline how the AI policy is enforced and adopted in your organization. By whom will it be enforced? How will affected parties be informed?"
        variant="outlined"
        {...register('enforcement')}
        sx={{ width: '100%' }}
      />
      <Typography variant="h6" {...orgProfileSubHeaderTextStyle}>
        Exceptions:
      </Typography>
      <TextField
        id="outlined-basic"
        label="Outline if there are any exceptions, when the AI policy or parts of it don’t apply (e.g. for R&D purposes or pilot projects)"
        variant="outlined"
        {...register('exceptions')}
        sx={{ width: '100%' }}
      />
      <Typography variant="h4" {...orgProfileHeaderTextStyle}>
        Violation of Policy
      </Typography>
      <Typography variant="h6" {...orgProfileSubHeaderTextStyle}>
        Authority:
      </Typography>
      <TextField
        id="outlined-basic"
        label="Who within the organization is responsible for handling violations of this AI policy and related actions (e.g. respective supervisor, HR, Compliance, etc.)?"
        variant="outlined"
        {...register('violationResponsible')}
        sx={{ width: '100%' }}
      />
      <Typography variant="h6" {...orgProfileSubHeaderTextStyle}>
        Non-compliance:
      </Typography>
      <TextField
        id="outlined-basic"
        label="What are the consequences and penalties for non-compliance with your AI policy?"
        variant="outlined"
        {...register('nonCompliance')}
        sx={{ width: '100%' }}
      />
    </>
  );
}

const oecdDefinitionTooltipText =
  'AI Definition: "An AI system is a machine-based system that derives from the the input received (e.g. data) how to generate ' +
  'outputs (e.g. predictions, content, recommendations, decisions, or algorithms) that can influence physical or virtual environments. ' +
  'AI systems can operate according to explicit (clearly defined) goals or implicit goals (derived from data). The underlying technology ' +
  'can be based on machine learning or logic- and knowledge-based concept and goes beyond simple data processing by enabling learning, ' +
  'reasoning and modelling processes. AI systems are characterised by varying degrees of autonomy, which means that they can act to a ' +
  'certain extent independently of human involvement and are able to operate without human intervention. Systems that are based exclusively ' +
  'on rules defined by natural persons for the automatic execution of actions do not fall under the definition of an AI system."';

const nistDefinitionTooltipText =
  'AI Definition: "An AI system is an engineered or machine-based system that can, for a given set of objectives, generate outputs such as predictions, ' +
  'recommendations, or decisions influencing real or virtual environments. AI systems are designed to operate with varying levels of autonomy."';

const applicabilityOfAITooltipText =
  'The AI policy helps you in governing AI activities within the organization. Please outline the applicability and enforcement of the policy here.';

import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Switch } from '@/components/ui/switch';
import { Button } from '@/components/ui/button';
import { RefreshCcw } from 'lucide-react';
import PaywallModal from '../components/PaywallModal';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

interface Framework {
  frameworkName: string;
  isEnabled: boolean;
  templateId: string;
  description: string | null;
}

interface FrameworkListProps {
  title: string;
  frameworks: Framework[];
  loading: boolean;
  onToggle: (id: string, isEnabled: boolean) => void;
  onReset?: (templateId: string) => void;
  showResetButton: boolean;
  isoPerms?: boolean;
}

const FrameworkList: React.FC<FrameworkListProps> = ({
  title,
  frameworks,
  loading,
  onToggle,
  onReset,
  showResetButton,
  isoPerms = false,
}) => {
  const [showPaywall, setShowPaywall] = useState(false);

  const handleSwitchClick = (
    templateId: string,
    newState: boolean,
    frameworkName: string
  ) => {
    if (!isoPerms && frameworkName === 'ISO/IEC 42001:2023') {
      setShowPaywall(true);
      return;
    }
    onToggle(templateId, newState);
  };

  const handleUpgrade = () => {
    window.location.href = '/pricing';
  };

  return (
    <>
      <div className="container mx-auto px-4 py-8">
        <h4>{title}</h4>
        {frameworks.length === 0 ? (
          <div className="text-center text-gray-500 mt-6">
            No frameworks available at this time.
          </div>
        ) : (
          <div className="grid gap-6 md:grid-cols-2 mt-6">
            {frameworks.map(
              ({ frameworkName, isEnabled, templateId, description }) => (
                <Card
                  key={templateId}
                  className="border-2 border-dark-blue-400"
                >
                  <CardHeader>
                    <CardTitle>{frameworkName}</CardTitle>
                    {description && (
                      <CardDescription>{description}</CardDescription>
                    )}
                  </CardHeader>
                  <CardContent>
                    <div className="flex items-center justify-between">
                      <span className="text-sm font-medium">
                        {isEnabled ? 'Enabled' : 'Disabled'}
                      </span>
                      <Switch
                        checked={isEnabled}
                        disabled={loading}
                        onCheckedChange={() =>
                          handleSwitchClick(
                            templateId,
                            !isEnabled,
                            frameworkName
                          )
                        }
                      />
                    </div>
                    {showResetButton && onReset && (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div className="mt-4">
                              <Button
                                variant="outline"
                                className="w-full"
                                onClick={() => onReset(templateId)}
                                disabled={loading || !isEnabled}
                              >
                                <RefreshCcw className="mr-2 h-4 w-4" />
                                Reset
                              </Button>
                            </div>
                          </TooltipTrigger>
                          {!isEnabled && (
                            <TooltipContent>
                              Enable the framework to reset
                            </TooltipContent>
                          )}
                        </Tooltip>
                      </TooltipProvider>
                    )}
                  </CardContent>
                </Card>
              )
            )}
          </div>
        )}
      </div>

      <PaywallModal
        open={showPaywall}
        onClose={() => setShowPaywall(false)}
        onAction={handleUpgrade}
        title="Unlock ISO 42001 Framework"
        primaryText="Upgrade to access the ISO 42001 Framework"
        secondaryText="Enhance your AI management with our premium plan, featuring the ISO 42001 framework for comprehensive compliance and risk management. Join industry leaders in securing your AI processes and building trust with stakeholders."
        actionButtonText="Upgrade Now"
      />
    </>
  );
};

export default FrameworkList;

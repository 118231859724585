import { FC, useEffect, useMemo, useState } from 'react';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  GetallUsersforTrainingQuery,
  GetLiteracyTrainingsQuery,
  GetTrailLiteracyTrainingPreviewsQuery,
  GetTrailTrainingAddonAccessQuery,
  TrainingsInterval,
  LiteracyTraining,
  TrailTrainingPreview,
  User,
  Language,
} from '../__generated__/gql/graphql';
import TrainingCreationModal from './training_creation_modal/TrainingCreationModal';
import TrainingDrawer from './TrainingsDrawer';
import CardGrid from '../components/CardGrid';
import TrainingAdminDetailCard from './TrainingAdminDetailCard';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { GraduationCap } from 'lucide-react';

export const GET_LITERACY_TRAININGS = gql`
  query GetLiteracyTrainings($targetQueryMutation: String!, $action: String!) {
    checkPermission(
      targetQueryMutation: $targetQueryMutation
      action: $action
    ) {
      hasPermission
    }
    allLiteracyTrainings(adminView: true) {
      id
      title
      responsiblePerson {
        id
        name
        email
      }
      language
      description
      isRequired
      interval
      trainingMaterial {
        id
        fileName
      }
      trainingMaterialSections {
        id
        title
        material {
          id
          fileName
        }
      }
      dueDate
      startDate
      completionLogs {
        id
        user {
          id
          name
          email
        }
        completionDate
      }
      questions {
        id
        questionText
        description
        options {
          id
          optionText
          isCorrect
        }
      }
      lastModified
      created
    }
  }
`;

const GET_USERS = gql`
  query getallUsersforTraining {
    allUsers {
      id
      email
      name
      initials
    }
  }
`;

const GET_TRAIL_LITERACY_TRAINING_PREVIEWS = gql`
  query GetTrailLiteracyTrainingPreviews {
    trailLiteracyTrainingPreviews {
      id
      previewTitle
      description
      trainingMaterial {
        id
        url
        contentBase64
      }
    }
  }
`;

const GET_TRAIL_TRAINING_ADDON_ACCESS = gql`
  query getTrailTrainingAddonAccess {
    organization {
      addonAccessControl {
        hasLiteracyAccess
      }
    }
  }
`;

const TrainingAdminDashboardPage: FC = () => {
  const navigate = useNavigate();
  const { data: allTrainingData } = useSuspenseQuery<GetLiteracyTrainingsQuery>(
    GET_LITERACY_TRAININGS,
    {
      variables: {
        targetQueryMutation: 'createTraining',
        action: 'mutation',
      },
    }
  );
  const { data: literacyAccess } =
    useSuspenseQuery<GetTrailTrainingAddonAccessQuery>(
      GET_TRAIL_TRAINING_ADDON_ACCESS
    );
  const hasLiteracyAccess =
    literacyAccess?.organization?.addonAccessControl?.hasLiteracyAccess ??
    false;

  useEffect(() => {
    if (!allTrainingData?.checkPermission?.hasPermission) {
      navigate('/literacy');
    }
  }, [allTrainingData, navigate]);

  const filteredTraining = useMemo(
    () => (allTrainingData?.allLiteracyTrainings as LiteracyTraining[]) || [],
    [allTrainingData]
  );

  const { data: allUserData } =
    useSuspenseQuery<GetallUsersforTrainingQuery>(GET_USERS);
  const { data: previewsData } =
    useSuspenseQuery<GetTrailLiteracyTrainingPreviewsQuery>(
      GET_TRAIL_LITERACY_TRAINING_PREVIEWS
    );

  const [selectedTrainingId, setSelectedTrainingId] = useState<string | null>(
    null
  );
  const selectedTraining = useMemo(() => {
    return filteredTraining.find(t => t.id === selectedTrainingId) || null;
  }, [filteredTraining, selectedTrainingId]);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const handleTrainingSelect = (training: LiteracyTraining) => {
    setSelectedTrainingId(training.id);
    setShowDrawer(true);
  };

  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1>Admin Training Dashboard</h1>
        <Link to="/literacy">
          <Button variant="outline" className="flex items-center gap-2">
            <GraduationCap className="h-4 w-4" />
            Trainings View
          </Button>
        </Link>
      </div>

      <CardGrid
        items={filteredTraining}
        title={
          filteredTraining.length === 0
            ? 'No Trainings created yet'
            : filteredTraining.length > 1
            ? filteredTraining.length + ' Trainings created'
            : '1 Training created'
        }
        renderCard={training => (
          <TrainingAdminDetailCard
            key={training.id}
            id={training.id}
            onClick={() => handleTrainingSelect(training)}
            title={training.title}
            isSelected={selectedTraining?.id === training.id}
            user={training?.responsiblePerson ?? {}}
            interval={training?.interval ?? TrainingsInterval.Yearly}
            updatedAt={training?.lastModified ?? 'not update yet'}
            required={training?.isRequired ?? false}
            language={training?.language ?? Language.English}
          />
        )}
        onAddNew={() => setShowCreationModal(true)}
        addNewCardProps={{ width: 400, height: 200 }}
      />

      {showCreationModal && (
        <TrainingCreationModal
          isOpen={showCreationModal}
          onClose={() => setShowCreationModal(false)}
          allUser={(allUserData.allUsers as User[]) || []}
          availableTrainingMaterials={
            previewsData?.trailLiteracyTrainingPreviews?.filter(
              (preview): preview is TrailTrainingPreview => preview !== null
            ) || []
          }
          hasLiteracyAccess={hasLiteracyAccess}
        />
      )}

      {selectedTraining && (
        <TrainingDrawer
          training={selectedTraining}
          isOpen={showDrawer}
          onClose={() => {
            setShowDrawer(false);
            setSelectedTrainingId(null);
          }}
          allUsers={(allUserData?.allUsers as User[]) || []}
          availableTrainingMaterials={
            previewsData?.trailLiteracyTrainingPreviews?.filter(
              (preview): preview is TrailTrainingPreview => preview !== null
            ) || []
          }
        />
      )}
    </div>
  );
};

export default TrainingAdminDashboardPage;

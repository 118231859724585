import React, { useState, useRef, KeyboardEvent, FocusEvent } from 'react';
import { X, ChevronRight } from 'lucide-react';
import { Button } from '../components/ui/button';
import { Textarea } from '../components/ui/textarea';

interface StackEditorProps {
  stacks: string[];
  isEditable: boolean;
  setStacks: (stacks: string[]) => void;
  placeholder?: string;
}

const StackEditor: React.FC<StackEditorProps> = ({
  stacks,
  isEditable,
  setStacks,
  placeholder = 'Add new item...',
}) => {
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editText, setEditText] = useState('');
  const newItemRef = useRef<HTMLTextAreaElement>(null);

  const handleAddStack = (text: string) => {
    if (text.trim()) {
      setStacks([...stacks, text.trim()]);
      if (newItemRef.current) {
        newItemRef.current.value = '';
        newItemRef.current.blur();
      }
    }
  };

  const handleDeleteStack = (index: number) => {
    const updatedStacks = [...stacks];
    updatedStacks.splice(index, 1);
    setStacks(updatedStacks);
  };

  const startEditing = (index: number, text: string) => {
    setEditIndex(index);
    setEditText(text);
  };

  const saveEdit = () => {
    if (editIndex !== null && editText.trim()) {
      const updatedStacks = [...stacks];
      updatedStacks[editIndex] = editText.trim();
      setStacks(updatedStacks);
      setEditIndex(null);
    }
  };

  const isAddingRef = useRef(false);

  const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    if (text.trim() && !isAddingRef.current) {
      isAddingRef.current = true;
      handleAddStack(text);
      setTimeout(() => {
        isAddingRef.current = false;
      }, 100);
    }
  };

  const handleKeyDown = (
    e: KeyboardEvent<HTMLTextAreaElement>,
    isEditing: boolean = false
  ) => {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      if (isEditing) {
        saveEdit();
      } else {
        handleAddStack(e.currentTarget.value);
      }
    } else if (e.key === 'Escape' && editIndex !== null) {
      setEditIndex(null);
    }
  };

  return (
    <div className="space-y-3">
      {stacks.length > 0 && (
        <ul className="space-y-1">
          {stacks.map((stack, index) => (
            <li key={index} className="group flex">
              <div className="flex items-start w-full p-2 pr-0 rounded-md">
                <ChevronRight className="h-4 w-4 text-muted-foreground mr-2 mt-1 shrink-0" />

                {index === editIndex ? (
                  <div className="flex w-full gap-2">
                    <Textarea
                      value={editText}
                      onChange={e => setEditText(e.target.value)}
                      onKeyDown={e => handleKeyDown(e, true)}
                      onBlur={() => saveEdit()}
                      autoFocus
                      className="mb-2"
                    />
                  </div>
                ) : (
                  <>
                    <div
                      className="whitespace-pre-wrap break-words flex-1 cursor-pointer"
                      onClick={() => isEditable && startEditing(index, stack)}
                    >
                      {stack}
                    </div>

                    {isEditable && (
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleDeleteStack(index)}
                        className="opacity-0 group-hover:opacity-100 h-8 w-8 ml-2 shrink-0"
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    )}
                  </>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}

      {isEditable && (
        <div className="space-y-2">
          <Textarea
            ref={newItemRef}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            placeholder={placeholder}
            className="min-h-[60px]"
          />
        </div>
      )}
    </div>
  );
};

export default StackEditor;

import { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Trash2, Upload, Check, AlertCircle, X } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Spinner } from '@/components/ui/spinner';

interface SectionUploadFieldProps {
  section: {
    id: number;
    title: string;
    file: File | null;
    fileId: string | null;
  };
  onChange: (section: {
    id: number;
    title: string;
    file: File | null;
    fileId: string | null;
  }) => void;
  onRemove: (id: number) => void;
  getPresignedUrl: (options: {
    variables: {
      fileName: string;
      contentType: string;
    };
  }) => Promise<{
    data?: {
      getPresignedUploadUrl?: {
        presignedUrl: string;
        fileId: string;
      };
    };
  }>;
}

const SectionUploadField: FC<SectionUploadFieldProps> = ({
  section,
  onChange,
  onRemove,
  getPresignedUrl,
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setIsUploading(true);
        try {
          const { data } = await getPresignedUrl({
            variables: {
              fileName: file.name,
              contentType: file.type,
            },
          });
          if (!data?.getPresignedUploadUrl) {
            throw new Error('Failed to get presigned URL');
          }
          const response = await fetch(
            data.getPresignedUploadUrl.presignedUrl,
            {
              method: 'PUT',
              body: file,
              headers: { 'Content-Type': file.type },
            }
          );
          if (!response.ok) {
            throw new Error(`Failed to upload file: ${await response.text()}`);
          }
          onChange({
            ...section,
            file,
            fileId: data.getPresignedUploadUrl.fileId,
          });
        } catch (error) {
          console.error('Error uploading section file:', error);
          toast.error('Failed to upload section file');
        } finally {
          setIsUploading(false);
        }
      }
    },
    accept: { 'application/pdf': ['.pdf'] },
    maxSize: 10485760,
  });

  const getValidationStatus = () => {
    const hasTitle = section.title.trim().length > 0;
    const hasFile = section.file !== null;

    return {
      isValid: hasTitle && hasFile,
      errors: [
        !hasTitle && 'Section title is required',
        !hasFile && 'PDF file is required',
      ].filter(Boolean) as string[],
    };
  };

  const handleRemoveFileSelection = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    onChange({
      ...section,
      file: null,
      fileId: null,
    });
  };

  const validationStatus = getValidationStatus();

  return (
    <Card className="border-2 border-dark-blue-400 shadow-sm hover:shadow-md transition-shadow mb-4">
      <CardHeader className="pb-4">
        <CardTitle className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <TooltipProvider>
              {validationStatus.isValid ? (
                <Tooltip>
                  <TooltipTrigger>
                    <Check className="w-5 h-5 text-green-500" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Section is valid</p>
                  </TooltipContent>
                </Tooltip>
              ) : (
                <Tooltip>
                  <TooltipTrigger>
                    <AlertCircle className="w-5 h-5 text-yellow-500" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <div className="space-y-1">
                      <p className="font-semibold">Please fix the following:</p>
                      <ul className="list-disc list-inside">
                        {validationStatus.errors.map((error, index) => (
                          <li key={index} className="text-sm">
                            {error}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </TooltipContent>
                </Tooltip>
              )}
            </TooltipProvider>
          </div>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => onRemove(section.id)}
            className="text-white hover:text-red-500"
          >
            <Trash2 className="w-4 h-4" />
          </Button>
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-6 pt-0">
        <div className="flex flex-col gap-1">
          <Label htmlFor={`section-${section.id}-title`} className="text-sm">
            Section Title
          </Label>
          <Input
            id={`section-${section.id}-title`}
            placeholder="Enter section title..."
            value={section.title}
            onChange={e => onChange({ ...section, title: e.target.value })}
            className="border-2"
          />
        </div>

        <div className="flex flex-col gap-1">
          <Label className="text-sm font-medium">PDF Upload</Label>
          <div
            {...getRootProps()}
            className={`border-2 border-dashed rounded-lg p-6 text-center transition-colors min-h-28 flex w-full items-center justify-center
              ${
                isDragActive
                  ? 'bg-dark-blue-200'
                  : section.file
                  ? 'bg-dark-blue-200/50'
                  : ''
              }`}
          >
            <input {...getInputProps()} />
            <div className="flex items-center justify-center gap-2">
              {isUploading ? (
                <Spinner size="small">
                  <span className="mt-2 text-sm text-muted-foreground">
                    Uploading file...
                  </span>
                </Spinner>
              ) : (
                <>
                  <Upload className="w-5 h-5" />
                  {section.file ? (
                    <div className="flex items-center gap-2">
                      <span>{section.file.name}</span>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={e => {
                          e.stopPropagation();
                          handleRemoveFileSelection();
                        }}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                  ) : (
                    <span>
                      {isDragActive
                        ? 'Drop the file here...'
                        : 'Drag and drop PDF, or click to select'}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default SectionUploadField;

import { ReactNode } from 'react';
import { Box } from '@mui/material';
import AddNewItemCard from './AddNewItemCard';

interface CardGridProps<T> {
  items: readonly T[];
  renderCard: (item: T) => ReactNode;
  onAddNew?: () => void;
  title?: string;
  addNewCardProps?: {
    width?: number;
    height?: string | number;
    minHeight?: number;
  };
  gridGap?: string;
}

const DEFAULT_PROPS = {
  width: 400,
  height: 'auto',
  minHeight: 200,
  gridGap: '12px',
};

const CardGrid = <T,>({
  items,
  renderCard,
  onAddNew,
  title,
  addNewCardProps = DEFAULT_PROPS,
  gridGap = DEFAULT_PROPS.gridGap,
}: CardGridProps<T>) => {
  const { width, height, minHeight } = addNewCardProps;

  const renderAddNewCard = () =>
    onAddNew && (
      <AddNewItemCard
        key="add-new"
        onClick={onAddNew}
        sx={{ width, height, minHeight }}
      />
    );

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fit, ${width}px)`,
        gap: gridGap,
        justifyContent: 'center',
        width: '100%',
        pb: 4,
      }}
    >
      {title && <h4 className="col-span-full mb-4">{title}</h4>}
      {items.map(item => renderCard(item))}
      {renderAddNewCard()}
    </Box>
  );
};

export default CardGrid;

import { useState } from 'react';
import { ApolloCache, gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import {
  GetLiteracyTrainingsQuery,
  TrainingsInterval,
  LiteracyTraining,
  User,
  TrailTrainingPreview,
  Language,
} from '../__generated__/gql/graphql';
import DeleteConfirmationDialog from '../components/DeleteConfirmationDialog';
import CompletionLogsSection from './training_admin_drawer/CompletionLogsSection';
import { TrainingDrawerHeader } from './training_admin_drawer/TrainingDrawerHeader';
import { DetailsSection } from './training_admin_drawer/DetailsSection';
import { GET_LITERACY_TRAININGS } from './TrainingAdminDashboardPage';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import QuestionsSection from './training_admin_drawer/QuestionsSection';
import TrainingMaterialsSection from './training_admin_drawer/TrainingMaterialsSection';
import { isQuestionsValid } from '@/utils/validation';

const UPDATE_TRAINING = gql`
  mutation UpdateTraining(
    $trainingId: ID!
    $title: String
    $description: String
    $responsiblePerson: String
    $isRequired: Boolean
    $interval: TrainingsInterval
    $startDate: DateTime
    $dueDate: DateTime
    $language: Language
    $questions: [TrainingQuestionInput!]
    $fileId: String
    $sections: [TrainingMaterialSectionInput!]
  ) {
    updateTraining(
      trainingId: $trainingId
      title: $title
      description: $description
      responsiblePerson: $responsiblePerson
      isRequired: $isRequired
      interval: $interval
      startDate: $startDate
      dueDate: $dueDate
      language: $language
      questions: $questions
      fileId: $fileId
      sections: $sections
    ) {
      training {
        id
        title
        description
        responsiblePerson {
          id
          name
        }
        isRequired
        interval
        startDate
        dueDate
        language
        trainingMaterial {
          id
          fileName
        }
        trainingMaterialSections {
          id
          title
          material {
            id
            fileName
          }
        }
        questions {
          id
          questionText
          description
          options {
            id
            optionText
            isCorrect
          }
        }
      }
    }
  }
`;

const DELETE_TRAINING = gql`
  mutation DeleteTraining($trainingId: ID!) {
    deleteTraining(trainingId: $trainingId) {
      training {
        id
      }
    }
  }
`;

const GET_PRESIGNED_UPLOAD_URL = gql`
  mutation GetPresignedUploadUrlForAdmin(
    $fileName: String!
    $contentType: String
  ) {
    getPresignedUploadUrl(fileName: $fileName, contentType: $contentType) {
      presignedUrl
      fileId
    }
  }
`;

export interface FormState {
  title: string;
  description: string;
  responsiblePerson: string;
  isRequired: boolean;
  interval: TrainingsInterval;
  startDate: string | null;
  dueDate: string | null;
  language: Language;
  questions: {
    id?: string;
    questionText: string;
    description?: string;
    options: {
      id?: string;
      optionText: string;
      isCorrect: boolean;
    }[];
  }[];
  materialMode: 'new' | 'existing' | 'sections';
  singleMaterialId: string | null;
  sections: { id: number; title: string; materialId: string | null }[];
}

interface TrainingDrawerProps {
  training: LiteracyTraining;
  isOpen: boolean;
  onClose: () => void;
  allUsers: User[];
  availableTrainingMaterials?: TrailTrainingPreview[];
}

export default function TrainingDrawer({
  training,
  isOpen,
  onClose,
  allUsers,
  availableTrainingMaterials = [],
}: TrainingDrawerProps) {
  const [isEditable, setIsEditable] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [formState, setFormState] = useState<FormState>({
    title: training.title,
    description: training.description || '',
    responsiblePerson: training.responsiblePerson?.id || '',
    isRequired: training.isRequired || false,
    interval: training.interval || TrainingsInterval.Yearly,
    startDate: training.startDate || null,
    dueDate: training.dueDate || null,
    language: training.language || Language.English,
    questions:
      training.questions?.map(q => ({
        id: q.id,
        questionText: q.questionText,
        description: q.description || '',
        options: q.options.map(o => ({
          id: o.id,
          optionText: o.optionText,
          isCorrect: o.isCorrect,
        })),
      })) || [],
    materialMode: training.trainingMaterial
      ? 'new'
      : training.trainingMaterialSections.length > 0
      ? 'sections'
      : 'new',
    singleMaterialId: training.trainingMaterial?.id || null,
    sections:
      training.trainingMaterialSections?.map(s => ({
        id: s.id ? parseInt(s.id) : Date.now(),
        title: s.title,
        materialId: s.material?.id,
      })) || [],
  });

  const resetForm = () => {
    setFormState({
      title: training.title,
      description: training.description || '',
      responsiblePerson: training.responsiblePerson?.id || '',
      isRequired: training.isRequired || false,
      interval: training.interval || TrainingsInterval.Yearly,
      startDate: training.startDate || null,
      dueDate: training.dueDate || null,
      language: training.language || Language.English,
      questions:
        training.questions?.map(q => ({
          id: q.id,
          questionText: q.questionText,
          description: q.description || '',
          options: q.options.map(o => ({
            id: o.id,
            optionText: o.optionText,
            isCorrect: o.isCorrect,
          })),
        })) || [],
      materialMode: training.trainingMaterial
        ? 'new'
        : training.trainingMaterialSections.length > 0
        ? 'sections'
        : 'new',
      singleMaterialId: training.trainingMaterial?.id || null,
      sections:
        training.trainingMaterialSections?.map(s => ({
          id: s.id ? parseInt(s.id) : Date.now(),
          title: s.title,
          materialId: s.material?.id,
        })) || [],
    });
  };

  const updateCacheAfterDelete = (
    cache: ApolloCache<GetLiteracyTrainingsQuery>,
    deletedTrainingId: string
  ) => {
    const existingData = cache.readQuery<GetLiteracyTrainingsQuery>({
      query: GET_LITERACY_TRAININGS,
      variables: {
        targetQueryMutation: 'createTraining',
        action: 'mutation',
      },
    });

    if (existingData?.allLiteracyTrainings) {
      cache.writeQuery<GetLiteracyTrainingsQuery>({
        query: GET_LITERACY_TRAININGS,
        variables: {
          targetQueryMutation: 'createTraining',
          action: 'mutation',
        },
        data: {
          allLiteracyTrainings: existingData.allLiteracyTrainings.filter(
            t => t?.id !== deletedTrainingId
          ),
          checkPermission: existingData.checkPermission,
        },
      });
    }
    cache.evict({
      id: cache.identify({
        __typename: 'LiteracyTraining',
        id: deletedTrainingId,
      }),
    });
    cache.gc();
  };

  const [getPresignedUploadUrl] = useMutation(GET_PRESIGNED_UPLOAD_URL);
  const handleUploadFile = async (file: File) => {
    const { data } = await getPresignedUploadUrl({
      variables: { fileName: file.name, contentType: file.type },
    });
    const { presignedUrl, fileId } = data.getPresignedUploadUrl;
    await fetch(presignedUrl, {
      method: 'PUT',
      body: file,
      headers: { 'Content-Type': file.type },
    });
    return fileId;
  };

  const [updateTraining] = useMutation(UPDATE_TRAINING, {
    update(cache, { data }) {
      const updatedTraining = data?.updateTraining?.training;
      if (!updatedTraining) return;

      const existingData = cache.readQuery<GetLiteracyTrainingsQuery>({
        query: GET_LITERACY_TRAININGS,
        variables: {
          targetQueryMutation: 'createTraining',
          action: 'mutation',
        },
      });

      if (existingData?.allLiteracyTrainings) {
        cache.writeQuery<GetLiteracyTrainingsQuery>({
          query: GET_LITERACY_TRAININGS,
          variables: {
            targetQueryMutation: 'createTraining',
            action: 'mutation',
          },
          data: {
            allLiteracyTrainings: existingData.allLiteracyTrainings.map(t =>
              t?.id === updatedTraining.id ? updatedTraining : t
            ),
            checkPermission: existingData.checkPermission,
          },
        });
      }
    },
    onCompleted: data => {
      const updatedTraining = data?.updateTraining?.training;
      if (updatedTraining) {
        toast.success('Training updated successfully');
        setFormState(prev => ({
          ...prev,
          title: updatedTraining.title,
          description: updatedTraining.description,
          singleMaterialId: updatedTraining.trainingMaterial?.id || null,
          sections: updatedTraining.trainingMaterialSections || [],
        }));
        setIsEditable(false);
      }
    },

    onError: error => {
      toast.error('Failed to update training');
      console.error('Update training error:', error);
    },
  });

  const [deleteTraining] = useMutation(DELETE_TRAINING, {
    variables: { trainingId: training.id },
    onCompleted: () => {
      toast.success('Training deleted successfully');
      onClose();
    },
    onError: error => {
      toast.error('Failed to delete training');
      console.error('Delete training error:', error);
    },
    update(cache) {
      updateCacheAfterDelete(cache, training.id);
    },
  });

  const onSave = () => {
    const variables: {
      trainingId: string;
      title: string;
      description: string;
      responsiblePerson: string;
      isRequired: boolean;
      interval: TrainingsInterval;
      startDate?: string;
      dueDate?: string;
      language?: Language;
      questions: {
        id?: string;
        questionText: string;
        description?: string;
        options: {
          id?: string;
          optionText: string;
          isCorrect: boolean;
        }[];
      }[];
      fileId?: string;
      sections?: { title: string; fileId: string | null }[];
    } = {
      trainingId: training.id,
      title: formState.title,
      description: formState.description,
      responsiblePerson: formState.responsiblePerson,
      isRequired: formState.isRequired,
      interval: formState.interval,
      questions: formState.questions.map(q => ({
        id: q.id,
        questionText: q.questionText,
        description: q.description,
        options: q.options.map(o => ({
          id: o.id,
          optionText: o.optionText,
          isCorrect: o.isCorrect,
        })),
      })),
    };
    if (formState.startDate) {
      variables.startDate = formState.startDate;
    }

    if (formState.dueDate) {
      variables.dueDate = formState.dueDate;
    }

    if (formState.language) {
      variables.language = formState.language;
    }

    if (
      formState.materialMode === 'new' ||
      formState.materialMode === 'existing'
    ) {
      if (formState.singleMaterialId) {
        variables.fileId = formState.singleMaterialId;
      }
    } else if (formState.materialMode === 'sections') {
      variables.sections = formState.sections.map(s => ({
        title: s.title,
        fileId: s.materialId,
      }));
    }

    const hasMaterials =
      formState.materialMode === 'new' || formState.materialMode === 'existing'
        ? !!formState.singleMaterialId
        : formState.sections.length > 0 &&
          formState.sections.every(s => !!s.materialId);

    if (!hasMaterials) {
      toast.error('All training materials must have a PDF file.');
      return;
    }

    if (isQuestionsValid(formState.questions)) {
      updateTraining({ variables });
    } else {
      toast.error('Please complete all questions with valid options');
    }
  };

  const saveDisabled =
    isEditable &&
    (!isQuestionsValid(formState.questions) ||
      ((formState.materialMode === 'new' ||
        formState.materialMode === 'existing') &&
        !formState.singleMaterialId) ||
      (formState.materialMode === 'sections' &&
        (formState.sections.length === 0 ||
          formState.sections.some(s => !s.materialId))));

  return (
    <>
      <Sheet
        open={isOpen}
        onOpenChange={open => {
          if (!open) onClose();
        }}
      >
        <SheetContent side="right" className="sm:min-w-[60%] overflow-auto">
          <TrainingDrawerHeader
            title={formState.title}
            isEditable={isEditable}
            onTitleChange={title => setFormState(prev => ({ ...prev, title }))}
            onEdit={() => {
              if (isEditable) resetForm();
              setIsEditable(!isEditable);
            }}
            onSave={onSave}
            onDelete={() => setDeleteDialogOpen(true)}
            saveDisabled={saveDisabled}
          />
          <hr className="my-3 border-t" />
          <div className="p-3">
            <DetailsSection
              formState={formState}
              setFormState={setFormState}
              isEditable={isEditable}
              allUsers={allUsers}
            />
            <CompletionLogsSection training={training} />

            <TrainingMaterialsSection
              formState={formState}
              setFormState={setFormState}
              isEditable={isEditable}
              training={training}
              handleUploadFile={handleUploadFile}
              availableTrainingMaterials={availableTrainingMaterials}
            />
            <QuestionsSection
              questions={formState.questions}
              setQuestions={questions =>
                setFormState(prev => ({ ...prev, questions }))
              }
              isEditable={isEditable}
            />
          </div>
        </SheetContent>
      </Sheet>
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => {
          deleteTraining();
          setDeleteDialogOpen(false);
        }}
        itemToDelete={{
          name: training.title,
          type: 'Training',
        }}
      />
    </>
  );
}

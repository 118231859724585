import React, { useState } from 'react';
import { Box, Button, Typography, Alert } from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'sonner';

const CREATE_FRAMEWORK_TEMPLATE = gql`
  mutation CreateGovernanceFrameworkTemplate(
    $categoriesCsv: String!
    $requirementsCsv: String!
    $specificationsCsv: String!
    $metadataCsv: String!
    $evidencesCsv: String!
  ) {
    createGovernanceFrameworkTemplate(
      categoriesCsv: $categoriesCsv
      requirementsCsv: $requirementsCsv
      specificationsCsv: $specificationsCsv
      metadataCsv: $metadataCsv
      evidencesCsv: $evidencesCsv
    ) {
      template {
        id
        isCustom
      }
      validationErrors
    }
  }
`;

const GET_CUSTOM_FRAMEWORKS = gql`
  query GetCustomFrameworks {
    organization {
      userCreatedFrameworks: availableFrameworkTemplates(
        frameworkType: GOVERNANCE
        userCreatedFrameworks: true
      ) {
        frameworkName
        templateId
        description
      }
    }
  }
`;

const REQUIRED_FILES = [
  'categories.csv',
  'requirements.csv',
  'specifications.csv',
  'metadata.csv',
  'evidences.csv',
];

const FrameworkTemplatePage: React.FC = () => {
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<string>('');
  const [files, setFiles] = useState<{ [key: string]: File | null }>({
    categories: null,
    requirements: null,
    specifications: null,
    metadata: null,
    evidences: null,
  });

  const [createTemplate, { loading }] = useMutation(CREATE_FRAMEWORK_TEMPLATE);
  const { data: customFrameworksData } = useQuery(GET_CUSTOM_FRAMEWORKS);

  const handleFolderSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    const folderName = selectedFiles[0]?.webkitRelativePath.split('/')[0] || '';
    setSelectedFolder(folderName);

    const newFiles: { [key: string]: File | null } = {
      categories: null,
      requirements: null,
      specifications: null,
      metadata: null,
      evidences: null,
    };

    selectedFiles.forEach(file => {
      const fileName = file.name.toLowerCase();
      if (fileName === 'categories.csv') newFiles.categories = file;
      if (fileName === 'requirements.csv') newFiles.requirements = file;
      if (fileName === 'specifications.csv') newFiles.specifications = file;
      if (fileName === 'metadata.csv') newFiles.metadata = file;
      if (fileName === 'evidences.csv') newFiles.evidences = file;
    });

    setFiles(newFiles);
  };

  const getMissingFiles = () => {
    return REQUIRED_FILES.filter(
      fileName =>
        !Object.values(files).some(
          file => file?.name.toLowerCase() === fileName
        )
    );
  };

  const handleSubmit = async () => {
    try {
      setValidationErrors([]);

      const missingFiles = getMissingFiles();
      if (missingFiles.length > 0) {
        setValidationErrors([
          `Missing required files: ${missingFiles.join(', ')}`,
        ]);
        return;
      }

      const readFile = async (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = e => resolve(e.target?.result as string);
          reader.onerror = _e =>
            reject(
              new Error(`Error reading file ${file.name}: ${reader.error}`)
            );
          reader.readAsText(file);
        });
      };

      const fileContents = await Promise.all(
        Object.entries(files).map(async ([key, file]) => {
          if (!file) throw new Error(`Missing ${key} file`);
          const content = await readFile(file);
          return [key, content];
        })
      );

      const fileData = Object.fromEntries(fileContents);

      const result = await createTemplate({
        variables: {
          categoriesCsv: fileData.categories,
          requirementsCsv: fileData.requirements,
          specificationsCsv: fileData.specifications,
          metadataCsv: fileData.metadata,
          evidencesCsv: fileData.evidences,
        },
      });

      const { template, validationErrors: errors } =
        result.data?.createGovernanceFrameworkTemplate || {};

      if (errors && errors.length > 0) {
        setValidationErrors(errors);
        toast.error('Failed to create template due to validation errors');
      } else if (template?.id) {
        toast.success('Framework template created successfully!');
        console.log(`Template created with ID: ${template.id}`);
      } else {
        console.error('Mutation failed:', result);
        toast.error('Failed to create framework template');
        setValidationErrors([
          'Failed to create framework template. Please check the server logs.',
        ]);
      }
    } catch (error) {
      console.error('Error creating template:', error);
      toast.error('An error occurred while creating the template');
      setValidationErrors([
        'An error occurred while creating the template. Please try again.',
      ]);
    }
  };

  const handleClear = () => {
    setSelectedFolder('');
    setFiles({
      categories: null,
      requirements: null,
      specifications: null,
      metadata: null,
      evidences: null,
    });
    setValidationErrors([]);

    // Reset the file input
    const fileInput = document.getElementById(
      'folder-upload'
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Create Framework Template
      </Typography>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Existing Custom Frameworks
        </Typography>
        {customFrameworksData?.organization?.userCreatedFrameworks?.length ? (
          <Box>
            <table
              style={{
                borderCollapse: 'collapse',
                width: '100%',
                maxWidth: '800px',
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: 'left',
                      padding: '8px',
                      borderBottom: '1px solid #ddd',
                    }}
                  >
                    Name
                  </th>
                  <th
                    style={{
                      textAlign: 'left',
                      padding: '8px',
                      borderBottom: '1px solid #ddd',
                    }}
                  >
                    Description
                  </th>
                  <th
                    style={{
                      textAlign: 'left',
                      padding: '8px',
                      borderBottom: '1px solid #ddd',
                    }}
                  >
                    Template ID
                  </th>
                </tr>
              </thead>
              <tbody>
                {customFrameworksData.organization.userCreatedFrameworks.map(
                  (framework: any) => (
                    <tr key={framework.templateId}>
                      <td
                        style={{
                          padding: '8px',
                          borderBottom: '1px solid #ddd',
                        }}
                      >
                        {framework.frameworkName}
                      </td>
                      <td
                        style={{
                          padding: '8px',
                          borderBottom: '1px solid #ddd',
                        }}
                      >
                        {framework.description || '-'}
                      </td>
                      <td
                        style={{
                          padding: '8px',
                          borderBottom: '1px solid #ddd',
                        }}
                      >
                        {framework.templateId}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </Box>
        ) : (
          <Typography color="textSecondary">
            No custom frameworks have been created yet.
          </Typography>
        )}
      </Box>

      {validationErrors.length > 0 && (
        <Box mb={2}>
          <Alert severity="error">
            <Typography variant="subtitle1" gutterBottom>
              Validation Errors:
            </Typography>
            <ul>
              {validationErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </Alert>
        </Box>
      )}

      <Box display="flex" flexDirection="column" gap={2} maxWidth={600}>
        <Box>
          <input
            type="file"
            // @ts-expect-error webkitdirectory is not supported in all browsers
            webkitdirectory=""
            directory=""
            onChange={handleFolderSelect}
            style={{ display: 'none' }}
            id="folder-upload"
          />
          <label htmlFor="folder-upload">
            <Button variant="contained" component="span">
              Select Framework Folder
            </Button>
          </label>
          {selectedFolder && (
            <Typography variant="caption" ml={2}>
              Selected folder: {selectedFolder}
            </Typography>
          )}
        </Box>

        {selectedFolder && (
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Found Files:
            </Typography>
            <ul>
              {REQUIRED_FILES.map(fileName => (
                <li key={fileName}>
                  {fileName}:{' '}
                  {Object.values(files).some(
                    file => file?.name.toLowerCase() === fileName
                  )
                    ? '✅'
                    : '❌'}
                </li>
              ))}
            </ul>
          </Box>
        )}

        <Box display="flex" gap={2}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={loading || !Object.values(files).every(Boolean)}
          >
            Create Template
          </Button>

          {selectedFolder && (
            <Button variant="outlined" onClick={handleClear} disabled={loading}>
              Clear Selection
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FrameworkTemplatePage;

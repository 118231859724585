import React from 'react';
import {
  RiskClassCategory,
  RiskClassRole,
  RiskClassSpecialCase,
} from '../__generated__/gql/graphql';
import {
  getRiskClassCategoryText,
  getRiskClassRoleText,
  getRiskClassSpecialCaseText,
} from '../risk_classification/QuestionnaireResult';
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from '@/components/ui/select';

export enum RiskClassType {
  ROLE = 'ROLE',
  CATEGORY = 'CATEGORY',
  SPECIAL_CASE = 'SPECIAL_CASE',
}

interface RiskClassTagProps {
  riskClassCategory?: RiskClassCategory;
  riskClassRole?: RiskClassRole;
  riskClassSpecialCase?: RiskClassSpecialCase;
  isEditable: boolean;
  setRiskClassRole?: (role: RiskClassRole) => void;
  setRiskClassCategory?: (category: RiskClassCategory) => void;
  setRiskClassSpecialCase?: (specialCase: RiskClassSpecialCase) => void;
  type: RiskClassType;
}

const RiskClassTag: React.FC<RiskClassTagProps> = ({
  riskClassCategory,
  riskClassRole,
  riskClassSpecialCase,
  isEditable,
  setRiskClassRole,
  setRiskClassCategory,
  setRiskClassSpecialCase,
  type,
}) => {
  const getMenuItemText = (value: string) => {
    switch (type) {
      case RiskClassType.ROLE:
        return getRiskClassRoleText(value as RiskClassRole);
      case RiskClassType.CATEGORY:
        return getRiskClassCategoryText(value as RiskClassCategory);
      case RiskClassType.SPECIAL_CASE:
        return getRiskClassSpecialCaseText(value as RiskClassSpecialCase);
    }
  };

  const getCurrentValue = () => {
    switch (type) {
      case RiskClassType.ROLE:
        return {
          value: riskClassRole,
          setter: setRiskClassRole,
          options: RiskClassRole,
        };
      case RiskClassType.CATEGORY:
        return {
          value: riskClassCategory,
          setter: setRiskClassCategory,
          options: RiskClassCategory,
        };
      case RiskClassType.SPECIAL_CASE:
        return {
          value: riskClassSpecialCase,
          setter: setRiskClassSpecialCase,
          options: RiskClassSpecialCase,
        };
    }
  };

  const currentConfig = getCurrentValue();

  const getBorderColor = (
    riskClassCategoryValue?: RiskClassCategory,
    riskClassRoleValue?: RiskClassRole
  ) => {
    if (riskClassRoleValue === RiskClassRole.OutOfScope) {
      return { border: 'border-gray-400', background: 'bg-transparent' };
    }
    switch (riskClassCategoryValue) {
      case RiskClassCategory.Minimal:
        return {
          border: 'border-green-500',
          background: 'bg-green-900/20',
        };
      case RiskClassCategory.Limited:
        return {
          border: 'border-orange-500',
          background: 'bg-orange-900/20',
        };
      case RiskClassCategory.High:
      case RiskClassCategory.HighAndLimited:
        return {
          border: 'border-red-500',
          background: 'bg-red-900/20',
        };
      case RiskClassCategory.HighExemptions:
        return {
          border: 'border-yellow-500',
          background: 'bg-yellow-900/20',
        };
      case RiskClassCategory.Prohibited:
        return {
          border: 'border-black',
          background: 'bg-grey-500/20',
        };
      default:
        return { border: 'border-gray-300', background: 'bg-transparent' };
    }
  };

  const { border, background } = getBorderColor(
    riskClassCategory,
    riskClassRole
  );

  const getDisplayText = () => {
    if (riskClassRole === RiskClassRole.OutOfScope) {
      return 'Out of Scope';
    }
    if (!currentConfig.value) {
      return 'Not set yet';
    }
    return getMenuItemText(currentConfig.value);
  };

  const handleChange = (newValue: string) => {
    switch (type) {
      case RiskClassType.ROLE:
        setRiskClassRole?.(newValue as RiskClassRole);
        break;
      case RiskClassType.CATEGORY:
        setRiskClassCategory?.(newValue as RiskClassCategory);
        break;
      case RiskClassType.SPECIAL_CASE:
        setRiskClassSpecialCase?.(newValue as RiskClassSpecialCase);
        break;
    }
  };

  return (
    <div
      className={`flex flex-col justify-center items-center gap-2.5 h-full ${border} ${background} border-1 rounded-lg text-center ${
        isEditable ? 'p-4' : 'py-4'
      }`}
    >
      {isEditable ? (
        <Select value={currentConfig.value || ''} onValueChange={handleChange}>
          <SelectTrigger className="w-[90%] mx-auto mt-0">
            <SelectValue placeholder="Select value" />
          </SelectTrigger>
          <SelectContent>
            {Object.values(currentConfig.options).map(value => (
              <SelectItem key={value} value={value}>
                {getMenuItemText(value)}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      ) : (
        <div className="text-sm font-medium">{getDisplayText()}</div>
      )}
    </div>
  );
};

export default RiskClassTag;

export const IntegrationButton = ({
  logo,
  label,
  action,
  disabled,
}: {
  logo: string;
  label: string;
  action?: () => void;
  disabled?: boolean;
}) => {
  return (
    <div
      onClick={disabled ? undefined : action}
      className={`
        flex flex-col justify-center items-center gap-2.5
        p-4 w-[100px] h-[100px]
        ${
          disabled
            ? 'cursor-not-allowed opacity-50'
            : 'cursor-pointer opacity-100'
        }
        ${disabled ? '' : 'hover:bg-white/[0.08] hover:rounded'}
      `}
    >
      <img src={logo} className="rounded-full w-10 h-10" />
      {label}
    </div>
  );
};

import { TrainingCompletion } from '@/__generated__/gql/graphql';
import { DateTime } from 'luxon';

export const buildLinkedInAddToProfileUrl = (
  completion: TrainingCompletion | null
): string => {
  if (!completion) return '';

  const now = DateTime.now();
  const currentYear = now.year;
  const currentMonth = now.month;
  const certUrl = 'https://www.trail-ml.com/';

  const expirationDate = now.plus({ years: 2 });

  const trainingTitle = completion.training?.title || 'Literacy Training';
  const certId = completion.certificateId || completion.id;

  const params = new URLSearchParams({
    startTask: 'CERTIFICATION_NAME',
    name: `${trainingTitle} Training`,
    organizationId: '92925089',
    issueYear: currentYear.toString(),
    issueMonth: currentMonth.toString(),
    expirationYear: expirationDate.year.toString(),
    expirationMonth: expirationDate.month.toString(),
    certUrl,
    certId,
  });

  return `https://www.linkedin.com/profile/add?${params.toString()}`;
};

import { gql, useQuery } from '@apollo/client';
import { GetExportedPdfsQuery } from '../__generated__/gql/graphql';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { formatDateTime } from '../utils/dateTimeHelpers';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

const GET_EXPORTED_PDFS = gql(`
    query GetExportedPdfs {
    allExportedPdfs {
        id
        fileName
        lastModified
        versionedFile{
            url
            }
        }
    }
`);

export default function ExportedDocumentList() {
  const { data, loading } = useQuery<GetExportedPdfsQuery>(GET_EXPORTED_PDFS);

  function handleDownload(url: string) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'document.pdf');
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="w-full py-12">
      <h5>Exported Documents</h5>
      {loading ? (
        <Skeleton className="mt-2 h-[150px]" />
      ) : data?.allExportedPdfs?.length === 0 ? (
        <p className="mt-2">
          No exported documents yet. Generated documents will be listed here
          after you export them.
        </p>
      ) : (
        <div className="w-full px-0 mt-2 rounded-md  border-dark-blue-300">
          <Table>
            <TableHeader>
              <TableRow className="border-dark-blue-200">
                <TableHead className="text-white">Document Name</TableHead>
                <TableHead className="text-white">Exported at</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data?.allExportedPdfs?.map(pdf => (
                <TableRow
                  className="border-dark-blue-300 border-b-2 rounded-md hover:bg-dark-blue-300/50 cursor-pointer"
                  key={pdf?.id}
                  onClick={() => handleDownload(pdf?.versionedFile?.url ?? '')}
                >
                  <TableCell className="flex gap-2 items-center">
                    <InsertDriveFileIcon />
                    {pdf?.fileName}
                  </TableCell>
                  <TableCell>{formatDateTime(pdf?.lastModified)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
}

import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import InfoIcon from '@mui/icons-material/Info';
import { RequirementAuditStatus } from '@/__generated__/gql/graphql';
import { colorThemes } from '../theme';

export const getAuditStatusIcon = (status: RequirementAuditStatus) => {
  switch (status) {
    case RequirementAuditStatus.MajorNonConformity:
      return <ErrorIcon sx={{ color: colorThemes.GREY_600 }} />;
    case RequirementAuditStatus.MinorNonConformity:
      return <WarningIcon sx={{ color: colorThemes.GREY_600 }} />;
    case RequirementAuditStatus.Observation:
      return <InfoIcon sx={{ color: colorThemes.GREY_600 }} />;
    case RequirementAuditStatus.NotReviewed:
    default:
      return <HourglassEmptyIcon sx={{ color: colorThemes.GREY_600 }} />;
  }
};

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { Notification } from '../__generated__/gql/graphql';
import { Check, CheckCheck, ExternalLink } from 'lucide-react';
import { Card, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Badge } from '../components/ui/badge';
import { cn } from '../lib/utils';
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from '../components/ui/tabs';
import { Collapsible, CollapsibleContent } from '../components/ui/collapsible';

const MARK_NOTIFICATIONS_AS_READ_MUTATION = gql`
  mutation MarkNotificationsAsRead($userId: ID!, $notificationId: ID) {
    markNotificationsAsRead(userId: $userId, notificationId: $notificationId) {
      user {
        notification {
          id
          name
          description
          isRead
          actionUrl
        }
      }
    }
  }
`;

export default function UserNotifications({
  notifications,
  userId,
}: {
  notifications: Notification[];
  userId: string;
}) {
  const [tabValue, setTabValue] = useState('unread');

  const [markNotificationsAsRead] = useMutation(
    MARK_NOTIFICATIONS_AS_READ_MUTATION
  );

  const handleMarkAllAsRead = () => {
    markNotificationsAsRead({
      variables: { userId },
      optimisticResponse: {
        __typename: 'Mutation',
        markNotificationsAsRead: {
          __typename: 'MarkNotificationsAsReadPayload',
          user: {
            __typename: 'User',
            notification: notifications.map(notification => ({
              ...notification,
              isRead: true,
              __typename: 'Notification',
            })),
          },
        },
      },
    });
  };

  const handleMarkAsRead = (notificationId: string) => {
    markNotificationsAsRead({
      variables: { userId, notificationId },
      optimisticResponse: {
        __typename: 'Mutation',
        markNotificationsAsRead: {
          __typename: 'MarkNotificationsAsReadPayload',
          user: {
            __typename: 'User',
            notification: notifications.map(notification => {
              if (notification.id === notificationId) {
                return {
                  ...notification,
                  isRead: true,
                  __typename: 'Notification',
                };
              }
              return {
                ...notification,
                __typename: 'Notification',
              };
            }),
          },
        },
      },
    });
  };

  const unreadNotifications = notifications.filter(n => !n.isRead);
  const readNotifications = notifications.filter(n => n.isRead);
  return (
    <Card className="h-full border-none">
      <CardContent className="p-4 h-full flex flex-col">
        <div className="mb-4">
          <Tabs
            defaultValue={tabValue}
            onValueChange={setTabValue}
            className="w-full"
          >
            <TabsList className="bg-transparent mb-2">
              <TabsTrigger
                value="unread"
                className="text-yellow-400 data-[state=active]:bg-dark-blue-200"
              >
                <div className="flex items-center">
                  Open
                  {unreadNotifications.length > 0 && (
                    <Badge className="ml-2 bg-yellow-400 text-dark-blue-200">
                      {unreadNotifications.length}
                    </Badge>
                  )}
                </div>
              </TabsTrigger>
              <TabsTrigger
                value="read"
                className="text-yellow-400 data-[state=active]:bg-dark-blue-200"
              >
                Done ({readNotifications.length})
              </TabsTrigger>
            </TabsList>

            <TabsContent value="unread" className="mt-0">
              {unreadNotifications.length > 0 ? (
                unreadNotifications.map(notification => (
                  <NotificationCard
                    key={notification.id}
                    notification={notification}
                    onMarkAsRead={handleMarkAsRead}
                  />
                ))
              ) : (
                <p className="text-white">No open notifications</p>
              )}
            </TabsContent>

            <TabsContent value="read" className="mt-0">
              {readNotifications.length > 0 ? (
                readNotifications.map(notification => (
                  <NotificationCard
                    key={notification.id}
                    notification={notification}
                    onMarkAsRead={handleMarkAsRead}
                  />
                ))
              ) : (
                <p className="text-white">No read notifications</p>
              )}
            </TabsContent>
          </Tabs>
        </div>

        {/* Mark All as Read Button */}
        <div className="text-right pt-4 border-t border-dark-blue-200">
          <Button
            onClick={handleMarkAllAsRead}
            disabled={unreadNotifications.length === 0 || tabValue === 'read'}
          >
            <CheckCheck className=" h-5 w-5 text-black" />
            Mark All as Read
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

const NotificationCard = ({
  notification,
  onMarkAsRead,
}: {
  notification: Notification;
  onMarkAsRead: (id: string) => void;
}) => {
  const [visible, setVisible] = useState(true);

  const handleMarkAsReadClick = () => {
    if (!notification.isRead) {
      setVisible(false);
      setTimeout(() => {
        onMarkAsRead(notification.id);
      }, 300);
    }
  };

  return (
    <Collapsible open={visible} className="transition-all duration-300">
      <CollapsibleContent>
        <div
          className={cn(
            'flex items-center justify-between p-4 rounded-xl mb-3 shadow-md transition-all duration-300 gap-4 border-l-6 group relative',
            notification.isRead ? 'bg-dark-blue-300' : 'bg-dark-blue-200',
            notification.isRead ? 'border-l-[#153554]' : 'border-l-yellow-400'
          )}
        >
          <div>
            <p
              className={cn(
                'text-lg font-bold',
                notification.isRead ? 'text-grey-200' : 'text-white'
              )}
            >
              {notification.name}
            </p>
            <p className="text-sm mt-1 text-muted-foreground">
              {notification.description}
            </p>
          </div>

          <div
            className="items-center w-[185px] flex gap-3 absolute justify-end right-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 h-full gradient-right-to-left"
            style={
              {
                '--gradient-color': notification.isRead
                  ? 'var(--color-dark-blue-300)'
                  : 'var(--color-dark-blue-200)',
                '--gradient-start': '70%',
                '--gradient-end': '100%',
              } as React.CSSProperties
            }
          >
            <div className="flex gap-2 flex-col items-end">
              {!notification.isRead && (
                <Button
                  size="sm"
                  variant="ghost"
                  className="text-[12px] font-medium"
                  onClick={e => {
                    e.stopPropagation();
                    handleMarkAsReadClick();
                  }}
                >
                  Mark as Read
                  <Check className="h-4 w-4" />
                </Button>
              )}

              <Button
                size="sm"
                variant="ghost"
                className="text-[12px] font-medium"
                onClick={e => e.stopPropagation()}
              >
                <Link to={notification.actionUrl || '/'} className="flex">
                  View
                  <ExternalLink className="ml-2 h-4 w-4" />
                </Link>
              </Button>
            </div>
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};
